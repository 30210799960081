import { graphql } from '@apollo/client/react/hoc';
import Bugsnag from '@bugsnag/js';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Button, Confirm, Grid, Message, Modal } from 'semantic-ui-react';

import DeleteCompanyMutation from '../../../../../graphql/mutations/delete-company.graphql';
import UploadFileMutation from '../../../../../graphql/mutations/upload-file.graphql';
import UpsertCompanyMenuItemsMutation from '../../../../../graphql/mutations/upsert-company-menu-items.graphql';
import UpsertCompanyMutation from '../../../../../graphql/mutations/upsert-company.graphql';
import CompaniesQuery from '../../../../../graphql/queries/all-companies.graphql';
import EditForm from '../forms/edit';
import LogoForm from '../forms/logo';
import MenuItemsForm from '../forms/menu-items';

@withRouter
@graphql(DeleteCompanyMutation, {
  name: 'deleteCompany',
  options: {
    refetchQueries: [{ query: CompaniesQuery }]
  }
})
@graphql(UploadFileMutation, {
  name: 'uploadFile'
})
@graphql(UpsertCompanyMutation, {
  name: 'upsertCompany',
  options: {
    refetchQueries: [{ query: CompaniesQuery }]
  }
})
@graphql(UpsertCompanyMenuItemsMutation, {
  name: 'upsertCompanyMenuItems'
})
class AdminCompanyEditDialog extends Component {
  static propTypes = {
    deleteCompany: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    uploadFile: PropTypes.func.isRequired,
    upsertCompany: PropTypes.func.isRequired,
    upsertCompanyMenuItems: PropTypes.func.isRequired
  };

  state = {
    data: null,
    deleting: false,
    error: null,
    file: null,
    isValid: false,
    submitting: false
  };

  render() {
    const { location, open, onClose } = this.props;
    const { isValid, submitting } = this.state;

    const search = qs.parse(location.search, { ignoreQueryPrefix: true });
    const isNew = !search.id;

    return (
      <>
        <Modal
          className="company-edit-dialog"
          closeIcon
          open={open}
          onClose={onClose}>
          <Modal.Header>{isNew ? 'Create' : 'Edit'} Company</Modal.Header>
          <Modal.Content>
            {this._renderError()}
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <LogoForm
                    onError={this._onError}
                    onFileSelect={this._onFileSelect}
                    submitting={submitting}
                  />
                </Grid.Column>
                <Grid.Column width={12}>
                  <EditForm
                    onDataChange={this._onDataChange}
                    onValidate={this._onValidate}
                    submitting={submitting}
                  />
                  <MenuItemsForm
                    onChange={this._onMenuItemsChange}
                    submitting={submitting}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            {!isNew && (
              <Button
                disabled={submitting}
                floated="left"
                onClick={this._onClickDelete}>
                Delete
              </Button>
            )}
            <Button disabled={submitting} onClick={onClose}>
              Nevermind
            </Button>
            <Button
              primary
              disabled={!isValid || submitting}
              loading={submitting}
              onClick={this._onSubmit}>
              {isNew ? 'Create' : 'Update'}
            </Button>
          </Modal.Actions>
        </Modal>
        {this._renderConfirmDelete()}
      </>
    );
  }

  _renderError() {
    const { error } = this.state;

    if (!error) {
      return null;
    }

    return <Message negative>{error.message}</Message>;
  }

  _renderConfirmDelete() {
    const { deleting } = this.state;

    return (
      <Confirm
        open={deleting}
        content="Are you sure you want to delete this channel?"
        onCancel={() => this.setState({ deleting: false })}
        onConfirm={this._onDelete}
      />
    );
  }

  _onError = (error) => {
    this.setState({ error });
  };

  _onFileSelect = (file) => {
    this.setState({ file });
  };

  _onDataChange = (data) => {
    this.setState({ data });
  };

  _onValidate = (errors) => {
    this.setState({ isValid: isEmpty(errors) });
  };

  _onClickDelete = () => {
    this.setState({ deleting: true });
  };

  _onDelete = () => {
    const { deleteCompany, onClose } = this.props;

    const search = qs.parse(location.search, { ignoreQueryPrefix: true });

    const variables = {
      id: search.id
    };

    this.setState({ error: null, submitting: true });
    deleteCompany({ variables })
      .then(() => {
        this.setState({ submitting: false }, onClose);
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
      });
  };

  _onMenuItemsChange = (menuItems) => {
    this.setState({ menuItems });
  };

  _onSubmit = () => {
    const {
      location,
      onClose,
      upsertCompany,
      upsertCompanyMenuItems
    } = this.props;
    const { data, file, menuItems } = this.state;

    const search = qs.parse(location.search, { ignoreQueryPrefix: true });

    const variables = {
      id: search.id || '',
      ...data
    };

    this.setState({ error: null, submitting: true });
    const fileRequest = file
      ? this._uploadFile().then((img) => {
          if (img) {
            variables.logoUrl = img.url;
          }
        })
      : Promise.resolve();

    fileRequest
      .then(() => upsertCompany({ variables }))
      .then(({ data: { upsertCompany } }) => {
        if (!menuItems) {
          return null;
        }
        const removedMenuItems = upsertCompany.menuItems.filter(
          (menuItem) => !menuItems.find((m) => m.id === menuItem.id)
        );
        const variables = {
          id: upsertCompany.id,
          delete: removedMenuItems.map(({ id }) => ({ id })),
          upsert: menuItems.map(({ id, label, url }) => ({
            where: { id: id || '' },
            create: { label, url },
            update: { label, url }
          }))
        };
        return upsertCompanyMenuItems({ variables });
      })
      .then(() => {
        this.setState({ submitting: false }, onClose);
        return null;
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'AdminCompanyEditDialog._onSubmit';
        });
      });
  };

  _uploadFile() {
    const { uploadFile } = this.props;
    const { file } = this.state;

    const variables = { file };
    return uploadFile({ variables }).then(({ data }) => {
      this.setState({ file: null, filePreview: null });
      return data.uploadFile;
    });
  }
}
export default AdminCompanyEditDialog;
