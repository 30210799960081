import './index.css';

import React, { Component } from 'react';

import { ROLES } from '../../../consts';
import withUser from '../../hoc/with-user';
import Controls from './controls';
import Sessions from './sessions';

@withUser({ authenticated: ROLES.GUIDE, redirect: false })
class Presence extends Component {
  render() {
    return (
      <>
        <Controls />
        <Sessions />
      </>
    );
  }
}
export default Presence;
