import { useMutation } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@windmill/react-ui';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';

import ApolloClient from '../../../../../apollo';
import CancelSessionMutation from '../../../../../graphql/mutations/cancel-session.graphql';
import CreateSessionMutation from '../../../../../graphql/mutations/create-session.graphql';
import SessionUpdatedSubscription from '../../../../../graphql/subscriptions/session-updated.graphql';
import history from '../../../../../history';
import * as tracker from '../../../../../tracker';
import useUser from '../../../../hooks/use-user';
import Avatar from '../../../../ui/avatar';
import ErrorDialog from '../../../../ui/error-dialog';
import GuideContext from '../../guide-context';

// Wait a short while before actually creating the requested session
// Gives the user a chance to cancel if the button was clicked by mistake
const TIMEOUT = 5000;

function RequestingDialog(props) {
  const { guide, isOpen, onClose } = props;

  const [error, setError] = useState(null);
  const [session, setSession] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [timeout, setTimeout] = useState(null);

  const { data: userData } = useUser();

  const [cancelSession] = useMutation(CancelSessionMutation);
  const [createSession] = useMutation(CreateSessionMutation);

  const cleanupTimeout = useCallback(() => {
    if (timeout) {
      window.clearTimeout(timeout);
      setTimeout(null);
    }
  }, [timeout]);

  const onCreateClick = useCallback(() => {
    cleanupTimeout();

    const { User } = userData;

    const queryString = qs.parse(location.search, {
      arrayLimit: 512,
      ignoreQueryPrefix: true
    });
    const topicIds = queryString.topicId || [];

    const variables = {
      clientId: User.id,
      guideId: guide.id,
      status: 'REQUESTING',
      topicConnections: topicIds.map((id) => ({ id })),
      userId: User.id
    };

    setError(null);
    setSubmitting(true);

    createSession({ variables })
      .then(({ data }) => {
        setSession(data.bookSession);
        setSubmitting(false);

        tracker.event('requestCall', 1);
      })
      .catch((error) => {
        setError(error);
        setSubmitting(false);

        tracker.event('requestCall', 0);

        Bugsnag.notify(error, function (event) {
          event.context = 'RequestingDialog.createSession';
          event.request.variables = variables;
        });
      });
  }, [createSession, guide, userData, cleanupTimeout]);

  useEffect(() => {
    if (timeout) {
      return false;
    }
    if (isOpen) {
      setTimeout(window.setTimeout(onCreateClick, TIMEOUT));
      return cleanupTimeout;
    }
  }, [isOpen, onCreateClick, cleanupTimeout, timeout]);

  useEffect(() => {
    if (session) {
      const subscription = ApolloClient.subscribe({
        query: SessionUpdatedSubscription,
        variables: {
          id: session.id
        }
      }).subscribe({
        next({ data }) {
          if (!data.session) {
            return;
          }
          const { node } = data.session;

          setSession(node);

          if (node.status === 'CONNECTING') {
            history.push({
              pathname: `/session/${session.id}`,
              search: ''
            });
            return;
          }
        }
      });

      return function cleanup() {
        subscription.unsubscribe();
      };
    }
  }, [session]);

  function onCancelClick() {
    if (!session) {
      onClose();
      return;
    }

    const variables = {
      id: session.id
    };

    setError(null);
    setSubmitting(true);

    cancelSession({ variables })
      .then(() => {
        setSubmitting(false);

        onClose();
      })
      .catch((error) => {
        setError(error);
        setSubmitting(false);

        Bugsnag.notify(error, function (event) {
          event.context = 'RequestingDialog.cancelSession';
          event.request.variables = variables;
        });
      });
  }

  function getStatus() {
    return session ? session.status : 'REQUESTING';
  }

  function renderHeader() {
    switch (getStatus()) {
      case 'REQUESTING':
        return <>Hang on, we&apos;re ringing {guide.firstName}</>;
      case 'MISSED':
        return <>{guide.firstName} missed your request.</>;
    }
  }

  const avatarSize = getStatus() === 'MISSED' ? '150px' : '250px';

  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalHeader className="text-center">{renderHeader()}</ModalHeader>
        <ModalBody className="p-4 text-center">
          <Avatar
            key={`avatar-${getStatus().toLowerCase()}`}
            style={{ width: avatarSize, height: avatarSize }}
            user={guide}
          />
          {getStatus() === 'MISSED' && (
            <p className="mt-4 text-lg">
              Would you like to ring again or try later?
            </p>
          )}
        </ModalBody>
        <ModalFooter className="justify-center pb-8">
          {(!session || session.status === 'REQUESTING') && (
            <button
              disabled={submitting}
              size="large"
              className="align-bottom inline-flex items-center justify-center cursor-pointer rounded-full transition-colors duration-150 focus:outline-none py-3 px-10 text-lg rounded-lg text-white border border-transparent active:opacity-90 hover:opacity-90 bg-red-600"
              onClick={onCancelClick}>
              <i className="icon lineawesome large phone slash" /> Cancel
            </button>
          )}
          {session && session.status === 'MISSED' && (
            <>
              <Button
                layout="outline"
                disabled={submitting}
                onClick={() => {
                  setSession(null);
                  onClose();
                }}>
                Try Later
              </Button>
              <Button
                layout="outline"
                disabled={submitting}
                onClick={onCreateClick}>
                <i className="icon lineawesome large phone" /> Ring Again
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
      <ErrorDialog
        error={error}
        onClose={() => {
          setError(null);
        }}
      />
    </>
  );
}
RequestingDialog.propTypes = {
  guide: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string.isRequired
  }),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};
function RequestingDialogWithGuideContext(props) {
  return (
    <GuideContext.Consumer>
      {(guide) => <RequestingDialog guide={guide} {...props} />}
    </GuideContext.Consumer>
  );
}
export default RequestingDialogWithGuideContext;
