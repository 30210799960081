import { graphql } from '@apollo/client/react/hoc';
import { getMainDefinition } from '@apollo/client/utilities';
import React from 'react';
import { Button, Icon, Message } from 'semantic-ui-react';

const ERRORS = {
  FAILED_TO_FETCH: 'Failed to fetch'
};

const FRIENDLY_MESSAGES = {
  [ERRORS.FAILED_TO_FETCH]:
    "Couldn't connect to the server, you may need to check your network settings and try again."
};

const refetches = [];

export default function (document, operationOptions = {}, options = {}) {
  const { kind, operation } = getMainDefinition(document);

  if (kind !== 'OperationDefinition' || operation !== 'query') {
    return graphql(document, operationOptions);
  }

  const name = operationOptions.name || 'data';

  return function componentWrapper(Component) {
    @graphql(document, operationOptions)
    class MyGraphQL extends React.Component {
      static displayName = `MyGraphQL(${
        Component.displayName || Component.name
      })`;

      state = {
        fetching: false
      };

      UNSAFE_componentWillMount() {
        this._addRefetch(this.props);
        window.addEventListener('offline', this._onOnlineChange);
        window.addEventListener('online', this._onOnlineChange);
      }

      UNSAFE_componentWillUpdate(nextProps) {
        this._removeRefetch(this.props);
        this._addRefetch(nextProps);
      }

      componentWillUnmount() {
        this._removeRefetch(this.props);
        window.removeEventListener('offline', this._onOnlineChange);
        window.removeEventListener('online', this._onOnlineChange);
      }

      _onOnlineChange = () => {
        this.forceUpdate();
      };

      _addRefetch(props) {
        const data = props[name];

        if (!data) {
          return;
        }

        refetches.push(data.refetch);
      }

      _removeRefetch(props) {
        const data = props[name];

        if (!data) {
          return;
        }

        const index = refetches.indexOf(data.refetch);
        if (index >= 0) {
          refetches.splice(index, 1);
        }
      }

      _getMessage(error) {
        if (error.networkError) {
          return this._getMessage(error.networkError);
        }
        if (error.message === ERRORS.FAILED_TO_FETCH) {
          if (navigator.onLine === false) {
            return (
              <p>
                You are currently offline.
                <br />
                <Icon name="wifi" className="lineawesome" size="large" />
                <br />
                Please check your network configuration to restore your
                connection.
              </p>
            );
          }
        }
        return FRIENDLY_MESSAGES[error.message] || error.message;
      }

      render() {
        const data = this.props[name];

        if (data && data.error && !options.hideError) {
          return this._renderError(data.error);
        }

        return <Component {...this.props} />;
      }

      _renderError(error) {
        const { fetching } = this.state;

        const message = this._getMessage(error);

        return (
          <div style={{ textAlign: 'center' }}>
            <Message negative compact>
              <p>{message}</p>
              <Button
                primary
                fluid
                loading={fetching}
                disabled={fetching || navigator.onLine === false}
                onClick={this._refetch}>
                <Icon name="refresh" size="large" />
                Retry
              </Button>
            </Message>
          </div>
        );
      }

      _refetch = () => {
        const start = new Date();
        this.setState({ fetching: true });
        Promise.all(refetches.map((refetch) => refetch()))
          .then(() => {
            this.setState({ fetching: false });
          })
          .catch(() => {
            const end = new Date();
            const diff = end - start;
            window.setTimeout(() => {
              this.setState({ fetching: false });
            }, Math.max(0, 500 - diff));
          });
      };
    }

    return MyGraphQL;
  };
}
