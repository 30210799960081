import PropTypes from 'prop-types';
import React, { Component } from 'react';

class VolumeIndicator extends Component {
  static propTypes = {
    track: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    if (window.AudioContext) {
      this._audioContext = new AudioContext();
    }

    this.state = {
      instant: 0
    };
  }

  componentDidMount() {
    this._connect();
  }

  componentDidUpdate(prevProps) {
    const { track } = this.props;

    if (track === prevProps.track) {
      return;
    }

    this._connect();
  }

  componentWillUnmount() {
    this._disconnect();
  }

  _connect() {
    const { track } = this.props;

    if (!track || !this._audioContext) {
      return;
    }

    this._disconnect();

    const mediaStream = new MediaStream([track]);
    this._mediaStreamSource = this._audioContext.createMediaStreamSource(
      mediaStream
    );
    this._scriptProcessor = this._audioContext.createScriptProcessor(
      2048,
      1,
      1
    );
    this._scriptProcessor.onaudioprocess = this._onAudioProcess;
    this._mediaStreamSource.connect(this._scriptProcessor);
    this._scriptProcessor.connect(this._audioContext.destination);
  }

  _disconnect() {
    if (this._mediaStreamSource) {
      this._mediaStreamSource.disconnect(this._scriptProcessor);
    }
    if (this._scriptProcessor) {
      this._scriptProcessor.disconnect(this._audioContext.destination);
      this._scriptProcessor.onaudioprocess = null;
    }
  }

  _onAudioProcess = (event) => {
    const data = event.inputBuffer.getChannelData(0);
    const sum = data.reduce((acc, value) => acc + value * value, 0);
    const instant = Math.sqrt(sum / data.length);

    this.setState({ instant });
  };

  render() {
    const { instant } = this.state;

    if (!this._audioContext) {
      return null;
    }

    return (
      <div className="volume-indicator-component">
        <div
          className="indicator"
          style={{ height: `${5 + Math.floor(instant * 33)}px` }}
        />
        <div
          className="indicator"
          style={{ height: `${5 + Math.floor(instant * 100)}px` }}
        />
        <div
          className="indicator"
          style={{ height: `${5 + Math.floor(instant * 33)}px` }}
        />
      </div>
    );
  }
}
export default VolumeIndicator;
