import get from 'lodash/get';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import useUser from '../../hooks/use-user';
import GuideContext from '../guide/guide-context';
import { CALL_LENGTH } from '../session';
import CalendarSelector from './calendar-selector';
import TimezoneSelector from './timezone-selector';

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm';
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

function mergeDateAndTime(date, time) {
  const d = moment(date.format(DATE_FORMAT), DATE_FORMAT);
  const t = moment(time.format(TIME_FORMAT), TIME_FORMAT);
  return d.hours(t.hours()).minutes(t.minutes()).toDate();
}

function inPreferedTimezone(d, timezone) {
  const m = moment(d);
  const guess = moment.tz.guess();
  if (timezone !== guess) {
    const localStr = m.format(DATE_TIME_FORMAT);
    return moment.tz(localStr, DATE_TIME_FORMAT, timezone);
  }
  return m;
}

function Scheduler(props) {
  const { children, guide, header, onChange, subHeader } = props;

  const [reason, setReason] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [timezone, setTimezone] = useState(props.timezone || moment.tz.guess());

  const { data: userData } = useUser();

  function onCalendarChange(date, time) {
    setSelectedDate(date);
    setSelectedTime(time);
  }

  function onTimezoneChange(timezone) {
    setTimezone(timezone);
  }

  function onReasonChange({ target: { value } }) {
    setReason(value);
  }

  useEffect(
    function () {
      if (!userData) {
        return;
      }
      const timezone = get(userData, 'User.timezone');
      if (!timezone) {
        return;
      }
      if (props.timezone) {
        return;
      }
      setTimezone(timezone);
    },
    [userData, props.timezone]
  );

  useEffect(() => {
    if (!selectedTime) {
      onChange({ date: selectedDate, reason });
      return;
    }

    const dateTime = mergeDateAndTime(
      moment(selectedDate, DATE_FORMAT),
      moment(selectedTime, TIME_FORMAT)
    );
    const scheduledTime = inPreferedTimezone(dateTime, timezone).utc().format();

    onChange({
      date: selectedDate,
      reason,
      scheduledTime,
      time: selectedTime
    });
  }, [onChange, reason, selectedDate, selectedTime, timezone]);

  return (
    <div className="scheduler-component flex flex-col sm:flex-row justify-stretch">
      <div className="p-8">
        <h2 className="mb-6 text-3xl font-bold">{header}</h2>
        <span className="text-xl">{subHeader}</span>
        <CalendarSelector
          guide={guide}
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          onChange={onCalendarChange}
          timezone={timezone}
        />
        <TimezoneSelector
          selectedTimezone={timezone}
          onChange={onTimezoneChange}
        />
      </div>
      <div className="p-8 flex-grow flex flex-col bg-blue-50">
        <h3 className="mb-4 text-xl leading-10">Session summary</h3>
        <span className="block text-lg">
          <i className="icon lineawesome calendar-check outline large text-gray-600" />
          {selectedDate
            ? moment(selectedDate).format('dddd, MMM Do')
            : 'No date selected'}
          {selectedTime
            ? ` - ${moment(selectedTime).format('h:mm A')} to ${moment(
                selectedTime
              )
                .add(CALL_LENGTH, 'minutes')
                .format('h:mm A')}`
            : null}
        </span>
        <label className="my-3 flex-grow flex flex-col space-y-2">
          <span>Anything else you want to share with me?</span>
          <textarea
            className="flex-grow p-2 border border-gray-300 rounded-md"
            value={reason}
            onChange={onReasonChange}
          />
        </label>
        {children}
      </div>
    </div>
  );
}
Scheduler.propTypes = {
  children: PropTypes.node,
  guide: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string
  }),
  header: PropTypes.node,
  onChange: PropTypes.func,
  subHeader: PropTypes.node,
  timezone: PropTypes.string
};
function SchedulerWithGuideContext(props) {
  return (
    <GuideContext.Consumer>
      {(guide) => <Scheduler guide={guide} {...props} />}
    </GuideContext.Consumer>
  );
}
export default SchedulerWithGuideContext;
