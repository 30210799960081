import { Button, Dropdown, DropdownItem, Input } from '@windmill/react-ui';
import debounce from 'lodash/debounce';
import delay from 'lodash/delay';
import isBoolean from 'lodash/isBoolean';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import countries from 'react-select-country-list';

import { useEditing } from './actions';

const selectedRef = React.createRef();

const countryData = countries().getData();

// function sortBy(a, b) {
//   if (a.value === 'US') {
//     return -1;
//   }
//   if (b.value === 'US') {
//     return 1;
//   }
//   return 0;
// }

function GuideBasicInfo(props) {
  const { data, onChange } = props;

  const [isEditing] = useEditing();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(
    function () {
      if (!isOpen || !selectedRef.current) {
        return;
      }
      const container = selectedRef.current.parentNode.parentNode;
      // Delay to work around the focus lock used by windmill
      delay(function () {
        container.scrollTop = selectedRef.current.offsetTop - 8;
      }, 50);
    },
    [isOpen]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleDropdown = useCallback(
    debounce((value) => {
      setIsOpen(isBoolean(value) ? value : !isOpen);
    }, 50),
    [isOpen]
  );

  const onClick = useCallback(() => {
    toggleDropdown(true);
  }, [toggleDropdown]);

  const onDropdownChange = useCallback(
    (value) => {
      toggleDropdown(false);
      onChange({ country: value });
    },
    [onChange, toggleDropdown]
  );

  // const sorted = countryData.sort(sortBy);
  const selected = countryData.find((c) => c.value === data.country);

  return (
    <div
      className={`flex ${
        isEditing
          ? 'flex-col space-y-4'
          : 'flex-col items-center md:flex-row md:space-x-4 md:justify-center md:justify-start'
      } text-lg`}>
      {isEditing && (
        <>
          <div className="flex flex-row space-x-2 items-center">
            <i className="icon lineawesome map-marker" style={{ margin: 0 }} />{' '}
            <Input
              className="font-bold text-lg border"
              value={data.city}
              onChange={({ target: { value } }) => {
                onChange({ city: value });
              }}
            />
            ,{' '}
            <Input
              className="font-bold text-lg border"
              value={data.state}
              onChange={({ target: { value } }) => {
                onChange({ state: value });
              }}
            />
          </div>
          <div className="relative">
            <Button
              layout="outline"
              block
              style={{ alignItems: 'start' }}
              iconLeft={() => <i className="icon globe americas" />}
              iconRight={() => <i className="icon sort down" />}
              onClick={onClick}>
              <span className="flex-grow text-left">
                {selected ? selected.label : 'Select Country'}
              </span>
            </Button>
            <Dropdown
              className="h-40 overflow-y-scroll bottom-0"
              align="right"
              isOpen={isOpen}
              onClose={() => toggleDropdown(false)}>
              {[{ label: 'United State', value: 'US' }]
                .concat(countryData)
                .map(({ label, value }) => {
                  const isSelected = false;

                  return (
                    <DropdownItem
                      key={value}
                      ref={selectedRef}
                      className={`text-left focus:outline-none ${
                        isSelected ? 'bg-primary text-white' : ''
                      }`}
                      onClick={() => onDropdownChange(value)}>
                      {label}
                    </DropdownItem>
                  );
                })}
            </Dropdown>
          </div>
        </>
      )}
      {!isEditing && data.city && data.state && (
        <span className="font-bold">
          <i className="icon lineawesome map-marker" style={{ margin: 0 }} />{' '}
          {data.city}, {data.state}
          {data.country ? `, ${data.country}` : ''}
        </span>
      )}
      {isEditing && (
        <div className="flex flex-row space-x-2 items-center whitespace-nowrap">
          <span
            className="inline-block text-center"
            style={{ width: '1.18em' }}>
            &bull;
          </span>
          Age&nbsp;
          <Input
            className="font-bold text-lg border"
            value={data.age}
            onChange={({ target: { value } }) => {
              const str = value.replace(/\D/g, '');
              const age = str === '' ? '' : parseInt(str, 10);
              onChange({ age });
            }}
          />
        </div>
      )}
      {!isEditing && data.age && (
        <span>
          <span
            className="inline-block text-center"
            style={{ width: '1.18em' }}>
            &bull;
          </span>
          Age <span className="font-bold">{data.age}</span>
        </span>
      )}
    </div>
  );
}
GuideBasicInfo.propTypes = {
  data: PropTypes.shape({
    age: PropTypes.number,
    city: PropTypes.string,
    country: PropTypes.string,
    state: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired
};
export default GuideBasicInfo;
