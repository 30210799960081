import Bugsnag from '@bugsnag/js';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Header, Message, Segment } from 'semantic-ui-react';

import { setAuthToken } from '../../../auth';
import CreateGuideApplicationMutation from '../../../graphql/mutations/create-guide-application.graphql';
import UserGuideApplicationQuery from '../../../graphql/queries/user-guide-application.graphql';
import graphql from '../../hoc/graphql';
import withAuth0 from '../../hoc/with-auth0';
import withUser from '../../hoc/with-user';
import { friendlyError } from '../../ui/error-dialog';
import GuideApplicationForm from './guide-application-form';

@graphql(CreateGuideApplicationMutation, {
  name: 'createGuideApplication',
  options: {
    refetchQueries: [{ query: UserGuideApplicationQuery }]
  }
})
@graphql(UserGuideApplicationQuery, {
  name: 'guideApplication'
})
@withRouter
@withAuth0()
@withUser({ loader: <Segment basic loading /> })
class Onboard extends Component {
  static propTypes = {
    auth0: PropTypes.object.isRequired,
    createGuideApplication: PropTypes.func.isRequired,
    guideApplication: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        guideApplication: PropTypes.shape({
          id: PropTypes.string,
          status: PropTypes.string
        })
      })
    }).isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        step: PropTypes.string
      })
    }),
    user: PropTypes.shape({
      User: PropTypes.shape({
        guideApplication: PropTypes.shape({
          id: PropTypes.string
        }),
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          })
        )
      })
    }).isRequired
  };

  state = {
    error: null,
    submitting: false
  };

  componentDidMount() {
    this._checkRegistered();
    this._checkGuideApplication();
  }

  componentDidUpdate() {
    this._checkRegistered();
    this._checkGuideApplication();
  }

  _checkRegistered() {
    const { history } = this.props;
    const { User } = this.props.user;

    if (!User) {
      return;
    }

    if (!User.roles.length) {
      return;
    }

    history.push('/dashboard');
  }

  _checkGuideApplication() {
    const { guideApplication, history } = this.props;

    if (!guideApplication || !guideApplication.User) {
      return;
    }

    const { User } = guideApplication;
    if (User.guideApplication && User.guideApplication.status !== 'ACCEPTED') {
      history.push(`/application/${User.guideApplication.id}`);
    }
  }

  render() {
    return (
      <div className="onboard">
        {this._renderError()}
        {this._renderGuideApplication()}
      </div>
    );
  }

  _renderError() {
    const { error } = this.state;

    if (!error) {
      return null;
    }

    return (
      <Message negative aria-invalid="true">
        <p>{friendlyError(error.message)}</p>
      </Message>
    );
  }

  _renderGuideApplication() {
    const { submitting } = this.state;

    return (
      <div>
        <Header as="h1">We’d love to get to know you!</Header>
        <p>
          A representative will be in touch with you in 24 hours to help get you
          set up as a LifeGuide!
        </p>
        <div style={{ maxWidth: 400, margin: '0 auto 5rem' }}>
          <GuideApplicationForm
            submitText="Submit Application"
            submitting={submitting}
            onSubmit={this._onSubmitGuideApplication}
          />
        </div>
      </div>
    );
  }

  _onSubmitGuideApplication = (data) => {
    const { createGuideApplication, history } = this.props;

    const variables = {
      data,
      timezone: moment.tz.guess(),
      ...data
    };

    this.setState({ error: null, submitting: true });
    createGuideApplication({ variables })
      .then(
        ({
          data: {
            createGuideApplicationWithUser: { id, authToken }
          }
        }) => {
          setAuthToken(authToken).then(() => {
            this.setState({ submitting: false }, () => {
              history.push(`/application/${id}`);
            });
          });
        }
      )
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'Onboard._onSubmitGuideApplication';
          event.request.variables = variables;
        });
      });
  };
}
export default Onboard;
