import { Button, Dropdown, DropdownItem } from '@windmill/react-ui';
import debounce from 'lodash/debounce';
import isBoolean from 'lodash/isBoolean';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { connect } from '../../../auth0';
import history from '../../../history';
import useUser from '../../hooks/use-user';
import Avatar from '../../ui/avatar';
import LoadingSpinner from '../../ui/loading-spinner';
import MenuItems from './menu-items';

export function getMenuItems(user) {
  const isRegistered = user && !!user.roles.length;

  function byRole({ roles, showForUser }) {
    if (showForUser) {
      return showForUser(user);
    }
    if (!isRegistered) {
      return roles && roles.some((role) => role === null);
    }
    return (
      roles &&
      roles.some((role) => {
        return user.roles.some((r) => r.name === role);
      })
    );
  }

  return MenuItems.filter(byRole);
}

const MenuItemPropTypes = {
  description: PropTypes.string,
  hash: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
  showForUser: PropTypes.func,
  url: PropTypes.string
};

function LinkMenuItem(menuItem) {
  const location = useLocation();

  const { description, hash, icon, label, name, url } = menuItem;
  const path = name === 'register' ? location.pathname : menuItem.path;
  const search =
    name === 'register' ? qs.stringify({ dialog: 'REGISTER' }) : '';
  const options = { pathname: path, search: search, hash: hash || '' };

  const href = path !== undefined ? history.createHref(options) : url;

  return (
    <a
      href={href}
      title={description}
      rel="noreferrer"
      target={url ? '_blank' : 'self'}
      onClick={(event) => {
        if (path !== undefined) {
          event.preventDefault();
          history.push(options);
        }
      }}
      className="flex flex-row items-center whitespace-nowrap mx-5">
      {icon && isFunction(icon) ? icon() : icon}
      {label}
    </a>
  );
}
LinkMenuItem.propTypes = MenuItemPropTypes;

function DropdownMenuItem(menuItem) {
  const { description, hash, label, name, onClick, showForUser } = menuItem;

  const path = name === 'register' ? location.pathname : menuItem.path;
  const search =
    name === 'register' ? qs.stringify({ dialog: 'REGISTER' }) : '';
  const options = { pathname: path, search: search, hash: hash || '' };

  return (
    <DropdownItem
      className={`${
        showForUser ? '' : 'sm:hidden'
      } focus:outline-none focus:bg-grey-200`}
      aria-label={description}
      onClick={(event) => {
        if (path !== undefined) {
          event.preventDefault();
          history.push(options);
        }
        onClick();
      }}>
      <span>{label}</span>
    </DropdownItem>
  );
}
DropdownMenuItem.propTypes = {
  ...MenuItemPropTypes,
  onClick: PropTypes.func.isRequired
};

export default function AppNav() {
  const { data, loading } = useUser();

  const [isOpen, setIsOpen] = useState(false);

  const menuItems = getMenuItems(data && data.User);
  const companyMenuItems =
    data && data.User
      ? data.User.companyIds.reduce((acc, companyConnection) => {
          if (!companyConnection.company) {
            return acc;
          }
          return acc.concat(
            companyConnection.company.menuItems.map(({ id, label, url }) => ({
              name: id,
              label,
              url
            }))
          );
        }, [])
      : [];
  const withCompanyMenuItems = menuItems.concat(companyMenuItems);

  function renderMenuItem(menuItem) {
    return <LinkMenuItem key={menuItem.name} {...menuItem} />;
  }

  function renderMainMenu() {
    const primaryMenuItems = withCompanyMenuItems.filter(
      (menuItem) => !menuItem.showForUser
    );

    return (
      <div className="w-8/12 hidden sm:flex flex-row flex-wrap justify-center items-center">
        {primaryMenuItems.map(renderMenuItem)}
      </div>
    );
  }

  function renderDropdownMenuItem(menuItem) {
    return (
      <DropdownMenuItem
        key={menuItem.name}
        {...menuItem}
        onClick={onCloseDropdown}
      />
    );
  }

  function renderUserMenu() {
    if (loading) {
      return <LoadingSpinner className="w-8 h-8" />;
    }

    return (
      <>
        {!(data && data.User) && (
          <div className="flex flex-row space-x-2">
            <Button
              layout="link"
              className="hidden sm:block whitespace-nowrap"
              onClick={() => {
                connect(null, '/login', {
                  prompt: 'login'
                });
              }}>
              Sign In
            </Button>
            <Button
              layout="outline"
              className="hidden sm:block whitespace-nowrap"
              onClick={() => {
                history.push({
                  pathname: location.pathname,
                  search: qs.stringify({
                    dialog: 'REGISTER'
                  })
                });
              }}>
              Sign Up
            </Button>
          </div>
        )}
        <div className="relative">
          <button
            className="focus:outline-none"
            aria-label="Menu"
            aria-haspopup="true"
            onClick={toggleDropdown}>
            {data && data.User && (
              <Avatar
                user={data.User}
                className="hidden sm:block"
                style={{ width: '40px', height: '40px' }}
              />
            )}
            <div className="sm:hidden">
              <i className="icon lineawesome large bars" />
            </div>
          </button>
          <Dropdown
            className="z-50"
            isOpen={isOpen}
            onClose={onCloseDropdown}
            align="right">
            {withCompanyMenuItems.map(renderDropdownMenuItem)}
          </Dropdown>
        </div>
      </>
    );
  }

  const toggleDropdown = debounce(function (value) {
    setIsOpen(isBoolean(value) ? value : !isOpen);
  }, 50);

  function onCloseDropdown() {
    toggleDropdown(false);
  }

  return (
    <>
      {renderMainMenu()}
      {renderUserMenu()}
    </>
  );
}
