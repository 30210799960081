import qs from 'qs';

export const PAGE_SIZE = 20;
export const SEARCH_DEFAULTS = {
  page: 1
};

export function locationToVariables(location, page) {
  const first = PAGE_SIZE;
  const skip = page > 0 ? (page - 1) * PAGE_SIZE : 0;
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  return {
    first,
    skip,
    achievementIds: [search.id]
  };
}
