import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';

import { ACCESS_CODE_KEY } from '../../consts';
import AccessCodeByCodeQuery from '../../graphql/queries/access-code-by-code.graphql';

function getCode() {
  const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
  return queryString.accessCode || localStorage.getItem(ACCESS_CODE_KEY);
}

export default function withAccessCode(options = {}) {
  return function componentWrapper(Component) {
    @graphql(AccessCodeByCodeQuery, {
      name: 'accessCode',
      skip: () => !getCode(),
      options: () => ({ variables: { code: getCode() } })
    })
    class WithAccessCode extends React.Component {
      static displayName = `withAccessCode(${
        Component.displayName || Component.name
      })`;

      static propTypes = {
        accessCode: PropTypes.shape({
          accessCode: PropTypes.shape({
            id: PropTypes.string
          }),
          loading: PropTypes.bool.isRequired
        })
      };

      render() {
        const { accessCode } = this.props;

        if (accessCode && accessCode.loading && options.loader) {
          return options.loader;
        }

        return <Component {...this.props} />;
      }
    }

    return WithAccessCode;
  };
}
