import './topic-groups.css';

import { useQuery } from '@apollo/client';
import { Button } from '@windmill/react-ui';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import { useLocation } from 'react-router-dom';

import TopicGroupsQuery from '../../../graphql/queries/topic-groups-with-topics.graphql';
import history from '../../../history';
import LoadingSpinner from '../../ui/loading-spinner';

import { GetStartedStepTitle } from '../topic-selection/get-started-step-title';

const DEFAULT_TOPIC_GROUP_IMAGE_URL =
  'https://react.semantic-ui.com/images/wireframe/image.png';
const TOPIC_GROUP_LIMIT = 3;
const TOPIC_DISPLAY_LIMIT = 4;
const TOPIC_MOBILE_DISPLAY_LIMIT = 3;

function TopicGroup({ imageUrl, onClick, selected, title, topics }) {
  return (
    <button
      className={`topic-group ${
        selected ? 'selected' : ''
      } relative overflow-hidden py-1.5 px-6 border border-primary rounded-lg flex flex-col items-center text-left focus:outline-none`}
      onClick={onClick}>
      <div
        className={`w-24 h-24 -m-12 absolute top-0 left-0 transform rotate-45 border border-primary ${
          selected ? 'bg-primary' : ''
        }`}
      />
      {selected && (
        <i className="absolute text-white top-2 left-2 icon check" />
      )}
      <img
        src={imageUrl || DEFAULT_TOPIC_GROUP_IMAGE_URL}
        alt={title}
        className="flex flex-col object-cover mx-auto my-3 rounded-full h-100"
      />
      <h4 className="flex items-center justify-center text-2xl font-bold text-center">
        {title}
      </h4>
      <div className="text-sm topics">
        <span className="block my-2">Topics Include:</span>
        <ul className="text-gray-500 list-inside">
          {topics.slice(0, TOPIC_DISPLAY_LIMIT).map((topic, i) => (
            <li
              key={topic.id}
              className={
                i >= TOPIC_MOBILE_DISPLAY_LIMIT - 1 ? 'hidden sm:list-item' : ''
              }>
              {topic.title}
            </li>
          ))}
          <li>More</li>
        </ul>
      </div>
    </button>
  );
}
TopicGroup.propTypes = {
  imageUrl: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  title: PropTypes.string,
  topics: PropTypes.array
};

export default function TopicGroups() {
  const { data, error, loading } = useQuery(TopicGroupsQuery);

  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  const selectedTopicGroupIds = search.topicGroupId || [];
  const canContinue = !!selectedTopicGroupIds.length;

  function toggleTopicGroup(topicGroup) {
    const newSelectedTopicGroupIds = [...selectedTopicGroupIds];
    const index = newSelectedTopicGroupIds.indexOf(topicGroup.id);
    if (index >= 0) {
      newSelectedTopicGroupIds.splice(index, 1);
    } else {
      newSelectedTopicGroupIds.push(topicGroup.id);
    }
    if (newSelectedTopicGroupIds.length > TOPIC_GROUP_LIMIT) {
      newSelectedTopicGroupIds.shift();
    }

    history.replace({
      pathname: location.pathname,
      search: qs.stringify({ topicGroupId: newSelectedTopicGroupIds })
    });
  }

  function renderTopicGroup(topicGroup) {
    const isSelected = selectedTopicGroupIds.includes(topicGroup.id);

    return (
      <TopicGroup
        key={topicGroup.id}
        {...topicGroup}
        selected={isSelected}
        onClick={function () {
          toggleTopicGroup(topicGroup);
        }}
      />
    );
  }

  function renderTopicGroups() {
    if (error) {
      return <div>Error</div>;
    }

    if (loading) {
      return <LoadingSpinner className="w-48 h-48 mx-auto my-8" />;
    }

    const { topicGroups } = data;

    return (
      <div className="grid grid-cols-2 gap-4 my-8 md:grid-cols-4 sm:gap-9">
        {topicGroups.map(renderTopicGroup)}
      </div>
    );
  }

  function submit() {
    history.push({
      pathname: '/topic-selection',
      search: qs.stringify({ topicGroupId: selectedTopicGroupIds })
    });
  }

  return (
    <div
      id="topics"
      className="flex flex-col w-full max-w-screen-xl px-4 mt-24 home-topic-groups-component mb-14">
      <GetStartedStepTitle
        stepNumber={1}
        title={'Choose Your Topics'}
        description={
          <span>
            <span className="font-bold">Click to select up to 3</span> topic
            categories then click{' '}
            <span className="font-bold">Go To Next Step</span>
          </span>
        }
      />
      {renderTopicGroups()}
      <div className="text-center">
        <Button
          size="large"
          className="font-bold"
          disabled={canContinue ? '' : true}
          onClick={submit}>
          Go to Next Step
        </Button>
      </div>
    </div>
  );
}
