import './index.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Header, Icon } from 'semantic-ui-react';

import { ROLES } from '../../../../consts';
import withUser from '../../../hoc/with-user';
import Notification from '../../../ui/notification';
import Sessions from './sessions';
import SessionsRoutes from './sessions-routes';

@withRouter
@withUser({
  authenticated: [ROLES.ADMIN, ROLES.CONCIERGE, ROLES.GUIDE_ADMIN],
  showError: true
})
class ConciergeSessions extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    notification: null
  };

  render() {
    return (
      <div className="concierge-calls px-4">
        {this._renderSessions()}
        {this._renderDialogRoutes()}
      </div>
    );
  }

  _renderDialogRoutes = () => {
    return (
      <SessionsRoutes onClose={this._onCloseDialog} onSubmit={this._onSubmit} />
    );
  };

  _renderSessions() {
    return (
      <div className="calls">
        <div className="header-row">
          <Header>Sessions</Header>
          <Button primary onClick={this._navTo('create-client')}>
            <Icon name="plus" />
            New User
          </Button>
          <Button primary onClick={this._navTo('create-session')}>
            <Icon name="plus" />
            New Session
          </Button>
        </div>
        <br />
        <Sessions />
        {this._renderNotification()}
      </div>
    );
  }

  _renderNotification = () => {
    const { notification } = this.state;
    return (
      <Notification
        open={!!notification}
        onClose={() => {
          this.setState({ notification: null });
        }}>
        <Icon name="check" color="green" /> {notification}
      </Notification>
    );
  };

  _navTo = (to) => () => {
    const { history, match, location } = this.props;

    let _to = `${match.url}/${to}?${location.search}`
      .split('?')
      .filter((x) => x)
      .join('?');

    history.push(_to);
  };

  _onCloseDialog = () => {
    const { history, location } = this.props;
    const _to = `/concierge/session-scheduling?${location.search}`
      .split('?')
      .filter((x) => x)
      .join('?');
    history.push(_to);
  };

  _onSubmit = (data) => {
    this._onCloseDialog();
    this.setState({ notification: 'Record Created' });
  };
}
export default ConciergeSessions;
