import React from 'react';

import useSavedGuides from '../../hooks/use-saved-guides';

export default function MyGuidesIcon() {
  const [savedGuides] = useSavedGuides();

  return (
    <div className="relative inline-block mr-2">
      {savedGuides.length ? (
        <span className="absolute inset-0 text-center leading-7 text-xs text-white">
          {Math.min(savedGuides.length, 99)}
        </span>
      ) : null}
      <i
        className={`icon large heart ${
          savedGuides.length ? '' : 'outline'
        } text-red-400`}
        style={{ margin: 0 }}
      />
    </div>
  );
}
