import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Message } from 'semantic-ui-react';

import { CONCIERGE_PHONE_NUMBER } from '../../../consts';

export default class GenericContactError extends Component {
  static propTypes = {
    error: PropTypes.node
  };

  _renderErrorMsgTxt = () => {
    const { error } = this.props;

    if (!error) {
      return <h2>Sorry, there was an error.</h2>;
    }
    return <h2>{error}</h2>;
  };

  render() {
    let concierge_phone = (
      <a
        href={`tel:${CONCIERGE_PHONE_NUMBER}`}
        className="app-header-help-cta-tel">
        {CONCIERGE_PHONE_NUMBER}
      </a>
    );

    return (
      <div className="error form-container">
        <Container>
          <img
            src="/logo-color.png"
            alt="LifeGuides logo"
            style={{ width: '200px' }}
          />
          <Message>
            {this._renderErrorMsgTxt()}
            <div>
              <span className="app-header-help-cta-label">
                {`Connect with our Wellbeing Concierge Team™ at `}
              </span>
              {concierge_phone}
            </div>
          </Message>
        </Container>
      </div>
    );
  }
}
