import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';

import CompaniesByIdQuery from '../../graphql/queries/all-companies-by-ids.graphql';
import ClientInviteByIdQuery from '../../graphql/queries/client-invite-by-id.graphql';
import withAccessCode from './with-access-code';
import withUser from './with-user';

function getIds(props) {
  const accessCode = props.accessCode && props.accessCode.accessCode;
  const { User } = props.user;

  let ids = [];

  if (accessCode) {
    if (accessCode.company) {
      ids.push(accessCode.company.id);
    }
  }

  const clientInvite = props.clientInvite && props.clientInvite.clientInvite;
  if (clientInvite && clientInvite.company) {
    ids.push(clientInvite.company.id);
  }

  if (User && User.companyIds.length) {
    ids = ids.concat(
      User.companyIds.map((companyConnection) => companyConnection.company.id)
    );
  }

  return ids;
}

function getInviteId(props) {
  const { location } = props;

  const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
  return queryString.inviteId;
}

export default function withCompanies(options = {}) {
  return function componentWrapper(Component) {
    @withAccessCode({ loader: options.loader || null })
    @withUser({ loader: options.loader || null })
    @graphql(ClientInviteByIdQuery, {
      name: 'clientInvite',
      skip: (props) => !getInviteId(props),
      options: (props) => ({
        variables: { id: getInviteId(props) }
      })
    })
    @graphql(CompaniesByIdQuery, {
      name: 'companies',
      skip: (props) => !getIds(props).length,
      options: (props) => ({
        variables: { ids: getIds(props) }
      })
    })
    class WithCompanies extends React.Component {
      static displayName = `withCompanies(${
        Component.displayName || Component.name
      })`;

      static propTypes = {
        accessCode: PropTypes.shape({
          accessCode: PropTypes.shape({
            company: PropTypes.shape({
              id: PropTypes.string
            })
          }),
          loading: PropTypes.bool
        }),
        companies: PropTypes.shape({
          loading: PropTypes.bool,
          companies: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string
            })
          )
        }),
        location: PropTypes.object.isRequired,
        user: PropTypes.shape({
          User: PropTypes.shape({
            companyIds: PropTypes.arrayOf(
              PropTypes.shape({
                company: PropTypes.shape({
                  id: PropTypes.string
                })
              })
            )
          })
        })
      };

      render() {
        const { companies } = this.props;

        if (companies && companies.loading && options.loader) {
          return options.loader;
        }
        return <Component {...this.props} />;
      }
    }

    return WithCompanies;
  };
}
