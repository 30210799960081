import { useQuery } from '@apollo/client';
import { Pagination } from 'semantic-ui-react';
import get from 'lodash/get';
import qs from 'qs';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GuidesQuery from '../../../graphql/queries/guides-pagination.graphql';
import UserToggledPresenceSubscription from '../../../graphql/subscriptions/user-toggled-presence.graphql';
import history from '../../../history';
import ErrorMessage from '../../ui/error-message/index';
import LoadingSpinner from '../../ui/loading-spinner/index';
import Dialogs from './dialogs';
import Facets from './facets';
import Row from './row';

const PAGE_SIZE = 20;

import { GetStartedStepTitle } from '../topic-selection/get-started-step-title';

export default function GuidesList() {
  const location = useLocation();
  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  const [ageMin, ageMax] = search.age ? search.age.split(',') : [];
  const variables = {
    first: PAGE_SIZE,
    skip: search.page ? (search.page - 1) * PAGE_SIZE : 0,
    where: {
      AND: [
        ...(ageMin ? [{ age_gte: parseInt(ageMin, 10) }] : []),
        ...(ageMax ? [{ age_gte: parseInt(ageMax, 10) }] : []),
        ...(search.achievementId
          ? [{ achievements_some: { id_in: search.achievementId } }]
          : []),
        ...(search.topicId
          ? [
              {
                guideProfiles_some: {
                  status: 'PUBLISHED',
                  topics_some: { topic: { id_in: search.topicId } }
                }
              }
            ]
          : [])
      ]
    },
    orderBy: search.availabilities
      ? search.availabilities.map((str) => {
          const [days, timeOfDay] = str.split('_');
          return { days, timeOfDay };
        })
      : []
  };
  const { data, error, loading, refetch, subscribeToMore } = useQuery(
    GuidesQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables
    }
  );

  useEffect(
    function () {
      if (!data) {
        return null;
      }

      try {
        const subscription = subscribeToMore({
          document: UserToggledPresenceSubscription,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData) {
              return prev;
            }
            const { node } = subscriptionData.data.userToggledPresence;

            const index = prev.guides.findIndex((user) => user.id === node.id);

            if (index < 0) {
              return prev;
            }

            const guides = prev.guides.slice();
            const guide = guides[index];
            guides[index] = {
              ...guide,
              isPresent: node.isPresent
            };

            return {
              ...prev,
              guides
            };
          }
        });

        return function cleanUp() {
          subscription();
        };
      } catch (error) {
        /* dev error only? */
      }
    },
    [data, subscribeToMore]
  );

  function renderPagination() {
    if (!data) {
      return <></>;
    }

    const hidePaginationStyles =
      data.usersConnection.aggregate.count > PAGE_SIZE ? '' : 'hidden';
    const totalPages = Math.ceil(
      data.usersConnection.aggregate.count / PAGE_SIZE
    );

    const activePage = search.page || 1;
    const startItem = (activePage - 1) * PAGE_SIZE + 1;
    const endItem = Math.min(
      activePage * PAGE_SIZE,
      data.usersConnection.aggregate.count
    );

    return (
      <div
        className={`flex justify-between items-center ${hidePaginationStyles}`}>
        <span>
          Showing {startItem}-{endItem} of{' '}
          {data.usersConnection.aggregate.count}
        </span>
        <Pagination
          totalPages={totalPages}
          activePage={activePage}
          pointing
          secondary
          firstItem={null}
          lastItem={null}
          onPageChange={(_e, { activePage }) => {
            history.replace({
              search: qs.stringify({ ...search, page: activePage })
            });
          }}
        />
      </div>
    );
  }

  function renderGuide(user) {
    return <Row key={user.id} guide={user} />;
  }

  function renderGuides() {
    if (error) {
      return <ErrorMessage error={error} retry={() => refetch()} />;
    }
    if (loading) {
      return <LoadingSpinner className="my-24 mx-auto w-48 h-48" />;
    }
    return (
      <ol className="grid grid-rows-none my-8 gap-4">
        {data.guides.map(renderGuide)}
      </ol>
    );
  }

  const yourMatchesHtml = get(data, 'usersConnection.aggregate.count', null) !==
    null && (
    <span>
      You have{' '}
      <span className="font-bold text-blue-600">
        {data.usersConnection.aggregate.count}
      </span>{' '}
      match
      {data.guides.length === 1 ? '' : 'es'}!
    </span>
  );

  return (
    <>
      <div className="flex-grow p-8">
        <div className="flex-grow flex flex-col">
          <GetStartedStepTitle
            stepNumber={3}
            title={'Choose Your Guide:'}
            description={
              <>
                {loading && <LoadingSpinner className="w-8 h-8" />}
                {yourMatchesHtml}
              </>
            }
          />
          <Facets />
          {renderGuides()}
        </div>
        {renderPagination()}
      </div>
      <Dialogs guides={get(data, 'guides', [])} />
    </>
  );
}
