import * as React from 'react';
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="katman_1"
    x={0}
    y={0}
    style={{
      enableBackground: 'new 0 0 231.3 129'
    }}
    viewBox="0 0 231.3 129"
    {...props}>
    <style>{'.st0{fill:#969696}'}</style>
    <path
      d="m68.8 109.9 2.5 7.9H53.6L50.3 129H21l-3.3-11.2H0l2.5-7.9zM21.5 52.6l2.3 1.9s-2.3 3.2.8 6.2l.5.4 15 10.9c2.9 2.3 4.9 5.4 5.9 8.8.3 1 1.3 5.9-.5 10.6-.3.7-.7 1.4-1 2.1l-.6 1-8.5 11.6-2.4-2c.1-.1.2-.2.2-.3.1-.2.2-.3.3-.5.5-.9.5-1.8.3-2.4 0-.2-.1-.3-.1-.5v-.1c0-.1-.1-.2-.2-.3l-.1-.2-.1-.2-.2-.3-.9-.9-.2-.2-15.5-11.1c-3.3-2.6-5.6-6.4-6.3-10.5-.1-.8-.1-1.7-.1-2.5 0-3.3.7-6.3 2.4-9l.6-.9 6.1-8.2 2.3-3.4zM35.4 0l1.9 1.5c-1.1 1.5-1.5 3.4-1.1 5.2.3 1.6 1.2 3 2.4 4l.5.4 24.1 17.6c5.2 3.7 8.2 9 9.3 15 1 5.6-.2 11.5-3.4 16.3l-.7.9-16 22.1-2.2-1.6s1.1-1.5 1.7-3.6l.2-.6.2-.8c.2-1 .2-2 .2-3l-.1-.7v-.2c-.1-.8-.3-1.5-.6-2.3 0-.1 0-.1-.1-.2-.7-1.8-2-3.5-3.5-4.8l-.7-.5-20-14.6c-5.2-3.7-8.2-9-9.3-15-.4-1.1-.4-2.6-.4-3.7 0-4.5 1.2-8.6 3.7-12.4l.7-1L35.4 0zM230.2 88.3c-.6-.8-1.7-1.2-2.7-1.2-1 0-1.9.4-2.7 1.2s-1.2 1.7-1.2 2.7c0 1.2.4 2.1 1.2 2.8.6.8 1.7 1.2 2.7 1.2 1 0 1.9-.4 2.7-1.2.8-.8 1.2-1.8 1.2-2.8-.1-1.1-.5-2-1.2-2.7zm-.4 5.1c-.6.6-1.4 1-2.3 1-.9 0-1.7-.4-2.3-1-.6-.8-1-1.5-1-2.4 0-.9.4-1.7 1-2.3.6-.6 1.4-1 2.3-1 .9 0 1.8.4 2.3 1 .6.6 1 1.4 1 2.3 0 1-.4 1.8-1 2.4zM117.8 67.7c3.9-1.9 6.7-5.3 6.7-11.1 0-3.3-.9-6.2-3.3-8.6-2.8-3-7.6-4.4-13.4-4.4v-.1H84.4v50.6H109.5c10.8-.3 17.9-5.1 17.9-13.9-.1-6.7-3.8-10.1-9.6-12.5zM94.9 53.8h11.5c4.8 0 7.2 1.9 7.2 5.3 0 3.9-3.3 5.3-8.1 5.3H94.9V53.8zm13.4 30.7H94.9v-11h13.4c5.7 0 8.1 2.4 8.1 5.8 0 3.3-3.3 5.2-8.1 5.2zM165.6 67.7c3.9-1.9 6.7-5.3 6.7-11.1 0-3.3-.9-6.2-3.3-8.6-2.8-3-7.6-4.4-13.4-4.4v-.1h-23.4v50.6h25c10.8-.3 17.9-5.1 17.9-13.9 0-6.7-3.7-10.1-9.5-12.5zm-22.9-13.9h11.5c4.8 0 7.2 1.9 7.2 5.3 0 3.9-3.3 5.3-8.1 5.3h-10.6V53.8zM156 84.5h-13.4v-11H156c5.8 0 8.1 2.4 8.1 5.8 0 3.3-3.3 5.2-8.1 5.2zM213.4 67.7c3.9-1.9 6.7-5.3 6.7-11.1 0-3.3-.9-6.2-3.3-8.6-2.8-3-7.6-4.4-13.4-4.4v-.1H180v50.6H205.1c10.8-.3 17.9-5.1 17.9-13.9 0-6.7-3.8-10.1-9.6-12.5zm-22.5-13.9h10.9c4.8 0 7.2 1.9 7.2 5.3 0 3.9-3.3 5.3-8.1 5.3h-10V53.8zm12.9 30.7h-13.3v-11h12.9c5.7 0 8.1 2.4 8 5.8 0 3.3-2.9 5.2-7.6 5.2z"
      className="st0"
    />
    <path
      d="M226.3 89c.3-.1.7-.1 1-.1.6 0 .9.1 1.2.3.2.2.3.5.3.8 0 .5-.3.9-.8 1.1.3.1.5.4.6.8.1.6.2 1 .3 1.1h-.6c-.1-.1-.2-.5-.3-1-.1-.6-.3-.8-.8-.8h-.5V93h-.5v-4zm.6 1.9h.5c.6 0 .9-.3.9-.8s-.4-.8-1-.8h-.5v1.6z"
      className="st0"
    />
  </svg>
);
export default SvgComponent;
