import { useQuery } from '@apollo/client';
import { Alert } from '@windmill/react-ui';
import { Pagination } from 'semantic-ui-react';
import get from 'lodash/get';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import GuidesByIdsQuery from '../../../graphql/queries/guides-by-ids-pagination.graphql';
import UserToggledPresenceSubscription from '../../../graphql/subscriptions/user-toggled-presence.graphql';
import useSavedGuides from '../../hooks/use-saved-guides';
import ErrorMessage from '../../ui/error-message/index';
import LoadingSpinner from '../../ui/loading-spinner/index';
import Dialogs from './dialogs';
import Row from './row';
import history from '../../../history';

const PAGE_SIZE = 20;

function SavedGuides() {
  const [hasPageFired, setHasPageFired] = useState(false);

  const [savedGuides] = useSavedGuides();

  const location = useLocation();
  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  const variables = {
    first: PAGE_SIZE,
    skip: search.page ? (search.page - 1) * PAGE_SIZE : 0,
    guideIds: savedGuides.map((g) => g.id)
  };

  const { data, error, loading, refetch, subscribeToMore } = useQuery(
    GuidesByIdsQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables
    }
  );

  useEffect(
    function () {
      if (!data) {
        return null;
      }

      try {
        const subscription = subscribeToMore({
          document: UserToggledPresenceSubscription,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData) {
              return prev;
            }
            const { node } = subscriptionData.data.userToggledPresence;

            const index = prev.guides.findIndex((user) => user.id === node.id);

            if (index < 0) {
              return prev;
            }

            const guides = prev.guides.slice();
            const guide = guides[index];
            guides[index] = {
              ...guide,
              isPresent: node.isPresent
            };

            return {
              ...prev,
              guides
            };
          }
        });

        return function cleanUp() {
          subscription();
        };
      } catch (error) {
        /* dev error only? */
      }
    },
    [data, subscribeToMore]
  );

  function renderGuide(user) {
    return <Row key={user.id} guide={user} />;
  }

  function renderGuides() {
    if (error) {
      return <ErrorMessage error={error} retry={() => refetch()} />;
    }
    if (loading) {
      return <LoadingSpinner className="my-24 mx-auto w-48 h-48" />;
    }
    if (data.guides.length === 0) {
      return (
        <Alert>
          You currently have no saved guides. Booking a session with a guide or
          hitting the
          <i
            className="icon large lineawesome heart text-red-500"
            style={{ margin: 0 }}
          />
          button will add them to this list.
        </Alert>
      );
    }
    return (
      <>
        <p className="bg-white rounded-lg px-4 py-2 text-gray-400">
          <i className="icon lightbulb outline" /> You can add more or remove
          them at any time.{' '}
          <span className="italic">
            Booking a session with a guide automatically adds them to this list.
          </span>
        </p>
        <ol className="grid grid-rows-none my-8 gap-4">
          {data.guides.map(renderGuide)}
        </ol>
      </>
    );
  }

  function renderPagination() {
    if (!data) {
      return <></>;
    }

    const hidePaginationStyles =
      data.usersConnection.aggregate.count > PAGE_SIZE ? '' : 'hidden';
    const totalPages = Math.ceil(
      data.usersConnection.aggregate.count / PAGE_SIZE
    );

    const activePage = search.page || 1;
    const startItem = (activePage - 1) * PAGE_SIZE + 1;
    const endItem = Math.min(
      activePage * PAGE_SIZE,
      data.usersConnection.aggregate.count
    );

    return (
      <div
        className={`flex justify-between items-center ${hidePaginationStyles}`}>
        <span>
          Showing {startItem}-{endItem} of{' '}
          {data.usersConnection.aggregate.count}
        </span>
        <Pagination
          totalPages={totalPages}
          activePage={activePage}
          pointing
          secondary
          firstItem={null}
          lastItem={null}
          onPageChange={(_e, { activePage }) => {
            history.replace({
              search: qs.stringify({ ...search, page: activePage })
            });
          }}
        />
      </div>
    );
  }

  return (
    <>
      <div className="flex-grow p-8">
        <div className="flex-grow flex flex-col">
          <h1 className="flex flex-row space-x-4 font-medium text-3xl leading-8 mb-4">
            <span className="font-bold">My Guides</span>
          </h1>
          <p className="my-2 text-lg">
            Reach out any time to talk with your short list of saved guides.
          </p>
          {renderGuides()}
        </div>
        {renderPagination()}
      </div>
      <Dialogs guides={get(data, 'guides', [])} />
    </>
  );
}
export default SavedGuides;
