import isError from 'lodash/isError';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React from 'react';

const ERRORS = {
  FAILED_TO_FETCH: 'Failed to fetch'
};

const FRIENDLY_MESSAGES = {
  [ERRORS.FAILED_TO_FETCH]:
    "Couldn't connect to the server, you may need to check your network settings and try again."
};

const GRAPH_QL_ERROR_PREFIX = 'GraphQL error: ';
const FUNCTION_EXECUTION_ERROR_PREFIX = 'function execution error: ';

function getErrorMessages(error) {
  if (isString(error)) {
    return [error];
  }
  if (isError(error)) {
    if (error.result && error.result.errors && error.result.errors.length) {
      return error.result.errors.map(getErrorMessages);
    }
    if (error.graphQLErrors && error.graphQLErrors.length) {
      return error.graphQLErrors.map(getErrorMessages);
    }
    if (error.networkError) {
      return getErrorMessages(error.networkError);
    }
    return [error.message];
  }
  if (isObject(error) && error.message) {
    return [error.message];
  }
  return [''];
}

function replacePrefixes(message) {
  const friendly = message
    .replace(GRAPH_QL_ERROR_PREFIX, '')
    .replace(FUNCTION_EXECUTION_ERROR_PREFIX, '');

  if (FRIENDLY_MESSAGES[friendly]) {
    return FRIENDLY_MESSAGES[friendly];
  }

  return friendly;
}

export function friendlyErrors(error) {
  return getErrorMessages(error).map((message) => replacePrefixes(message));
}

export function friendlyError(error) {
  return getErrorMessages(error)
    .map((message) => replacePrefixes(message))
    .join(', ');
}

function ErrorMessage(props) {
  const { children, error, retry } = props;

  if (!error) {
    return null;
  }

  const messages = getErrorMessages(error).map((message, index) => (
    <li key={index}>{message}</li>
  ));

  return (
    <div className="bg-red-50 p-4 rounded flex items-start text-red-600 my-4 shadow-lg mx-auto max-w-2xl">
      <div className="text-lg">
        <i className="icon lineawesome exclamation red" />
      </div>
      <div className="px-3 flex-grow">
        <h3 className="text-red-800 font-semibold tracking-wider">
          An Error has Occurred
        </h3>
        <ul className="list-disc list-inside">{messages}</ul>
        {retry && (
          <div className="space-x-6">
            <button
              className="text-red-900 font-semibold tracking-wider hover:underline focus:outline-none"
              onClick={retry}>
              Retry
            </button>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

ErrorMessage.propTypes = {
  children: PropTypes.node,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string
    })
  ]),
  retry: PropTypes.func
};

export default ErrorMessage;
