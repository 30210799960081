import './App.css';
import '../semantic.css';
import './theme.css';

import React, { useEffect, useState } from 'react';

import AppHeader from './container/app-header';
import AppWorkspace from './container/app-workspace';
import RegisterDialog from './container/register/dialog';
import ServiceWorkerUpdate from './container/service-worker-update';

function DebuglocalStorage() {
  const [sessionToken, setSessionToken] = useState('gg');
  useEffect(() => {
    const getToken = async () => {
      console.log('reading localstorage...');
      const token = await localStorage.getItem('lifeguides-auth');
      console.log({ token });
      setSessionToken(token);
    };

    getToken();
  }, []);

  return (
    <div style={{ background: 'orange', textAlign: 'center', maxWidth: '80%' }}>
      <pre>{'Session TOKEN'}</pre>
      <div style={{ fontSize: 10, padding: 10, wordWrap: 'break-word' }}>
        <span>{sessionToken}</span>
      </div>
    </div>
  );
}

export default function App() {
  return (
    <div className="flex flex-col items-center w-full">
      <AppHeader />
      {/* <DebuglocalStorage /> */}
      <AppWorkspace />
      <ServiceWorkerUpdate />
      <RegisterDialog />
    </div>
  );
}
