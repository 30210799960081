import { useQuery } from '@apollo/client';
import qs from 'qs';
import React from 'react';
import { useLocation } from 'react-router-dom';

import AccessCodeByRouteQuery from '../../../graphql/queries/access-code-by-route.graphql';
import history from '../../../history';
import LoadingSpinner from '../../ui/loading-spinner/index';

function AccessCode() {
  const location = useLocation();

  const route = location.pathname.slice(1);

  const { data, loading } = useQuery(AccessCodeByRouteQuery, {
    variables: { route }
  });

  if (loading) {
    return <LoadingSpinner className="my-8 mx-auto w-48 h-48" />;
  }

  if (data && data.accessCode) {
    history.push({
      pathname: '/',
      search: qs.stringify({ accessCode: data.accessCode.code })
    });
    return null;
  }

  history.push({ pathname: '/404' });
  return null;
}
export default AccessCode;
