import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Icon, Label, TransitionablePortal } from 'semantic-ui-react';

import withWidth, { isWidthDown } from '../../hoc/with-width';

const TIMEOUT_DELAY = 5000;

@withWidth()
export default class Notification extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    message: PropTypes.node,
    onClose: PropTypes.func,
    onHide: PropTypes.func,
    open: PropTypes.bool,
    sticky: PropTypes.bool,
    width: PropTypes.number.isRequired
  };

  componentDidMount() {
    this._startTimeout();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this._startTimeout();
    }
  }

  componentWillUnmount() {
    if (this._timeout) {
      window.clearInterval(this._timeout);
    }
  }

  _startTimeout() {
    const { open, sticky, onClose } = this.props;

    if (!open) {
      return;
    }
    if (sticky) {
      return;
    }
    if (this._timeout) {
      return;
    }

    this._timeout = window.setTimeout(() => {
      if (onClose) {
        onClose();
      }
      delete this._timeout;
    }, TIMEOUT_DELAY);
  }

  render() {
    const {
      children,
      message,
      onClose,
      onHide,
      open,
      sticky,
      width
    } = this.props;

    const isTablet = isWidthDown('computer', width);

    return (
      <TransitionablePortal
        closeOnDocumentClick
        closeOnEscape
        closeOnRootNodeClick
        open={open}
        onClose={onClose}
        onHide={onHide}
        transition={{
          animation: 'fly up',
          duration: 400
        }}>
        <div
          onClick={onClose}
          style={{
            position: 'fixed',
            bottom: isTablet ? 87 : 0, // Jank. Need to use a context provider to pass down a ref and use as mount node for portal instead
            left: 0,
            right: 0,
            textAlign: 'center',
            zIndex: 11112
          }}>
          <Label
            color="black"
            style={{
              padding: '1em',
              boxShadow: '0 1px 3px 0 rgba(0,0,0,.08)',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0
            }}>
            {children || message}
            {sticky && <Icon name="close" color="grey" />}
          </Label>
        </div>
      </TransitionablePortal>
    );
  }
}
