import './index.css';

import Bugsnag from '@bugsnag/js';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Dropdown,
  Header,
  Icon,
  Label,
  Segment
} from 'semantic-ui-react';

import { impersonate } from '../../../../auth';
import { ROLES, ROLE_LABELS } from '../../../../consts';
import BanUserMutation from '../../../../graphql/mutations/ban-user.graphql';
import UpdateClientInviteStatusMutation from '../../../../graphql/mutations/update-client-invite-status.graphql';
import UpdateCompanyOnUserStatusMutation from '../../../../graphql/mutations/update-company-on-user-status.graphql';
import UpdateUserConnectCompanyMutation from '../../../../graphql/mutations/update-user-connect-company.graphql';
import UpdateUserConnectRoleMutation from '../../../../graphql/mutations/update-user-connect-role.graphql';
import UpdateUserDisconnectRoleMutation from '../../../../graphql/mutations/update-user-disconnect-role.graphql';
import UpdateUserFeaturedMutation from '../../../../graphql/mutations/update-user-featured.graphql';
import UpdateUserGuideStatusMutation from '../../../../graphql/mutations/update-user-guide-status.graphql';
import UpdateUserVideoUrlMutation from '../../../../graphql/mutations/update-user-video-link.graphql';
import AllCompaniesQuery from '../../../../graphql/queries/all-companies.graphql';
import AllUserRolesQuery from '../../../../graphql/queries/all-user-roles.graphql';
import UserByIdAdminQuery from '../../../../graphql/queries/user-by-id-admin.graphql';
import graphql from '../../../hoc/graphql';
import withUser from '../../../hoc/with-user';
import Avatar from '../../../ui/avatar';
import ErrorDialog from '../../../ui/error-dialog';
import Notification from '../../../ui/notification';
import UserBasicInfo from './basic-info';
import ConfirmBanDialog from './confirm-ban-dialog';
import ConfirmImpersonateDialog from './confirm-impersonate-dialog';
import ConfirmRevokeClientInviteDialog from './confirm-revoke-client-invite-dialog';
import ConfirmRoleToggleDialog from './confirm-role-toggle-dialog';
import UpdateVideoURLDialog from './update-video-url-dialog';

const DIALOGS = {
  CONFIRM_BAN: 'CONFIRM_BAN',
  CONFIRM_IMPERSONATE: 'CONFIRM_IMPERSONATE',
  CONFIRM_REVOKE_CLIENT_INVITE: 'CONFIRM_REVOKE_CLIENT_INVITE',
  CONFIRM_ROLE_TOGGLE: 'CONFIRM_ROLE_TOGGLE',
  UPDATE_CALL_CREDITS: 'UPDATE_CALL_CREDITS',
  UPDATE_VIDEO_URL: 'UPDATE_VIDEO_URL'
};

@withUser({ authenticated: [ROLES.ADMIN] })
@graphql(AllUserRolesQuery, { name: 'roles' })
@graphql(AllCompaniesQuery, { name: 'companies' })
@graphql(BanUserMutation, {
  name: 'banUser',
  options: {
    update: (
      store,
      {
        data: {
          banUser: { id }
        }
      }
    ) => {
      const { userRoles } = store.readQuery({ query: AllUserRolesQuery });

      const role = userRoles.find((r) => r.name === ROLES.BANNED);

      const params = { query: UserByIdAdminQuery, variables: { id } };
      const data = store.readQuery(params);
      const updated = {
        ...data,
        user: {
          ...data.user,
          roles: data.user.roles.concat([role])
        }
      };

      store.writeQuery({ ...params, data: updated });
    }
  }
})
@graphql(UpdateUserConnectCompanyMutation, { name: 'connectCompany' })
@graphql(UpdateUserConnectRoleMutation, { name: 'connectRole' })
@graphql(UpdateUserDisconnectRoleMutation, { name: 'disconnectRole' })
@graphql(UpdateClientInviteStatusMutation, { name: 'updateClientInviteStatus' })
@graphql(UpdateCompanyOnUserStatusMutation, {
  name: 'updateCompanyOnUserStatus'
})
@graphql(UpdateUserGuideStatusMutation, { name: 'updateGuideStatus' })
@graphql(UpdateUserFeaturedMutation, { name: 'updateIsFeatured' })
@graphql(UpdateUserVideoUrlMutation, { name: 'updateVideoUrl' })
@graphql(UserByIdAdminQuery, {
  name: 'userById',
  options: ({
    match: {
      params: { id }
    }
  }) => ({
    variables: { id }
  })
})
@withRouter
class User extends Component {
  static propTypes = {
    banUser: PropTypes.func.isRequired,
    companies: PropTypes.shape({
      companies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          isDeleted: PropTypes.bool,
          name: PropTypes.string
        })
      ),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    connectCompany: PropTypes.func.isRequired,
    connectRole: PropTypes.func.isRequired,
    disconnectRole: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    roles: PropTypes.shape({
      userRoles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string
        })
      ),
      loading: PropTypes.bool.isRequired
    }),
    updateClientInviteStatus: PropTypes.func.isRequired,
    updateCompanyOnUserStatus: PropTypes.func.isRequired,
    updateGuideStatus: PropTypes.func.isRequired,
    updateIsFeatured: PropTypes.func.isRequired,
    updateVideoUrl: PropTypes.func.isRequired,
    userById: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      user: PropTypes.shape({
        id: PropTypes.string,
        clientInvite: PropTypes.shape({
          id: PropTypes.string,
          company: PropTypes.shape({
            id: PropTypes.string
          }),
          status: PropTypes.string
        }),
        companyIds: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            company: PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string
            })
          })
        ),
        emailAddress: PropTypes.string,
        firstName: PropTypes.string,
        isFeatured: PropTypes.bool,
        lastName: PropTypes.string,
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          })
        )
      })
    }).isRequired
  };

  state = {
    error: null,
    openDialog: null,
    role: null,
    submitting: false,
    success: false,
    successMessage: null
  };

  render() {
    const { history, location } = this.props;
    const { loading, user } = this.props.userById;
    const { error, submitting, success, successMessage } = this.state;

    const search = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (loading) {
      return <Segment basic loading />;
    }

    if (!user) {
      return null;
    }

    const isGuide = user.roles.some((role) => role.name === ROLES.GUIDE);

    return (
      <div className="p-4 admin-user">
        <Header size="large">
          <Button
            primary
            icon="angle left"
            size="small"
            className="lineawesome"
            style={{
              width: '2em',
              height: '2em',
              fontSize: '.8em',
              marginRight: '1em'
            }}
            onClick={() => {
              history.push(
                search.returnUrl
                  ? search.returnUrl
                  : { pathname: '/admin/users' }
              );
            }}
          />
          User {user.id}
        </Header>
        <Segment>
          {this._renderControls()}
          {isGuide && (
            <Button
              fluid
              onClick={() => {
                history.push({ pathname: `/guide/${user.id}` });
              }}>
              View and Edit Guide Profile
            </Button>
          )}
          <UserBasicInfo
            submitting={submitting}
            user={user}
            onGuideStatusChange={(value) => this._updateGuideStatus(value)}
            onVideoURLClick={() =>
              this.setState({ openDialog: DIALOGS.UPDATE_VIDEO_URL })
            }
          />
        </Segment>
        <ErrorDialog
          error={error}
          onClose={() => {
            this.setState({ error: null });
          }}
        />
        <Notification
          open={success}
          onClose={() => {
            this.setState({ success: false });
          }}>
          <Icon name="check" color="green" /> {successMessage}
        </Notification>
        {this._renderDialogs()}
      </div>
    );
  }

  _renderDialogs() {
    const { user } = this.props.userById;
    const { openDialog, role, submitting } = this.state;

    const props = {
      onClose: this._onCloseDialog,
      submitting,
      user
    };

    switch (openDialog) {
      case DIALOGS.CONFIRM_BAN:
        return <ConfirmBanDialog {...props} onSubmit={this._ban} />;
      case DIALOGS.CONFIRM_IMPERSONATE:
        return (
          <ConfirmImpersonateDialog
            {...props}
            onSubmit={this._impersonateUser}
          />
        );
      case DIALOGS.CONFIRM_REVOKE_CLIENT_INVITE:
        return (
          <ConfirmRevokeClientInviteDialog
            {...props}
            onSubmit={this._revokeClientInvite}
          />
        );
      case DIALOGS.CONFIRM_ROLE_TOGGLE:
        return (
          <ConfirmRoleToggleDialog
            {...props}
            role={role}
            onSubmit={this._toggleRole}
          />
        );
      case DIALOGS.UPDATE_VIDEO_URL:
        return (
          <UpdateVideoURLDialog {...props} onSubmit={this._updateVideoURL} />
        );
      default:
        return null;
    }
  }

  _renderControls() {
    const { user } = this.props.userById;
    const isClient = user.roles.some((r) => r.name === ROLES.CLIENT);
    const isGuide = user.roles.some((r) => r.name === ROLES.GUIDE);
    const hasPendingClientInvite =
      user.clientInvite && user.clientInvite.status === 'PENDING';
    const isRegistered = get(user, 'roles.length');

    const userRole = isRegistered
      ? user.roles.map((role) => ROLE_LABELS[role.name]).join(', ')
      : hasPendingClientInvite
      ? 'INVITED'
      : null;

    return (
      <div className="controls">
        <div className="user-name">
          <Avatar user={user} />
          <Header>
            {user.firstName} {user.lastName}
            <Header.Subheader>{user.emailAddress}</Header.Subheader>
          </Header>
        </div>

        {isGuide && (
          <div style={{ paddingRight: '20px' }}>
            <Label
              style={{
                backgroundColor: 'transparent'
              }}>
              Featured:{' '}
            </Label>
            <Checkbox
              toggle
              style={{
                verticalAlign: 'middle'
              }}
              checked={user.isFeatured ? user.isFeatured : false}
              onChange={() => this._toggleFeatured()}
            />
          </div>
        )}

        {(isClient || hasPendingClientInvite) && (
          <div className="companies">
            <div>Companies:&nbsp;&nbsp;</div>
            {this._renderCompaniesMenu()}
          </div>
        )}

        <span style={{ margin: '0 .5em' }}>
          Roles: {userRole}
          &nbsp;
          {this._renderRolesMenu()}
        </span>

        <Button
          onClick={() =>
            this.setState({
              openDialog: DIALOGS.CONFIRM_IMPERSONATE
            })
          }>
          Impersonate
        </Button>
      </div>
    );
  }

  _renderCompaniesMenu() {
    const { user } = this.props.userById;
    const { companies, loading } = this.props.companies;
    const { submitting } = this.state;

    let companyIds = [];
    let isInvitedClient = false;
    if (user) {
      if (user.roles.length) {
        companyIds = companyIds.concat(
          user.companyIds
            .filter(
              (companyConnection) => companyConnection.status === 'ACTIVE'
            )
            .map(
              (companyConnection) =>
                companyConnection.company && companyConnection.company.id
            )
        );
      } else if (
        user.clientInvite &&
        user.clientInvite.company &&
        user.clientInvite.company.id &&
        user.clientInvite.status === 'PENDING'
      ) {
        isInvitedClient = true;
        companyIds.push(user.clientInvite.company.id);
      }
    }
    const availableCompanies = companies
      ? companies.filter((company) => {
          return !company.isDeleted || companyIds.includes(company.id);
        })
      : [];

    return (
      <Dropdown
        multiple
        selection
        loading={loading}
        disabled={(!user.roles.length && !isInvitedClient) || submitting}
        options={availableCompanies.map((company) => ({
          key: company.id,
          text: company.name,
          value: company.id
        }))}
        value={companyIds}
        onChange={
          isInvitedClient
            ? () =>
                this.setState({
                  openDialog: DIALOGS.CONFIRM_REVOKE_CLIENT_INVITE
                })
            : this._onCompaniesChange
        }
      />
    );
  }

  _renderRolesMenu() {
    const { user } = this.props.userById;

    function hasRole(name) {
      return user.roles.some((r) => r.name === name);
    }

    const roles = [
      {
        name: ROLES.CORP_ADMIN,
        shouldShow: () => hasRole(ROLES.CLIENT)
      },
      {
        name: ROLES.CONCIERGE,
        shouldShow: () => hasRole(ROLES.GUIDE)
      },
      {
        name: ROLES.GUIDE_ADMIN,
        shouldShow: () => hasRole(ROLES.GUIDE)
      },
      {
        name: ROLES.GUIDE_CONTENT_MANAGER,
        shouldShow: () => hasRole(ROLES.GUIDE)
      },
      {
        name: ROLES.TESTER,
        shouldShow: () => hasRole(ROLES.GUIDE)
      },
      {
        getLabel: (hasRole) => (hasRole ? 'Un-ban' : 'Ban'),
        name: ROLES.BANNED,
        onClick: () => {
          if (hasRole(ROLES.BANNED)) {
            this.setState({
              openDialog: DIALOGS.CONFIRM_ROLE_TOGGLE,
              role: ROLES.BANNED
            });
          } else {
            this.setState({ openDialog: DIALOGS.CONFIRM_BAN });
          }
        },
        shouldShow: () => true
      }
    ].filter(({ shouldShow }) => shouldShow());

    return (
      <Dropdown icon="cog" direction="left">
        <Dropdown.Menu>
          {roles.map(({ getLabel, name, onClick }) => (
            <Dropdown.Item
              key={name}
              text={
                getLabel
                  ? getLabel(hasRole(name))
                  : `${hasRole(name) ? 'Remove' : 'Make'} ${ROLE_LABELS[name]}`
              }
              onClick={() => {
                if (onClick) {
                  onClick();
                } else {
                  this.setState({
                    openDialog: DIALOGS.CONFIRM_ROLE_TOGGLE,
                    role: name
                  });
                }
              }}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  _onCloseDialog = () => {
    this.setState({ openDialog: null });
  };

  _onCompaniesChange = (event, { value }) => {
    const { user } = this.props.userById;
    const { companies } = this.props.companies;

    const addedCompanies = value
      .filter(
        (id) =>
          !user.companyIds.find(
            (cc) => cc.companyId === id && cc.status === 'ACTIVE'
          )
      )
      .map((id) => companies.find((c) => c.id === id));
    const removedCompanies = user.companyIds
      .filter((cc) => !value.includes(cc.company.id) && cc.status === 'ACTIVE')
      .map((cc) => companies.find((c) => c.id === cc.company.id));
    const allCompanies = [...addedCompanies, ...removedCompanies];

    this.setState({ error: null, submitting: true, success: false });
    const requests = allCompanies.map((company) =>
      this._toggleCompany(company)
    );
    return Promise.all(requests)
      .then(() => {
        // Should sucecss message be set here? Only the last successful will show
        // I think only one value will change at a time anyways
        this.setState(
          {
            submitting: false
          },
          () => {
            this.props.userById.refetch();
          }
        );
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
      });
  };

  _impersonateUser = () => {
    const { history } = this.props;
    const { user } = this.props.userById;

    impersonate(user.id).then(() => {
      history.push('/dashboard');
    });
  };

  _ban = (data) => {
    const { banUser } = this.props;
    const { user } = this.props.userById;

    const variables = {
      ...data,
      id: user.id
    };

    this.setState({ error: null, submitting: true, success: false });
    banUser({ variables })
      .then(() => {
        this.setState({
          openDialog: null,
          submitting: false,
          success: true,
          successMessage: `${user.firstName} has been banned`
        });
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'Users._ban';
          event.request.variables = variables;
        });
      });
  };

  _revokeClientInvite = () => {
    const { userById } = this.props;
    const { user } = userById;
    const { updateClientInviteStatus } = this.props;

    const variables = {
      id: user.clientInvite.id,
      status: 'REVOKED'
    };

    this.setState({ error: null, submitting: true, success: false });
    updateClientInviteStatus({ variables })
      .then(() => {
        this.setState(
          {
            openDialog: null,
            submitting: false,
            success: true,
            successMessage: `${user.firstName}'s invite has been revoked`
          },
          () => {
            userById.refetch();
          }
        );
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'User._revokeClientInvite';
          event.request.variables = variables;
        });
      });
  };

  _toggleCompany(company) {
    const { connectCompany, updateCompanyOnUserStatus } = this.props;
    const { user } = this.props.userById;

    const companyConnection = user.companyIds.find(
      (c) => c.company.id == company.id
    );

    const mutation = companyConnection
      ? updateCompanyOnUserStatus
      : connectCompany;

    const variables = {
      ...(companyConnection
        ? {
            id: companyConnection.id,
            status:
              companyConnection.status === 'ACTIVE' ? 'TERMINATED' : 'ACTIVE'
          }
        : { id: user.id, companyId: company.id })
    };

    return mutation({ variables })
      .then(() => {
        this.setState({
          submitting: false,
          success: true,
          successMessage: `${user.firstName} has been ${
            companyConnection && companyConnection.status === 'ACTIVE'
              ? 'removed from'
              : 'assigned to'
          } ${company.name || company.company.name}`
        });
      })
      .catch((error) => {
        Bugsnag.notify(error, function (event) {
          event.context = 'User._toggleCompany';
          event.request.variables = variables;
        });
        throw error;
      });
  }

  _toggleFeatured() {
    const { updateIsFeatured } = this.props;
    const { user } = this.props.userById;

    let variables = {
      id: user.id,
      isFeatured: !user.isFeatured
    };
    let message = variables.isFeatured
      ? 'User has been added to featured guides.'
      : 'User has been removed from featured guides.';

    this.setState({ error: null, submitting: true, success: false });
    updateIsFeatured({ variables })
      .then(() => {
        this.setState({
          submitting: false,
          success: true,
          successMessage: `${message}`
        });
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'User._toggleFeatured';
          event.request.variables = variables;
        });
      });
  }

  _toggleRole = () => {
    const { connectRole, disconnectRole } = this.props;
    const { user } = this.props.userById;
    const { userRoles } = this.props.roles;
    const { role } = this.state;

    const userRole = userRoles.find((r) => r.name === role);
    const hasRole = !!user.roles.find((r) => r.name === role);

    const variables = {
      userId: user.id,
      roleId: userRole.id
    };
    const mutation = hasRole ? disconnectRole : connectRole;

    this.setState({ error: null, submitting: true, success: false });
    mutation({ variables })
      .then(() => {
        this.setState({
          openDialog: null,
          submitting: false,
          success: true,
          successMessage: `${userRole.name} has been ${
            hasRole ? 'removed' : 'added'
          } ${hasRole ? 'from' : 'to'} ${user.firstName}`
        });
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'User._toggleRole';
          event.request.variables = variables;
        });
      });
  };

  _updateGuideStatus(status) {
    const { updateGuideStatus } = this.props;
    const { user } = this.props.userById;

    const variables = {
      id: user.id,
      status: status || null
    };

    this.setState({ error: null, submitting: true, success: false });
    updateGuideStatus({ variables })
      .then(() => {
        this.setState({
          submitting: false,
          success: true,
          successMessage: status
            ? `Guide status set to ${status.toLowerCase()}`
            : 'Guide status set to active'
        });
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'User._updateGuideStatus';
          event.request.variables = variables;
        });
      });
  }

  _updateVideoURL = (data) => {
    const { updateVideoUrl } = this.props;
    const { user } = this.props.userById;

    const variables = {
      id: user.id,
      videoUrl: data.url
    };

    this.setState({ error: null, submitting: true, success: false });
    updateVideoUrl({ variables })
      .then(() => {
        this.setState({
          openDialog: null,
          submitting: false,
          success: true,
          successMessage: `The video link has been added to ${user.firstName}'s profile.`
        });
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'User._updateVideoURL';
          event.request.variables = variables;
        });
      });
  };
}
export default User;
