import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dropdown, Form, Input } from 'semantic-ui-react';
import countries from 'react-select-country-list';

import MyForm, { FormField, PhoneNumberInput } from '../../../ui/form';

const countryData = countries().getData();

const usaFirstReorderedCountries = [
  { value: 'US', label: 'United States' },
  ...countryData.filter((c) => c.value !== 'US' && c.label !== 'United States')
];

class InviteClientForm extends Component {
  static propTypes = {
    onDataChange: PropTypes.func,
    onValidate: PropTypes.func
  };

  state = {
    data: {
      address: '',
      city: '',
      country: '',
      emailAddress: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      state: ''
    }
  };

  render() {
    const { onValidate } = this.props;
    const { data } = this.state;

    const handleCountryChange = (event, { value, options }) => {
      const selectedCountry = options.find((option) => option.value === value);
      this.setState((prevState) => ({
        ...prevState,
        country: selectedCountry.value
      }));
    };

    return (
      <MyForm
        className="concierge-create-call-request-form"
        data={data}
        onChange={this._onDataChange}
        onValidate={onValidate}>
        <FormField
          component={Input}
          name="emailAddress"
          label="E-mail Address *"
          type="email"
          autoComplete="off"
          validator={({ emailAddress }) => {
            if (!emailAddress) {
              throw new Error('E-mail address is required');
            }
          }}
        />
        <FormField
          component={Input}
          name="firstName"
          label="First Name *"
          type="text"
          autoComplete="off"
          validator={({ firstName }) => {
            if (!firstName) {
              throw new Error('First name is required');
            }
          }}
        />
        <FormField
          component={Input}
          name="lastName"
          label="Last Name *"
          type="text"
          autoComplete="off"
          validator={({ lastName }) => {
            if (!lastName) {
              throw new Error('Last name is required');
            }
          }}
        />
        <FormField
          component={PhoneNumberInput}
          name="phoneNumber"
          label="Personal Mobile Number"
          type="text"
          autoComplete="off"
          aria-label="Personal Mobile Number"
          aria-required="true"
          validator={({ phoneNumber }) => {
            if (!phoneNumber) {
              throw new Error('Must enter a mobile number');
            }
          }}
        />
        <FormField
          component={Input}
          type="text"
          name="address"
          label="Address"
          autoComplete="off"
          aria-placeholder="Address"
        />
        <Form.Group widths="equal" aria-placeholder="Enter your city and state">
          <FormField
            component={Input}
            name="city"
            label="City"
            autoComplete="off"
            aria-placeholder="City"
          />
          <FormField
            component={Input}
            name="state"
            label="State/Territory"
            autoComplete="off"
            aria-placeholder="State/Territory"
          />
        </Form.Group>

        <FormField
          component={Dropdown}
          name="country"
          label="Country"
          placeholder="Country"
          fluid
          selection
          search
          onChange={handleCountryChange}
          valueKey="value"
          textKey="label"
          value={data.country}
          options={usaFirstReorderedCountries.map((country) => ({
            key: country.label,
            value: country.label,
            text: country.label,
            selected: data.country === country.label
          }))}
          validator={({ country }) => {
            if (!country) {
              throw new Error('Must select a country');
            }
          }}
        />
      </MyForm>
    );
  }

  _onDataChange = (data) => {
    const { onDataChange } = this.props;

    const formatted = {
      ...data,
      phoneNumber: data.phoneNumber.replace(/\D/g, '')
    };

    this.setState({
      data: formatted
    });

    if (onDataChange) {
      onDataChange(formatted);
    }
  };
}
export default InviteClientForm;
