import PropTypes from 'prop-types';
import React, { Component } from 'react';

class CircularButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(['red']),
    icon: PropTypes.node,
    onClick: PropTypes.func
  };

  constructor(props) {
    super(props);

    this._ref = React.createRef();
  }

  render() {
    const { className, color, icon, ...remaining } = this.props;

    const classNames = ['circular-button']
      .concat([className, color])
      .filter((x) => !!x);

    return (
      <button
        ref={this._ref}
        {...remaining}
        className={classNames.join(' ')}
        onClick={this._onClick}>
        {icon}
      </button>
    );
  }

  _onClick = (event) => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(event);
    }

    this._ref.current.blur();
  };
}
export default CircularButton;
