import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Accordion } from 'semantic-ui-react';

import SessionNote from './session-note';

export default class SessionNotes extends PureComponent {
  static propTypes = {
    notes: PropTypes.array.isRequired,
    session: PropTypes.object,
    user: PropTypes.object.isRequired
  };

  state = { activeIndex: 0 };

  changeTab = (note, index) => {
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  noteToHtml = (note, index) => {
    const { session, user } = this.props;

    const _id = `note_${note.id || index}`;
    const _active = index === this.state.activeIndex || false;

    return (
      <SessionNote
        active={_active}
        session={session}
        note={note}
        user={user}
        data-index={index}
        onClick={() => {
          this.changeTab(note, index);
        }}
        key={_id}
      />
    );
  };

  render() {
    const { notes } = this.props;
    const _notes = notes.map(this.noteToHtml);
    return (
      <Accordion styled fluid>
        {_notes}
      </Accordion>
    );
  }
}
