import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import GuideContext from '../guide/guide-context';
import { DIALOGS } from '../guide/profile-view/dialogs';
import { useDialogs } from '../guide/profile-view/dialogs';
import ConfirmSessionDialog from '../guide/profile-view/dialogs/confirm-session';
import SchedulerDialog from '../guide/profile-view/dialogs/scheduler';

function GuideDialogs(props) {
  const { guides } = props;

  const [session, setSession] = useState(null);
  const [dialog, setDialog] = useDialogs();

  const location = useLocation();
  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  const selectedGuide = guides.find((user) => user.id === search.guideId);

  function onCloseDialog() {
    setDialog(null);
  }

  function onCreateSession(session) {
    setSession(session);
    setDialog(DIALOGS.CONFIRM_SESSION);
  }

  function renderDialogs() {
    const props = { isOpen: true, onClose: onCloseDialog };

    switch (dialog) {
      case DIALOGS.CONFIRM_SESSION:
        return session ? (
          <ConfirmSessionDialog {...props} session={session} />
        ) : null;
      case DIALOGS.SCHEDULE:
        return <SchedulerDialog {...props} onSubmit={onCreateSession} />;
      default:
        return null;
    }
  }

  return (
    <GuideContext.Provider value={selectedGuide}>
      {selectedGuide && renderDialogs()}
    </GuideContext.Provider>
  );
}
GuideDialogs.propTypes = {
  guides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  )
};
export default GuideDialogs;
