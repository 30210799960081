import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Checkbox, Icon, Table } from 'semantic-ui-react';

import CompaniesQuery from '../../../../graphql/queries/all-companies.graphql';
import graphql from '../../../hoc/graphql';
import FullscreenLoader from '../../../ui/fullscreen-loader';

@withRouter
@graphql(CompaniesQuery, { name: 'companies' })
class AdminCompaniesList extends Component {
  static propTypes = {
    companies: PropTypes.shape({
      companies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          paysEmployeeSubscription: PropTypes.bool
        })
      ),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  render() {
    const { loading } = this.props.companies;

    if (loading) {
      return <FullscreenLoader />;
    }

    return <div>{this._renderCompanies()}</div>;
  }

  _renderCompanies() {
    const { companies } = this.props.companies;

    return (
      <>
        <Table className="admin-companies-list" striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Phone #</Table.HeaderCell>
              <Table.HeaderCell>Company Payment</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {companies.filter((c) => !c.isDeleted).map(this._renderCompany)}
          </Table.Body>
        </Table>
      </>
    );
  }

  _renderCompany = (company) => {
    const { history } = this.props;

    return (
      <Table.Row key={`company-${company.id}`} className="company">
        <Table.Cell>{company.name}</Table.Cell>
        <Table.Cell>{company.primaryPhone}</Table.Cell>
        <Table.Cell>
          <Checkbox disabled checked={company.paysEmployeeSubscription} />
        </Table.Cell>
        <Table.Cell textAlign="right">
          <Button
            onClick={() => {
              history.push(this._createUrl('/dialog/edit', { id: company.id }));
            }}>
            <Icon name="edit" />
            Edit Company
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  };

  _createUrl(path, params) {
    const { match } = this.props;

    const returnUrl = `${location.pathname}${location.search}`;
    const paramsStr = qs.stringify(params);

    return `${match.url}${path}${location.search}${
      location.search ? '&' : '?'
    }${paramsStr}&returnUrl=${encodeURIComponent(returnUrl)}`;
  }
}
export default AdminCompaniesList;
