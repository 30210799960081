import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Header, Input, Modal } from 'semantic-ui-react';

class UpdateVideoURLDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    user: PropTypes.shape({
      id: PropTypes.string,
      videoUrl: PropTypes.string
    }).isRequired
  };

  state = {
    url: ''
  };

  render() {
    const { onClose, submitting, user } = this.props;
    const { url } = this.state;

    return (
      <Modal open size="mini" onClose={onClose}>
        <Modal.Header>Add Video Url</Modal.Header>
        <Modal.Content>
          {user.videoUrl && (
            <div style={{ marginBottom: 30 }}>
              <Header as="h4">Current Video: </Header>
              <p>{user.videoUrl}</p>
            </div>
          )}
          <Input
            name="Video Url"
            placeholder={user.videoUrl || 'http://vimeo.com'}
            aria-label="Video Url"
            fluid
            onChange={(e, { name, value }) => {
              this.setState({ url: value });
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={submitting} onClick={onClose}>
            Nevermind
          </Button>
          <Button
            primary
            loading={submitting}
            disabled={submitting || url === ''}
            onClick={this._onSubmit}>
            {user.videoUrl ? 'Update Video' : 'Add Video'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  _onSubmit = () => {
    const { onSubmit } = this.props;
    const { url } = this.state;

    onSubmit({ url });
  };
}
export default UpdateVideoURLDialog;
