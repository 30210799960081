
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ClientInviteInfo"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ClientInvite"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"company"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"logoUrl"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"paysEmployeeSubscription"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"prices"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PriceInfo"},"directives":[]}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"isSpouse"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"status"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"user"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"emailAddress"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"firstName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lastName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"phoneNumber"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":312}};
    doc.loc.source = {"body":"#import \"../fragments/price.graphql\"\n\nfragment ClientInviteInfo on ClientInvite {\n  id\n  createdAt\n  company {\n    id\n    logoUrl\n    name\n    paysEmployeeSubscription\n    prices {\n      ...PriceInfo\n    }\n  }\n  isSpouse\n  status\n  user {\n    id\n    emailAddress\n    firstName\n    lastName\n    phoneNumber\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../fragments/price.graphql").definitions));


      module.exports = doc;
    
