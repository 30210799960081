import { Button } from '@windmill/react-ui';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Icon, Message } from 'semantic-ui-react';

import withUser from '../../../hoc/with-user';
import Notification from '../../../ui/notification';
import SpouseInviteDialog from './spouse-invite-dialog';

const DIALOGS = {
  SPOUSE_INVITE: 'SPOUSE_INVITE'
};

@withUser({ authenticated: true })
class SpouseInfo extends Component {
  static propTypes = {
    user: PropTypes.shape({
      User: PropTypes.shape({
        sentClientInvites: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string
          })
        )
      })
    }).isRequired
  };

  state = {
    openDialog: null,
    success: false
  };

  render() {
    const { success } = this.state;

    return (
      <>
        {this._renderContent()}
        <Notification
          open={success}
          onClose={() => {
            this.setState({ success: false });
          }}>
          <Icon name="check" color="green" /> Spouse invitation sent
        </Notification>
      </>
    );
  }

  _renderContent() {
    const { User } = this.props.user;

    if (User.sentClientInvites.length) {
      return this._renderSpouse();
    }
    return this._renderSpouseInvite();
  }

  _renderSpouse() {
    const { User } = this.props.user;

    const [clientInvite] = User.sentClientInvites;
    return (
      <div>
        {clientInvite.user.firstName} {clientInvite.user.lastName}
      </div>
    );
  }

  _renderSpouseInvite() {
    return (
      <>
        <Message>
          You&apos;ve not yet invited your spouse to use LifeGuides
        </Message>
        <Button
          layout="outline"
          onClick={() => {
            this.setState({ openDialog: DIALOGS.SPOUSE_INVITE });
          }}>
          Invite Your Spouse
        </Button>
        {this._renderDialogs()}
      </>
    );
  }

  _renderDialogs() {
    const { openDialog } = this.state;

    switch (openDialog) {
      case DIALOGS.SPOUSE_INVITE:
        return (
          <SpouseInviteDialog
            onClose={this._onCloseDialog}
            onSubmit={this._onSubmitSpouseInvite}
          />
        );
      default:
        return null;
    }
  }

  _onCloseDialog = () => {
    this.setState({ openDialog: null });
  };

  _onSubmitSpouseInvite = () => {
    this.setState({ openDialog: null, success: true });
  };
}
export default SpouseInfo;
