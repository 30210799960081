import './guide-video.css';

import { Modal } from '@windmill/react-ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import GuideVideo from '../../../guide-video';

function GuideVideoDialog(props) {
  const { isOpen, onClose, url } = props;

  useEffect(() => {
    const root = document.getElementById('root');
    root.className = 'filter blur';
    return function cleanup() {
      root.className = '';
    };
  }, []);

  return (
    <Modal
      className="guide-video-dialog m-w-full"
      isOpen={isOpen}
      onClose={onClose}>
      <GuideVideo url={url} />
    </Modal>
  );
}
GuideVideoDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string
};
export default GuideVideoDialog;
