import './index.css';

import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { Icon, Menu, Message, Pagination, Tab } from 'semantic-ui-react';

import { ROLES } from '../../../consts';
import SessionsByGuideIdStatusesQuery from '../../../graphql/queries/sessions-by-guide-id-statuses-pagination.graphql';
import graphql from '../../hoc/graphql';
import withUser from '../../hoc/with-user';
import LoadingSpinner from '../../ui/loading-spinner';
import SessionListItem from './session-list-item';

const Tabs = [
  {
    name: 'scheduled',
    orderBy: 'scheduledTime_ASC',
    statuses: ['IN_PROGRESS', 'UPCOMING'],
    title: 'Upcoming Sessions'
  },
  {
    name: 'history',
    orderBy: 'createdAt_DESC',
    statuses: ['CANCELLED', 'COMPLETED', 'ERRORED', 'MISSED'],
    title: 'Session History'
  }
];

const PAGE_SIZE = 10;

function parseSearch(hash) {
  const [, str] = hash.split('?');
  if (!str) {
    return {
      page: 1
    };
  }
  const search = qs.parse(str, { ignoreQueryPrefix: true });
  return {
    page: parseInt(search.page, 10) || 1
  };
}

function searchToVariables(search) {
  const first = PAGE_SIZE;
  const skip = search.page > 0 ? (search.page - 1) * PAGE_SIZE : 0;

  return {
    first,
    skip
  };
}

function queryOptions(props) {
  const { location } = props;
  const { User } = props.user;

  const [, name] = location.hash
    ? /^#(\w+)/.exec(location.hash)
    : [null, 'history'];
  const tab = Tabs.find((t) => t.name === name) || Tabs[0];

  const search = parseSearch(location.hash);

  const variables = searchToVariables(search);
  return {
    fetchPolicy: 'network-only',
    variables: {
      ...variables,
      orderBy: tab.orderBy,
      statuses: tab.statuses,
      guideId: User.id
    }
  };
}

@withUser({
  authenticated: true,
  roles: [ROLES.GUIDE],
  loader: <LoadingSpinner className="my-4 mx-auto w-48 h-48" />,
  showError: true
})
@graphql(SessionsByGuideIdStatusesQuery, {
  name: 'sessions',
  options: queryOptions
})
class SessionTabs extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    sessions: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      sessions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      ),
      sessionsConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      })
    }).isRequired
  };

  _refetch(search, statuses) {
    const { sessions } = this.props;

    this.setState({ loading: true });
    const variables = searchToVariables(search);
    sessions
      .refetch({
        ...variables,
        statuses
      })
      .then(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { location } = this.props;

    const [, name] = location.hash
      ? /^#(\w+)/.exec(location.hash)
      : [null, 'history'];
    const index = Tabs.findIndex((t) => t.name === name) || 0;

    return (
      <div className="session-tabs-component p-4">
        <Tab
          activeIndex={index}
          menu={{
            secondary: true,
            pointing: true,
            stackable: true
          }}
          panes={this._renderPanes()}
          onTabChange={this._onTabChange}
        />
        {this._renderPagination()}
      </div>
    );
  }

  _renderPanes() {
    return Tabs.map(({ name, title }) => {
      return {
        menuItem: <Menu.Item key={`tab-${name}`}>{title}</Menu.Item>,
        render: () => this._renderSessions()
      };
    });
  }

  _renderSessions() {
    const { loading, sessions } = this.props.sessions;

    if (loading) {
      return <LoadingSpinner className="my-4 mx-auto w-48 h-48" />;
    }

    if (!sessions.length) {
      return this._renderEmptyState();
    }

    return (
      <div className="sessions-list">{sessions.map(this._renderSession)}</div>
    );
  }

  _renderEmptyState() {
    return <Message>No records to show</Message>;
  }

  _renderSession = (session) => {
    return <SessionListItem key={`session-${session.id}`} session={session} />;
  };

  _renderPagination() {
    const { location } = this.props;
    const { sessionsConnection } = this.props.sessions;

    const search = parseSearch(location.hash);

    const count = sessionsConnection ? sessionsConnection.aggregate.count : 0;
    const totalPages = Math.ceil(count / PAGE_SIZE);

    if (count < 1) {
      return null;
    }

    return (
      <div textAlign="center">
        <Pagination
          activePage={search.page}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true
          }}
          firstItem={null}
          lastItem={null}
          prevItem={
            count > PAGE_SIZE
              ? { content: <Icon name="angle left lineawesome" />, icon: true }
              : null
          }
          nextItem={
            count > PAGE_SIZE
              ? { content: <Icon name="angle right lineawesome" />, icon: true }
              : null
          }
          onPageChange={this._onPageChange}
          totalPages={totalPages}
        />
      </div>
    );
  }

  _onTabChange = (event, { activeIndex }) => {
    const tab = Tabs[activeIndex];

    this._search({ page: 1 }, tab.name);
  };

  _onPageChange = (e, { activePage }) => {
    this._search({
      page: activePage
    });
  };

  _search(params, _name) {
    const { history, location } = this.props;

    const name =
      _name || location.hash.replace('#', '').split('?')[0] || Tabs[0].name;
    const tab = Tabs.find((t) => t.name === name) || Tabs[0];
    const search = parseSearch(location.hash);
    const updated = {
      ...search,
      ...params
    };

    this._refetch(updated, tab.statuses);

    const url = `${location.pathname}#${name}?${qs.stringify(updated)}`;
    history.push(url);
  }
}
export default SessionTabs;
