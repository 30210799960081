import './availability-form.css';

import range from 'lodash/range';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Checkbox, Dropdown, Form, Input, Label } from 'semantic-ui-react';

import MyForm, { FormField } from '../../ui/form';

const DATE_FORMAT = moment.HTML5_FMT.DATE;
const HOUR_FORMAT = 'hh';
const MINUTE_FORMAT = 'mm';
const AM_PM_FORMAT = 'A';
const TIME_FORMAT = 'HH:mm';
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

const Validators = [
  {
    name: 'endTime',
    validator: function ({ endTime, startTime }) {
      if (!endTime.isAfter(startTime)) {
        throw new Error('End time must be after the start time');
      }
    }
  },
  {
    name: 'startTime',
    validator: function ({ endTime, startTime }) {
      if (!endTime.isAfter(startTime)) {
        throw new Error('Start time must be before the end time');
      }
    }
  }
];

class AvailabilityForm extends Component {
  static propTypes = {
    event: PropTypes.shape({
      end: PropTypes.object.isRequired,
      resource: PropTypes.shape({
        id: PropTypes.string,
        description: PropTypes.string,
        endTime: PropTypes.string,
        isRecurring: PropTypes.bool,
        startTime: PropTypes.string
      }),
      start: PropTypes.object.isRequired
    }),
    onDataChange: PropTypes.func,
    onValidate: PropTypes.func,
    timezone: PropTypes.string
  };

  constructor(props) {
    super(props);

    const { event, timezone } = props;

    function getEndDate() {
      if (event && event.resource) {
        return event.resource
          ? moment(event.resource.endTime)
          : moment.tz(event.end, timezone);
      } else {
        return moment.tz(timezone);
      }
    }

    function getStartDate() {
      if (event && event.resource) {
        return event.resource
          ? moment(event.resource.startTime)
          : moment.tz(event.resource.startTime, timezone);
      } else {
        return moment.tz(timezone);
      }
    }

    function getEffectiveDate(type) {
      if (!event || !event.resource || !event.resource[type]) {
        return null;
      }
      const d = moment(event.resource[type]);
      switch (type) {
        case 'effectiveEndDate':
          d.endOf('day');
          break;
        case 'effectiveStartDate':
          d.startOf('day');
          break;
      }
      return d.format(DATE_FORMAT);
    }

    const data = {
      description: '',
      effectiveEndDate: getEffectiveDate('effectiveEndDate'),
      effectiveStartDate: getEffectiveDate('effectiveStartDate'),
      endDate: getEndDate().format(DATE_FORMAT),
      endDayOfWeek: getEndDate().weekday(),
      endTime: moment().startOf('hour').add(1, 'hour'),
      isRecurring: false,
      startDate: getStartDate().format(DATE_FORMAT),
      startDayOfWeek: getStartDate().weekday(),
      startTime: moment().startOf('hour')
    };

    this.state = {
      data: {
        ...data,
        ...(event && {
          description: (event.resource && event.resource.description) || '',
          endTime: moment(event.end),
          isRecurring: (event.resource && event.resource.isRecurring) || false,
          startTime: moment(event.start)
        })
      },
      dirty: {
        startTime: false,
        endTime: false
      },
      childErrors: null,
      errors: {}
    };
  }

  componentDidMount() {
    const { event } = this.props;

    if (event) {
      this._onDataChange(this.state.data);
    }
  }

  render() {
    const { data, dirty, errors } = this.state;

    const daysOfWeek = range(0, 7).map((i) =>
      moment().weekday(i).format('dddd')
    );
    const times = range(12).map((i) => moment().startOf('day').add(i, 'hours'));

    return (
      <MyForm
        className="availability-form"
        data={data}
        onChange={this._onDataChange}
        onValidate={this._onValidate}>
        <Form.Field>
          <Checkbox
            toggle
            label="Recurring"
            aria-label="Recurring"
            aria-required="true"
            checked={data.isRecurring}
            onChange={() => {
              const isRecurring = !data.isRecurring;

              this._onDataChange({
                ...data,
                isRecurring
              });
            }}
          />
        </Form.Field>
        {data.isRecurring && (
          <Form.Group widths="equal">
            <FormField
              component={Input}
              name="effectiveStartDate"
              label="Effective Starting"
              aria-label="Effective Starting"
              max="9999-12-31"
              min="1900-01-01"
              type="date"
              autoComplete="off"
              validator={({ effectiveStartDate, isRecurring }) => {
                if (!isRecurring && !moment(effectiveStartDate).isValid()) {
                  throw new Error('Not a valid date');
                }
              }}
            />
            <FormField
              component={Input}
              name="effectiveEndDate"
              label="Effective End Date"
              aria-label="Effective End Date"
              max="9999-12-31"
              min="1900-01-01"
              type="date"
              autoComplete="off"
              validator={({ effectiveEndDate, isRecurring }) => {
                if (!isRecurring && !moment(effectiveEndDate).isValid()) {
                  throw new Error('Not a valid date');
                }
              }}
            />
          </Form.Group>
        )}
        <Form.Group widths="equal">
          {data.isRecurring ? (
            <FormField
              component={Dropdown}
              name="startDayOfWeek"
              label="Start Day of Week *"
              aria-label="Start Day of Week"
              fluid
              selection
              search
              options={daysOfWeek.map((dayOfWeek, i) => ({
                text: dayOfWeek,
                value: i
              }))}
            />
          ) : (
            <FormField
              component={Input}
              name="startDate"
              label="Start Date *"
              aria-label="Start Date"
              max="9999-12-31"
              min="1900-01-01"
              type="date"
              autoComplete="off"
              validator={({ startDate, isRecurring }) => {
                if (!isRecurring && !startDate) {
                  throw new Error('Start Date is required');
                }
                const isValid = moment(startDate)
                  .endOf('day')
                  .isAfter(moment().startOf('day'));
                if (!isRecurring && !isValid) {
                  throw new Error('Start Date must not be in the past');
                }
              }}
            />
          )}
          {/*
            TODO: Abstract this time selector into a single re-usable component,
            allowing it to be dropped into the FormField component
            to no longer require wrapping form errors and custom errors
            */}
          <Form.Field>
            <label>Start Time *</label>
            <div className="time-selector">
              <div className="dropdowns">
                <Dropdown
                  selection
                  defaultValue={data.startTime.format(HOUR_FORMAT)}
                  options={times.map((time) => ({
                    text: `${time.format(HOUR_FORMAT)}`,
                    value: time.format(HOUR_FORMAT)
                  }))}
                  onChange={(event, { value }) => {
                    const isPM = data.startTime.format(AM_PM_FORMAT) === 'PM';
                    const hours = parseInt(value, 10) + (isPM ? 12 : 0);
                    data.startTime.hours(hours);
                    this.setState({ dirty: { ...dirty, startTime: true } });
                    this._onDataChange(data);
                  }}
                />
                :
                <Dropdown
                  selection
                  defaultValue={data.startTime.format(MINUTE_FORMAT)}
                  options={[
                    { text: '00', value: '00' },
                    { text: '30', value: '30' }
                  ]}
                  onChange={(event, { value }) => {
                    data.startTime.minutes(parseInt(value, 10));
                    this.setState({ dirty: { ...dirty, startTime: true } });
                    this._onDataChange(data);
                  }}
                />
                &nbsp;
                <Dropdown
                  selection
                  defaultValue={data.startTime.format(AM_PM_FORMAT)}
                  options={[
                    { text: 'AM', value: 'AM' },
                    { text: 'PM', value: 'PM' }
                  ]}
                  onChange={(event, { value }) => {
                    const isChanged =
                      value !== data.startTime.format(AM_PM_FORMAT);
                    if (!isChanged) {
                      return;
                    }
                    const isPM = value === 'PM';
                    const hours = data.startTime.hours() + (isPM ? 12 : -12);
                    data.startTime.hours(hours);
                    this.setState({ dirty: { ...dirty, startTime: true } });
                    this._onDataChange(data);
                  }}
                />
              </div>
              {errors.startTime && dirty.startTime && (
                <Label basic color="red" pointing aria-invalid="true">
                  {errors.startTime.message}
                </Label>
              )}
            </div>
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          {data.isRecurring ? (
            <FormField
              component={Dropdown}
              name="endDayOfWeek"
              label="End Day of Week *"
              aria-label="End Day of Week"
              fluid
              selection
              search
              options={daysOfWeek.map((dayOfWeek, i) => ({
                text: dayOfWeek,
                value: i
              }))}
            />
          ) : (
            <FormField
              component={Input}
              name="endDate"
              label="End Date *"
              aria-label="End Date"
              max="9999-12-31"
              min="1900-01-01"
              type="date"
              autoComplete="off"
              validator={({ endDate, isRecurring }) => {
                if (!isRecurring && !endDate) {
                  throw new Error('End Date is required');
                }
                const isValid = moment(endDate)
                  .endOf('day')
                  .isAfter(moment().startOf('day'));
                if (!isRecurring && !isValid) {
                  throw new Error('End Date must not be in the past');
                }
              }}
            />
          )}
          <Form.Field>
            <label>End Time *</label>
            <div className="time-selector">
              <div className="dropdowns">
                <Dropdown
                  selection
                  defaultValue={data.endTime.format(HOUR_FORMAT)}
                  options={times.map((time) => ({
                    text: `${time.format(HOUR_FORMAT)}`,
                    value: time.format(HOUR_FORMAT)
                  }))}
                  onChange={(event, { value }) => {
                    const isPM = data.endTime.format(AM_PM_FORMAT) === 'PM';
                    const hours = parseInt(value, 10) + (isPM ? 12 : 0);
                    data.endTime.hours(hours);
                    this.setState({ dirty: { ...dirty, endTime: true } });
                    this._onDataChange(data);
                  }}
                />
                :
                <Dropdown
                  selection
                  defaultValue={data.endTime.format(MINUTE_FORMAT)}
                  options={[
                    { text: '00', value: '00' },
                    { text: '30', value: '30' }
                  ]}
                  onChange={(event, { value }) => {
                    data.endTime.minutes(parseInt(value, 10));
                    this.setState({ dirty: { ...dirty, endTime: true } });
                    this._onDataChange(data);
                  }}
                />
                &nbsp;
                <Dropdown
                  selection
                  defaultValue={data.endTime.format(AM_PM_FORMAT)}
                  options={[
                    { text: 'AM', value: 'AM' },
                    { text: 'PM', value: 'PM' }
                  ]}
                  onChange={(event, { value }) => {
                    const isChanged =
                      value !== data.endTime.format(AM_PM_FORMAT);
                    if (!isChanged) {
                      return;
                    }
                    const isPM = value === 'PM';
                    const hours = data.endTime.hours() + (isPM ? 12 : -12);
                    data.endTime.hours(hours);
                    this.setState({ dirty: { ...dirty, endTime: true } });
                    this._onDataChange(data);
                  }}
                />
              </div>
              {errors.endTime && dirty.endTime && (
                <Label basic color="red" pointing aria-invalid="true">
                  {errors.endTime.message}
                </Label>
              )}
            </div>
          </Form.Field>
        </Form.Group>
        <FormField
          component={Input}
          type="text"
          name="description"
          label="Description"
          aria-label="Description"
          placeholder="Optional"
        />
      </MyForm>
    );
  }

  _onDataChange = (data) => {
    const { onDataChange, onValidate } = this.props;
    const { childErrors } = this.state;

    const endDate = data.isRecurring
      ? moment().weekday(data.endDayOfWeek)
      : moment(data.endDate, DATE_FORMAT);
    const endDateStr = endDate.format(DATE_FORMAT);
    const endTime = moment(
      `${endDateStr} ${data.endTime.format(TIME_FORMAT)}`,
      DATE_TIME_FORMAT
    );
    const startDate = data.isRecurring
      ? moment().weekday(data.startDayOfWeek)
      : moment(data.startDate, DATE_FORMAT);
    const startDateStr = startDate.format(DATE_FORMAT);
    const startTime = moment(
      `${startDateStr} ${data.startTime.format(TIME_FORMAT)}`,
      DATE_TIME_FORMAT
    );

    const parsed = {
      ...data,
      endTime,
      startTime
    };

    const errors = Validators.reduce((acc, { name, validator }) => {
      try {
        validator(parsed);
      } catch (error) {
        return { ...acc, [name]: error };
      }
      return acc;
    }, {});

    this.setState({
      data: parsed,
      errors
    });

    if (onDataChange) {
      const formatted = {
        description: parsed.description,
        effectiveEndDate:
          (parsed.effectiveEndDate &&
            moment(parsed.effectiveEndDate).toDate()) ||
          null,
        effectiveStartDate:
          (parsed.effectiveStartDate &&
            moment(parsed.effectiveStartDate).toDate()) ||
          null,
        endTime: parsed.endTime.toDate(),
        isRecurring: parsed.isRecurring,
        startTime: parsed.startTime.toDate()
      };

      onDataChange(formatted);
    }

    if (onValidate) {
      const mergedErrors = {
        ...childErrors,
        ...errors
      };

      onValidate(mergedErrors);
    }
  };

  _onValidate = (childErrors) => {
    const { onValidate } = this.props;
    const { errors } = this.state;

    const mergedErrors = {
      ...childErrors,
      ...errors
    };

    this.setState({ childErrors });

    if (onValidate) {
      onValidate(mergedErrors);
    }
  };
}
export default AvailabilityForm;
