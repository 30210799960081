import qs from 'qs';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ACCESS_CODE_KEY } from '../../../consts';
import Routes from '../../routes';
import AppFooter from '../app-footer';
import LiveChat from '../live-chat';

const ref = React.createRef();

export { ref };
export default function AppWorkspace() {
  const location = useLocation();

  useEffect(
    function () {
      const queryString = qs.parse(location.search, {
        ignoreQueryPrefix: true
      });
      if (queryString.accessCode) {
        localStorage.setItem(ACCESS_CODE_KEY, queryString.accessCode);
      }
    },
    [location]
  );

  return (
    <div className="w-full flex-grow overflow-y-auto" ref={ref}>
      <div className="flex flex-col items-center">
        <Routes />
        <AppFooter />
        <LiveChat />
      </div>
    </div>
  );
}
