import { Button } from '@windmill/react-ui';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { REDIRECT_URL_KEY } from '../../../consts';
import history from '../../../history';
import useUser from '../../hooks/use-user';
import Avatar from '../../ui/avatar';
import LoadingSpinner from '../../ui/loading-spinner/index';
import { DIALOGS } from '../guide/profile-view/dialogs';
import SaveGuideButton from '../guide/save-button';

const MAX_TOPIC_ROWS = 4;

function GuideRow(props) {
  const { guide } = props;
  const {
    id,
    age,
    city,
    firstName,
    guideProfiles,
    isPresent,
    lastName,
    state
  } = guide;

  const [guideProfile] = guideProfiles;
  const topics = guideProfile ? guideProfile.topics : [];

  const location = useLocation();
  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  const { data: userData, loading } = useUser();

  const length =
    topics.length <= MAX_TOPIC_ROWS ? topics.length : MAX_TOPIC_ROWS - 1;
  const sortedTopics = [...topics];
  sortedTopics.sort((a, b) => {
    const ai = search.topicId ? search.topicId.indexOf(a.topic.id) : -1;
    const bi = search.topicId ? search.topicId.indexOf(b.topic.id) : -1;
    return bi - ai;
  });
  const availableTopics = sortedTopics.slice(0, length);

  function onViewClick() {
    history.push({ pathname: `/guide/${id}`, search: location.search });
  }

  function onConnectClick() {
    if (!get(userData, 'User.roles', []).length) {
      const scheduleDialogPath = qs.stringify({
        ...search,
        dialog: DIALOGS.SCHEDULE,
        guideId: id
      });
      const scheduleRedirectPath = location.pathname + '?' + scheduleDialogPath;
      localStorage.setItem(REDIRECT_URL_KEY, scheduleRedirectPath);

      history.push({
        pathname: location.pathname,
        search: qs.stringify({ ...search, dialog: 'REGISTER' })
      });
      return;
    }
    history.push({
      pathname: location.pathname,
      search: qs.stringify({ ...search, dialog: DIALOGS.SCHEDULE, guideId: id })
    });
  }

  return (
    <li
      className="relative text-center md:text-left md:grid md:grid-cols-4 bg-white border rounded-lg p-6 gap-6 space-y-6 md:space-y-0"
      style={{ gridTemplateColumns: '80px minmax(220px, 1fr) 3fr auto' }}>
      <SaveGuideButton
        guide={guide}
        className="absolute bottom-1 left-1 rounded-full w-8 h-8"
      />
      <div className="flex justify-center items-center">
        <Avatar user={guide} style={{ width: '80px', height: '80px' }} />
      </div>
      <div className="flex flex-col space-y-4 items-center md:items-start">
        <div className="font-bold text-4xl">
          {firstName} {lastName?.[0]?.concat('.') || ''}
        </div>
        {isPresent ? (
          <div className="text-green-400 text-lg">Available Now!</div>
        ) : null}
        {city && state && (
          <span>
            <i
              className="icon lineawesome map-marker w-5 mr-1"
              style={{ margin: 0, marginRight: '', width: '1.25rem' }}
            />
            {city}, {state}
          </span>
        )}
        {age && (
          <span>
            <span className="inline-block w-5 mr-1 text-center">&bull;</span>Age{' '}
            {age}
          </span>
        )}
      </div>
      <div className={`grid grid-rows-${length} gap-2`}>
        {availableTopics.map(({ id, short, topic: { title } }) => (
          <div
            key={id}
            className="text-left whitespace-nowrap overflow-ellipsis overflow-hidden">
            <span className="font-extrabold text-blue-500">{title}</span>
            &nbsp; &nbsp;
            <span className="">{short}</span>
          </div>
        ))}
        {topics.length > MAX_TOPIC_ROWS && (
          <div className="font-extrabold whitespace-nowrap">
            + {topics.length - availableTopics.length} more in profile
          </div>
        )}
      </div>
      <div className="flex flex-col justify-around space-y-2">
        <Button onClick={onViewClick}>View Profile</Button>
        <Button disabled={loading} layout="outline" onClick={onConnectClick}>
          {loading ? (
            <>
              <LoadingSpinner className="w-6 h-6" />
              &nbsp;
            </>
          ) : (
            'Connect'
          )}
        </Button>
      </div>
    </li>
  );
}
GuideRow.propTypes = {
  guide: PropTypes.shape({
    id: PropTypes.string,
    age: PropTypes.number,
    city: PropTypes.string,
    firstName: PropTypes.string,
    guideProfiles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        topics: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            short: PropTypes.string,
            topic: PropTypes.shape({
              id: PropTypes.string,
              title: PropTypes.string
            })
          })
        )
      })
    ),
    isPresent: PropTypes.bool,
    lastName: PropTypes.string,
    state: PropTypes.string
  })
};
export default GuideRow;
