import PropTypes from 'prop-types';
import React, { Component } from 'react';

class VideoView extends Component {
  static propTypes = {
    track: PropTypes.object
  };

  constructor(props) {
    super(props);

    this._ref = React.createRef();
  }

  componentDidMount() {
    const videoElement = this._ref.current.querySelector('video');
    videoElement.onloadedmetadata = () => {
      try {
        videoElement.play();
      } catch (error) {
        /* no-op */
      }
    };

    this._setVideoSource();
  }

  componentDidUpdate(prevProps) {
    const { track } = this.props;

    if (prevProps.track === track) {
      return;
    }

    this._setVideoSource();
  }

  _setVideoSource() {
    const { track } = this.props;

    const videoElement = this._ref.current.querySelector('video');
    const supportsSrcObject = 'srcObject' in videoElement;

    if (!track) {
      if (supportsSrcObject) {
        videoElement.srcObject = null;
      } else {
        videoElement.src = null;
      }
      return;
    }

    const videoMediaStream = new MediaStream([track]);
    if (supportsSrcObject) {
      videoElement.srcObject = videoMediaStream;
    } else {
      videoElement.src = window.URL.createObjectURL(videoMediaStream);
    }
  }

  render() {
    return (
      <div
        ref={this._ref}
        className="video-component"
        dangerouslySetInnerHTML={{
          __html: '<video autoplay muted playsinline />'
        }}></div>
    );
  }
}
export default VideoView;
