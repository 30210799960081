import { useQuery } from '@apollo/client';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@windmill/react-ui';
import { Pagination } from 'semantic-ui-react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import TopicsByNamePaginationQuery from '../../../../../graphql/queries/topics-by-name-pagination.graphql';
import TopicsPaginationQuery from '../../../../../graphql/queries/topics-pagination.graphql';
import history from '../../../../../history';
import ErrorMessage from '../../../../ui/error-message';
import LoadingSpinner from '../../../../ui/loading-spinner';

const PAGE_SIZE = 10;

const inputRef = React.createRef();

function GuideProfileTopicsSelectDialog(props) {
  const { data, onClose, onSelect } = props;

  const location = useLocation();
  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  // const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState(search.searchTerm || '');
  const [selectedTopics, setSelectedTopics] = useState([]);

  const variables = {
    first: PAGE_SIZE,
    skip: search.page ? (search.page - 1) * PAGE_SIZE : 0,
    searchTerm: search.searchTerm
  };
  const { data: topicsData, error, loading, refetch } = useQuery(
    search.searchTerm ? TopicsByNamePaginationQuery : TopicsPaginationQuery,
    {
      variables
    }
  );

  // function onBlur() {
  //   setIsSearchFocused(false);
  // }

  // function onFocus() {
  //   setIsSearchFocused(document.activeElement === inputRef.current);
  // }

  // useEffect(function () {
  //   document.addEventListener('blur', onBlur, true);
  //   document.addEventListener('focus', onFocus, true);
  //   return function cleanup() {
  //     document.removeEventListener('blur', onBlur, true);
  //     document.removeEventListener('focus', onFocus, true);
  //   };
  // }, []);

  const setSearchParam = debounce(function (value) {
    history.replace({
      pathname: location.pathname,
      search: qs.stringify({
        ...search,
        searchTerm: value
      })
    });
  }, 500);

  function onSearchChange({ target: { value } }) {
    setSearchTerm(value);
    setSearchParam(value);
  }

  function onClickTopic(topic) {
    const newSelectedTopics = [...selectedTopics];
    const index = newSelectedTopics.findIndex((t) => t.id === topic.id);
    if (index >= 0) {
      newSelectedTopics.splice(index, 1);
    } else {
      newSelectedTopics.push(topic);
    }
    setSelectedTopics(newSelectedTopics);
  }

  function onAddClick() {
    onSelect(selectedTopics);
  }

  function renderSearch() {
    return (
      <div className="relative my-2 flex flex-row items-center">
        <label>Search:</label>&nbsp;
        <Input ref={inputRef} value={searchTerm} onChange={onSearchChange} />
      </div>
    );
  }

  function renderTopic(topic) {
    const { id, title } = topic;

    const isOnProfile = data.topics.some((t) => t.topic.id === id);
    const isSelected = isOnProfile || selectedTopics.some((t) => t.id === id);

    const iconClasses = ['icon square large bg-white'];
    if (isSelected) {
      iconClasses.push('check text-primary');
    } else {
      iconClasses.push('outline text-gray-400');
    }

    return (
      <TableRow
        key={id}
        className={
          isOnProfile ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
        }
        onClick={() => {
          if (!isOnProfile) {
            onClickTopic(topic);
          }
        }}>
        <TableCell>
          <i className={iconClasses.join(' ')} />
        </TableCell>
        <TableCell>{title}</TableCell>
      </TableRow>
    );
  }

  function renderContent() {
    if (error) {
      return <ErrorMessage error={error} retry={() => refetch()} />;
    }
    if (loading) {
      return <LoadingSpinner className="w-48 h-48 mx-auto" />;
    }

    const { topics } = topicsData;
    return (
      <>
        <Table>
          <TableBody>{topics.map(renderTopic)}</TableBody>
        </Table>
      </>
    );
  }

  function renderPagination() {
    if (!data || !topicsData) {
      return <></>;
    }

    const hidePaginationStyles =
      topicsData.topicsConnection.aggregate.count > PAGE_SIZE ? '' : 'hidden';
    const totalPages = Math.ceil(
      topicsData.topicsConnection.aggregate.count / PAGE_SIZE
    );

    const activePage = search.page || 1;
    const startItem = (activePage - 1) * PAGE_SIZE + 1;
    const endItem = Math.min(
      activePage * PAGE_SIZE,
      topicsData.topicsConnection.aggregate.count
    );

    return (
      <div
        className={`flex justify-between items-center ${hidePaginationStyles}`}>
        <span>
          Showing {startItem}-{endItem} of{' '}
          {topicsData.topicsConnection.aggregate.count}
        </span>
        <Pagination
          totalPages={totalPages}
          activePage={activePage}
          pointing
          secondary
          firstItem={null}
          lastItem={null}
          onPageChange={(_e, { activePage }) => {
            history.replace({
              search: qs.stringify({ ...search, page: activePage })
            });
          }}
        />
      </div>
    );
  }

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalHeader>Add Topics</ModalHeader>
      <ModalBody>
        {renderSearch()}
        {renderContent()}
        {renderPagination()}
      </ModalBody>
      <ModalFooter>
        <Button className="w-full sm:w-auto" layout="outline" onClick={onClose}>
          Nevermind
        </Button>
        <Button
          className="w-full sm:w-auto"
          disabled={!selectedTopics.length}
          onClick={onAddClick}>
          Add {selectedTopics.length} Topic
          {selectedTopics.length === 1 ? '' : 's'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
GuideProfileTopicsSelectDialog.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        topic: PropTypes.shape({
          id: PropTypes.string
        })
      })
    )
  }),
  onClose: PropTypes.func,
  onSelect: PropTypes.func
};
export default GuideProfileTopicsSelectDialog;
