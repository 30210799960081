import { useQuery } from '@apollo/client';
import { Button } from '@windmill/react-ui';
import qs from 'qs';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import AchievementsQuery from '../../../graphql/queries/achievements.graphql';
import history from '../../../history';
import Dropdown from './dropdown';

function achievementToOption({ id, title }) {
  return {
    key: id,
    text: title,
    value: id
  };
}

export default function Facets() {
  const location = useLocation();
  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  const { data, error, loading } = useQuery(AchievementsQuery);

  const onAgeChange = useCallback(
    (newAge) => {
      const { age, page, ...remaining } = search;
      const newSearch = {
        ...remaining,
        ...(newAge && { age: newAge })
      };
      history.push({
        pathname: location.pathname,
        search: qs.stringify(newSearch)
      });
    },
    [location.pathname, search]
  );

  const onAvailabilitiesChange = useCallback(
    (availability) => {
      const { availabilities, page, ...remaining } = search;
      const newAvailabilities = availabilities ? [...availabilities] : [];
      const index = newAvailabilities.indexOf(availability);
      if (index >= 0) {
        newAvailabilities.splice(index, 1);
      } else {
        newAvailabilities.push(availability);
      }
      const newSearch = {
        ...remaining,
        ...(newAvailabilities.length && { availabilities: newAvailabilities })
      };
      history.push({
        pathname: location.pathname,
        search: qs.stringify(newSearch)
      });
    },
    [location.pathname, search]
  );

  const onAchievementsChange = useCallback(
    (achievement) => {
      const { achievementId, page, ...remaining } = search;
      const newAchievements = achievementId ? [...achievementId] : [];
      const index = newAchievements.indexOf(achievement);
      if (index >= 0) {
        newAchievements.splice(index, 1);
      } else {
        newAchievements.push(achievement);
      }
      const newSearch = {
        ...remaining,
        ...(newAchievements.length && { achievementId: newAchievements })
      };
      history.push({
        pathname: location.pathname,
        search: qs.stringify(newSearch)
      });
    },
    [location.pathname, search]
  );

  function renderAchievements() {
    if (error) {
      return null;
    }
    if (loading) {
      return null;
    }

    return (
      <Dropdown
        multiple
        onChange={onAchievementsChange}
        options={data ? data.achievements.map(achievementToOption) : null}
        trigger={
          <Button
            className="bg-white"
            layout="outline"
            iconRight={() => (
              <i className="relative icon lineawesome angle down large -right-2" />
            )}>
            {/* This is formerly called Achevements, and will be the Achievements entity in prisma/db -Jesse */}
            Badges
          </Button>
        }
        value={search.achievementId ? search.achievementId : []}
      />
    );
  }

  return (
    <div className="flex flex-row mt-8 space-x-4">
      <Dropdown
        multiple
        onChange={onAvailabilitiesChange}
        options={[
          {
            key: 'WEEKDAYS_MORNINGS',
            text: 'Weekday Mornings',
            value: 'WEEKDAYS_MORNINGS'
          },
          {
            key: 'WEEKDAYS_AFTERNOONS',
            text: 'Weekday Afternoons',
            value: 'WEEKDAYS_AFTERNOONS'
          },
          {
            key: 'WEEKDAYS_EVENINGS',
            text: 'Weekday Evenings',
            value: 'WEEKDAYS_EVENINGS'
          },
          {
            key: 'WEEKENDS_MORNINGS',
            text: 'Weekend Mornings',
            value: 'WEEKENDS_MORNINGS'
          },
          {
            key: 'WEEKENDS_AFTERNOONS',
            text: 'Weekend Afternoons',
            value: 'WEEKENDS_AFTERNOONS'
          },
          {
            key: 'WEEKENDS_EVENINGS',
            text: 'Weekend Evenings',
            value: 'WEEKENDS_EVENINGS'
          }
        ]}
        trigger={
          <Button
            className="bg-white"
            layout="outline"
            iconRight={() => (
              <i className="relative icon lineawesome angle down large -right-2" />
            )}>
            Availability
          </Button>
        }
        value={search.availabilities ? search.availabilities : []}
      />
      {renderAchievements()}
      <div className="hidden">
        <Dropdown
          onChange={onAgeChange}
          options={[
            { key: 'none', text: 'No Preference', value: '' },
            { key: '20s', text: '20 - 29', value: '20,29' },
            { key: '30s', text: '30 - 39', value: '30,39' },
            { key: '40s', text: '40 - 49', value: '40,49' },
            { key: '50s', text: '50 - 59', value: '50,59' },
            { key: '60+', text: '60 +', value: '60' }
          ]}
          trigger={
            <Button
              className="bg-white"
              layout="outline"
              iconRight={() => (
                <i className="relative icon lineawesome angle down large -right-2" />
              )}>
              Age Range
            </Button>
          }
          value={search.age || ''}
        />
      </div>
    </div>
  );
}
