import './index.css';

import Bugsnag from '@bugsnag/js';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Divider,
  Header,
  Icon,
  Message,
  Segment,
  Step
} from 'semantic-ui-react';

import { FBPNG, GOOGSVG } from '../../../assets';
import { setAuthToken } from '../../../auth';
import { FACEBOOK_ACCOUNT_URL, TWITTER_ACCOUNT_URL } from '../../../consts';
import RegisterGuideAuth0Mutation from '../../../graphql/mutations/register-guide-auth0.graphql';
import RegisterGuideMutation from '../../../graphql/mutations/register-guide.graphql';
import GuideApplicationQuery from '../../../graphql/queries/guide-application.graphql';
import graphql from '../../hoc/graphql';
import withAuth0, { Connections } from '../../hoc/with-auth0';
import { friendlyError } from '../../ui/error-dialog';
import RegisterForm from './register-form';

const LocalStorageKey = 'guide-application-id';

@graphql(GuideApplicationQuery, {
  name: 'guideApplication',
  options: ({ match }) => {
    const id = localStorage.getItem(LocalStorageKey) || match.params.id;
    return { variables: { id } };
  }
})
@graphql(RegisterGuideMutation, {
  name: 'registerGuide'
})
@graphql(RegisterGuideAuth0Mutation, {
  name: 'registerGuideAuth0'
})
@withAuth0()
@withRouter
class GuideApplication extends Component {
  static propTypes = {
    auth0: PropTypes.object.isRequired,
    guideApplication: PropTypes.shape({
      guideApplication: PropTypes.object,
      loading: PropTypes.bool.isRequired
    }),
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    registerGuide: PropTypes.func.isRequired,
    registerGuideAuth0: PropTypes.func.isRequired
  };

  state = {
    connecting: false,
    error: null,
    step: 0,
    submitting: false
  };

  componentDidMount() {
    const { auth0, history, match, registerGuideAuth0 } = this.props;

    if (auth0.code) {
      this.setState({ connecting: true });

      const id = localStorage.getItem(LocalStorageKey) || match.params.id;

      const variables = {
        applicationId: id,
        timezone: moment.tz.guess(),
        code: auth0.code
      };

      this.setState({ submitting: true });
      registerGuideAuth0({
        variables
      })
        .then(
          ({
            data: {
              registerGuideAuth0: { authToken }
            }
          }) => {
            setAuthToken(authToken).then(() => {
              this.setState({ connecting: false, step: 2, submitting: false });
            });
          }
        )
        .catch((error) => {
          this.setState({
            connecting: false,
            error,
            step: 1,
            submitting: false
          });
          history.push(`/application/${id}`);
        });
    }
  }

  render() {
    const { connecting } = this.state;

    const content = connecting
      ? this._renderConnecting()
      : this._renderGuideApplication();

    return <div className="guide-application">{content}</div>;
  }

  _renderConnecting() {
    const { auth0 } = this.props;

    if (auth0.error) {
      return (
        <Message negative>
          <p>Authentication error: {auth0.error.errorDescription}</p>
        </Message>
      );
    }

    if (!auth0.user) {
      return null;
    }

    const connectionName = Connections[auth0.user.identities[0].connection];

    return (
      <Segment basic loading>
        {`Connecting to ${connectionName}...`}
      </Segment>
    );
  }

  _renderGuideApplication() {
    const { guideApplication, loading } = this.props.guideApplication;

    if (loading) {
      return <Segment basic loading={loading} />;
    }

    if (!guideApplication) {
      return null;
    }

    switch (guideApplication.status) {
      case 'PENDING':
        return this._renderPending();
      case 'ACCEPTED':
        return this._renderAccepted();
      default:
        return null;
    }
  }

  _renderPending() {
    return (
      <div style={{ textAlign: 'center' }}>
        <Icon name="check circle" size="massive" color="green" />
        <br />
        <Header as="h1">You&apos;re almost a LifeGuide!</Header>
        <p>A representative will contact you in less than</p>
        <Header size="huge" style={{ margin: '0 0 2em' }}>
          24 hours
        </Header>
        <p>IN THE MEANTIME</p>
        <Button
          primary
          size="massive"
          onClick={() => {
            window.open(`https://www.lifeguides.com/`, '_blank');
          }}>
          Learn More about LifeGuides
        </Button>
      </div>
    );
  }

  _renderAccepted() {
    const { step } = this.state;

    if (step === 0) {
      return this._renderCongratulations();
    }

    return (
      <>
        <Step.Group className="step-container" ordered unstackable>
          <Step aria-label="step 1 of 1" active={step === 1} />
        </Step.Group>
        {this._renderError()}
        {step === 1 && this._renderRegistrationForm()}
      </>
    );
  }

  _renderCongratulations() {
    return (
      <div style={{ textAlign: 'center' }}>
        <Header as="h1">Congratulations, You&apos;re a LifeGuide!</Header>
        <p>You soon will be helping alleviate suffering world-wide.</p>
        <p>Thank you for joining us on this mission!</p>
        <br />
        <Icon name="hand peace outline" size="massive" color="black" />
        <br />
        <br />
        <p>There are just a few more steps before getting your first call.</p>
        <Button
          primary
          size="massive"
          onClick={() => {
            this.setState({ step: 1 });
          }}>
          Finish Your Profile
        </Button>
        <br />
        <br />
        <hr style={{ width: '50%' }} />
        <p>© LifeGuides, Inc</p>
        <br />
        <h3>Stay Up to Date !</h3>
        <div>
          <a
            href={FACEBOOK_ACCOUNT_URL}
            target="_blank"
            rel="noopener noreferrer">
            <Icon
              name="facebook f"
              size="big"
              color="grey"
              className="circular inverted"
            />
          </a>
          <a
            href={TWITTER_ACCOUNT_URL}
            target="_blank"
            rel="noopener noreferrer">
            <Icon
              name="twitter"
              size="big"
              color="grey"
              className="circular inverted"
            />
          </a>
        </div>
      </div>
    );
  }

  _renderError() {
    const { error } = this.state;

    if (!error) {
      return null;
    }

    return (
      <Message negative>
        <p>{friendlyError(error.message)}</p>
      </Message>
    );
  }

  _renderRegistrationForm() {
    const { guideApplication } = this.props.guideApplication;

    return (
      <div style={{ textAlign: 'center' }}>
        <Header as="h1">Finish Setting Up Your Account</Header>
        <p>Welcome, LifeGuide! We are happy to have you.</p>
        <div style={{ maxWidth: 480, margin: '0 auto' }}>
          <Divider />
          <Header size="medium">
            {guideApplication.user.firstName} {guideApplication.user.lastName}
            <Header.Subheader>
              {guideApplication.user.emailAddress} -{' '}
              {guideApplication.user.city}, {guideApplication.user.state}
            </Header.Subheader>
          </Header>
          <Divider />
          {this._renderSocialMediaLogIns()}
          <Divider horizontal>or</Divider>
          <RegisterForm onSubmit={this._onSubmitRegistration} />
        </div>
      </div>
    );
  }

  _renderSocialMediaLogIns() {
    return (
      <div className="social-buttons equal width fields">
        <div className="google field">
          <Button
            basic
            className="hollow-button"
            onClick={() => this._connect('google-oauth2')}>
            <img src={GOOGSVG} className="google-logo" /> Sign up with Google
          </Button>
        </div>
        <div className="facebook field">
          <Button
            basic
            className="hollow-button"
            onClick={() => this._connect('facebook')}>
            <img src={FBPNG} className="facebook-logo" />
            Sign up with Facebook
          </Button>
        </div>
      </div>
    );
  }

  _connect(connection) {
    const { auth0 } = this.props;
    const { guideApplication } = this.props.guideApplication;

    localStorage.setItem(LocalStorageKey, guideApplication.id);

    auth0.connect(connection, '/application');
  }

  _onSubmitRegistration = (data) => {
    const { history, registerGuide } = this.props;
    const { guideApplication } = this.props.guideApplication;

    const variables = {
      applicationId: guideApplication.id,
      timezone: moment.tz.guess(),
      ...data
    };

    this.setState({ error: null, submitting: true });
    registerGuide({ variables })
      .then(
        ({
          data: {
            registerGuide: { authToken }
          }
        }) => {
          setAuthToken(authToken).then(() => {
            history.push('/dashboard');
          });
        }
      )
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'GuideApplication._onSubmitRegistration';
          event.request.variables = variables;
        });
      });
  };
}
export default GuideApplication;
