import * as React from 'react';
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={49}
    height={41}
    fill="none"
    {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#6EA3D4"
        d="M27.72 11.274C27.72 5.23 32.173 0 38.278 0 45.534 0 49 6.533 49 13.723c0 11.43-7.59 24.34-21.445 27.277l-1.157-3.92c6.927-2.938 11.386-8.657 11.386-15.521-5.94 0-10.064-4.41-10.064-10.291v.006Zm-26.398 0C1.322 5.23 5.775 0 11.88 0c7.256 0 10.722 6.533 10.722 13.723 0 11.437-7.59 24.34-21.445 27.277L0 37.08c6.927-2.938 11.386-8.657 11.386-15.521-5.94 0-10.064-4.41-10.064-10.291v.006Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M49 41H0V0h49z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
