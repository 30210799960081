import './dashboard-layout.css';
import './guide.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { ROLES } from '../../../consts';
import GuideDashboardQuery from '../../../graphql/queries/guide-dashboard.graphql';
import graphql from '../../hoc/graphql';
import withUser from '../../hoc/with-user';
import withWidth, { isWidthDown } from '../../hoc/with-width';
import LoadingSpinner from '../../ui/loading-spinner/index';
import SessionListItem from '../session-tabs/session-list-item';
import ProfileStepsProgress from './profile-steps';
import profileSteps from './profile-steps/guide-steps';

@withUser({ authenticated: true, roles: [ROLES.GUIDE] })
@graphql(GuideDashboardQuery, {
  options: (props) => {
    const { User } = props.user;
    return {
      fetchPolicy: 'network-only',
      variables: {
        userId: User.id
      }
    };
  }
})
@withRouter
@withWidth()
class GuideDashboard extends Component {
  static propTypes = {
    data: PropTypes.shape({
      sessions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      ),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string
      }).isRequired
    }).isRequired,
    width: PropTypes.number.isRequired,
    match: PropTypes.object.isRequired
  };

  render() {
    const { data } = this.props;
    if (!data) {
      return null;
    }

    return data.loading ? this._loader() : this._dashboard();
  }

  _loader = () => {
    return <LoadingSpinner className="my-4 mx-auto w-48 h-48" />;
  };

  _dashboard = () => {
    const { width } = this.props;
    const isTablet = isWidthDown('computer', width);

    const classes = `guide-dashboard dashboard-layout ${
      isTablet ? 'tablet' : ''
    } dash-avenir`;

    return (
      <div className={classes}>
        <div className="above-fold">{this._renderUpcoming()}</div>

        <div className="below-fold">
          <ProfileStepsProgress
            profileSteps={profileSteps}
            user={this.props.user}
          />
        </div>
      </div>
    );
  };

  _renderUpcoming() {
    const { sessions } = this.props.data;

    if (!sessions) {
      return null;
    }

    const count = sessions.length || 0;

    const header = (
      <span className="guide-details-header">
        Upcoming Sessions{' '}
        <span className="sessions-count">{` (${count}) `}</span>
      </span>
    );

    let callList = (
      <div className={`sessions-placeholder`}>
        <span>You Currently Have No Upcoming Sessions</span>
      </div>
    );

    let seeMore = null;

    if (count) {
      callList = (
        <div>
          {sessions.slice(0, 10).map((session) => (
            <SessionListItem key={`session-${session.id}`} session={session} />
          ))}
        </div>
      );
    }

    if (count > 10) {
      seeMore = (
        <div className="seemore">
          <Button
            primary
            onClick={this._sendToScheduledCalls}
            className="see-more-button">
            See More
          </Button>
        </div>
      );
    }

    return (
      <div className="scheduled-sessions">
        {header}
        {callList}
        {seeMore}
      </div>
    );
  }

  _sendToScheduledCalls = () => {
    const { history } = this.props;
    history.push('/sessions#scheduled');
  };
}
export default GuideDashboard;
