import flow from 'lodash/flow';
import { useEffect } from 'react';

import history from '../../../history';
import withUser from '../../hoc/with-user';

function Settings() {
  useEffect(function () {
    history.push('/settings/profile');
  }, []);

  return null;
}

const withUserQuery = withUser({ authenticated: true, showError: true });

export default flow([withUserQuery])(Settings);
