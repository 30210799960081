import './index.css';

import { graphql } from '@apollo/client/react/hoc';
import Bugsnag from '@bugsnag/js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button } from '@windmill/react-ui';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import {
  Divider,
  Dropdown,
  Form,
  Header,
  Icon,
  Message
} from 'semantic-ui-react';

import apolloClient from '../../../apollo';
import { FBPNG, GOOGSVG } from '../../../assets';
import { setAuthToken } from '../../../auth';
import {
  ACCESS_CODE_KEY,
  CLIENT_INVITE_KEY,
  REDIRECT_URL_KEY,
  SAVED_GUIDES_KEY,
  STRIPE_DEFAULT_PRICE_IDS
} from '../../../consts';
import CreateCardMutation from '../../../graphql/mutations/create-card-and-subscribe.graphql';
import RegisterClientAuth0Mutation from '../../../graphql/mutations/register-client-auth0.graphql';
import RegisterClientMutation from '../../../graphql/mutations/register-client.graphql';
import AccessCodeByCodeQuery from '../../../graphql/queries/access-code-by-code.graphql';
import ClientInviteByIdQuery from '../../../graphql/queries/client-invite-by-id.graphql';
import ClientInvitesByEmailAddressQuery from '../../../graphql/queries/client-invites-by-email-address.graphql';
import PricesQuery from '../../../graphql/queries/prices-by-ids.graphql';
import UserQuery from '../../../graphql/queries/user.graphql';
import history, { breadcrumbs } from '../../../history';
import * as tracker from '../../../tracker';
import withAuth0, { Connections } from '../../hoc/with-auth0';
import withUser from '../../hoc/with-user';
import ErrorDialog from '../../ui/error-dialog';
import ErrorMessage from '../../ui/error-message';
import LoadingSpinner from '../../ui/loading-spinner';
import { ref as WorkspaceRef } from '../app-workspace';
import StripeCardForm from '../stripe-card-form';
import Auth0RegistrationForm from './auth0-registration-form';
import CorporateEmailForm from './corporate-email-form';
import PasswordRegistrationForm from './password-registration-form';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

function formatPrice(price, noInterval) {
  if (!price) {
    return '';
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: price.currency.toUpperCase()
  });

  const amount = formatter.format(price.unitAmount / 100);
  if (noInterval) {
    return amount;
  }

  const count =
    price.recurringIntervalCount === 1
      ? 'a'
      : `every ${price.recurringIntervalCount}`;
  const interval = price.recurringInterval;

  return `${amount} ${count} ${interval}${
    price.recurringIntervalCount === 1 ? '' : 's'
  }`;
}

@graphql(CreateCardMutation, {
  name: 'createCard'
})
@graphql(PricesQuery, {
  name: 'prices',
  options: {
    variables: { id_in: STRIPE_DEFAULT_PRICE_IDS }
  }
})
@graphql(RegisterClientMutation, {
  name: 'registerClient',
  options: {
    refetchQueries: [{ query: UserQuery }],
    awaitRefetchQueries: true,
    update: (
      store,
      {
        data: {
          registerClient: { authToken }
        }
      }
    ) => {
      if (authToken) {
        setAuthToken(authToken);
      }
    }
  }
})
@graphql(RegisterClientAuth0Mutation, {
  name: 'registerClientAuth0',
  options: {
    refetchQueries: [{ query: UserQuery }],
    awaitRefetchQueries: true,
    update: (
      store,
      {
        data: {
          registerClientAuth0: { authToken }
        }
      }
    ) => {
      if (authToken) {
        setAuthToken(authToken);
      }
    }
  }
})
@withAuth0()
@withUser()
@withRouter
class Register extends Component {
  static propTypes = {
    auth0: PropTypes.object.isRequired,
    createCard: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    onError: PropTypes.func,
    prices: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      prices: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          currency: PropTypes.string,
          recurringInterval: PropTypes.string,
          recurringIntervalCount: PropTypes.number,
          unitAmount: PropTypes.number
        })
      )
    }),
    registerClient: PropTypes.func.isRequired,
    registerClientAuth0: PropTypes.func.isRequired,
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        id: PropTypes.string,
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    }).isRequired
  };

  state = {
    accessCode: null,
    clientInvite: null,
    clientInviteRevoked: false,
    corporateEmailAddress: null,
    error: null,
    errorConfirm: null,
    fetching: true,
    registerSuccess: false,
    registering: false,
    priceId: null,
    step: 0,
    steps: [],
    submitting: false,
    userCompletedSteps: false,
    showPrivacyPolicy: false
  };

  _steps = [
    {
      name: 'corporate-email',
      render: this._renderCorporateEmailForm,
      shouldShow: () =>
        !(this.props.auth0.code || this.props.auth0.user) &&
        this._hasAccessCodeWithCompany() &&
        !this._hasClientInviteWithCompany()
    },
    {
      name: 'registration',
      render: this._renderRegistrationForm,
      shouldShow: () => true
    },
    {
      name: 'payment',
      render: this._renderPaymentForm,
      shouldShow: () =>
        !this._hasAccessCodeWithTrialPeriod() &&
        !this._hasAccessCodeWithCompanyThatPays() &&
        !this._hasClientInviteWithCompanyThatPays()
    }
  ];

  constructor(props) {
    super(props);
    this._checkAuthenticated(props);
  }

  componentDidMount() {
    const accessCodeRequest = this._checkAccessCode();
    const clientInviteRequest = this._checkClientInvite();

    Promise.all([accessCodeRequest, clientInviteRequest])
      .then(() => {
        const steps = this._getAvailableSteps();
        this.setState({ fetching: false, steps });
      })
      .catch((error) => {
        const steps = this._getAvailableSteps();
        this.setState({ fetching: false, error, steps });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { error, step } = this.state;

    if (prevState.step !== step) {
      // Only neccessary for Google Analytics tracking, doesn't affect state
      //history.replace(`/register/${step}`);
    }

    if (error && error !== prevState.error && WorkspaceRef.current) {
      // eslint-disable-next-line react/no-find-dom-node
      const workspace = ReactDOM.findDOMNode(WorkspaceRef.current);
      workspace.scrollTop = 0;
    }

    /**
     * Prevent infinite update loop via history.push by only checking
     * for authenticated user if previous props user is null.
     */
    if (!prevProps.user?.User) {
      this._checkAuthenticated(this.props);
    }
  }

  _hasAccessCodeWithCompany() {
    const { accessCode } = this.state;
    return !!(accessCode && accessCode.company);
  }

  _hasAccessCodeWithTrialPeriod() {
    const { accessCode } = this.state;
    return !!(
      accessCode &&
      ((accessCode.company &&
        accessCode.company.prices.some((price) => !!price.trialPeriodDays)) ||
        accessCode.prices.some((price) => !!price.trialPeriodDays))
    );
  }

  _hasAccessCodeWithCompanyThatPays() {
    const { accessCode } = this.state;
    return !!(
      accessCode &&
      accessCode.company &&
      accessCode.company.paysEmployeeSubscription
    );
  }

  _hasClientInviteWithCompany() {
    const { clientInvite } = this.state;
    return !!(clientInvite && clientInvite.company);
  }

  _hasClientInviteWithCompanyThatPays() {
    const { clientInvite } = this.state;
    return !!(
      clientInvite &&
      clientInvite.company &&
      clientInvite.company.paysEmployeeSubscription
    );
  }

  _isInvitedSpouse() {
    const { clientInvite } = this.state;
    return !!(clientInvite && clientInvite.isSpouse);
  }

  _getAvailableSteps() {
    return this._steps.filter((step) =>
      step.shouldShow ? step.shouldShow() : true
    );
  }

  _checkAuthenticated(props) {
    const { User } = props.user;
    const { registering } = this.state;

    if (registering) {
      // Prevent redirect while still on steps
      return;
    }

    if (User && User.roles.length) {
      history.push('/');
    }
  }

  _checkAccessCode() {
    const { location } = this.props;

    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
    const code =
      queryString.accessCode || localStorage.getItem(ACCESS_CODE_KEY);

    if (!code) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      this._getAccessCode(code)
        .then((accessCode) => {
          if (accessCode) {
            if (
              accessCode.startsAt &&
              moment(accessCode.startsAt).isAfter(moment())
            ) {
              return resolve();
            }
            if (
              accessCode.endsAt &&
              moment(accessCode.endsAt).isBefore(moment())
            ) {
              return resolve();
            }
            this.setState({ accessCode }, resolve);
          } else {
            return resolve();
          }
        })
        .catch((error) => reject(error));
    });
  }

  _getAccessCode(code) {
    const variables = { code };
    return apolloClient
      .query({
        query: AccessCodeByCodeQuery,
        variables
      })
      .then(({ data: { accessCode } }) => accessCode);
  }

  _checkClientInvite() {
    const { location } = this.props;

    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
    const inviteId =
      queryString.inviteId || localStorage.getItem(CLIENT_INVITE_KEY);

    if (!inviteId) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      this._getClientInvite(inviteId)
        .then((clientInvite) => {
          if (!clientInvite) {
            reject(new Error('Invalid invitation code'));
          } else if (clientInvite.status === 'REVOKED') {
            reject(
              new Error(
                'The person who invited you appears to have cancelled your invite.'
              )
            );
          } else if (clientInvite.status !== 'PENDING') {
            reject(new Error('Invalid invite, invitation may have expired.'));
          } else {
            localStorage.setItem(CLIENT_INVITE_KEY, inviteId);
            this.setState({ clientInvite }, resolve);
          }
        })
        .catch((error) => reject(error));
    });
  }

  _getClientInvite(id) {
    const variables = { id };
    return apolloClient
      .query({
        query: ClientInviteByIdQuery,
        variables
      })
      .then(({ data: { clientInvite } }) => clientInvite);
  }

  _getClientInvitesByEmailAddress(emailAddress) {
    const variables = { emailAddress };
    return apolloClient
      .query({
        query: ClientInvitesByEmailAddressQuery,
        variables
      })
      .then(({ data: { clientInvites } }) => clientInvites);
  }

  _getAvailablePrices() {
    const { prices } = this.props.prices;
    const { accessCode, clientInvite } = this.state;

    const accessCodePrices =
      accessCode && accessCode.prices.length && accessCode.prices;
    const company =
      (accessCode && accessCode.company) ||
      (clientInvite && clientInvite.company);
    const companyPrices =
      company &&
      !company.paysEmployeeSubscription &&
      company.prices.length &&
      company.prices;
    const defaultPrices = prices || [];
    const availablePrices = accessCodePrices || companyPrices || defaultPrices;

    const intervals = ['day', 'week', 'month', 'year'];
    return [...availablePrices].sort(
      (a, b) =>
        intervals.indexOf(a.recurringInterval) -
        intervals.indexOf(b.recurringInterval)
    );
  }

  _connect(connection) {
    const { auth0 } = this.props;
    const { accessCode, clientInvite } = this.state;

    if (accessCode) {
      // Put the code in storage before redirecting
      localStorage.setItem(ACCESS_CODE_KEY, accessCode.code);
    }
    if (clientInvite) {
      localStorage.setItem(CLIENT_INVITE_KEY, clientInvite.id);
    }

    auth0.connect(connection, '/register');
  }

  render() {
    return <div className="registration">{this._renderContents()}</div>;
  }

  _renderContents() {
    const { fetching, steps } = this.state;

    if (fetching) {
      return this._renderLoading();
    }

    if (steps.length) {
      return this._renderSteps();
    }
  }

  _renderLoading() {
    return <LoadingSpinner className="mx-ayto my-4 w-48 h-48" />;
  }

  _renderSteps() {
    const { step, steps, userCompletedSteps } = this.state;

    return (
      <div className="registration-steps">
        {this._renderAuth0Error()}
        {this._renderError()}
        {userCompletedSteps
          ? this._renderLoading()
          : steps.map((s, i) =>
              i === step ? (
                <React.Fragment key={s.name}>
                  {s.render.apply(this)}
                </React.Fragment>
              ) : null
            )}
      </div>
    );
  }

  _renderLogIn() {
    const { auth0 } = this.props;

    return (
      <div className="already-registered">
        <Button layout="outline" onClick={() => auth0.connect(null, '/login')}>
          Already registered? Log in
        </Button>
      </div>
    );
  }

  _renderAuth0Error() {
    const { auth0 } = this.props;

    if (!auth0.error) {
      return null;
    }

    return (
      <Message negative>
        <p>Authentication error: {auth0.error.errorDescription}</p>
      </Message>
    );
  }

  _renderError() {
    const { error, errorConfirm } = this.state;

    if (!error) {
      return null;
    }

    if (errorConfirm) {
      return (
        <ErrorDialog
          error={error}
          onClose={() => {
            errorConfirm();
          }}
        />
      );
    }

    return <ErrorMessage error={error} />;
  }

  _renderCorporateEmailForm() {
    const { accessCode, submitting } = this.state;

    // let corporateEmailHeaderText = <h1>Enter Your Corporate E-mail</h1>;

    return (
      <div className="registration-step corporate-email-step">
        {/*corporateEmailHeaderText*/}
        {accessCode.company.logoUrl && (
          <img
            src={accessCode.company.logoUrl}
            alt={accessCode.company.name}
            className="company-logo"
          />
        )}
        <CorporateEmailForm
          submitting={submitting}
          onSubmit={this._onSubmitCorporateEmail}
        />
        <br />
        {this._renderLogIn()}
      </div>
    );
  }

  _renderRegistrationForm() {
    const { auth0 } = this.props;

    return auth0.user
      ? this._renderAuth0Registration()
      : this._renderPasswordRegistration();
  }

  _renderAuth0Registration() {
    const { user } = this.props.auth0;
    const { clientInvite, showPrivacyPolicy, submitting } = this.state;

    if (!user) {
      return null;
    }

    const connectionName = Connections[user.identities[0].connection];

    return (
      <div className="registration-step credentials auth0">
        {this._renderSignupHeader()}
        <Header as="h2">
          {user.given_name} {user.family_name}
          <Header.Subheader>
            <Icon name="check" />
            {connectionName} Connected
          </Header.Subheader>
        </Header>
        <Divider />
        <Auth0RegistrationForm
          clientInvite={clientInvite}
          submitting={submitting}
          onSubmit={this._onSubmitAuth0Registration}
          showPrivacyPolicy={showPrivacyPolicy}
          onClosePrivacyPolicy={() => {
            this.setState({
              showPrivacyPolicy: false
            });
          }}>
          {this._renderSignupCopy()}
        </Auth0RegistrationForm>
      </div>
    );
  }

  _renderPasswordRegistration() {
    const { clientInvite, showPrivacyPolicy, submitting } = this.state;

    return (
      <div className="registration-step credentials email-password">
        {this._renderSignupHeader()}
        {this._renderSocialMediaLogIns()}
        <PasswordRegistrationForm
          clientInvite={clientInvite}
          submitting={submitting}
          onSubmit={this._onSubmitRegistration}
          showPrivacyPolicy={showPrivacyPolicy}
          onClosePrivacyPolicy={() => {
            this.setState({
              showPrivacyPolicy: false
            });
          }}>
          {this._renderSignupCopy()}
        </PasswordRegistrationForm>
        <br />
        {this._renderLogIn()}
      </div>
    );
  }

  _renderSignupHeader() {
    const redirectUrl = localStorage.getItem(REDIRECT_URL_KEY);

    return (
      <div>
        <Header as="h1">
          {redirectUrl
            ? 'Create an Account to Book Your Call'
            : 'Create an Account'}
        </Header>
      </div>
    );
  }

  _renderSignupCopy = () => {
    return (
      <p>
        Keep your information confidential by using your personal email account.{' '}
        <a
          href="#"
          onClick={() => {
            this.setState({
              showPrivacyPolicy: true
            });
          }}>
          Learn more.
        </a>
      </p>
    );
  };

  _renderSocialMediaLogIns() {
    const buttons = [
      { connection: 'google-oauth2', imgSrc: GOOGSVG, name: 'Google' },
      { connection: 'facebook', imgSrc: FBPNG, name: 'Facebook' }
    ];
    return (
      <div className="mb-8 w-full flex flex-col space-y-4">
        {buttons.map(({ connection, imgSrc, name }) => (
          <Button
            key={name}
            layout="outline"
            block
            iconLeft={() => <img src={imgSrc} className="w-8 mr-6" />}
            className=""
            style={{ justifyContent: 'start' }}
            onClick={() => this._connect(connection)}>
            Sign up with {name}
          </Button>
        ))}
      </div>
    );
  }

  _renderPaymentForm() {
    const { loading: pricesLoading } = this.props.prices;
    const { loading: stateLoading, priceId, submitting } = this.state;

    const loading = pricesLoading || stateLoading;

    const prices = this._getAvailablePrices();
    const [defaultPrice] = prices;
    const value = priceId || (defaultPrice && defaultPrice.id) || '';
    const selectedPrice = prices.find((p) => p.id === value);

    return (
      <div className="form-frame" key="payment-form">
        <Header as="h1">Checkout</Header>
        <p>To schedule calls, you will need to purchase a subscription.</p>
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <StripeCardForm
                elements={elements}
                stripe={stripe}
                submitButtonText={
                  selectedPrice
                    ? `Checkout for ${formatPrice(selectedPrice, true)}`
                    : 'Checkout'
                }
                submitting={loading || submitting}
                onSubmit={this._onSubmitCard}>
                <Form.Field>
                  <label>Plan</label>
                  <Dropdown
                    loading={loading}
                    fluid
                    selection
                    options={prices.map((price) => ({
                      key: `price-${price.id}`,
                      text: formatPrice(price),
                      value: price.id
                    }))}
                    value={value}
                    onChange={(_, { value }) => {
                      this.setState({ priceId: value });
                    }}
                  />
                </Form.Field>
              </StripeCardForm>
            )}
          </ElementsConsumer>
        </Elements>
      </div>
    );
  }

  _register(data, mutation) {
    const { accessCode, clientInvite, corporateEmailAddress } = this.state;

    const storage = localStorage.getItem(SAVED_GUIDES_KEY);
    const savedGuides = storage ? JSON.parse(storage) : [];

    const variables = {
      accessCode: accessCode ? accessCode.code : null,
      breadcrumbs: breadcrumbs(),
      clientInviteId: clientInvite ? clientInvite.id : null,
      corporateEmailAddress,
      savedGuides,
      timezone: moment.tz.guess(),
      ...data
    };

    this.setState({ error: null, submitting: true });
    mutation({ variables })
      .then(this._onRegistrationSuccess)
      .catch(this._onRegistrationError.bind(this, variables));
  }

  _onSubmitCorporateEmail = (data) => {
    this.setState({ error: null, submitting: true });
    this._getClientInvitesByEmailAddress(data.corporateEmailAddress).then(
      (clientInvites) => {
        if (clientInvites.length) {
          const [clientInvite] = clientInvites.sort((a, b) =>
            a.status === b.status ? 0 : a.status === 'PENDING' ? -1 : 1
          );
          this.setState(
            {
              clientInvite,
              submitting: false
            },
            this._nextStep
          );
        } else {
          this.setState(
            {
              corporateEmailAddress: data.corporateEmailAddress,
              submitting: false
            },
            this._nextStep
          );
        }
      }
    );
  };

  _onSubmitAuth0Registration = (data) => {
    const { auth0, registerClientAuth0 } = this.props;

    this._register(
      {
        ...data,
        code: auth0.code
      },
      registerClientAuth0
    );
  };

  _onSubmitRegistration = (data) => {
    const { registerClient } = this.props;

    this._register(data, registerClient);
  };

  _onSubmitCard = (source) => {
    const { createCard, onError } = this.props;
    const { priceId } = this.state;

    const prices = this._getAvailablePrices();
    const [defaultPrice] = prices;

    const variables = {
      source: source.id,
      priceId: priceId || (defaultPrice && defaultPrice.id)
    };

    this.setState({ submitting: true });
    createCard({ variables })
      .then(() => {
        this.setState({ submitting: false }, this._nextStep);
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'Register._onSubmitCard';
          event.request.variables = variables;
        });
      });
  };

  _onRegistrationError(variables, error) {
    const { onError } = this.props;

    if (onError) {
      onError(error);
    }

    tracker.event('signUp', 0);

    this.setState({ error, submitting: false });
    Bugsnag.notify(error, function (event) {
      event.context = 'Register._onRegistrationError';
      event.request.variables = variables;
    });
  }

  _onRegistrationSuccess = () => {
    tracker.event('signUp', 1);

    localStorage.removeItem(ACCESS_CODE_KEY);
    localStorage.removeItem(CLIENT_INVITE_KEY);
    localStorage.removeItem(SAVED_GUIDES_KEY);

    this.setState(
      {
        error: null,
        registerSuccess: true,
        submitting: false
      },
      this._nextStep
    );
  };

  _nextStep = () => {
    const { step, steps } = this.state;

    const nextIndex = step + 1;
    const userCompletedSteps = nextIndex >= steps.length;

    this.setState(
      {
        registering: this.state.registerSuccess ? false : true,
        step: nextIndex,
        userCompletedSteps
      },
      () => {
        if (userCompletedSteps) {
          this._completeSteps();
        }
      }
    );
  };

  _completeSteps = () => {
    const { location } = this.props;
    const { userCompletedSteps } = this.state;

    if (!userCompletedSteps) {
      return;
    }

    const redirectUrl = localStorage.getItem(REDIRECT_URL_KEY);
    if (redirectUrl) {
      localStorage.removeItem(REDIRECT_URL_KEY);
      history.push(redirectUrl);
      return;
    }

    history.push({ pathname: location.pathname, search: '' });
  };
}
export default Register;
