import './index.css';
import '../create-session-form/session-select-form-layout.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';

import apolloClient from '../../../../../apollo';
import { DEFAULT_AVATAR_URL } from '../../../../../consts';
import ClientsByNameQuery from '../../../../../graphql/queries/clients-by-name.graphql';
import MyForm, { FormField } from '../../../../ui/form';
import ClientInfo from './info';

const SEARCH_SPEED = 500;

class ClientSelector extends Component {
  static propTypes = {
    onChange: PropTypes.func
  };

  state = {
    clients: [],
    loading: false,
    selectedClient: null
  };

  _query(searchQuery) {
    const parts = searchQuery.split(/\s+/);

    this.setState({ loading: true, searchQuery });
    const requests = parts.map((name) => {
      const variables = { name: name.toLowerCase() };
      return apolloClient
        .query({ query: ClientsByNameQuery, variables })
        .then(({ data: { users } }) => users);
    });
    Promise.all(requests).then((results) => {
      const [first, ...remaining] = results;
      const clients = remaining.reduce(
        (acc, cs) => _.intersectionBy(acc, cs, (c) => c.id),
        first
      );
      this.setState({ clients, loading: false });
    });
  }

  render() {
    const { loading, searchQuery, selectedClient } = this.state;

    const url =
      (selectedClient && selectedClient.avatarUrl) || DEFAULT_AVATAR_URL;

    return (
      <MyForm
        className="call-request-form-client-selector my-form-container"
        data={{
          selectedClient
        }}
        onChange={this._onDataChange}>
        <div className="client-selector-avatar field-container">
          <div
            className="field-cell circular-avi"
            style={{ backgroundImage: `url(${url})` }}
          />
        </div>
        <div className="client-selector-user field-container">
          <FormField
            component={Dropdown}
            className="field-cell fluid"
            name="selectedClient"
            label="Member"
            deburr
            loading={loading}
            fluid
            noResultsMessage={
              loading
                ? 'Searching'
                : searchQuery
                ? 'No results found'
                : 'Type a name to search'
            }
            search
            selection
            options={this.clientsToClientOptions()}
            onSearchChange={this._onSearchChange}
          />
        </div>
        <div className="client-selector-info field-container">
          <ClientInfo id={selectedClient && selectedClient.id} />
        </div>
      </MyForm>
    );
  }

  clientsToClientOptions = () => {
    const { clients } = this.state;

    return _.map(clients, (client) => {
      return {
        text: `${client.firstName} ${client.lastName}`,
        value: client,
        key: client.id,
        image: { avatar: true, src: client.avatarUrl || DEFAULT_AVATAR_URL }
      };
    });
  };

  _onDataChange = (data) => {
    const { onChange } = this.props;

    this.setState(data);
    if (_.isFunction(onChange)) {
      onChange(data);
    }
  };

  _onSearchChange = _.debounce((event, { searchQuery }) => {
    this._query(searchQuery);
  }, SEARCH_SPEED);
}

export default ClientSelector;
