import { useMutation } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import debounce from 'lodash/debounce';
import intersectionBy from 'lodash/intersectionBy';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useState } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';

import ApolloClient from '../../../../../../apollo';
import { DEFAULT_AVATAR_URL } from '../../../../../../consts';
import UpdateUserConnectAchievementMutation from '../../../../../../graphql/mutations/update-user-connect-achievement.graphql';
import GuidesByAchievementsPaginationQuery from '../../../../../../graphql/queries/guides-by-achievements-pagination.graphql';
import GuidesByNameQuery from '../../../../../../graphql/queries/guides-by-name.graphql';
import ErrorDialog from '../../../../../ui/error-dialog';
import { locationToVariables } from './params';

const SEARCH_SPEED = 500;

function AchievementGuideSearch(props) {
  const { page } = props;

  const [error, setError] = useState(null);
  const [guides, setGuides] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedGuide, setSelectedGuide] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const [connectGuide] = useMutation(UpdateUserConnectAchievementMutation, {
    refetchQueries: [
      {
        query: GuidesByAchievementsPaginationQuery,
        variables: locationToVariables(location, page)
      }
    ]
  });

  function query(searchQuery) {
    const parts = searchQuery.split(/\s+/);

    setLoading(true);
    setSearchQuery(searchQuery);

    const requests = parts.map((name) => {
      const variables = { name: name.toLowerCase() };
      return ApolloClient.query({ query: GuidesByNameQuery, variables }).then(
        ({ data: { users } }) => users
      );
    });
    Promise.all(requests).then((results) => {
      const [first, ...remaining] = results;
      const guides = remaining.reduce(
        (acc, gs) => intersectionBy(acc, gs, (g) => g.id),
        first
      );
      setLoading(false);
      setGuides(guides);
    });
  }

  function onChange(event, { value }) {
    const selectedGuide = guides.find((g) => g.id === value);
    setSelectedGuide(selectedGuide);
  }

  const onSearchChange = debounce(function (event, { searchQuery }) {
    query(searchQuery);
  }, SEARCH_SPEED);

  function onAddClick() {
    const search = qs.parse(location.search, { ignoreQueryPrefix: true });

    const variables = {
      userId: selectedGuide.id,
      achievementId: search.id
    };

    setError(null);
    setSubmitting(true);
    connectGuide({ variables })
      .then(() => {
        setGuides([]);
        setSearchQuery('');
        setSelectedGuide(null);
        setSubmitting(false);
      })
      .catch((error) => {
        setError(error);
        setSubmitting(false);
        Bugsnag.notify(error, function (event) {
          event.context = 'AdminAchievementGuidesDialog._onAddClick';
          event.request.variables = variables;
        });
      });
  }

  function guidesToOptions() {
    return guides.map((guide) => ({
      key: guide.id,
      image: {
        avatar: true,
        src: guide.avatarUrl || DEFAULT_AVATAR_URL
      },
      text: `${guide.firstName} ${guide.lastName}`,
      value: guide.id
    }));
  }

  return (
    <>
      <div className="flex flex-row space-x-4 items-center">
        <label>Search Guides</label>
        <Dropdown
          loading={loading}
          deburr
          fluid
          noResultsMessage={
            loading
              ? 'Searching'
              : searchQuery
              ? 'No results found'
              : 'Type a name to search'
          }
          search
          selection
          options={guidesToOptions()}
          value={(selectedGuide && selectedGuide.id) || ''}
          onChange={onChange}
          onSearchChange={onSearchChange}
        />
        <Button
          primary
          loading={submitting}
          disabled={!selectedGuide || submitting}
          onClick={onAddClick}>
          Assign to achievement
        </Button>
      </div>
      <ErrorDialog
        error={error}
        onClose={() => {
          setError(null);
        }}
      />
    </>
  );
}
AchievementGuideSearch.propTypes = {
  page: PropTypes.number.isRequired
};
export default AchievementGuideSearch;
