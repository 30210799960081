import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import history from '../../history';
import useUser from '../hooks/use-user';
import ErrorMessage from '../ui/error-message';
import LoadingSpinner from '../ui/loading-spinner';

function hasRole(user, roleName) {
  return user.roles.some((role) => role.name === roleName);
}

function isAuthorized(user, auth) {
  if (user && user.roles.length) {
    if (isBoolean(auth)) {
      return auth;
    }
    if (isString(auth)) {
      return hasRole(user, auth);
    }
    if (isArray(auth)) {
      return auth.some((role) => hasRole(user, role));
    }
  } else {
    return !auth;
  }
}

function AuthRoute({ children, authenticated, ...rest }) {
  const { data, error, loading, refetch } = useUser();

  return (
    <Route
      {...rest}
      render={function ({ location }) {
        if (error) {
          return <ErrorMessage error={error} retry={() => refetch()} />;
        } else if (loading) {
          return (
            <div className="h-48">
              <LoadingSpinner className="w-48 h-48" />
            </div>
          );
        } else if (!data) {
          return null;
        } else if (!isAuthorized(data.User, authenticated)) {
          if (data.User) {
            history.push('/401');
            return null;
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location }
                }}
              />
            );
          }
        }
        return children;
      }}
    />
  );
}

AuthRoute.propTypes = {
  authenticated: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  children: PropTypes.node
};

export default AuthRoute;
