import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, Icon, Segment } from 'semantic-ui-react';

import AdminDashboardQuery from '../../../graphql/queries/admin-dashboard.graphql';
import graphql from '../../hoc/graphql';

@graphql(AdminDashboardQuery, {
  options: {
    fetchPolicy: 'network-only',
    variables: {
      time: new Date().toISOString()
    }
  }
})
class AdminDashboard extends Component {
  static propTypes = {
    data: PropTypes.shape({
      accessCodesConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      }),
      guideApplicationsConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      }),
      loading: PropTypes.bool.isRequired,
      usersConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      }),
      companiesConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      })
    }).isRequired
  };

  render() {
    return (
      <Segment basic>
        <Card.Group stackable itemsPerRow={4}>
          {this._renderPendingGuideApplications()}
          {this._renderManage()}
        </Card.Group>
      </Segment>
    );
  }

  _renderPendingGuideApplications() {
    const { guideApplicationsConnection, loading } = this.props.data;

    const content = loading ? (
      <Segment basic loading />
    ) : (
      this._renderCardContent(
        'clipboard',
        guideApplicationsConnection.aggregate.count
      )
    );

    return this._renderCard(
      content,
      'Pending Guide Applications',
      '/admin/guide-applications',
      'View and Approve'
    );
  }

  _renderManage() {
    const links = [
      { path: '/admin/access-codes', text: 'Access Codes' },
      { path: '/admin/companies', text: 'Companies' },
      { path: '/admin/achievements', text: 'Guide Achievements' },
      { path: '/guide-availability', text: 'Guide Availability' },
      { path: '/admin/topic-groups', text: 'Topic Categories' },
      { path: '/admin/topics', text: 'Topics' },
      { path: '/admin/users', text: 'Users' }
    ];

    return (
      <Card style={{ textAlign: 'center' }}>
        <Card.Content style={{ padding: '2em 0' }}>
          <Card.Header style={{ fontSize: '1em' }}>Manage Content</Card.Header>
        </Card.Content>
        <Card.Content extra>
          {links.map(({ path, text }, index) => (
            <Link
              key={index}
              className="ui block"
              to={{ pathname: path, search: '' }}>
              {text}
            </Link>
          ))}
        </Card.Content>
      </Card>
    );
  }

  _renderCardContent(icon, number) {
    return (
      <div>
        <Icon
          name={icon}
          style={{
            marginLeft: '-1em',
            fontSize: '.9em',
            color: '#bebebe'
          }}
        />
        {number}
      </div>
    );
  }

  _renderCard(content, title, linkPath, linkText) {
    return (
      <Card style={{ textAlign: 'center' }}>
        <Card.Content style={{ padding: '2em 0' }}>
          <Card.Header style={{ fontSize: '1em' }}>{title}</Card.Header>
          <Card.Description
            style={{
              margin: '.5em 0',
              fontSize: '2.75em',
              fontWeight: 'bold',
              color: '#000'
            }}>
            {content}
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Link className="ui" to={{ pathname: linkPath, search: '' }}>
            {linkText}
          </Link>
        </Card.Content>
      </Card>
    );
  }
}
export default AdminDashboard;
