import React from 'react';

import Intro from './intro';
import PresentGuides from './present-guides';
import TopicGroups from './topic-groups';

export default function Home() {
  return (
    <>
      <Intro />
      <TopicGroups />
      <PresentGuides />
    </>
  );
}
