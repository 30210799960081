import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Intercom, { IntercomAPI } from 'react-intercom';
import { withRouter } from 'react-router-dom';

import { ROLES } from '../../../consts';
import withUser from '../../hoc/with-user';

const SUPPORTED_ROLES = [ROLES.CLIENT, ROLES.GUIDE];
const FULLSCREEN_ROUTES = ['chat-now'];
const BLACKLISTED_ROUTES = ['/call/'];

@withUser()
@withRouter
class LiveChat extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string
    }),
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string,
        emailAddress: PropTypes.string,
        companyIds: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            company: PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string
            })
          })
        ),
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    })
  };

  state = {
    booted: false
  };

  _isFullscreenChat = () => {
    const { location } = this.props;
    return FULLSCREEN_ROUTES.reduce((acc, route) => {
      return (
        acc || (location.pathname && location.pathname.indexOf(route) > -1)
      );
    }, false);
  };

  _isBlacklistedRoute = () => {
    const { location } = this.props;
    return BLACKLISTED_ROUTES.reduce((acc, route) => {
      return acc || (location.pathname && location.pathname.startsWith(route));
    }, false);
  };

  componentDidMount() {
    if (this._isFullscreenChat()) {
      IntercomAPI('show');
    } else {
      IntercomAPI('hide');
    }
  }

  componentDidUpdate() {
    if (this._isFullscreenChat()) {
      IntercomAPI('show');
    } else {
      IntercomAPI('hide');
    }
  }

  _injectFullscreenCss = () => {
    return (
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .intercom-namespace .intercom-messenger-frame {
            width: 70% !important;
            bottom: 0 !important;
            right: 0 !important;
            left: 30% !important;
            height: calc(100% - 84px) !important;
            min-height: unset !important;
            max-height: unset !important;
            border-radius: 0 !important;
            animation: fade-in 1s;
            box-shadow: none !important;
            z-index: 1 !important;
          }
          .intercom-namespace .intercom-launcher-frame {
            display: none;
          }

          .intercom-namespace .intercom-launcher-frame + div,
          .intercom-modal-container {
            opacity: 0 !important;
          }
        `
        }}
      />
    );
  };

  render() {
    if (!process.env.REACT_APP_INTERCOM_APP_ID) {
      return null;
    }

    const { User } = this.props.user;

    const isSupported =
      !User || User.roles.some((role) => SUPPORTED_ROLES.includes(role.name));
    if (!isSupported) {
      return null;
    }

    if (this._isBlacklistedRoute()) {
      return null;
    }

    const userData = User
      ? {
          user_id: User.id,
          email: User.emailAddress,
          name: `${User.firstName} ${User.lastName}`,
          companies: User.companyIds
        }
      : null;

    return (
      <>
        {this._isFullscreenChat() ? this._injectFullscreenCss() : null}
        <Intercom appID={process.env.REACT_APP_INTERCOM_APP_ID} {...userData} />
      </>
    );
  }
}
export default LiveChat;
