import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dimmer, Icon, Label, Loader, Popup } from 'semantic-ui-react';

import ClientByIdQuery from '../../../../../graphql/queries/client-by-id-concierge.graphql';
import graphql from '../../../../hoc/graphql';

@graphql(ClientByIdQuery, {
  name: 'client',
  skip: ({ id }) => !id,
  options: ({ id }) => ({ variables: { id } })
})
class ClientInfo extends Component {
  static propTypes = {
    id: PropTypes.string,
    client: PropTypes.shape({
      loading: PropTypes.bool,
      user: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        timezone: PropTypes.string
      })
    })
  };

  render() {
    const { id } = this.props;

    if (!id) {
      return <div className="info-box empty" />;
    }

    const { loading, user } = this.props.client;

    if (loading) {
      return (
        <Dimmer active inverted style={{ position: 'relative' }}>
          <Loader />
        </Dimmer>
      );
    }

    const companyName =
      user.companyIds?.[0]?.company?.name || 'Company name not available';

    return (
      <div className="info-box">
        <Popup
          trigger={
            <Label basic>
              <Icon name="clock" />
              {` (${moment.tz(user.timezone).format('z')})`}
            </Label>
          }
          header="Timezone"
          content={`${user.firstName} is in the ${user.timezone} region`}
        />
        <Popup
          trigger={
            <Label basic>
              <Icon name="building" />
              {companyName}
            </Label>
          }
          header="Company"
          content={`${user.firstName} works at ${companyName}`}
        />
      </div>
    );
  }
}
export default ClientInfo;
