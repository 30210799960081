import { graphql } from '@apollo/client/react/hoc';
import { isEmpty, sampleSize } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Dropdown, Form, Input, Modal } from 'semantic-ui-react';

import CompaniesQuery from '../../../../graphql/queries/all-companies.graphql';
import PricesQuery from '../../../../graphql/queries/prices.graphql';
import MyForm, { FormField } from '../../../ui/form';

function generateCode() {
  const chars =
    'abcdefghijklmnopqrstufwxyzABCDEFGHIJKLMNOPQRSTUFWXYZ1234567890';
  return sampleSize(chars, 8).join('');
}

@graphql(CompaniesQuery, { name: 'companies' })
@graphql(PricesQuery, { name: 'prices' })
class EditDialog extends Component {
  static propTypes = {
    accessCode: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
      company: PropTypes.shape({
        id: PropTypes.string
      }),
      endsAt: PropTypes.string,
      name: PropTypes.string,
      priceIds: PropTypes.arrayOf(PropTypes.string),
      route: PropTypes.string,
      startsAt: PropTypes.string
    }).isRequired,
    companies: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      companies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      )
    }).isRequired,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    prices: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      prices: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      )
    }),
    submitting: PropTypes.bool
  };

  constructor(props) {
    super(props);

    const { accessCode } = props;

    const data = {
      code: accessCode.code || generateCode(),
      endsAt: accessCode.endsAt
        ? moment.utc(accessCode.endsAt).format('YYYY-MM-DD')
        : '',
      name: accessCode.name || '',
      priceIds: accessCode.priceIds || [],
      route: accessCode.route || '',
      startsAt: accessCode.startsAt
        ? moment.utc(accessCode.startsAt).format('YYYY-MM-DD')
        : '',
      companyId: accessCode.company ? accessCode.company.id : null
    };

    this.state = {
      data,
      isValid: false
    };
  }

  render() {
    const { accessCode, submitting } = this.props;
    const { loading: companiesLoading, companies } = this.props.companies;
    const { loading: pricesLoading, prices } = this.props.prices;
    const { data, isValid } = this.state;

    const isNew = !accessCode.id;

    const publishedCompanies = companies
      ? companies
          .filter((company) => !company.isDeleted)
          .map((company) => ({
            text: company.name,
            value: company.id
          }))
      : [];

    return (
      <Modal
        open
        size="tiny"
        closeOnDimmerClick={false}
        onClose={this._onClose}>
        <Modal.Content>
          <Modal.Description>
            <MyForm
              data={data}
              onChange={this._onDataChange}
              onValidate={(errors) => {
                this.setState({ isValid: isEmpty(errors) });
              }}>
              <FormField
                component={Input}
                name="code"
                label="Code"
                autoComplete="off"
                action={{
                  labelPosition: 'right',
                  icon: 'gear',
                  content: 'Generate',
                  onClick: () => {
                    const { data } = this.state;
                    const updatedData = {
                      ...data,
                      code: generateCode()
                    };
                    this.setState({
                      data: updatedData
                    });
                  }
                }}
                validator={({ code }) => {
                  if (!code) {
                    throw new Error('Code is required');
                  }
                }}
              />
              <FormField
                component={Input}
                name="route"
                label="Route"
                autoComplete="off"
              />
              <FormField
                component={Input}
                name="name"
                label="Name"
                autoComplete="off"
                validator={({ name }) => {
                  if (!name) {
                    throw new Error('Name is required');
                  }
                }}
              />
              <FormField
                component={Dropdown}
                name="companyId"
                label="Companies"
                fluid
                selection
                loading={companiesLoading}
                options={[{ text: '', value: null }].concat(publishedCompanies)}
              />
              <Form.Group widths="equal">
                <FormField
                  component={Input}
                  name="startsAt"
                  label="Starts"
                  type="date"
                  autoComplete="off"
                  validator={({ startsAt }) => {
                    if (startsAt && !moment(startsAt).isValid()) {
                      throw new Error('Start must be a valid date');
                    }
                  }}
                />
                <FormField
                  component={Input}
                  name="endsAt"
                  label="Ends"
                  type="date"
                  autoComplete="off"
                  validator={({ endsAt }) => {
                    if (endsAt && !moment(endsAt).isValid()) {
                      throw new Error('End must be a valid date');
                    }
                  }}
                />
              </Form.Group>
              <FormField
                component={Dropdown}
                name="priceIds"
                label="Prices"
                fluid
                selection
                multiple
                loading={pricesLoading}
                options={
                  prices
                    ? prices.map((price) => ({
                        key: `price-${price.id}`,
                        text: `${price.nickname}`,
                        value: price.id
                      }))
                    : []
                }
              />
            </MyForm>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={submitting} onClick={this._onClose}>
            Cancel
          </Button>
          <Button
            primary
            loading={submitting}
            disabled={!isValid || submitting}
            onClick={this._onSubmit}>
            {isNew ? 'Create' : 'Update'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  _onClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  _onDataChange = (data) => {
    this.setState({ data });
  };

  _onSubmit = () => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    const parsed = {
      code: data.code,
      endsAt: data.endsAt ? moment(data.endsAt).toISOString() : null,
      name: data.name,
      priceIds: data.priceIds,
      route: data.route || null,
      startsAt: data.startsAt ? moment(data.startsAt).toISOString() : null,
      companyId: data.companyId
    };

    onSubmit(parsed);
  };
}
export default EditDialog;
