import './index.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';

class FullscreenLoader extends Component {
  static propTypes = {
    style: PropTypes.object
  };
  render() {
    const { style } = this.props;
    let _style = style ? { ...style } : {};
    return (
      <div className="fullscreen-loader" style={_style}>
        <Segment
          basic
          loading
          size="massive"
          className="fullscreen-loader-spinner"
        />
      </div>
    );
  }
}

export default FullscreenLoader;
