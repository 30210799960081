import './guide-video-preview.css';

import PropTypes from 'prop-types';
import React from 'react';

function GuideVideoPreview(props) {
  const { label, onClick, url } = props;

  function _onClick(event) {
    event.preventDefault();
    onClick();
  }

  return (
    <div className="guide-video-preview">
      <a href={url} onClick={_onClick}>
        <i className="icon lineawesome play circle" />
        <span>{label}</span>
      </a>
    </div>
  );
}
GuideVideoPreview.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired
};
export default GuideVideoPreview;
