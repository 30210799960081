import PropTypes from 'prop-types';
import React from 'react';

import GuideContext from '../guide-context';

function GuideAchievements(props) {
  const { guide } = props;

  if (!guide.achievements.length) {
    return null;
  }

  function renderAchievement({ id, imageUrl, title }) {
    return (
      <li key={id} className="p-2 flex items-center viz">
        <img src={imageUrl} alt={title} className="w-16 h-16 mr-4" />
        <span>{title}</span>
      </li>
    );
  }

  return (
    <ul
      className="grid gap-1"
      style={{
        gridTemplateColumns: 'repeat(auto-fill, minmax(22ch, 1fr))'
      }}>
      {guide.achievements.map(renderAchievement)}
    </ul>
  );
}
GuideAchievements.propTypes = {
  guide: PropTypes.shape({
    achievements: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        imageUrl: PropTypes.string,
        title: PropTypes.string
      })
    ).isRequired
  }).isRequired
};
function GuideAchievementsWithGuideContext(props) {
  return (
    <GuideContext.Consumer>
      {(guide) => <GuideAchievements guide={guide} {...props} />}
    </GuideContext.Consumer>
  );
}
export default GuideAchievementsWithGuideContext;
