import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router';

import Dialogs from './dialogs';

@withRouter
class AdminCompanyRoutes extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route
          path={`${match.url}/dialog/:name`}
          render={() => <Dialogs parentPath={match.url} />}
        />
      </Switch>
    );
  }
}
export default AdminCompanyRoutes;
