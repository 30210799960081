import './edit.css';

import { graphql } from '@apollo/client/react/hoc';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Button, Confirm, Message, Modal } from 'semantic-ui-react';

import DeleteTopicMutation from '../../../../../graphql/mutations/delete-topic.graphql';
import UpsertTopicMutation from '../../../../../graphql/mutations/upsert-topic.graphql';
import TopicsPaginationQuery from '../../../../../graphql/queries/topics-pagination.graphql';
import EditForm from '../forms/edit';
import { locationToVariables } from '../params';

@withRouter
@graphql(DeleteTopicMutation, {
  name: 'deleteTopic',
  options: ({ location }) => ({
    refetchQueries: [
      { query: TopicsPaginationQuery, variables: locationToVariables(location) }
    ]
  })
})
@graphql(UpsertTopicMutation, {
  name: 'upsertTopic',
  options: ({ location }) => ({
    refetchQueries: [
      { query: TopicsPaginationQuery, variables: locationToVariables(location) }
    ]
  })
})
class AdminTopicEditDialog extends Component {
  static propTypes = {
    deleteTopic: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    upsertTopic: PropTypes.func.isRequired
  };

  state = {
    data: null,
    deleting: false,
    error: null,
    isValid: false,
    submitting: false
  };

  render() {
    const { location, open, onClose } = this.props;
    const { isValid, submitting } = this.state;

    const search = qs.parse(location.search, { ignoreQueryPrefix: true });
    const isNew = !search.id;

    return (
      <>
        <Modal
          className="topic-edit-dialog"
          closeIcon
          open={open}
          onClose={onClose}>
          <Modal.Header>{isNew ? 'Create' : 'Edit'} Topic</Modal.Header>
          <Modal.Content>
            {this._renderError()}
            <EditForm
              onDataChange={this._onDataChange}
              onValidate={this._onValidate}
              submitting={submitting}
            />
          </Modal.Content>
          <Modal.Actions>
            {!isNew && (
              <Button
                disabled={submitting}
                floated="left"
                onClick={this._onClickDelete}>
                Delete
              </Button>
            )}
            <Button disabled={submitting} onClick={onClose}>
              Nevermind
            </Button>
            <Button
              primary
              disabled={!isValid || submitting}
              loading={submitting}
              onClick={this._onSubmit}>
              {isNew ? 'Create' : 'Update'}
            </Button>
          </Modal.Actions>
        </Modal>
        {this._renderConfirmDelete()}
      </>
    );
  }

  _renderError() {
    const { error } = this.state;

    if (!error) {
      return null;
    }

    return <Message negative>{error.message}</Message>;
  }

  _renderConfirmDelete() {
    const { deleting } = this.state;

    return (
      <Confirm
        open={deleting}
        content="Are you sure you want to delete this topic?"
        onCancel={() => this.setState({ deleting: false })}
        onConfirm={this._onDelete}
      />
    );
  }

  _onDataChange = (data) => {
    this.setState({ data });
  };

  _onValidate = (errors) => {
    this.setState({ isValid: isEmpty(errors) });
  };

  _onClickDelete = () => {
    this.setState({ deleting: true });
  };

  _onDelete = () => {
    const { deleteTopic, onClose } = this.props;

    const search = qs.parse(location.search, { ignoreQueryPrefix: true });

    const variables = {
      id: search.id
    };

    this.setState({ error: null, submitting: true });
    deleteTopic({ variables })
      .then(() => {
        this.setState({ submitting: false }, onClose);
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
      });
  };

  _onSubmit = () => {
    const { location, onClose, upsertTopic } = this.props;
    const { data } = this.state;

    const search = qs.parse(location.search, { ignoreQueryPrefix: true });

    const variables = {
      id: search.id || '',
      ...data
    };

    this.setState({ error: null, submitting: true });
    upsertTopic({ variables })
      .then(() => {
        this.setState({ submitting: false }, onClose);
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
      });
  };
}
export default AdminTopicEditDialog;
