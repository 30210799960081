import { graphql } from '@apollo/client/react/hoc';
import Bugsnag from '@bugsnag/js';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';

import UpdateSessionCompletedMutation from '../../../graphql/mutations/update-session-completed.graphql';
import GuideDashboardQuery from '../../../graphql/queries/guide-dashboard.graphql';
import * as tracker from '../../../tracker';
import withUser from '../../hoc/with-user';
import ErrorDialog from '../../ui/error-dialog';
import SessionNotes from '../session-notes';

@withUser({ authenticated: true })
@graphql(UpdateSessionCompletedMutation, {
  name: 'updateSessionCompleted',
  options: (props) => {
    const { User } = props.user;

    return {
      refetchQueries: [
        { query: GuideDashboardQuery, variables: { userId: User.id } }
      ]
    };
  }
})
class CompleteSessionDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    session: PropTypes.shape({
      id: PropTypes.string
    }).isRequired,
    updateSessionCompleted: PropTypes.func.isRequired,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  };

  state = {
    error: null,
    submitting: false
  };

  render() {
    const { onClose, open, session } = this.props;
    const { error, submitting } = this.state;

    return (
      <>
        <Modal open={open} size="small">
          <Modal.Content scrolling>
            <Header size="huge" style={{ margin: 0 }}>
              Way to Go!
            </Header>
            <Header size="medium" style={{ margin: 0, fontWeight: 'normal' }}>
              Take some time to finish up your call
            </Header>
            <Header size="tiny">Notes</Header>
            <SessionNotes session={session} />
          </Modal.Content>
          <Modal.Actions>
            <Button disabled={submitting} onClick={onClose}>
              Nevermind
            </Button>
            <Button
              primary
              disabled={submitting}
              loading={submitting}
              onClick={this._completeSession}>
              Submit
            </Button>
          </Modal.Actions>
        </Modal>
        <ErrorDialog
          error={error}
          onClose={() => {
            this.setState({ error: null });
          }}
        />
      </>
    );
  }

  _completeSession = () => {
    const { onClose, updateSessionCompleted, session } = this.props;
    const { User } = this.props.user;

    const variables = {
      id: session.id,
      userId: User.id
    };

    this.setState({ error: null, submitting: true });
    updateSessionCompleted({ variables })
      .then((response) => {
        // TODO: Add topic
        tracker.event('completeCall', 1);

        this.setState({ submitting: false }, () => onClose(response));
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'CompleteSessionDialog._completeSession';
          event.request.variables = variables;
        });
      });
  };
}
export default CompleteSessionDialog;
