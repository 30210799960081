import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Input } from 'semantic-ui-react';

import AchievementByIdQuery from '../../../../../graphql/queries/achievement-by-id.graphql';
import ErrorMessage from '../../../../ui/error-message';
import MyForm, { FormField } from '../../../../ui/form';
import FullscreenLoader from '../../../../ui/fullscreen-loader/index';

function mapData(achievement) {
  return {
    title: '',
    ...(achievement && {
      imageUrl: achievement.imageUrl,
      title: achievement.title
    })
  };
}

function AchievementEditForm(props) {
  const { onDataChange, onValidate } = props;

  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { data: queryData, error, loading, refetch } = useQuery(
    AchievementByIdQuery,
    {
      notifyOnNetworkStatusChange: true,
      skip: !search.id,
      variables: { id: search.id }
    }
  );

  const [data, setData] = useState(
    mapData(queryData ? queryData.achievement : {})
  );

  const onChange = useCallback(
    (data) => {
      setData(data);

      if (onDataChange) {
        onDataChange({ ...data });
      }
    },
    [onDataChange]
  );

  useEffect(
    function () {
      if (queryData) {
        onChange(mapData(queryData.achievement));
      }
    },
    [onChange, queryData]
  );

  return (
    <MyForm data={data} onChange={onChange} onValidate={onValidate}>
      <ErrorMessage error={error} retry={() => refetch()} />
      {loading ? (
        <FullscreenLoader />
      ) : (
        <>
          <FormField
            component={Input}
            name="title"
            label="Title"
            type="text"
            autoComplete="off"
            validator={({ title }) => {
              if (!title) {
                throw new Error('Title is required');
              }
            }}
          />
        </>
      )}
    </MyForm>
  );
}
AchievementEditForm.propTypes = {
  onDataChange: PropTypes.func,
  onValidate: PropTypes.func
};
export default AchievementEditForm;
