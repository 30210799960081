import { graphql } from '@apollo/client/react/hoc';
import Bugsnag from '@bugsnag/js';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Confirm } from 'semantic-ui-react';

import { ROLES } from '../../../consts';
import CancelSessionMutation from '../../../graphql/mutations/cancel-session.graphql';
import ClientDashboardQuery from '../../../graphql/queries/client-dashboard.graphql';
import GuideDashboardQuery from '../../../graphql/queries/guide-dashboard.graphql';
import * as tracker from '../../../tracker';
import withUser from '../../hoc/with-user';
import ErrorDialog from '../../ui/error-dialog';

@withUser()
@graphql(CancelSessionMutation, {
  name: 'cancelSession',
  options: (props) => {
    const { User } = props.user;

    const isGuide = User.roles.some((role) => role.name === ROLES.GUIDE);

    const variables = {
      userId: User.id
    };

    const refetchQueries = [
      {
        query: isGuide ? GuideDashboardQuery : ClientDashboardQuery,
        variables
      }
    ];

    return {
      refetchQueries
    };
  }
})
class CancelSessionDialog extends Component {
  static propTypes = {
    //graphql
    cancelSession: PropTypes.func.isRequired,

    //args
    open: PropTypes.bool,
    onClose: PropTypes.func,
    session: PropTypes.shape({
      id: PropTypes.string
    }),

    //withUser
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string,
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    }).isRequired
  };

  state = {
    submitting: false,
    error: null,
    success: false,
    successMessage: null
  };

  render() {
    return <div className="cancelcall-modal">{this._renderCancel()}</div>;
  }

  _renderCancel() {
    const { open, onClose, session } = this.props;
    const { error, submitting } = this.state;

    if (!open || !session) {
      return null;
    }

    return (
      <>
        <Confirm
          open
          content="Are you sure you want to cancel this scheduled call?"
          cancelButton={<Button disabled={submitting}>Nevermind</Button>}
          confirmButton={
            <Button disabled={submitting} loading={submitting}>
              Yes, please cancel
            </Button>
          }
          onCancel={onClose}
          onConfirm={this._onConfirmCancel}
        />
        <ErrorDialog
          error={error}
          onClose={() => {
            this.setState({ error: null });
          }}
        />
      </>
    );
  }

  _onConfirmCancel = () => {
    const { cancelSession, onClose, session } = this.props;

    const variables = {
      id: session.id
    };

    this.setState({ error: null, submitting: true, success: false });
    cancelSession({ variables }, { partialRefetch: true })
      .then(() => {
        tracker.event('cancelCall', 1);

        this.setState({
          submitting: false,
          success: true,
          successMessage: 'Call Cancelled'
        });

        if (onClose) {
          onClose();
        }
      })
      .catch((error) => {
        tracker.event('cancelCall', 0);

        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'CancelSessionDialog._onConfirmCancel';
          event.request.variables = variables;
        });
      });
  };
}
export default CancelSessionDialog;
