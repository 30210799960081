export const TWILIO_ERROR_SEVERITY = {
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning'
};
export const TWILIO_ERRORS = {
  SIGNALING_CONNECTION_ERROR: {
    code: 53000,
    message:
      'Network disconnected, please check your network settings and try rejoining.',
    severity: TWILIO_ERROR_SEVERITY.INFO
  },
  SIGNALING_CONNECTION_DISCONNECTED: {
    code: 53001,
    message:
      'Network disconnected, please check your network settings and try rejoining.',
    severity: TWILIO_ERROR_SEVERITY.INFO
  },
  SIGNALING_CONNECTION_TIMED_OUT: {
    code: 53002,
    message:
      'Network disconnected, please check your network settings and try rejoining.',
    severity: TWILIO_ERROR_SEVERITY.INFO
  },
  PARTICIPANT_DUPLICATE_IDENTITY: {
    code: 53205,
    message:
      'You have joined the video session on another device and will be disconnected here.',
    severity: TWILIO_ERROR_SEVERITY.INFO
  },
  MEDIA_CONNECTION_FAILED: {
    code: 53405,
    message:
      'Media stream disconnected, please check your network settings and try rejoining.',
    severity: TWILIO_ERROR_SEVERITY.INFO
  }
};
