import { Button } from '@windmill/react-ui';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';

import MyForm, { FormField } from '../../ui/form';
import LoadingSpinner from '../../ui/loading-spinner/index';

class CorporateEmailForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool
  };

  state = {
    data: {
      corporateEmailAddress: ''
    },
    isValid: false
  };

  render() {
    const { submitting } = this.props;
    const { data, isValid } = this.state;

    return (
      <div className="corporate-email-form" style={{ width: '100%' }}>
        <MyForm
          data={data}
          onChange={(data) => {
            this.setState({ data });
          }}
          onValidate={(errors) => {
            this.setState({ isValid: isEmpty(errors) });
          }}>
          <FormField
            component={Input}
            name="corporateEmailAddress"
            label="Work or Personal E-mail Address"
            aria-label="Work or Personal E-mail Address"
            aria-required="true"
            type="email"
            validator={({ corporateEmailAddress }) => {
              if (!corporateEmailAddress) {
                throw new Error('Work or Personal e-mail address is required');
              }
            }}
          />
          <Button
            block
            size="larger"
            disabled={!isValid || submitting}
            onClick={this._onSubmit}>
            {submitting ? (
              <>
                <LoadingSpinner className="w-6 h-6" />
                &nbsp;
              </>
            ) : (
              'Continue'
            )}
          </Button>
        </MyForm>
      </div>
    );
  }

  _onSubmit = () => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    onSubmit(data);
  };
}
export default CorporateEmailForm;
