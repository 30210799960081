import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

class ConfirmBanDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    user: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    }).isRequired
  };

  state = {
    reason: ''
  };

  render() {
    const { onClose, submitting, user } = this.props;
    const { reason } = this.state;

    return (
      <Modal open size="mini" onClose={onClose}>
        <Modal.Header>Confirm Ban</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              Please provide a reason to ban {user.firstName} {user.lastName}:
            </p>
            <textarea
              style={{ width: '100%', minHeight: '5em' }}
              value={reason}
              onChange={this._onReasonChange}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={submitting} onClick={onClose}>
            Nevermind
          </Button>
          <Button
            primary
            loading={submitting}
            disabled={!reason.length || submitting}
            onClick={this._onSubmit}>
            <Icon name="ban" />
            Ban {user.firstName}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  _onReasonChange = ({ target: { value } }) => {
    this.setState({ reason: value });
  };

  _onSubmit = () => {
    const { onSubmit } = this.props;
    const { reason } = this.state;

    onSubmit({ reason });
  };
}
export default ConfirmBanDialog;
