import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import TopicGroupsWithTopicsQuery from '../../../graphql/queries/topic-groups-with-topics.graphql';
import history from '../../../history';
import ErrorMessage from '../../ui/error-message/index';
import LoadingSpinner from '../../ui/loading-spinner/index';

function Topic({ id, title }) {
  const location = useLocation();
  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  const selectedTopicIds = search.topicId ? search.topicId : [];

  const isSelected = selectedTopicIds.includes(id);
  const iconClasses = ['icon square large bg-white'];
  if (isSelected) {
    iconClasses.push('check text-primary');
  } else {
    iconClasses.push('outline text-gray-400');
  }

  function onClick() {
    const newSelectedTopicIds = [...selectedTopicIds];
    const index = newSelectedTopicIds.indexOf(id);
    if (index >= 0) {
      newSelectedTopicIds.splice(index, 1);
    } else {
      newSelectedTopicIds.push(id);
    }

    const { page, ...remaining } = search;

    history.replace({
      pathname: location.pathname,
      search: qs.stringify({ ...remaining, topicId: newSelectedTopicIds })
    });
  }

  return (
    <button
      className="flex pl-10 text-left whitespace-nowrap space-x-3 items-center focus:outline-none"
      onClick={onClick}>
      <i
        className={iconClasses.join(' ')}
        style={{ margin: 0, fontSize: '1.5rem' }}
      />
      <span>{title}</span>
    </button>
  );
}
Topic.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string
};

function TopicGroup({ title, topics }) {
  const location = useLocation();
  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  const selectedTopics = topics.filter(
    (topic) => search.topicId && search.topicId.includes(topic.id)
  );
  const hasSelectedTopics = !!selectedTopics.length;

  const [isOpen, setIsOpen] = useState(hasSelectedTopics);

  function renderTopic(topic) {
    return <Topic key={topic.id} {...topic} />;
  }

  function renderTopics() {
    return (
      <div
        className={`overflow-hidden space-y-2 ${
          isOpen ? 'max-h-screen' : 'max-h-0'
        }`}
        style={{
          transition: 'max-height 0.3s ease-out'
        }}>
        {topics.map(renderTopic)}
      </div>
    );
  }

  function onClick() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <button
        className="w-full my-2 block flex items-center focus:outline-none"
        onClick={onClick}>
        <i
          className={`icon lineawesome angle ${isOpen ? 'up' : 'down'}`}
          style={{ margin: 0, fontSize: '1.5rem' }}
        />
        <span
          className={`flex-grow ml-4 whitespace-nowrap ${
            hasSelectedTopics ? 'font-bold' : ''
          } text-left`}>
          {title}
        </span>
        {hasSelectedTopics && (
          <span className="block bg-primary rounded-full text-white text-xs p-1 w-5 h-5 text-center">
            {selectedTopics.length}
          </span>
        )}
      </button>
      {renderTopics()}
    </>
  );
}
TopicGroup.propTypes = {
  title: PropTypes.string,
  topics: PropTypes.arrayOf(PropTypes.shape(Topic.propTypes))
};

export default function TopicFilters() {
  const [isOpen, setIsOpen] = useState(false);
  const { data, error, loading, refetch } = useQuery(
    TopicGroupsWithTopicsQuery,
    { notifyOnNetworkStatusChange: true }
  );

  const location = useLocation();
  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  function renderTopicGroup(topicGroup) {
    return <TopicGroup key={topicGroup.id} {...topicGroup} />;
  }

  function renderTopicGroups() {
    if (error) {
      return <ErrorMessage error={error} retry={() => refetch()} />;
    }
    if (loading) {
      return <LoadingSpinner className="w-10 h-10" />;
    }
    return (
      <div className="py-2 px-4">{data.topicGroups.map(renderTopicGroup)}</div>
    );
  }

  function onClick() {
    setIsOpen(!isOpen);
  }

  function renderContent() {
    const containerClassNames = ['relative bg-white'];
    const buttonClassNames = [
      'flex flex-row w-full text-left text-lg font-bold whitespace-nowrap focus:outline-none'
    ];

    if (isOpen) {
      containerClassNames.push('border rounded-r');
      buttonClassNames.push('justify-between p-4 border-b');
    } else {
      containerClassNames.push(
        'mt-8 lg:mt-24 mr-2 p-2 border border-l-0 rounded-r-full'
      );
      buttonClassNames.push('flex-grow px-4');
    }

    return (
      <div className={containerClassNames.join(' ')}>
        <button className={buttonClassNames.join(' ')} onClick={onClick}>
          <span className="flex-grow mr-8">Selected Topics</span>
          <i
            className={`icon fontawesome ${
              isOpen ? 'times' : 'bars'
            } text-gray-400`}
            style={{ margin: 0 }}
          />
        </button>
        {isOpen ? (
          renderTopicGroups()
        ) : search.topicId ? (
          <span className="absolute -top-2 -right-2 bg-primary rounded-full text-white text-xs p-1 w-6 h-6 text-center">
            {search.topicId.length}
          </span>
        ) : null}
      </div>
    );
  }

  return renderContent();
}
