import Bugsnag from '@bugsnag/js';

import apolloClient from './apollo';
import { reconnectSubscription } from './apollo';
import { AUTH_TOKEN_NAME } from './consts';
import IsEmailAddressRegisteredMutation from './graphql/mutations/is-email-address-registered.graphql';
import UserAuthTokenQuery from './graphql/queries/user-auth-token.graphql';
import UserQuery from './graphql/queries/user.graphql';
import history, { clearBreadcrumbs } from './history';

const PARENT_TOKEN_NAME = 'parent-auth';
const REDIRECT_URL = 'auth-redirect';

let authToken = localStorage.getItem(AUTH_TOKEN_NAME);

function getAuthToken() {
  return authToken;
}

function setAuthToken(token) {
  if (token) {
    localStorage.setItem(AUTH_TOKEN_NAME, token);
  } else {
    localStorage.removeItem(AUTH_TOKEN_NAME);
  }
  authToken = token;
  reconnectSubscription();
  return getUser(true);
}

function getUser(refetch) {
  const options = {
    query: UserQuery,
    fetchPolicy: refetch ? 'network-only' : 'cache-first'
  };

  return apolloClient.query(options).then(({ data: { User } }) => User);
}

function emailExists(emailAddress) {
  const options = {
    mutation: IsEmailAddressRegisteredMutation,
    variables: {
      emailAddress
    }
  };

  return apolloClient.mutate(options).then(
    ({
      data: {
        isEmailAddressRegistered: { isRegistered }
      }
    }) => isRegistered
  );
}

function impersonate(userId) {
  const options = {
    query: UserAuthTokenQuery,
    variables: { id: userId }
  };

  return apolloClient.query(options).then(({ data: { userAuthToken } }) => {
    localStorage.setItem(PARENT_TOKEN_NAME, authToken);
    localStorage.setItem(REDIRECT_URL, history.location.pathname);
    return setAuthToken(userAuthToken.authToken);
  });
}

function logout() {
  const parentToken = localStorage.getItem(PARENT_TOKEN_NAME);
  const redirectUrl = localStorage.getItem(REDIRECT_URL);

  localStorage.clear();
  clearBreadcrumbs();
  authToken = null;
  Bugsnag.setUser(null, null, null);
  reconnectSubscription();

  // Receiving "Store reset while query was in flight." errors,
  // currently appears to be no resolution other than waiting for requests to complete
  // https://github.com/apollographql/apollo-client/issues/2919
  // Doing exponential backoff, max 3 tries
  const reset = new Promise((resolve, reject) => {
    const retries = 3;

    function reset(count) {
      apolloClient
        .resetStore()
        .then(resolve)
        .catch((error) => {
          if (count < retries) {
            const timeout = Math.pow(2, count) * 200;
            window.setTimeout(() => {
              reset(count + 1);
            }, timeout);
          } else {
            reject(error);
          }
        });
    }

    reset(0);
  });

  let redirected = false;
  const redirect = () => {
    if (!redirected) {
      history.push({
        pathname: redirectUrl || '/',
        search: ''
      });
      redirected = true;
    }
  };

  // apolloClient.resetStore seems to have a bug in current version where the promise isn't resolving
  // Add a 2 second timeout
  window.setTimeout(redirect, 2000);

  return reset
    .then(() => {
      if (parentToken) {
        return setAuthToken(parentToken);
      } else {
        return setAuthToken(null);
      }
    })
    .then(redirect);
}

export { emailExists, getAuthToken, impersonate, logout, setAuthToken };
