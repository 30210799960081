import * as React from 'react';
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={92}
    height={88}
    fill="none"
    {...props}>
    <path
      fill="#DF1F5E"
      d="M45.636 0c-1.945 0-3.89 1.007-4.896 3.037L29.6 25.624 4.674 29.241c-4.47.649-6.262 6.159-3.02 9.315l18.032 17.572-4.265 24.823c-.767 4.452 3.924 7.847 7.916 5.749l22.298-11.703V0Z"
    />
    <path
      fill="#DF1F5E"
      d="M45.352 0c1.945 0 3.89 1.007 4.896 3.037l11.14 22.587 24.925 3.617c4.47.649 6.261 6.159 3.02 9.315L71.3 56.128l4.265 24.823c.768 4.452-3.924 7.847-7.916 5.749L45.352 74.997V0Z"
    />
  </svg>
);
export default SvgComponent;
