import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { LogoNavyWithTag } from '../../../assets';

const NAV = [
  {
    title: 'Getting Started',
    links: [
      {
        title: 'Become a Guide',
        url: 'https://lifeguides.com/become-a-guide/'
      },
      {
        title: 'Member Center',
        url: '/'
      }
    ]
  },
  {
    title: 'Company',
    links: [
      {
        title: 'Resources',
        url:
          'https://my.lifeguides.com/resources/?_ga=2.90073502.468414476.1617905029-202351608.1617905029'
      },
      { title: 'Our Team', url: 'https://lifeguides.com/meet-our-team/' }
    ]
  },
  {
    title: 'Legal',
    links: [
      { title: 'Terms of Use', path: '/terms-and-conditions' },
      { title: 'Privacy Policy', path: '/privacy-policy' }
    ]
  }
];

const SOCIAL_MEDIA_LINKS = [
  { icon: 'linkedin', url: 'https://www.linkedin.com/company/25038429/' },
  { icon: 'facebook-f', url: 'https://www.facebook.com/LifeGuidesNow/' },
  { icon: 'twitter', url: 'https://twitter.com/LifeGuidesNow' },
  { icon: 'instagram', url: 'https://www.instagram.com/lifeguidesnow/' }
];

export default function AppFooter() {
  return (
    <div className="flex flex-col items-center w-full px-4 mb-16 border-t-2 sm:mb-0">
      <div className="grid items-end w-full max-w-screen-xl grid-flow-row grid-cols-1 gap-4 my-8 text-center sm:my-16 sm:grid-cols-none sm:grid-rows-3 sm:grid-flow-col sm:text-left">
        {NAV.map(({ title, links }, index) => (
          <Fragment key={index}>
            <h4 className="text-2xl font-medium text-neutral">{title}</h4>
            {links.map(({ title, path, url }, index) => {
              if (path) {
                return (
                  <Link
                    key={index}
                    to={{ pathname: path, search: '', hash: '' }}
                    className="text-lg text-primary">
                    {title}
                  </Link>
                );
              }
              if (url) {
                return (
                  <a key={index} href={url} className="text-lg text-primary">
                    {title}
                  </a>
                );
              }
            })}
          </Fragment>
        ))}
      </div>
      <div className="flex flex-row items-center w-full max-w-screen-xl my-8 sm:my-16 justify-stretch">
        <Link
          to={{ pathname: '/', search: '', hash: '' }}
          className="hidden sm:inline"
          style={{ flexGrow: '3' }}>
          <img className="h-11" src={LogoNavyWithTag} alt="LifeGuides Logo" />
        </Link>
        <div className="flex justify-center flex-grow space-x-2 sm:space-x-6">
          {SOCIAL_MEDIA_LINKS.map(({ icon, url }, index) => (
            <a key={index} href={url}>
              <i
                className={`icon lineawesome circular large bg-primary text-white ${icon}`}
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
