import React from 'react';
import { Button } from 'semantic-ui-react';

import { ACCESS_CODE_KEY } from '../../../consts';
import history from '../../../history';

const BIOCLINICA_ACCESS_CODE = 'TEST';

export default function BioclinicaLanding() {
  return (
    <div style={{ textAlign: 'center' }}>
      <Button
        primary
        size="huge"
        onClick={() => {
          localStorage.setItem(ACCESS_CODE_KEY, BIOCLINICA_ACCESS_CODE);
          history.push('/onboard/client');
        }}>
        Do the thing
      </Button>
    </div>
  );
}
