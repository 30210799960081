const assessQuestions = [
  'Have you been intoxicated in the last 24 hours?',
  'Do you want to harm yourself or others?',
  'Have you had suicidal thoughts?',
  'Do you have a <b>Plan</b>?',
  '(If a plan exists) How likely do you feel you would carry out the suicide plan?',
  '(If a plan exists) Do you have <b>Means</b> to carry out the plan?'
];

export default assessQuestions;
