import { Input } from '@windmill/react-ui';
import PropTypes from 'prop-types';
import React from 'react';

import { useEditing } from './actions';

function GuideProfileQualifications(props) {
  const { data, onChange } = props;

  const [isEditing] = useEditing();

  const qualifications = data.qualifications;

  if (!qualifications.length && !isEditing) {
    return null;
  }

  function renderQualification(qualification, i) {
    const content = isEditing ? (
      <Input
        className="text-xl font-bold border"
        value={qualification}
        onChange={({ target: { value } }) => {
          const qualifications = [...data.qualifications];
          qualifications[i] = value;
          onChange(qualifications);
        }}
      />
    ) : (
      <span className="text-xl font-bold">{qualification}</span>
    );

    return (
      <React.Fragment key={i}>
        {i > 0 ? (
          <div className="sm:h-4 border-gray-200 sm:border-gray-400 border-t sm:border-t-0 sm:border-l-2" />
        ) : null}
        <li>{content}</li>
      </React.Fragment>
    );
  }

  return (
    <ul className="flex flex-col space-y-1 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-4">
      {qualifications.map(renderQualification)}
    </ul>
  );
}
GuideProfileQualifications.propTypes = {
  data: PropTypes.shape({
    qualifications: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired
};
export default GuideProfileQualifications;
