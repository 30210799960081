import { useMutation } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@windmill/react-ui';
import get from 'lodash/get';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import CreateSessionMutation from '../../../graphql/mutations/create-session.graphql';
import SessionsByGuideIdQuery from '../../../graphql/queries/sessions-by-guide-id.graphql';
import useUser from '../../hooks/use-user';
import ErrorDialog from '../../ui/error-dialog';
import LoadingSpinner from '../../ui/loading-spinner';
import GuideContext from '../guide/guide-context';
import Scheduler from '../scheduler';
import { CALL_LENGTH } from '../session';
import AddToCalendar from './add-to-calendar';

function ScheduleSessionDialog(props) {
  const { onClose, open, session } = props;

  const [createdSession, setCreatedSession] = useState(null);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const { data: userData } = useUser();

  const [createSession] = useMutation(CreateSessionMutation, {
    refetchQueries: [
      {
        query: SessionsByGuideIdQuery,
        variables: {
          afterDate: moment().subtract(1, 'month').startOf('day').toISOString(),
          guideId: get(userData, 'User.id', null)
        }
      }
    ]
  });

  const onChange = useCallback((data) => {
    setData(data);
  }, []);

  function onSubmit() {
    const { User } = userData;

    const topicIds = session.topics.map(({ id }) => id);

    const variables = {
      clientId: session.client.id,
      reason: data.reason,
      guideId: User.id,
      scheduledTime: data.scheduledTime,
      status: 'UPCOMING',
      topicConnections: topicIds.map((id) => ({ id })),
      userId: User.id
    };

    setError(null);
    setSubmitting(true);
    createSession({ variables })
      .then(({ data: { bookSession } }) => {
        setSubmitting(false);
        setCreatedSession(bookSession);
      })
      .catch((error) => {
        setError(error);
        setSubmitting(false);

        Bugsnag.notify(error, function (event) {
          event.context = 'ScheduleSessionDialog._onSubmit';
          event.request.variables = variables;
        });
      });
  }

  function renderConfirmationDialog() {
    if (!createdSession) {
      return null;
    }

    const timezone = get(userData, 'User.timezone', moment.tz.guess());
    const scheduledTime = moment(createdSession.scheduledTime).tz(timezone);

    return (
      <Modal isOpen={open} onClose={onClose}>
        <ModalHeader className="text-xl">Session Booked</ModalHeader>
        <ModalBody>
          <p className="text-lg">
            Your session with {session.client.firstName} has been booked for
            <span className="block font-bold">
              {scheduledTime.format('dddd, MMM Do')} -{' '}
              {scheduledTime.format('h:mm A')} to{' '}
              {scheduledTime
                .clone()
                .add(CALL_LENGTH, 'minutes')
                .format('h:mm A')}
            </span>
          </p>
          <br />
          <AddToCalendar session={session} />
        </ModalBody>
        <ModalFooter>
          <Button layout="outline" onClick={onClose}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  const classNames = [
    'scheduler-dialog relative w-full max-h-full overflow-y-auto max-h-screen bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4'
  ];
  if (data.date) {
    classNames.push('sm:max-w-5xl');
  } else {
    classNames.push('sm:max-w-4xl');
  }

  const isValid = !!data.scheduledTime;

  return (
    <>
      <Modal className={classNames.join(' ')} isOpen={open} onClose={onClose}>
        <GuideContext.Provider value={userData && userData.User}>
          <Scheduler
            onChange={onChange}
            header="Schedule another session"
            subHeader={`Book again with ${session.client.firstName}`}>
            <div className="flex flex-row justify-end space-x-4">
              <Button
                className="w-full sm:w-auto"
                layout="outline"
                disabled={submitting}
                onClick={onClose}>
                Nevermind
              </Button>
              <Button
                className="w-full sm:w-auto"
                disabled={!isValid || submitting}
                onClick={onSubmit}>
                {submitting ? (
                  <>
                    <LoadingSpinner className="w-6 h-6" />
                    &nbsp;
                  </>
                ) : (
                  'Confirm'
                )}
              </Button>
            </div>
          </Scheduler>
        </GuideContext.Provider>
      </Modal>
      {renderConfirmationDialog()}
      <ErrorDialog
        error={error}
        onClose={() => {
          setError(null);
        }}
      />
    </>
  );
}
ScheduleSessionDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  session: PropTypes.shape({
    id: PropTypes.string,
    client: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string
    }),
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string
      })
    )
  })
};
export default ScheduleSessionDialog;
