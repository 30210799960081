import { ROLES } from '../../../consts';
import MyGuidesIcon from './my-guides-icon';

const MenuItems = [
  /* DASHBOARD */
  {
    name: 'dashboard',
    label: 'Overview',
    path: '/dashboard',
    description: 'Overview',
    roles: [ROLES.ADMIN, ROLES.GUIDE]
  },
  /* ADMIN */
  {
    name: 'reporting',
    label: 'Reporting',
    path: '/admin/reporting',
    description: 'View admin reports',
    roles: [ROLES.ADMIN]
  },
  {
    name: 'guide-admin-users',
    label: 'Guides',
    path: '/admin/users',
    description: 'View and manage registered guides',
    roles: [ROLES.GUIDE_ADMIN, ROLES.GUIDE_CONTENT_MANAGER]
  },
  {
    name: 'guide-applications',
    label: 'Guide Applications',
    path: '/admin/guide-applications',
    description: 'View and approve guide applications',
    roles: [ROLES.ADMIN, ROLES.GUIDE_ADMIN]
  },
  {
    name: 'guide-availability',
    label: 'Guide Availability',
    path: '/guide-availability',
    description: 'View and manage guide availability times',
    roles: [ROLES.ADMIN, ROLES.CONCIERGE, ROLES.GUIDE_ADMIN]
  },
  {
    name: 'admin-users',
    label: 'Users',
    path: '/admin/users',
    description: 'View and manage registered users',
    roles: [ROLES.ADMIN]
  },
  /* CONCIERGE */
  {
    name: 'concierge',
    label: 'Concierge',
    path: '/concierge/session-scheduling',
    description: 'View sessions or schedule for users',
    roles: [ROLES.ADMIN, ROLES.CONCIERGE, ROLES.GUIDE_ADMIN]
  },
  {
    name: 'call-script',
    label: 'WBC Call Script',
    //path: '/concierge/call-script',
    url:
      'https://docs.google.com/document/d/1tfmZECHtfwkUY1ZEdD_aNjLVEic6OwcLXFOuNRvQYKo/view',
    description: 'Learn the WBC script to manage calls effectively',
    roles: [ROLES.CONCIERGE, ROLES.GUIDE_ADMIN]
  },
  {
    name: 'crisis-protocol',
    label: 'Crisis Protocol',
    path: '/concierge/crisis-protocol',
    description: 'Follow these steps when participants are in crisis',
    roles: [ROLES.CONCIERGE, ROLES.GUIDE_ADMIN]
  },
  /* CORP ADMIN */
  {
    name: 'corp-admin-users',
    label: 'Manage Users',
    path: '/corp-admin/users',
    description: 'View and manage users within company',
    roles: [ROLES.CORP_ADMIN]
  },
  /* GUIDE ADMIN */
  {
    name: 'edit-achievements',
    label: 'Edit Achievements',
    path: '/admin/achievements',
    roles: [ROLES.GUIDE_ADMIN]
  },
  /* GUIDE */
  {
    name: 'sessions',
    label: 'My Sessions',
    path: `/sessions`,
    description: 'List of scheduled and past sessions',
    roles: [ROLES.GUIDE]
  },
  // {
  //   name: 'guide-resources',
  //   label: 'Guide Resources',
  //   path: '/resources',
  //   description: 'Resources',
  //   roles: [ROLES.GUIDE]
  // },
  /* CLIENT */
  {
    name: 'guides-client',
    label: 'Find a New Guide',
    path: '/',
    hash: '#topics',
    description: "Find a Life Guide that's perfect for you",
    roles: [ROLES.CLIENT]
  },
  {
    name: 'guides-unauthed',
    label: 'Find a Guide',
    path: '/',
    hash: '#topics',
    description: "Find a Life Guide that's perfect for you",
    roles: [null]
  },
  {
    name: 'saved-guides',
    label: 'My Guides',
    icon: MyGuidesIcon,
    path: '/my-guides',
    description: 'My saved guides',
    roles: [null, ROLES.CLIENT]
  },
  {
    name: 'sessions',
    label: 'Sessions',
    path: '/my-sessions',
    description: 'My Sessions',
    roles: [null, ROLES.CLIENT]
  },
  // {
  //   name: 'lg-resources',
  //   label: 'Resources',
  //   url: `${LIFEGUIDES_RESOURCES_URL}`,
  //   description: 'View the lifeguides resources lexicon',
  //   roles: [null, ROLES.CLIENT, ROLES.GUIDE]
  // },
  /* USER LINKS */
  {
    name: 'profile',
    label: 'My Profile',
    path: '/settings/profile',
    description: 'View and manage your personal details',
    showForUser: (user) =>
      user &&
      user.roles.some((role) => [ROLES.CLIENT, ROLES.GUIDE].includes(role.name))
  },
  {
    name: 'payment-options',
    label: 'Account',
    path: '/settings/payment-options',
    description: 'View and manage your payment methods and subscriptions',
    showForUser: (user) => {
      if (!user) {
        return false;
      }
      const isClient = user.roles.some((role) => role.name === ROLES.CLIENT);
      const hasCompanyThatPays = user.companyIds.some(
        (c) => c.status === 'ACTIVE' && c.company.paysEmployeeSubscription
      );

      return isClient && !hasCompanyThatPays;
    }
  },
  {
    name: 'logout',
    label: 'Log Out',
    path: '/logout',
    description: 'Log out',
    showForUser: (user) => !!user
  },
  {
    name: 'login',
    label: 'Sign In',
    path: '/login',
    description: 'Log in',
    showForUser: (user) => !user
  },
  {
    name: 'register',
    label: 'Register',
    description: 'Register',
    showForUser: (user) => !user
  }
];
export default MenuItems;
