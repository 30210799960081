import { graphql } from '@apollo/client/react/hoc';
import Bugsnag from '@bugsnag/js';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { withRouter } from 'react-router-dom';
import { Button, Dimmer, Icon, Loader } from 'semantic-ui-react';

import CompanyByIdQuery from '../../../../../graphql/queries/company-by-id.graphql';

const DEFAULT_IMAGE =
  'https://react.semantic-ui.com/images/wireframe/image.png';

@withRouter
@graphql(CompanyByIdQuery, {
  name: 'company',
  skip: ({ location }) => {
    const search = qs.parse(location.search, { ignoreQueryPrefix: true });
    return !search.id;
  },
  options: ({ location }) => {
    const search = qs.parse(location.search, { ignoreQueryPrefix: true });
    const variables = {
      id: search.id
    };
    return { variables };
  }
})
class LogoSelector extends Component {
  static propTypes = {
    company: PropTypes.shape({
      company: PropTypes.shape({
        id: PropTypes.string,
        logoUrl: PropTypes.string
      }),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    onError: PropTypes.func,
    onFileSelect: PropTypes.func,
    submitting: PropTypes.bool
  };

  state = {
    file: null,
    filePreview: null
  };

  render() {
    const { submitting } = this.state;

    return (
      <>
        <Dropzone
          ref={(ref) => {
            this._dropzone = ref;
          }}
          className="logo-dropzone"
          acceptStyle={{
            borderStyle: 'solid',
            borderColor: '#21ba45'
          }}
          rejectStyle={{
            borderStyle: 'solid',
            borderColor: '#db2828'
          }}
          maxSize={2000000}
          disabled={submitting}
          multiple={false}
          onDrop={this._onDrop}
          onDropRejected={this._onDropRejected}>
          {this._renderDropzoneContents}
        </Dropzone>

        <div className="logo-dropzone-cta-container">
          <Button.Group
            fluid
            vertical
            labeled
            icon
            className="logo-dropzone-cta">
            <Button
              icon="camera"
              content="Select a Logo"
              aria-placeholder="Select a logo"
              className="logo-dropzone-cta upload"
              disabled={submitting}
              onClick={() => {
                this._dropzone.open();
              }}
            />
          </Button.Group>
        </div>
      </>
    );
  }

  _renderDropzoneContents = ({
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject
  }) => {
    const company = this.props.company && this.props.company.company;
    const { file, filePreview, uploadingFile, submitting } = this.state;

    const fileUrl =
      filePreview ||
      (file && (file.preview || file.url)) ||
      (company && company.logoUrl) ||
      DEFAULT_IMAGE;

    let overlay = null;

    if (isDragActive) {
      overlay = <Icon name="upload" color="green" size="huge" />;
    }
    if (isDragReject) {
      overlay = <Icon name="cancel" color="red" size="huge" />;
    }
    if (uploadingFile) {
      overlay = (
        <Dimmer
          inverted
          active
          style={{
            zIndex: '0',
            display: 'flex',
            height: '175px',
            background: 'transparent'
          }}>
          <Loader />
        </Dimmer>
      );
    }

    let style = {
      display: 'flex',
      height: '175px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: `url(${fileUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'contain'
    };

    if (submitting) {
      style.backgroundImage = ``;
    }

    return (
      <div {...getRootProps()} style={style}>
        <input {...getInputProps()} />

        {overlay}
      </div>
    );
  };

  _onDrop = ([file]) => {
    const { onFileSelect } = this.props;

    this.setState({ file, filePreview: null });

    if (onFileSelect) {
      onFileSelect(file);
    }

    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ filePreview: reader.result });
    };

    // eslint-disable-next-line no-console
    reader.onabort = () => console.log('file reading was aborted');
    // eslint-disable-next-line no-console
    reader.onerror = () => console.log('file reading has failed');

    try {
      reader.readAsDataURL(file);
    } catch (err) {
      const variables = { file };
      Bugsnag.notify(err, function (event) {
        event.context = 'LogoSelector._onDrop';
        event.request.variables = variables;
      });
    }
  };

  _onDropRejected = () => {
    const { onError } = this.props;

    if (onError) {
      onError(
        new Error('The file was too large. Choose a file smaller than 2 MB.')
      );
    }
  };
}
export default LogoSelector;
