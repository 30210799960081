import './sub-channel-card.css';

import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Card } from 'semantic-ui-react';

import ArticleLinksBySubChannelIdQuery from '../../../graphql/queries/article-links-by-sub-channel-id.graphql';

@graphql(ArticleLinksBySubChannelIdQuery, {
  name: 'articleLinks',
  options: ({ subChannel }) => {
    const variables = {
      subChannelId: subChannel.id
    };
    return { variables };
  }
})
@withRouter
class SubChannelCard extends Component {
  static propTypes = {
    articleLinks: PropTypes.shape({
      articleLinks: PropTypes.array,
      loading: PropTypes.bool.isRequired
    }).isRequired,
    channel: PropTypes.shape({
      id: PropTypes.string,
      slug: PropTypes.string.isRequired
    }).sRequired,
    history: PropTypes.object.isRequired,
    subChannel: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired
  };

  render() {
    const { channel, history, subChannel } = this.props;
    const { articleLinks, loading } = this.props.articleLinks;

    const url = `/${channel.slug}/resources/sub-channel/${subChannel.id}`;
    const count = loading ? 'All' : articleLinks.length;

    return (
      <Card
        className="subchannel-card"
        style={{ padding: '1em' }}
        onClick={() => {
          history.push(url);
        }}>
        <Card.Content>
          <Card.Header>{subChannel.title}</Card.Header>
          <Card.Description>{subChannel.description}</Card.Description>
        </Card.Content>
        <Card.Content extra style={{ textAlign: 'center' }}>
          <Link className="ui" to={{ pathname: url, search: '' }}>
            See {count} Articles
          </Link>
        </Card.Content>
      </Card>
    );
  }
}
export default SubChannelCard;
