import './index.css';

import Bugsnag from '@bugsnag/js';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import GetVideoTokenMutation from '../../../graphql/mutations/get-video-token.graphql';
import graphql from '../../hoc/graphql';
import ErrorDialog from '../../ui/error-dialog';
import { TWILIO_ERRORS, TWILIO_ERROR_SEVERITY } from './errors';
import LocalPreview from './local-preview';
import Room from './room';
import SessionContext from './session-context';

@graphql(GetVideoTokenMutation, { name: 'getVideoToken' })
class TwilioVideo extends Component {
  static propTypes = {
    getVideoToken: PropTypes.func.isRequired,
    onConnected: PropTypes.func,
    onDisconnected: PropTypes.func,
    session: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  };

  state = {
    connecting: false,
    disconnectError: null,
    error: null,
    room: null,
    sinkId: null,
    tracks: []
  };

  componentWillUnmount() {
    this._stopTracks();
  }

  _stopTracks() {
    const { tracks } = this.state;

    tracks
      .filter((track) => track.readyState === 'live')
      .forEach((track) => track.stop());
  }

  _onTracksChange = (tracks) => {
    this.setState({ tracks });
  };

  _getToken() {
    const { getVideoToken, session } = this.props;

    const variables = { sessionId: session.id };
    return getVideoToken({ variables }).then(
      ({
        data: {
          getVideoToken: { token }
        }
      }) => token
    );
  }

  _connect = (tracks, sinkId) => {
    this.setState({ connecting: true, error: null, sinkId }, () => {
      return this._getToken()
        .then((token) => {
          return import('twilio-video').then((Video) => {
            return Video.connect(token, {
              networkQuality: {
                local: 3,
                remote: 3
              },
              tracks
            }).then(this._onRoomConnected);
          });
        })
        .catch((error) => {
          this.setState({ connecting: false, error });

          Bugsnag.notify(error, function (event) {
            event.context = 'TwilioVideo._connect';
          });
        });
    });
  };

  _onRoomConnected = (room) => {
    const { onConnected } = this.props;

    room.on('disconnected', this._onRoomDisconnected);

    this.setState({ connecting: false, room });

    if (isFunction(onConnected)) {
      onConnected();
    }
  };

  _onRoomDisconnected = (room, error) => {
    const { onDisconnected } = this.props;

    room.off('disconnected', this._onRoomDisconnected);

    this._stopTracks();

    const knownError =
      error && Object.values(TWILIO_ERRORS).find((e) => e.code === error.code);
    this.setState({ error: knownError || error, room: null });

    if (error) {
      Bugsnag.notify(error, function (event) {
        event.context = 'TwilioVideo._onRoomDisconnected';
        event.request.sid = room.sid;
        event.severity =
          (knownError && knownError.severity) || TWILIO_ERROR_SEVERITY.ERROR;
      });

      this.setState({ disconnectError: error });
    }

    if (isFunction(onDisconnected)) {
      onDisconnected(error);
    }
  };

  render() {
    const { session } = this.props;
    const { error } = this.state;

    return (
      <SessionContext.Provider value={session}>
        <div className="twilio-video-component">
          {this._renderContent()}
          <ErrorDialog
            error={error}
            onClose={() => {
              this.setState({ error: null });
            }}
          />
        </div>
      </SessionContext.Provider>
    );
  }

  _renderContent() {
    return this._renderRoom() || this._renderLocalPreview();
  }

  _renderLocalPreview() {
    const { connecting, disconnectError } = this.state;

    return (
      <LocalPreview
        connecting={connecting}
        showCallMe={!!disconnectError}
        onChange={this._onTracksChange}
        onSubmit={this._connect}
      />
    );
  }

  _renderRoom() {
    const { room, sinkId } = this.state;

    if (!room) {
      return null;
    }

    return <Room room={room} sinkId={sinkId} />;
  }
}
export default TwilioVideo;
