import 'rc-slider/assets/index.css';

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@windmill/react-ui';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import React, { useState } from 'react';
import AvatarEditor from 'react-avatar-editor';

const DEGREES = [0, 90, 180, 270];

const avatarEditorRef = React.createRef();
const modalRef = React.createRef();

function isDescendant(parent, child) {
  if (!child) {
    return false;
  }
  if (parent === child) {
    return true;
  }
  return isDescendant(parent, child.parentNode);
}

function AvatarCropperDialog(props) {
  const { image, isOpen, onClose, onLoadFailure, onSelect } = props;

  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);

  function rotate(index) {
    setRotation((rotation + index + DEGREES.length) % DEGREES.length);
  }

  function onScaleChange(value) {
    setScale(value / 100 + 1);
  }

  function onSelectClick() {
    const canvas = avatarEditorRef.current.getImage();
    canvas.toBlob((blob) => {
      const file = new File([blob], 'avatar.webp', {
        type: 'image/webp',
        lastModified: new Date().getTime()
      });
      onSelect(file);
    });
  }

  const clientWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const borderWidth = 25;
  const avatarWidth = Math.min(Math.floor(clientWidth - borderWidth * 2), 400);

  return (
    <Modal
      ref={modalRef}
      className="w-full px-6 py-4 overflow-y-auto max-h-screen bg-white rounded-t-lg sm:rounded-lg sm:m-4 sm:max-w-xl"
      isOpen={isOpen}
      onClose={({ target }) => {
        if (isDescendant(modalRef.current, target)) {
          onClose();
        }
      }}>
      <ModalHeader></ModalHeader>
      <ModalBody>
        <div className="flex justify-center">
          <AvatarEditor
            ref={avatarEditorRef}
            border={borderWidth}
            borderRadius={Math.ceil(avatarWidth / 2)}
            onLoadFailure={onLoadFailure}
            height={avatarWidth}
            image={image}
            rotate={DEGREES[rotation]}
            scale={scale}
            width={avatarWidth}
          />
        </div>
        <div className="my-4 flex justify-around">
          <Button layout="outline" onClick={() => rotate(-1)}>
            <i className="icon undo" />
          </Button>
          <Button layout="outline" onClick={() => rotate(1)}>
            <i className="icon redo" />
          </Button>
        </div>
        <div className="my-4">
          <Slider value={(scale - 1) * 100} onChange={onScaleChange} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="w-full sm:w-auto" layout="outline" onClick={onClose}>
          Nevermind
        </Button>
        <Button className="w-full sm:w-auto" onClick={onSelectClick}>
          Use Avatar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
AvatarCropperDialog.propTypes = {
  image: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onLoadFailure: PropTypes.func,
  onSelect: PropTypes.func
};
export default AvatarCropperDialog;
