import './index.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Header,
  List,
  Modal,
  Rating,
  Segment,
  Container
} from 'semantic-ui-react';

import { ROLES } from '../../../consts';
import UpdateSessionRatingMutation from '../../../graphql/mutations/update-session-rating.graphql';
import SessionByIdGuideQuery from '../../../graphql/queries/session-by-id-guide.graphql';
import SessionByIdQuery from '../../../graphql/queries/session-by-id.graphql';
import graphql from '../../hoc/graphql';
import withUser from '../../hoc/with-user';
import LoadingSpinner from '../../ui/loading-spinner';
import ClientDetails from '../client-details';
import ClientFaceplate from '../client-details/faceplate';
import SessionNotes from '../session-notes';
import TwilioVideo from '../twilio-video';
import CancelSessionDialog from './cancel-session-dialog';
import CompleteSessionDialog from './complete-session-dialog';
import crisisQuestions from './crisis-refer-questions';
import assessQuestions from './crisis-assess-questions';
import debriefActions from './crisis-debrief-actions';
import ScheduleSessionDialog from './schedule-session-dialog';
import SendMessageDialog from './send-message-dialog';

export const CALL_LENGTH = 30;

const DIALOGS = {
  CANCEL_SESSION: 'CANCEL_SESSION',
  COMPLETE_SESSION: 'COMPLETE_SESSION',
  SCHEDULE_SESSION: 'SCHEDULE_SESSION',
  SEND_MESSAGE: 'SEND_MESSAGE',
  SHOULD_COMPLETE_SESSION: 'SHOULD_COMPLETE_SESSION'
};

const SIDE_BARS = {
  EAP: 'EAP',
  CRISIS: 'CRISIS'
};

function topicsToString(topics) {
  if (!topics.length) {
    return '';
  }

  const topicTitles = topics.map((topic) => topic.title);
  const [lastTopic, ...remainingTopics] = topicTitles.reverse();
  if (!remainingTopics.length) {
    return lastTopic;
  }

  return `${remainingTopics.join(', ')} and ${lastTopic}`;
}

@withRouter
@withUser({
  authenticated: [ROLES.CLIENT, ROLES.GUIDE],
  loader: <LoadingSpinner className="w-48 h-48 mx-auto my-4" />,
  showError: true
})
@graphql(SessionByIdGuideQuery, {
  name: 'session',
  options: ({
    match: {
      params: { id }
    }
  }) => ({ variables: { id } }),
  skip: ({ user }) => {
    return !(
      user &&
      user.User &&
      user.User.roles.some((role) => role.name === ROLES.GUIDE)
    );
  }
})
@graphql(SessionByIdQuery, {
  name: 'session',
  options: ({
    match: {
      params: { id }
    }
  }) => ({ variables: { id } }),
  skip: ({ user }) => {
    return !!(
      user &&
      user.User &&
      user.User.roles.some((role) => role.name === ROLES.GUIDE)
    );
  }
})
@graphql(UpdateSessionRatingMutation, { name: 'updateRating' })
class Session extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    session: PropTypes.shape({
      session: PropTypes.shape({
        id: PropTypes.string,
        client: PropTypes.object,
        guide: PropTypes.object,
        reason: PropTypes.string,
        rescheduledTo: PropTypes.shape({
          id: PropTypes.string
        }),
        scheduledTime: PropTypes.string,
        status: PropTypes.string,
        topics: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string
          })
        )
      }),
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func
    }),
    updateRating: PropTypes.func.isRequired,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string,
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          })
        )
      }).isRequired
    }).isRequired
  };

  state = {
    completed: false,
    connected: false,
    dialog: null,
    sideBar: null,
    started: null
  };

  _onTwilioVideoConnected = () => {
    this.setState({ connected: true, started: new Date() });
  };

  _onTwilioVideoDisconnected = (error) => {
    const { User } = this.props.user;

    if (error) {
      return;
    }

    const isGuide = User.roles.some((role) => role.name === ROLES.GUIDE);
    if (isGuide) {
      this.setState({
        connected: false,
        dialog: DIALOGS.SHOULD_COMPLETE_SESSION
      });
    } else {
      this.setState({ completed: true, connected: false });
    }
  };

  _checkRescheduled() {
    const { history, location } = this.props;
    const { session } = this.props.session;
    if (!session) {
      return;
    }

    if (session.status === 'RESCHEDULED' && session.rescheduledTo) {
      history.push({
        pathname: `/session/${session.rescheduledTo.id}`,
        search: location.search
      });
    }
  }

  componentDidMount() {
    this._checkRescheduled();
  }

  componentDidUpdate() {
    this._checkRescheduled();
  }

  render() {
    const { history } = this.props;
    const { loading, session } = this.props.session;
    const { User } = this.props.user;

    if (loading) {
      return <LoadingSpinner className="w-48 h-48 mx-auto my-4" />;
    }

    if (!session) {
      history.replace('/404');
      return null;
    }

    const classNames = ['call-container px-4'].concat(
      User.roles.map((role) => `is-${role.name.toLowerCase()}`)
    );

    return (
      <div className={classNames.join(' ')}>
        {this._renderContent()}
        {this._renderDialogs()}
      </div>
    );
  }

  _renderContent() {
    const { User } = this.props.user;

    const isGuide = User.roles.some((role) => role.name === ROLES.GUIDE);

    return isGuide ? this._renderGuideContent() : this._renderClientContent();
  }

  _renderClientContent() {
    const { session } = this.props.session;
    const { completed } = this.state;

    if (completed) {
      return this._renderClientCompleted();
    }

    return (
      <TwilioVideo
        session={session}
        onConnected={this._onTwilioVideoConnected}
        onDisconnected={this._onTwilioVideoDisconnected}
      />
    );
  }

  _renderGuideContent() {
    const { session } = this.props.session;
    const { connected } = this.state;

    const hasEAP = session.client.companyIds.some(
      ({ company }) => !!company.employeeAssistanceProgram
    );
    const topicsString = topicsToString(session.topics);

    return (
      <>
        <div className="my-4 client-header-container">
          <ClientFaceplate client={session.client} />
          <div className="controls">
            {!connected && (
              <>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    this.setState({ dialog: DIALOGS.COMPLETE_SESSION });
                  }}>
                  Complete Session
                </a>
                &nbsp;|&nbsp;
              </>
            )}
            <a
              href="#"
              onClick={(event) => {
                event.preventDefault();
                this.setState({ dialog: DIALOGS.SEND_MESSAGE });
              }}>
              Send Text
            </a>
            &nbsp;|&nbsp;
            <a
              href="#"
              onClick={(event) => {
                event.preventDefault();
                this.setState({ dialog: DIALOGS.SCHEDULE_SESSION });
              }}>
              Book Again
            </a>
          </div>
          <Button
            onClick={() => {
              this.setState({
                sideBar:
                  this.state.sideBar === SIDE_BARS.CRISIS
                    ? null
                    : SIDE_BARS.CRISIS
              });
            }}>
            Crisis
          </Button>
          {hasEAP && (
            <Button
              onClick={() => {
                this.setState({
                  sideBar:
                    this.state.sideBar === SIDE_BARS.EAP ? null : SIDE_BARS.EAP
                });
              }}>
              Company EAP
            </Button>
          )}
        </div>
        <div className="reason">
          <Header>{topicsString}</Header>
          <label>Reason for call:</label>
          <p>
            {session.reason || 'Member did not provide a reason for this call.'}
          </p>
        </div>
        <div className="video">
          <TwilioVideo
            session={session}
            onConnected={this._onTwilioVideoConnected}
            onDisconnected={this._onTwilioVideoDisconnected}
          />
          {this._renderSideBar()}
        </div>
        <Segment className="call-notes-container">
          <Header className="call-notes-text">Call Notes</Header>
          <SessionNotes className="call-notes" session={session} />
        </Segment>
        <ClientDetails className="client-details" client={session.client} />
      </>
    );
  }

  _renderSideBar() {
    const { sideBar } = this.state;

    switch (sideBar) {
      case SIDE_BARS.CRISIS:
        return this._renderCrisisProtocol();
      case SIDE_BARS.EAP:
        return this._renderEmployeeAssistanceProgram();
      default:
        return null;
    }
  }

  _renderEmployeeAssistanceProgram() {
    const { session } = this.props.session;

    const company = session.client.companyIds
      .map(({ company }) => company)
      .find((company) => !!company.employeeAssistanceProgram);

    return (
      <div className="sidebar">
        <Segment>
          <div
            className="rich-text"
            dangerouslySetInnerHTML={{
              __html: company.employeeAssistanceProgram
            }}
          />
        </Segment>
      </div>
    );
  }

  _renderCrisisAfterCallDebrief() {
    return (
      <div>
        <div className="p-4 call-client-header">After the Call Debrief</div>
        {this._renderDebriefActions()}
      </div>
    );
  }

  _renderProtocolQuestions() {
    return crisisQuestions.map((q, qidx) => {
      return (
        <List.Item key={q.id} className="p-4">
          <List.Header as="">
            <span className="mr-2 text-gray-600">{qidx + 1}.</span>
            <span
              className="text-gray-800"
              dangerouslySetInnerHTML={{ __html: q.title }}
            />
            <span>:</span>
          </List.Header>
          <List.List>
            {q.questions.map((qI) => {
              return (
                <List.Item key={qI} className="flex p-1">
                  <div className="mr-2 text-gray-600">•</div>
                  <div className="text-gray-800">
                    <span dangerouslySetInnerHTML={{ __html: qI }} />
                  </div>
                </List.Item>
              );
            })}
          </List.List>
        </List.Item>
      );
    });
  }

  _renderCrisisRefer() {
    return (
      <div>
        <div className="p-4 call-client-header">Refer</div>
        <p className="p-4">
          If a member responds <i>Yes</i> to the <b>Plan</b> and <b>Means</b>
          &nbsp; Questions, immediately refer to a Crisis Specialist by doing
          the following:
        </p>
        {this._renderProtocolQuestions()}
      </div>
    );
  }

  _renderAssessQuestions() {
    return (
      <List>
        {assessQuestions.map((q, i) => {
          return (
            <List.Item key={`question-${i}`} className="flex p-1">
              <span className="ml-4 mr-2 text-gray-600">{`${i + 1}.`}</span>
              <span dangerouslySetInnerHTML={{ __html: q }}></span>
            </List.Item>
          );
        })}
      </List>
    );
  }

  _renderDebriefActions() {
    return debriefActions.map((q, qidx) => {
      return (
        <List.Item key={q.id} className="p-4">
          <List.Header as="">
            <span className="mr-2 text-gray-600">{qidx + 1}.</span>
            <span
              className="text-gray-800"
              dangerouslySetInnerHTML={{ __html: q.title }}
            />
            <span>:</span>
          </List.Header>
          <List.List>
            {q.actions.map((qI) => {
              return (
                <List.Item key={qI} className="flex p-1">
                  <div className="mr-2 text-gray-600">•</div>
                  <div className="text-gray-800">
                    <span dangerouslySetInnerHTML={{ __html: qI }} />
                  </div>
                </List.Item>
              );
            })}
          </List.List>
        </List.Item>
      );
    });
  }

  _renderCrisisAssess() {
    return (
      <div>
        <div className="p-4 call-client-header">Assess</div>
        <p className="p-4">
          In order to effectively assess if a member is in crisis, and to
          determine the next steps, please employ yes/no assessment questions.
          Begin by gathering essential information through direct inquiries that
          elicit clear yes or no responses.
        </p>
        {this._renderAssessQuestions()}
      </div>
    );
  }

  _renderCrisisProtocol() {
    return (
      <Container>
        <Segment className={`crisis`}>
          <div className="p-2">{this._renderCrisisAssess()}</div>
          <div className="p-2">{this._renderCrisisRefer()}</div>
          <div className="p-2">{this._renderCrisisAfterCallDebrief()}</div>
        </Segment>
      </Container>
    );
  }

  _renderClientCompleted() {
    const { history } = this.props;

    return (
      <div className="client-post-call">
        <Header>You have left the call</Header>
        <Button
          onClick={() => {
            this.setState({ completed: false });
          }}>
          Rejoin
        </Button>
        <Button
          primary
          onClick={() => {
            history.push({
              pathname: '/',
              search: ''
            });
          }}>
          Return to home screen
        </Button>
        <Segment compact>
          <Header size="small">How would you rate the call quality?</Header>
          <Rating
            icon="star"
            maxRating={5}
            size="massive"
            onRate={this._onRate}
          />
        </Segment>
      </div>
    );
  }

  _renderDialogs() {
    const { session } = this.props.session;
    const { dialog } = this.state;

    switch (dialog) {
      case DIALOGS.CANCEL_SESSION:
        return (
          <CancelSessionDialog
            session={session}
            onClose={this._onCloseDialog}
            open
          />
        );
      case DIALOGS.COMPLETE_SESSION:
        return this._renderCompleteDialog();
      case DIALOGS.SCHEDULE_SESSION:
        return this._renderScheduleDialog();
      case DIALOGS.SEND_MESSAGE:
        return (
          <SendMessageDialog
            session={session}
            onClose={this._onCloseDialog}
            open
          />
        );
      case DIALOGS.SHOULD_COMPLETE_SESSION:
        return this._renderShouldCompleteDialog();
    }
  }

  _renderShouldCompleteDialog() {
    return (
      <Modal open size="tiny">
        <Modal.Content>
          <Header size="medium">You have left the call</Header>
          <p>
            Select CONTINUE if you need to reconnect the call now or later.
            Choose COMPLETE if you consider the call completed and wish to
            finish the post-call tasks.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              this.setState({ dialog: null });
            }}>
            CONTINUE
          </Button>
          <Button
            primary
            onClick={() => {
              this.setState({ dialog: DIALOGS.COMPLETE_SESSION });
            }}>
            COMPLETE
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  _renderCompleteDialog() {
    const { history } = this.props;
    const { session } = this.props.session;

    return (
      <CompleteSessionDialog
        open
        onClose={(response) => {
          if (response) {
            history.push({
              pathname: '/dashboard',
              search: ''
            });
          } else {
            this.setState({ dialog: null });
          }
        }}
        session={session}
      />
    );
  }

  _renderScheduleDialog() {
    const { session } = this.props.session;

    return (
      <ScheduleSessionDialog
        open
        onClose={() => {
          this.setState({ dialog: null });
        }}
        session={session}
      />
    );
  }

  _onCloseDialog = () => {
    this.setState({ dialog: null });
  };

  _onRate = (event, { rating }) => {
    const { updateRating } = this.props;
    const { session } = this.props.session;

    const variables = {
      id: session.id,
      rating
    };

    updateRating({ variables });
  };
}
export default Session;
