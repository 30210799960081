import './index.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Container, Header } from 'semantic-ui-react';

import { ROLES } from '../../../../consts';
import withUser from '../../../hoc/with-user';
import List from './list';
import Routes from './routes';

@withRouter
@withUser({ authenticated: [ROLES.ADMIN] })
class AdminCompanies extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  render() {
    return (
      <div className="admin-companies-component p-4">
        <div className="header-container">
          <Header>Companies</Header>
          <Button
            icon="plus"
            content="Create Company"
            onClick={this._onClickCreate}
          />
        </div>
        <Container textAlign="center">
          <List />
        </Container>
        <Routes />
      </div>
    );
  }

  _onClickCreate = () => {
    const { history, location, match } = this.props;

    const returnUrl = `${location.pathname}${location.search}`;

    history.push(
      `${match.url}/dialog/edit${location.search}${
        location.search ? '&' : '?'
      }returnUrl=${encodeURIComponent(returnUrl)}`
    );
  };
}
export default AdminCompanies;
