import './index.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Divider, Header, Input, List, Segment } from 'semantic-ui-react';

class ClientDetails extends Component {
  static propTypes = {
    client: PropTypes.object.isRequired
  };

  render() {
    return <div className="client-details">{this._renderClient()}</div>;
  }

  _renderClient() {
    const { client } = this.props;

    const location = client.state
      ? client.city
        ? `${client.city}, ${client.state}`
        : client.state
      : '';

    return (
      <Segment className="profile-segment">
        <Header>Member Profile</Header>
        <Divider />
        <List className="profile-fields">
          {this._renderListItem('Name', client.firstName || '')}
          {this._renderListItem('Age', client.age || '')}
          {this._renderListItem('Location', location)}
        </List>
      </Segment>
    );
  }

  _renderListItem(title, value) {
    return (
      <List.Item key={`list-item-${title}`} className="profile-field">
        <List.Header>{title}</List.Header>
        <List.Description>
          <Input icon="lock" fluid disabled={true} value={value} />
        </List.Description>
      </List.Item>
    );
  }
}
export default ClientDetails;
