import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import qs from 'qs';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Icon, Pagination } from 'semantic-ui-react';

import { ROLES, ROLE_LABELS } from '../../../../consts';
import UsersAdminPaginationQuery from '../../../../graphql/queries/users-admin-pagination.graphql';
import history from '../../../../history';
import useUser from '../../../hooks/use-user';
import Avatar from '../../../ui/avatar';
import DataGrid from '../../../ui/data-grid';
import ErrorMessage from '../../../ui/error-message';
import LoadingSpinner from '../../../ui/loading-spinner';

const PAGE_SIZE = 20;
const SORT_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC'
};
const SEARCH_DEFAULTS = {
  page: 1,
  sort: 'createdAt',
  direction: SORT_DIRECTIONS.DESC
};

const COLUMNS = [
  {
    // eslint-disable-next-line react/display-name
    formatter: (_, user) => <Avatar user={user} />
  },
  {
    prop: 'firstName',
    label: 'First Name',
    formatter: 'capitalizedText',
    sortable: true
  },
  {
    prop: 'lastName',
    label: 'Last Name',
    formatter: 'capitalizedText',
    sortable: true
  },
  {
    prop: 'emailAddress',
    label: 'E-Mail Address',
    sortable: true
  },
  {
    prop: 'role',
    label: 'Role',
    formatter: (_, user) => {
      const isRegistered = get(user, 'roles.length');
      const hasPendingClientInvite =
        get(user, 'clientInvite.status') === 'PENDING';

      return isRegistered
        ? user.roles.map((role) => ROLE_LABELS[role.name]).join(', ')
        : hasPendingClientInvite
        ? 'INVITED'
        : null;
    },
    sortable: false
  },
  {
    prop: 'company',
    label: 'Company',
    formatter: (_, user) => {
      const isRegistered = get(user, 'roles.length');
      const hasPendingClientInvite =
        get(user, 'clientInvite.status') === 'PENDING';

      return isRegistered
        ? user.companyIds
            .map((connection) => get(connection, 'company.name'))
            .filter((name) => !!name)
            .join(', ')
        : hasPendingClientInvite &&
            get(user, 'clientInvite.company.name', null);
    },
    sortable: false
  },
  {
    prop: 'createdAt',
    label: 'Created',
    formatter: 'dateTimeFromNow',
    sortable: true
  }
];

function parseSearch(str) {
  const search = qs.parse(str, { ignoreQueryPrefix: true });
  return {
    ...search,
    page: parseInt(search.page, 10) || SEARCH_DEFAULTS.page,
    sort: search.sort || SEARCH_DEFAULTS.sort,
    direction: search.direction || SEARCH_DEFAULTS.direction
  };
}

function AdminUsersList() {
  const location = useLocation();

  const { data: userData } = useUser();

  const isAdmin =
    userData &&
    userData.User &&
    userData.User.roles.some((role) => role.name === ROLES.ADMIN);

  const search = parseSearch(location.search);
  const searchTerm = search.filter ? search.filter.toLowerCase() : '';
  const skip = search.page > 0 ? (search.page - 1) * PAGE_SIZE : 0;
  const first = PAGE_SIZE;
  const orderBy = `${search.sort}_${search.direction}`;

  function buildWhere() {
    const where = {
      OR: [
        { emailAddressLower_contains: searchTerm },
        { firstNameLower_contains: searchTerm },
        { lastNameLower_contains: searchTerm }
      ]
    };

    if (isAdmin) {
      if (search.companyId) {
        where.companyIds_some = {
          company: {
            id: search.companyId
          },
          status: 'ACTIVE'
        };
      }
      if (search.roles) {
        where.roles_some = {
          name_in: search.roles
        };
      }
    } else {
      where.roles_some = {
        name: ROLES.GUIDE
      };
    }

    return where;
  }

  const variables = {
    first,
    orderBy,
    skip,
    where: buildWhere()
  };

  const { data, error, loading, refetch } = useQuery(
    UsersAdminPaginationQuery,
    { variables }
  );

  function onClickUser(user) {
    const pathname = isAdmin ? `/admin/user/${user.id}` : `/guide/${user.id}`;
    history.push({
      pathname,
      search: qs.stringify({
        returnUrl: `${location.pathname}${location.search}`
      })
    });
  }

  function onPageChange(e, { activePage }) {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...search,
        page: activePage
      })
    });
  }

  function onSort(name) {
    const direction =
      search.sort === name
        ? search.direction === SORT_DIRECTIONS.ASC
          ? SORT_DIRECTIONS.DESC
          : SORT_DIRECTIONS.ASC
        : SORT_DIRECTIONS.ASC;

    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...search,
        sort: name,
        direction
      })
    });
  }

  function renderPagination() {
    if (!data.usersConnection) {
      return null;
    }

    const search = parseSearch(location.search);

    const count = data.usersConnection.aggregate.count;
    const totalPages = Math.ceil(count / PAGE_SIZE);

    if (!count) {
      return null;
    }

    return (
      <Container textAlign="center">
        <Pagination
          activePage={search.page}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true
          }}
          firstItem={null}
          lastItem={null}
          prevItem={
            count > PAGE_SIZE
              ? { content: <Icon name="angle left lineawesome" />, icon: true }
              : null
          }
          nextItem={
            count > PAGE_SIZE
              ? { content: <Icon name="angle right lineawesome" />, icon: true }
              : null
          }
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </Container>
    );
  }

  function renderUsers() {
    if (error) {
      return <ErrorMessage error={error} retry={() => refetch()} />;
    }

    if (loading) {
      return <LoadingSpinner className="mx-auto my-4 w-48 h-48" />;
    }

    const search = parseSearch(location.search);
    const direction =
      search.direction === SORT_DIRECTIONS.ASC ? 'ascending' : 'descending';

    return (
      <div style={{ paddingTop: '1em' }}>
        <DataGrid
          columns={COLUMNS}
          data={data.users}
          loading={loading}
          onRowClick={onClickUser}
          onSort={onSort}
          sortColumn={search.sort}
          sortDirection={direction}
        />
        {renderPagination()}
      </div>
    );
  }

  return renderUsers();
}
export default AdminUsersList;
