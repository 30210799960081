import { useQuery } from '@apollo/client';
import qs from 'qs';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Icon, Table } from 'semantic-ui-react';

import TopicGroupsQuery from '../../../../graphql/queries/topic-groups.graphql';
import history from '../../../../history';
import ErrorMessage from '../../../ui/error-message';
import FullscreenLoader from '../../../ui/fullscreen-loader';

const DEFAULT_IMAGE_URL =
  'https://react.semantic-ui.com/images/wireframe/image.png';

export default function TopicGroupList() {
  const location = useLocation();
  const { data, error, loading, refetch } = useQuery(TopicGroupsQuery, {
    notifyOnNetworkStatusChange: true
  });

  function createUrl(path, params) {
    const returnUrl = `${location.pathname}${location.search}`;
    const paramsStr = qs.stringify(params);

    return `${location.pathname}${path}${location.search}${
      location.search ? '&' : '?'
    }${paramsStr}&returnUrl=${encodeURIComponent(returnUrl)}`;
  }

  function renderTopicGroup(topicGroup) {
    return (
      <Table.Row
        key={topicGroup.id}
        style={{ backgroundColor: topicGroup.color }}>
        <Table.Cell>
          <img
            src={topicGroup.imageUrl || DEFAULT_IMAGE_URL}
            alt={`${topicGroup.title} image`}
            style={{ width: '50px' }}
          />
        </Table.Cell>
        <Table.Cell>{topicGroup.title}</Table.Cell>
        <Table.Cell textAlign="right">
          <Button
            onClick={() => {
              history.push(createUrl('/dialog/edit', { id: topicGroup.id }));
            }}>
            <Icon name="edit" />
            Edit Topic Group
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }

  function renderTopicGroups() {
    if (error) {
      return (
        <Table.Row>
          <Table.Cell colspan={3}>
            <ErrorMessage error={error} retry={() => refetch()} />
          </Table.Cell>
        </Table.Row>
      );
    }

    if (loading) {
      return (
        <Table.Row>
          <Table.Cell colspan={3}>
            <FullscreenLoader />
          </Table.Cell>
        </Table.Row>
      );
    }

    return data.topicGroups.map(renderTopicGroup);
  }

  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Title</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderTopicGroups()}</Table.Body>
    </Table>
  );
}
