import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Video from './video';
import VolumeIndicator from './volume-indicator';

class LocalTracksView extends Component {
  static propTypes = {
    children: PropTypes.node,
    tracks: PropTypes.arrayOf(PropTypes.object)
  };

  render() {
    const { children, tracks, ...remaining } = this.props;

    const audioTrack = tracks.find((track) => track.kind === 'audio');
    const videoTrack = tracks.find((track) => track.kind === 'video');

    return (
      <div className="local-tracks-view" {...remaining}>
        <Video track={videoTrack} />
        {audioTrack && <VolumeIndicator track={audioTrack} />}
        {children}
      </div>
    );
  }
}
export default LocalTracksView;
