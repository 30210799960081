import qs from 'qs';
import React from 'react';

import { connect, getUserInfo, logout } from '../../auth0';

export { Connections } from '../../auth0';

export default function withAuth0() {
  return function componentWrapper(Component) {
    class WithAuth0 extends React.Component {
      static displayName = `withAuth0(${
        Component.displayName || Component.name
      })`;

      state = {
        error: null,
        user: null
      };

      componentDidMount() {
        getUserInfo()
          .then((user) => {
            this.setState({ user });
          })
          .catch((error) => {
            this.setState({ error });
          });
      }

      render() {
        const { error, user } = this.state;

        const { hash } = window.location;
        const queryString = qs.parse(hash.substring(1));

        const auth0Props = {
          code: queryString.code,
          connect,
          error,
          logout,
          user
        };

        return <Component auth0={auth0Props} {...this.props} />;
      }
    }

    return WithAuth0;
  };
}
