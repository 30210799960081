import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

import head from 'lodash/head';
import isArray from 'lodash/isArray';
import last from 'lodash/last';
import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component, Suspense, lazy } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Confirm, Header, Label, Loader } from 'semantic-ui-react';

import CreateOrUpdateUserAvailabilityMutation from '../../../graphql/mutations/create-or-update-user-availability.graphql';
import DeleteUserAvailabilityMutation from '../../../graphql/mutations/delete-user-availability.graphql';
import GuideByIdQuery from '../../../graphql/queries/guide-by-id.graphql';
import UserAvailabilitiesByUserIdQuery from '../../../graphql/queries/user-availabilities-by-user-id.graphql';
import graphql from '../../hoc/graphql';
import Avatar from '../../ui/avatar';
import ErrorDialog from '../../ui/error-dialog';
import FullScreenLoader from '../../ui/fullscreen-loader';
import TimezoneSelector from '../../ui/timezone-selector';
import AvailabilityDialog from './availability-dialog';

const DragAndDropCalendar = lazy(() => import('./dnd-calendar'));

const moment = extendMoment(Moment);

const DATE_FORMAT = moment.HTML5_FMT.DATE;
const TIME_FORMAT = 'HH:mm';
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

function nextWeekDay(d) {
  return (d + 1) % 7;
}

function dayRange(start, end) {
  const days = [];
  let i = start;
  do {
    days.push(i);
    i = nextWeekDay(i);
  } while (i != nextWeekDay(end));
  return days;
}

function mergeDateAndTime(date, time) {
  const d = moment(date.format(DATE_FORMAT), DATE_FORMAT);
  const t = moment(time.format(TIME_FORMAT), TIME_FORMAT);
  return d.hours(t.hours()).minutes(t.minutes()).toDate();
}

function inTimezone(date, timezone) {
  const str = moment(date).format(DATE_TIME_FORMAT);
  return moment.tz(str, DATE_TIME_FORMAT, timezone);
}

@withRouter
@graphql(CreateOrUpdateUserAvailabilityMutation, {
  name: 'createOrUpdateAvailability',
  options: (props) => ({
    update: (store, { data: { upsertUserAvailability } }) => {
      const { match } = props;

      const variables = {
        userId: match.params.id
      };

      const data = store.readQuery({
        query: UserAvailabilitiesByUserIdQuery,
        variables
      });

      const { userAvailabilities } = data;
      const newAvailabilities = [...userAvailabilities];
      const included = newAvailabilities.some(
        (a) => a.id === upsertUserAvailability.id
      );
      if (!included) {
        newAvailabilities.push(upsertUserAvailability);

        store.writeQuery({
          query: UserAvailabilitiesByUserIdQuery,
          variables,
          data: {
            ...data,
            userAvailabilities: newAvailabilities
          }
        });
      }
    }
  })
})
@graphql(DeleteUserAvailabilityMutation, {
  name: 'deleteAvailability',
  options: (props) => ({
    update: (store, { data: { deleteUserAvailability } }) => {
      const { match } = props;

      const variables = {
        userId: match.params.id
      };

      const data = store.readQuery({
        query: UserAvailabilitiesByUserIdQuery,
        variables
      });

      const { userAvailabilities } = data;
      const newAvailabilities = [...userAvailabilities];
      const index = newAvailabilities.findIndex(
        (a) => a.id === deleteUserAvailability.id
      );
      if (index >= 0) {
        newAvailabilities.splice(index, 1);

        store.writeQuery({
          query: UserAvailabilitiesByUserIdQuery,
          variables,
          data: {
            ...data,
            userAvailabilities: newAvailabilities
          }
        });
      }
    }
  })
})
@graphql(UserAvailabilitiesByUserIdQuery, {
  name: 'availabilities',
  options: ({ match }) => ({
    variables: { userId: match.params.id }
  })
})
@graphql(GuideByIdQuery, {
  name: 'guide',
  options: ({ match }) => ({ variables: { id: match.params.id } })
})
class AvailabilityCalendar extends Component {
  static propTypes = {
    availabilities: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      userAvailabilities: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      )
    }).isRequired,
    createOrUpdateAvailability: PropTypes.func.isRequired,
    deleteAvailability: PropTypes.func.isRequired,
    guide: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      user: PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        timezone: PropTypes.string
      })
    }).isRequired,
    match: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    const { user } = this.props.guide;

    this.state = {
      confirmDelete: false,
      error: null,
      events: [],
      range: {
        end: moment().endOf('week').toDate(),
        start: moment().startOf('week').toDate()
      },
      selectedEvent: null,
      submitting: false,
      timezone: (user && user.timezone) || moment.tz.guess(),
      view: null
    };

    import('react-big-calendar').then(({ Views, momentLocalizer }) => {
      const localizer = momentLocalizer(moment);
      const view = Views.WEEK;
      this.setState({ localizer, view, Views });
    });
  }

  componentDidMount() {
    const { userAvailabilities } = this.props.availabilities;
    if (userAvailabilities && userAvailabilities.length) {
      this._updateEvents();
    }
  }

  componentDidUpdate(prevProps) {
    const { userAvailabilities } = this.props.availabilities;
    const { user } = this.props.guide;

    if (!prevProps.availabilities.userAvailabilities && userAvailabilities) {
      this._updateEvents();
    } else if (
      prevProps.availabilities.userAvailabilities &&
      prevProps.availabilities.userAvailabilities !== userAvailabilities
    ) {
      this._updateEvents();
    }

    if (user && user !== prevProps.guide.user) {
      this.setState(
        {
          timezone: (user && user.timezone) || moment.tz.guess()
        },
        this._updateEvents
      );
    }
  }

  _updateEvents = () => {
    const { userAvailabilities } = this.props.availabilities;
    const { user } = this.props.guide;
    const { range, timezone } = this.state;

    if (!userAvailabilities || !user) {
      return;
    }

    const events = userAvailabilities.reduce((acc, availability) => {
      const baseEndTime = moment(availability.endTime);
      const endDate = baseEndTime.clone().tz(timezone).endOf('day');
      const endDay = endDate.day();

      const baseStartTime = moment(availability.startTime);
      const startDate = baseStartTime.clone().tz(timezone).startOf('day');
      const startDay = startDate.day();

      const daysOfWeek = dayRange(startDay, endDay);

      const included = {};

      if (availability.isRecurring) {
        const events = Array.from(
          moment
            .range(
              inTimezone(range.start, timezone),
              inTimezone(range.end, timezone)
            )
            .by('day')
        ).reduce((acc, day) => {
          const week = day.week();
          const isOnDay = daysOfWeek.includes(day.day());

          const isEffectiveEndDateAfterDay =
            !availability.effectiveEndDate ||
            moment(availability.effectiveEndDate)
              .tz(user.timezone || timezone)
              .endOf('day')
              .isSameOrAfter(day);
          const isEffectiveStartDateBeforeDay =
            !availability.effectiveStartDate ||
            moment(availability.effectiveStartDate)
              .tz(user.timezone || timezone)
              .startOf('day')
              .isSameOrBefore(day);

          if (
            !isOnDay ||
            !isEffectiveEndDateAfterDay ||
            !isEffectiveStartDateBeforeDay ||
            included[week]
          ) {
            return acc;
          }

          included[week] = true;

          const shift = (m, ref) => {
            return moment(day)
              .tz(timezone)
              .day(ref)
              .hours(m.hours())
              .minutes(m.minutes());
          };

          const endTime = shift(baseEndTime.clone().tz(timezone), endDay);
          const startTime = shift(baseStartTime.clone().tz(timezone), startDay);

          const description =
            daysOfWeek.length > 1
              ? `${startTime.format('h:mma')} - ${endTime.format('h:mma')} ${
                  availability.description
                }`
              : availability.description;

          return acc.concat([
            {
              end: mergeDateAndTime(endTime, endTime),
              start: mergeDateAndTime(startTime, startTime),
              resource: availability,
              title: `(Recurring) ${description || ''}`
            }
          ]);
        }, []);
        return acc.concat(events);
      } else {
        const endTime = baseEndTime.clone().tz(timezone);
        const startTime = baseStartTime.clone().tz(timezone);

        const description =
          daysOfWeek.length > 1
            ? `${startTime.format('h:mma')} - ${endTime.format('h:mma')} ${
                availability.description
              }`
            : availability.description;

        return acc.concat([
          {
            end: mergeDateAndTime(endDate, endTime),
            start: mergeDateAndTime(startDate, startTime),
            resource: availability,
            title: `${description || ''}`
          }
        ]);
      }
    }, []);

    this.setState({ events });
  };

  render() {
    const { loading } = this.props.availabilities;
    const { error, localizer, timezone, view, Views } = this.state;

    if (loading) {
      return <FullScreenLoader />;
    }

    return (
      <div className="availability-calendar">
        {this._renderGuide()}
        <div className="controls">
          <TimezoneSelector
            selection
            value={timezone}
            onChange={(event, { value }) => {
              this.setState({ timezone: value }, this._updateEvents);
            }}
          />
          <Button onClick={this._onAddClick}>Add Available Time</Button>
        </div>
        <Suspense fallback={<FullScreenLoader />}>
          {view && (
            <DragAndDropCalendar
              defaultDate={moment().toDate()}
              defaultView={Views.WEEK}
              events={this.state.events}
              localizer={localizer}
              resizable
              popup
              onEventDrop={this._onEventDrop}
              onEventResize={this._onEventResize}
              onRangeChange={this._onRangeChange}
              onSelectEvent={this._onSelectEvent}
              onSelectSlot={this._onSelectSlot}
              onView={this._onView}
              selectable={[Views.DAY, Views.WEEK].includes(view)}
              style={{ height: '100vh' }}
              views={[Views.DAY, Views.MONTH, Views.WEEK]}
            />
          )}
        </Suspense>
        {this._renderAvailabilityDialog()}
        {this._renderConfirmDelete()}
        <ErrorDialog
          error={error}
          onClose={() => {
            this.setState({ error: null });
          }}
        />
      </div>
    );
  }

  _renderGuide() {
    const { loading, user } = this.props.guide;

    if (loading) {
      return <Loader active />;
    }

    if (!user) {
      return;
    }

    return (
      <Header>
        <Avatar user={user} />
        {user.firstName} {user.lastName}
        {user.timezone && <Label>{moment.tz(user.timezone).format('z')}</Label>}
      </Header>
    );
  }

  _renderAvailabilityDialog() {
    const { selectedEvent, submitting, timezone } = this.state;

    if (!selectedEvent) {
      return null;
    }

    return (
      <AvailabilityDialog
        event={selectedEvent}
        timezone={timezone}
        submitting={submitting}
        onClose={() => {
          this.setState({ selectedEvent: null });
        }}
        onDelete={() => {
          this.setState({ confirmDelete: true });
        }}
        onSubmit={this._onSubmit}
      />
    );
  }

  _renderConfirmDelete() {
    const { confirmDelete } = this.state;

    return (
      <Confirm
        open={confirmDelete}
        content="Are you sure you want to delete this availability slot?"
        onCancel={() => {
          this.setState({ confirmDelete: false });
        }}
        onConfirm={this._onDelete}
      />
    );
  }

  _onAddClick = () => {
    const event = {
      end: moment().startOf('hour').add(1, 'hour').toDate(),
      start: moment().startOf('hour').toDate()
    };

    this.setState({ selectedEvent: event });
  };

  _onEventDrop = ({ event, start, end }) => {
    this.setState({
      selectedEvent: {
        ...event,
        end,
        start
      }
    });
  };

  _onEventResize = ({ event, start, end }) => {
    this.setState({
      selectedEvent: {
        ...event,
        end,
        start
      }
    });
  };

  _onRangeChange = (range) => {
    if (isArray(range)) {
      this.setState(
        {
          range: {
            start: head(range),
            end: last(range)
          }
        },
        this._updateEvents
      );
    } else {
      this.setState({ range }, this._updateEvents);
    }
  };

  _onSelectEvent = (event) => {
    this.setState({ selectedEvent: event });
  };

  _onSelectSlot = (event) => {
    const end = moment(event.end);
    const secondLater = end.clone().add(1, 'second');
    const isEndOfDay = !end.isSame(secondLater, 'day');

    this.setState({
      selectedEvent: {
        ...event,
        ...(isEndOfDay ? { end: secondLater.toDate() } : null)
      }
    });
  };

  _onView = (view) => {
    this.setState({ view });
  };

  _onDelete = () => {
    const { deleteAvailability } = this.props;
    const { selectedEvent } = this.state;

    const variables = {
      id: selectedEvent.resource.id
    };

    this.setState({ error: null, submitting: true });
    deleteAvailability({ variables })
      .then(() => {
        this.setState({
          confirmDelete: false,
          selectedEvent: null,
          submitting: false
        });
        this._updateEvents();
      })
      .catch((error) => {
        this.setState({ confirmDelete: false, error, submitting: false });
      });
  };

  _onSubmit = (data) => {
    const { createOrUpdateAvailability, match } = this.props;
    const { selectedEvent, timezone } = this.state;

    const endTime = inTimezone(data.endTime, timezone);
    const startTime = inTimezone(data.startTime, timezone);
    const effectiveEndTime =
      data.isRecurring && data.effectiveEndTime
        ? inTimezone(data.effectiveEndTime, timezone)
        : null;
    const effectiveStartTime =
      data.isRecurring && data.effectiveStartTime
        ? inTimezone(data.effectiveStartTime, timezone)
        : null;

    const variables = {
      ...data,
      id: (selectedEvent.resource && selectedEvent.resource.id) || '',
      effectiveEndTime: effectiveEndTime
        ? effectiveEndTime.toISOString()
        : null,
      effectiveStartTime: effectiveStartTime
        ? effectiveStartTime.toISOString()
        : null,
      endTime: endTime.toISOString(),
      startTime: startTime.toISOString(),
      userId: match.params.id
    };

    this.setState({ error: null, submitting: true });
    createOrUpdateAvailability({ variables })
      .then(() => {
        this.setState({ selectedEvent: null, submitting: false });
        this._updateEvents();
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
      });
  };
}
export default AvailabilityCalendar;
