import { Button, Dropdown, DropdownItem } from '@windmill/react-ui';
import debounce from 'lodash/debounce';
import delay from 'lodash/delay';
import isBoolean from 'lodash/isBoolean';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Timezones } from '../../ui/timezone-selector';

const selectedRef = React.createRef();

function TimezoneSelector(props) {
  const { selectedTimezone } = props;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(
    function () {
      if (!isOpen || !selectedRef.current) {
        return;
      }
      const container = selectedRef.current.parentNode.parentNode;
      // Delay to work around the focus lock used by windmill
      delay(function () {
        container.scrollTop = selectedRef.current.offsetTop - 8;
      }, 50);
    },
    [isOpen]
  );

  function onChange(value) {
    props.onChange(value);
    toggleDropdown(false);
  }

  function onClick() {
    toggleDropdown();
  }

  const toggleDropdown = debounce(function (value) {
    setIsOpen(isBoolean(value) ? value : !isOpen);
  }, 50);

  const guess = moment.tz.guess();

  const timezones = [...Timezones];

  function addZone(name) {
    if (!timezones.find((zone) => zone.names.includes(name))) {
      const abbr = moment.tz(name).zoneAbbr();
      timezones.push({
        names: [name],
        title: `${name} (${abbr})`
      });
    }
  }
  addZone(guess);
  addZone(selectedTimezone);

  const selected = timezones.find((zone) =>
    zone.names.includes(selectedTimezone)
  );

  const options = timezones.map((zone) => {
    const [first] = zone.names;
    const m = moment.tz(first);
    return {
      text: `(GMT ${m.format('Z')}) ${zone.title}`,
      value: first
    };
  });

  return (
    <div className="relative">
      <Button
        layout="link"
        block
        style={{ alignItems: 'start' }}
        iconLeft={() => <i className="icon globe americas text-gray-400" />}
        iconRight={() => <i className="icon sort down text-gray-400" />}
        onClick={onClick}>
        <span className="flex-grow text-left text-gray-400">
          {selected.title}
        </span>
      </Button>
      <Dropdown
        className="h-40 overflow-y-scroll bottom-0"
        align="right"
        isOpen={isOpen}
        onClose={() => toggleDropdown(false)}>
        {options.map((option) => {
          const { text, value } = option;
          const isSelected = selected && selected.names[0] === value;

          return (
            <DropdownItem
              key={value}
              ref={isSelected ? selectedRef : null}
              className={`focus:outline-none ${
                isSelected ? 'bg-primary text-white' : ''
              }`}
              onClick={() => onChange(value)}>
              {text}
            </DropdownItem>
          );
        })}
      </Dropdown>
    </div>
  );
}
TimezoneSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedTimezone: PropTypes.string.isRequired
};
export default TimezoneSelector;
