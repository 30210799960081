import './index.css';
import '../create-session-form/session-select-form-layout.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';

import TopicsQuery from '../../../../../graphql/queries/topics.graphql';
import graphql from '../../../../hoc/graphql';
import MyForm, { FormField } from '../../../../ui/form';
import TopicsInfo from './info';

const DEFAULT_TOPIC_IMAGE =
  'https://react.semantic-ui.com/images/wireframe/image.png';

@graphql(TopicsQuery, { name: 'topics' })
class TopicSelector extends Component {
  static propTypes = {
    topics: PropTypes.shape({
      topics: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string
        })
      ),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    onChange: PropTypes.func
  };

  state = {
    selectedTopics: []
  };

  render() {
    const { loading } = this.props.topics;
    const { selectedTopics } = this.state;
    const data = { selectedTopics };

    return (
      <MyForm
        className="call-request-form-topic-selector my-form-container"
        data={data}
        onChange={this._onDataChange}>
        <div className="topic-selector-avatar  field-container ">
          <div
            className={`field-cell circular-avi`}
            style={{ backgroundImage: `url(${DEFAULT_TOPIC_IMAGE})` }}
          />
        </div>
        <div className="guide-selector-topic field-container">
          <FormField
            loading={loading}
            disabled={loading}
            deburr
            search={!loading}
            className="field-cell fluid"
            component={Dropdown}
            name="selectedTopics"
            label="Topics"
            fluid
            selection
            multiple
            options={this.guideTopicToGuideTopicOptions()}
          />
        </div>
        <div className="topic-selector-info field-container">
          <TopicsInfo topics={selectedTopics} />
        </div>
      </MyForm>
    );
  }

  guideTopicToGuideTopicOptions = () => {
    const { topics } = this.props.topics;

    if (!topics) {
      return [];
    }

    return _.chain(topics)
      .sortBy((topic) => topic.title)
      .map((topic) => {
        return {
          text: topic.title,
          value: topic,
          key: topic.id,
          image: {
            avatar: true,
            src: topic.imageUrl ? topic.imageUrl : DEFAULT_TOPIC_IMAGE
          }
        };
      })
      .value();
  };

  _onDataChange = (data) => {
    const { onChange } = this.props;
    this.setState(data);
    _.isFunction(onChange) && onChange(data);
  };
}

export default TopicSelector;
