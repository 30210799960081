import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import CircularButton from './circular-button';

const TYPES = [
  {
    kind: 'video',
    icon: {
      on: 'video',
      off: 'video slash'
    }
  },
  {
    kind: 'audio',
    icon: {
      on: 'microphone',
      off: 'microphone slash'
    }
  }
];

class LocalTrackControls extends Component {
  static propTypes = {
    room: PropTypes.object,
    tracks: PropTypes.arrayOf(PropTypes.object)
  };

  _isTrackEnabled(kind) {
    const { tracks } = this.props;

    if (!tracks) {
      return false;
    }

    return tracks
      .filter((t) => t.kind === kind)
      .some((track) =>
        track.isEnabled === undefined ? track.enabled : track.isEnabled
      );
  }

  _toggleTrack(kind) {
    const { tracks } = this.props;

    tracks
      .filter((t) => t.kind === kind)
      .forEach((track) => {
        if (track.isEnabled === undefined) {
          track.enabled = !track.enabled;
        } else if (isFunction(track.enable)) {
          track.enable(!track.isEnabled);
        }
      });

    this.forceUpdate();
  }

  _disconnect() {
    const { room } = this.props;

    room.disconnect();
  }

  render() {
    return (
      <div className="controls">
        {this._renderToggleButtons()}
        {this._renderDisconnect()}
      </div>
    );
  }

  _renderToggleButtons() {
    return TYPES.map(this._renderToggleButton);
  }

  _renderToggleButton = (type, i) => {
    const { tracks } = this.props;
    const { icon, kind } = type;

    const isEnabled = this._isTrackEnabled(kind);

    return (
      <CircularButton
        key={kind}
        icon={
          <Icon name={isEnabled ? icon.on : icon.off} className="lineawesome" />
        }
        color={isEnabled ? null : 'red'}
        disabled={!tracks.length}
        onClick={() => {
          this._toggleTrack(kind);
        }}
      />
    );
  };

  _renderDisconnect() {
    const { room } = this.props;

    if (!room) {
      return null;
    }

    const isConnected = ['connected', 'reconnecting'].includes(room.state);

    return (
      <CircularButton
        icon={<Icon name="phone" className="lineawesome" />}
        disabled={!isConnected}
        color="red"
        onClick={() => {
          this._disconnect();
        }}
      />
    );
  }
}
export default LocalTrackControls;
