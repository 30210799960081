import 'react-datepicker/dist/react-datepicker.css';

import './index.css';
import '../create-session-form/session-select-form-layout.css';

import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component, Suspense, lazy } from 'react';

import FullscreenLoader from '../../../../ui/fullscreen-loader';

const DatePicker = lazy(() => import('react-datepicker'));

class CalendarSelector extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    selectedDayOf: PropTypes.number
  };

  state = {
    selectedDayOf: new Date()
  };

  render() {
    const { onChange } = this.props;
    let _selectedDate = this.props.selectedDayOf || this.state.selectedDayOf;
    return (
      <div className="call-request-form-bookingtime-selector my-form-container">
        <Suspense fallback={<FullscreenLoader />}>
          <DatePicker
            maxDate={moment().add(2, 'months').toDate()}
            selected={_selectedDate}
            onChange={(d) => {
              this.setState({
                selectedDayOf: d
              });

              if (_.isFunction(onChange)) {
                onChange({
                  selectedDayOf: d
                });
              }
            }}
            inline
            fixedHeight
            renderDayContents={this.dayToDecoratedDay}
          />
        </Suspense>
      </div>
    );
  }

  dayToDecoratedDay = (day, date) => {
    const dayCell = <span>{day}</span>;
    return dayCell;
  };

  _onDataChange = (data) => {
    const { onChange } = this.props;
    if (_.isFunction(onChange)) {
      onChange(data);
    }
  };
}

export default CalendarSelector;
