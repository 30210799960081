import { useMutation, useQuery } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import { Button } from '@windmill/react-ui';
import get from 'lodash/get';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { REDIRECT_URL_KEY, ROLES } from '../../../../consts';
import CreateGuideProfileMutation from '../../../../graphql/mutations/create-guide-profile.graphql';
import PublishGuideProfileMutation from '../../../../graphql/mutations/publish-guide-profile.graphql';
import UploadFileMutation from '../../../../graphql/mutations/upload-file.graphql';
import GuideByIdQuery from '../../../../graphql/queries/guide-by-id.graphql';
import GuideProfileByIdQuery from '../../../../graphql/queries/guide-profile-by-id.graphql';
import GuideProfilesByGuideIdPaginationQuery from '../../../../graphql/queries/guide-profiles-by-guide-id-pagination.graphql';
import history from '../../../../history';
import useUser from '../../../hooks/use-user';
import AvatarCropperDialog from '../../../ui/avatar-cropper-dialog';
import ErrorDialog from '../../../ui/error-dialog';
import ErrorMessage from '../../../ui/error-message';
import LoadingSpinner from '../../../ui/loading-spinner';
import GuideContext from '../guide-context';
import SaveGuideButton from '../save-button';
import Achievements from './achievements';
import Actions from './actions';
import Availabilities from './availabilities';
import Avatar from './avatar';
import BasicInfo from './basic-info';
import Biography from './biography';
import CoverPhoto from './cover-photo';
import { useData } from './data';
import { DIALOGS, useDialogs } from './dialogs';
import AssignAchievementsDialog from './dialogs/achievements-select';
import ConfirmSessionDialog from './dialogs/confirm-session';
import GuideStatusDialog from './dialogs/guide-status';
import GuideVideoDialog from './dialogs/guide-video';
import RequestingDialog from './dialogs/requesting';
import RevisionsDialog from './dialogs/revisions';
import SchedulerDialog from './dialogs/scheduler';
import TopicsSelectDialog from './dialogs/topics-select';
import Qualifications from './qualifications';
import TopicDescriptions from './topic-descriptions';

function GuideProfileView(props) {
  const { guide, id } = props;

  const [error, setError] = useState(null);
  const [requesting, setRequesting] = useState(false);
  const [session, setSession] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);

  const location = useLocation();

  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  const { data, isDirty, setData } = useData(id);
  const [dialog, setDialog] = useDialogs();

  const { data: userData } = useUser();

  const isRegistered = !!get(userData, 'User.roles', []).length;

  function hasRole(name) {
    return get(userData, 'User.roles', []).some((role) => role.name === name);
  }

  const {
    data: guideProfileData,
    error: guideProfileError,
    loading: guideProfileLoading,
    refetch: guideProfileRefetch
  } = useQuery(GuideProfileByIdQuery, {
    notifyOnNetworkStatusChange: true,
    skip: !id,
    variables: { id }
  });

  const [createGuideProfile] = useMutation(CreateGuideProfileMutation, {
    refetchQueries: [
      { query: GuideByIdQuery, variables: { id: guide.id } },
      {
        query: GuideProfilesByGuideIdPaginationQuery,
        variables: { guideId: guide.id, first: 10, skip: 0 }
      }
    ]
  });
  const [publishGuideProfile] = useMutation(PublishGuideProfileMutation, {
    refetchQueries: [{ query: GuideProfileByIdQuery, variables: { id } }]
  });
  const [uploadFile] = useMutation(UploadFileMutation);

  const loading = guideProfileLoading;
  const dataError = guideProfileError;
  const refetch = guideProfileRefetch;

  const guideProfile = guideProfileData && guideProfileData.guideProfile;

  function onCloseDialog() {
    setDialog(null);
  }

  function goToRegister() {
    const dialog = (location.search ? '&' : '?') + 'dialog=SCHEDULE';
    localStorage.setItem(
      REDIRECT_URL_KEY,
      location.pathname + location.search + dialog
    );
    setDialog(DIALOGS.REGISTER);
  }

  function readFile(file) {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onabort = () => reject(new Error('File reading was aborted'));
      reader.onerror = () => reject(new Error('File reading has failed'));

      try {
        reader.readAsDataURL(file);
      } catch (error) {
        const variables = { file };
        Bugsnag.notify(error, function (event) {
          event.context = 'ProfileView._readFile';
          event.request.variables = variables;
        });
        reject(error);
      }
    });
  }

  function onHeaderFileDrop([headerFile], rejectedFiles) {
    if (headerFile) {
      setData({ headerFile });
      readFile(headerFile).then((result) => {
        setData({ headerImageUrl: result });
      });
    } else if (rejectedFiles && rejectedFiles.length) {
      const error = rejectedFiles[0].errors[0];
      setError(rejectedFiles[0].errors[0]);
      Bugsnag.notify(error, function (event) {
        event.context = 'ProfileView._onHeaderFileDrop';
        event.request.variables = { rejectedFiles };
        event.severity = 'info';
      });
    }
  }

  function onAvatarFileDrop([avatarFile], rejectedFiles) {
    if (avatarFile) {
      setData({ avatarFile });
      setDialog(DIALOGS.AVATAR_CROPPER);
    } else if (rejectedFiles && rejectedFiles.length) {
      const error = rejectedFiles[0].errors[0];
      setError(rejectedFiles[0].errors[0]);
      Bugsnag.notify(error, function (event) {
        event.context = 'ProfileView._onAvatarFileDrop';
        event.request.variables = { rejectedFiles };
        event.severity = 'info';
      });
    }
  }

  function onCropLoadFailure(...args) {
    onCloseDialog();
    setError(new Error('Image failed to load'));
  }

  function onCropAvatar(avatarFile) {
    setData({ avatarFile });
    readFile(avatarFile).then((result) => {
      setData({ avatarUrl: result });
    });
    onCloseDialog();
  }

  function onAvailabilitiesChange(availabilities) {
    setData({ availabilities });
  }

  function onQualificationChange(qualifications) {
    setData({ qualifications });
  }

  function onBiographyChange(biography) {
    setData({ biography });
  }

  function onGuideVideoClick(url) {
    setVideoUrl(url);
    setDialog(DIALOGS.VIDEO);
  }

  function onScheduleClick() {
    if (!isRegistered) {
      return goToRegister();
    }
    setDialog(DIALOGS.SCHEDULE);
  }

  function onTalkNowClick() {
    if (!isRegistered) {
      return goToRegister();
    }
    setRequesting(true);
    setDialog(DIALOGS.REQUESTING_SESSION);
  }

  function onClickAddTopic() {
    setDialog(DIALOGS.ADD_TOPICS);
  }

  function onSelectTopics(topics) {
    const topicDescriptions = topics.map((topic) => ({
      long: '',
      short: '',
      topic
    }));
    setData({ topics: data.topics.concat(topicDescriptions) });
    onCloseDialog();
  }

  function onTopicDescriptionChange(topicDescription) {
    const { topics } = data;
    const index = topics.findIndex(
      (t) => t.topic.id === topicDescription.topic.id
    );
    const updated = [...topics];
    updated[index] = topicDescription;
    setData({ topics: updated });
  }

  function onClickRemoveTopic(topic) {
    const { topics } = data;
    const index = topics.findIndex((t) => t.topic.id === topic.id);
    const updated = [...topics];
    updated.splice(index, 1);
    setData({ topics: updated });
  }

  function uploadImage(file) {
    const variables = { file };
    return uploadFile({ variables }).then(({ data }) => data.uploadFile);
  }

  function uploadImages() {
    const { avatarFile, headerFile } = data;

    const files = [avatarFile, headerFile];
    return Promise.all(
      files.map((file) =>
        file ? uploadImage(file) : Promise.resolve({ url: null })
      )
    ).then(([{ url: avatarUrl }, { url: headerImageUrl }]) => ({
      avatarUrl,
      headerImageUrl
    }));
  }

  function saveGuideProfile(data) {
    const { User } = userData;

    const variables = {
      age: data.age,
      availabilities: data.availabilities.map(({ days, timesOfDay }) => ({
        days,
        timesOfDay: { set: timesOfDay }
      })),
      avatarUrl: data.avatarUrl,
      biography: data.biography,
      city: data.city,
      country: data.country,
      guideId: guide.id,
      headerImageUrl: data.headerImageUrl,
      qualifications: data.qualifications,
      state: data.state,
      status: 'SUBMITTED',
      topics: data.topics.map(({ long, short, topic }) => ({
        long,
        short,
        topic: { connect: { id: topic.id } }
      })),
      userId: User.id
    };

    return createGuideProfile({ variables }).then(({ data }) => {
      const { edit, ...remaining } = search;
      history.push({
        pathname: location.pathname,
        search: qs.stringify({
          ...remaining,
          revisionId: data.createGuideProfile.id
        })
      });
    });
  }

  function onSubmit() {
    setError(null);
    setSubmitting(true);

    uploadImages()
      .then(({ avatarUrl, headerImageUrl }) => {
        const newData = {
          ...data,
          ...(avatarUrl ? { avatarUrl } : null),
          ...(headerImageUrl ? { headerImageUrl } : null)
        };
        return saveGuideProfile(newData);
      })
      .then(() => {
        setSubmitting(false);
      })
      .catch((error) => {
        setError(error);
        setSubmitting(false);
        Bugsnag.notify(error, function (event) {
          event.context = 'ProfileView._onSubmit';
          event.request.variables = data;
        });
      });
  }

  function onPublish() {
    const { User } = userData;

    const variables = {
      id,
      publishedAt: moment.utc().format(),
      userId: User.id
    };

    setError(null);
    setSubmitting(true);
    publishGuideProfile({ variables })
      .then(() => {
        setSubmitting(false);
      })
      .catch((error) => {
        setError(error);
        setSubmitting(false);
        Bugsnag.notify(error, function (event) {
          event.context = 'ProfileView._onPublish';
          event.request.variables = variables;
        });
      });
  }

  function onCreateSession(session) {
    setSession(session);
    setDialog(DIALOGS.CONFIRM_SESSION);
  }

  data.guide = {
    isPresent: guide.isPresent
  };

  function renderContent() {
    if (dataError) {
      return <ErrorMessage error={dataError} retry={() => refetch()} />;
    }
    if (loading) {
      return <LoadingSpinner className="w-48 h-48 mx-auto mb-16 mt-36" />;
    }

    const isUnregistered = get(userData, 'User.roles', []).length === 0;
    const isClient = hasRole(ROLES.CLIENT);
    const canSchedule =
      (isUnregistered || isClient) && guide.guideStatus === null;

    return (
      <>
        <CoverPhoto data={data} onChange={onHeaderFileDrop} />
        <div className="absolute top-8 left-4">
          <Link
            className="text-white hover:text-white hover:opacity-75"
            to={
              search.returnUrl
                ? search.returnUrl
                : {
                    pathname: '/guides',
                    search: qs.stringify({
                      ...(search.topicId ? { topicId: search.topicId } : null)
                    })
                  }
            }>
            <i className="icon angle left large" />
            Back to Results
          </Link>
        </div>
        <div className="relative z-10 flex flex-col max-w-screen-xl mx-4 mb-8 -mt-8 space-y-8 bg-no-repeat md:-mt-16 md:mx-20">
          <div className="flex flex-col space-y-8 md:flex-row md:items-start md:space-y-0 md:space-x-8">
            <div
              className="relative flex flex-col items-center px-8 pb-8 bg-white md:w-4/12 rounded-xl"
              style={{ minWidth: '333px' }}>
              {(isUnregistered || isClient) && (
                <div className="absolute flex flex-col items-center text-gray-400 top-4 left-4">
                  <SaveGuideButton
                    guide={guide}
                    className="w-12 h-12 rounded-full"
                    size="large"
                  />
                  Save
                </div>
              )}
              <div className="mb-4" style={{ marginTop: '-100px' }}>
                <Avatar data={data} onChange={onAvatarFileDrop} />
              </div>
              <div className="text-4xl font-bold">
                {guide?.firstName} {guide?.lastName?.[0]?.concat('.') || ''}
              </div>
              {guide.isPresent ? (
                <div className="text-green-400">Available Now!</div>
              ) : null}
              <BasicInfo data={data} onChange={setData} />
              <div className="flex flex-col w-full mt-6 space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                <Button
                  size={guide.isPresent ? 'regular' : 'larger'}
                  block={!guide.isPresent}
                  className="flex-grow font-bold"
                  disabled={!canSchedule}
                  onClick={onScheduleClick}>
                  Schedule a Session
                </Button>
                {guide.isPresent && (
                  <Button
                    layout="outline"
                    className="flex-grow"
                    disabled={!canSchedule}
                    onClick={onTalkNowClick}>
                    Talk Now
                  </Button>
                )}
              </div>
              <div className="flex flex-col w-full mt-6">
                <h3 className="text-xl font-bold">
                  I&apos;m Usually Available:
                </h3>
                <Availabilities data={data} onChange={onAvailabilitiesChange} />
              </div>
            </div>
            <div className="flex flex-col space-y-8 md:w-8/12">
              <div className="p-8 bg-white rounded-xl">
                <Qualifications data={data} onChange={onQualificationChange} />
                <Biography
                  data={data}
                  onChange={onBiographyChange}
                  onGuideVideoClick={onGuideVideoClick}
                />
              </div>
              <div className="flex grid flex-col p-4 bg-white rounded-xl">
                {/* This was label was formerly called Achievements -Jesse */}
                <h3 className="grid p-2 text-lg font-bold">Badges</h3>
                <Achievements data={data} />
              </div>
            </div>
          </div>
          <TopicDescriptions
            data={data}
            onAdd={onClickAddTopic}
            onChange={onTopicDescriptionChange}
            onRemove={onClickRemoveTopic}
          />
        </div>
      </>
    );
  }

  function renderDialogs() {
    const props = { isOpen: true, onClose: onCloseDialog };

    switch (dialog) {
      case DIALOGS.ADD_TOPICS:
        return (
          <TopicsSelectDialog
            {...props}
            data={data}
            onSelect={onSelectTopics}
          />
        );
      case DIALOGS.ASSIGN_ACHIEVEMENTS:
        return <AssignAchievementsDialog {...props} data={data} />;
      case DIALOGS.AVATAR_CROPPER:
        return (
          <AvatarCropperDialog
            {...props}
            image={data.avatarFile}
            onLoadFailure={onCropLoadFailure}
            onSelect={onCropAvatar}
          />
        );
      case DIALOGS.CONFIRM_SESSION:
        return session ? (
          <ConfirmSessionDialog {...props} session={session} />
        ) : null;
      case DIALOGS.GUIDE_STATUS:
        return <GuideStatusDialog {...props} />;
      case DIALOGS.REQUESTING_SESSION:
        return requesting ? <RequestingDialog {...props} /> : null;
      case DIALOGS.REVISIONS:
        return <RevisionsDialog {...props} />;
      case DIALOGS.SCHEDULE:
        return <SchedulerDialog {...props} onSubmit={onCreateSession} />;
      case DIALOGS.VIDEO:
        return videoUrl ? <GuideVideoDialog {...props} url={videoUrl} /> : null;
      default:
        return null;
    }
  }

  return (
    <>
      <div className="relative flex flex-col items-center w-full bg-gray-100 guide-profile-view">
        {renderContent()}
        <Actions
          dirty={isDirty}
          guideProfile={guideProfile}
          onPublish={onPublish}
          onSubmit={onSubmit}
          submitting={submitting}
        />
      </div>
      {renderDialogs()}
      <ErrorDialog
        error={error}
        onClose={() => {
          setError(null);
        }}
      />
    </>
  );
}
GuideProfileView.propTypes = {
  guide: PropTypes.shape({
    id: PropTypes.string.isRequired,
    age: PropTypes.number,
    avatarUrl: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    firstName: PropTypes.string,
    guideStatus: PropTypes.string,
    isPresent: PropTypes.bool,
    lastName: PropTypes.string,
    state: PropTypes.string
  }).isRequired,
  id: PropTypes.string
};
function GuideProfileWithGuideContext(props) {
  return (
    <GuideContext.Consumer>
      {(guide) => <GuideProfileView guide={guide} {...props} />}
    </GuideContext.Consumer>
  );
}
export default GuideProfileWithGuideContext;
