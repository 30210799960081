import Bugsnag from '@bugsnag/js';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Card,
  Dimmer,
  Header,
  Icon,
  Image,
  Loader,
  Message,
  Modal,
  Segment
} from 'semantic-ui-react';

import {
  VideoInstructionsCog,
  VideoInstructionsLock,
  VideoInstructionsMedia,
  VideoInstructionsPencil
} from '../../../assets';
import { ROLES } from '../../../consts';
import withUser from '../../hoc/with-user';
import withWidth, { isWidthDown } from '../../hoc/with-width';
import FullscreenLoader from '../../ui/fullscreen-loader/index';
import CallMeDialog from './call-me-dialog';
import DevicesForm from './devices-form';
import LocalTrackControls from './local-track-controls';
import LocalTracksView from './local-tracks-view';
import makeStream from './make-stream';

const DIALOGS = {
  CALL_ME: 'CALL_ME',
  DEVICES: 'DEVICES'
};

function iOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

class BrowserNotSupportedError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}

@withUser({ loader: <FullscreenLoader /> })
@withWidth()
class LocalPreview extends Component {
  static propTypes = {
    connecting: PropTypes.bool,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    showCallMe: PropTypes.bool,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string,
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          })
        ).isRequired
      }).isRequired
    }).isRequired,
    width: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.state = {
      data: {},
      dialog: null,
      error: null,
      online: 'onLine' in navigator ? navigator.onLine : true,
      tracks: [],
      wasOffline: false
    };
  }

  componentDidMount() {
    window.addEventListener('offline', this._onOffline);
    window.addEventListener('online', this._onOnline);

    const constraints = this._getConstraints({});
    this._getMediaTracks(constraints);
  }

  componentWillUnmount() {
    window.removeEventListener('offline', this._onOffline);
    window.removeEventListener('online', this._onOnline);
  }

  _onOnline = () => {
    this.setState({ online: true, wasOffline: true });
  };

  _onOffline = () => {
    this.setState({ online: false });
  };

  _getConstraints(data) {
    return {
      audio: data.audioId ? { deviceId: { exact: data.audioId } } : true,
      video: data.videoId
        ? { deviceId: { exact: data.videoId } }
        : { facingMode: 'user' }
    };
  }

  _getMediaTracks(constraints) {
    const { onChange } = this.props;

    this._stopTracks();

    const promise = makeStream(constraints);
    if (!promise) {
      const error = new BrowserNotSupportedError('Unsupported Browser');
      this.setState({ error });

      Bugsnag.notify(error, function (event) {
        event.context = 'LocalPreview._getMediaTracks';
        event.severity = 'info';
      });

      return;
    }

    return promise
      .then((mediaStream) => {
        const tracks = mediaStream.getTracks();

        this.setState({
          tracks
        });

        if (isFunction(onChange)) {
          onChange(tracks);
        }
      })
      .catch((error) => {
        this.setState({ error });

        Bugsnag.notify(error, function (event) {
          event.context = 'LocalPreview._getMediaTracks';
          event.severity = 'info';
        });
      });
  }

  _stopTracks() {
    const { tracks } = this.state;

    tracks
      .filter((track) => track.readyState === 'live')
      .forEach((track) => track.stop());
  }

  render() {
    return (
      <div className="local-preview-component">
        {this._renderContent()}
        {this._renderDialogs()}
      </div>
    );
  }

  _renderContent() {
    return this._renderError() || this._renderPreview();
  }

  _renderError() {
    const { error } = this.state;

    if (!error) {
      return null;
    }

    switch (error.name) {
      case 'BrowserNotSupportedError':
        return this._renderUnsupportedBrowser();
      case 'NotAllowedError':
        return this._renderPermissionDenied();
      case 'NotFoundError':
        return this._renderNoDevices();
      default:
        return this._renderUnkownError();
    }
  }

  _renderUnsupportedBrowser() {
    return (
      <Segment className="media-error">
        <Header>Your Internet Browser is Unsupported</Header>
        <Message negative>
          <Header size="small">
            LifeGuides needs access to your camera and microphone to make a
            video call but your browser does not support access to these
            features.
          </Header>
          {iOS() && (
            <ul>
              <li>
                For iOS device support you&apos;ll need to use the Safari
                browser
              </li>
            </ul>
          )}
        </Message>
        <Header size="small">
          Or we can call your phone and connect you to an audio-only session.
        </Header>
        <Button
          className="call-me"
          primary
          size="large"
          onClick={() => this.setState({ dialog: DIALOGS.CALL_ME })}>
          <Icon name="phone" />
          Join Via Phone
        </Button>
      </Segment>
    );
  }

  _renderPermissionDenied() {
    return (
      <Segment className="media-error">
        <Header>Your camera and microphone are blocked</Header>
        <Message>
          <Header size="small">
            LifeGuides needs access to your camera & microphone to connect to
            your session.
          </Header>
          <Header size="small">
            To allow access, do one of the following:
          </Header>
          <ul>
            <li>
              Click the camera blocked icon{' '}
              <Icon name="video slash" className="lineawesome" /> in your
              browser&apos;s address bar. Allow camera &amp; mic access.
            </li>
            <li>
              Click the upper left icon in the browser&apos;s address bar to get
              to Website Settings. Allow camera &amp; mic access. Once done,
              refresh this page.
            </li>
          </ul>
        </Message>
        <Header size="small">
          Still having trouble? We can call your phone and connect you to an
          audio-only session.
        </Header>
        <Button
          className="call-me"
          primary
          size="large"
          onClick={() => this.setState({ dialog: DIALOGS.CALL_ME })}>
          <Icon name="phone" />
          Join Via Phone
        </Button>
      </Segment>
    );
  }

  _renderNoDevices() {
    return (
      <Segment className="media-error">
        <Header>Your device does not have a microphone</Header>
        <Message negative>
          <Header size="small">
            LifeGuides needs access to a microphone to connect to this session
            from the application but your device does not have this feature.
          </Header>
        </Message>
        <Header size="small">
          We can call your phone and connect you to an audio-only session.
        </Header>
        <Button
          className="call-me"
          primary
          size="large"
          onClick={() => this.setState({ dialog: DIALOGS.CALL_ME })}>
          <Icon name="phone" />
          Join Via Phone
        </Button>
      </Segment>
    );
  }

  _renderUnkownError() {
    const { error } = this.state;

    return (
      <Segment className="media-error">
        <Header>An error has occured</Header>
        <Message negative>{error.message}</Message>

        <Header size="small">
          Having trouble? We can call your phone and connect you to an
          audio-only session.
        </Header>
        <Button
          className="call-me"
          primary
          size="large"
          onClick={() => this.setState({ dialog: DIALOGS.CALL_ME })}>
          <Icon name="phone" />
          Join Via Phone
        </Button>
      </Segment>
    );
  }

  _renderPreview() {
    const { tracks } = this.state;

    const supportsGetCapabilities =
      window.MediaStreamTrack &&
      'getCapabilities' in window.MediaStreamTrack.prototype;

    return (
      <>
        <div className="preview">
          <LocalTracksView tracks={tracks} />
          <LocalTrackControls tracks={tracks} />
          {supportsGetCapabilities && (
            <Button
              icon={
                <Icon
                  name="cog"
                  inverted
                  color="grey"
                  className="lineawesome"
                />
              }
              circular
              basic
              size="large"
              className="settings"
              onClick={() => this.setState({ dialog: DIALOGS.DEVICES })}
            />
          )}
        </div>
        {this._renderReady()}
        {this._renderInstructions()}
      </>
    );
  }

  _renderReady() {
    const { connecting, showCallMe } = this.props;
    const { online, tracks, wasOffline } = this.state;

    return (
      <Dimmer.Dimmable
        as={Segment}
        className="ready"
        basic
        blurring
        dimmed={connecting}>
        <Dimmer inverted active={connecting}>
          <Loader>Connecting...</Loader>
        </Dimmer>

        {online ? (
          <>
            <Button
              onClick={this._onSubmit}
              primary
              size="large"
              disabled={!tracks.length}>
              Join Session
            </Button>
            {showCallMe || wasOffline ? (
              <Segment className="media-error">
                <Header size="small">
                  If you&apos;re having difficulties with your connection we can
                  call your phone and connect you to an audio-only session.
                </Header>
                <Button
                  className="call-me"
                  primary
                  size="large"
                  onClick={() => this.setState({ dialog: DIALOGS.CALL_ME })}>
                  <Icon name="phone" />
                  Join Via Phone
                </Button>
              </Segment>
            ) : (
              <Button
                className="call-me de-emphasized"
                primary
                size="large"
                onClick={() => this.setState({ dialog: DIALOGS.CALL_ME })}>
                <Icon name="phone" />
                Join Via Phone
              </Button>
            )}
          </>
        ) : (
          <Message>
            You are currently offline, please check your network settings.
          </Message>
        )}
      </Dimmer.Dimmable>
    );
  }

  _renderInstructions() {
    const { width } = this.props;
    const { User } = this.props.user;

    const isClient = User.roles.some((role) => role.name === ROLES.CLIENT);
    const isTablet = isWidthDown('computer', width);

    if (!isClient) {
      return null;
    }

    return (
      <div className="instructions">
        <Header size="huge">Steps to a Great Session with your Guide</Header>
        <Card.Group itemsPerRow={isTablet ? 2 : 4} stackable>
          <Card>
            <Card.Content>
              <Image src={VideoInstructionsPencil} />
              <Card.Description>
                Log-in 5-10 minutes prior to your session starting. Find a quiet
                and comfortable place where you can talk without distractions.
                Grab a beverage, a pen, and some paper or a notebook in case
                you&apos;d like to take notes.
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Image src={VideoInstructionsCog} />
              <Card.Description>
                Make sure your device has a working video camera and microphone.
                We recommend using headphones for the best experience. To check
                or adjust your audio and video settings before your call starts,
                click the settings icon <Icon name="cog" />.
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Image src={VideoInstructionsMedia} />
              <Card.Description>
                To mute your microphone, click the microphone icon
                <div className="toggles">
                  <span className="off">
                    <Icon name="microphone slash" className="lineawesome" />
                  </span>
                  OFF
                  <span className="on">
                    <Icon name="microphone" className="lineawesome" />
                  </span>
                  ON
                </div>
                To turn your video on or off, click the video icon. You can turn
                your video off at anytime during the call
                <div className="toggles">
                  <span className="off">
                    <Icon name="video slash" className="lineawesome" />
                  </span>
                  OFF
                  <span className="on">
                    <Icon name="video" className="lineawesome" />
                  </span>
                  ON
                </div>
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Image src={VideoInstructionsLock} />
              <Card.Description>
                We take your privacy very seriously. This call is 100%
                confidential and will not be recorded.
              </Card.Description>
            </Card.Content>
          </Card>
        </Card.Group>
      </div>
    );
  }

  _renderDialogs() {
    const { dialog } = this.state;

    switch (dialog) {
      case DIALOGS.CALL_ME:
        return this._renderCallMeDialog();
      case DIALOGS.DEVICES:
        return this._renderDevicesDialog();
      default:
        return null;
    }
  }

  _renderCallMeDialog() {
    return (
      <CallMeDialog open onClose={() => this.setState({ dialog: null })} />
    );
  }

  _renderDevicesDialog() {
    const { tracks } = this.state;

    return (
      <Modal
        open
        size="small"
        className="media-devices-dialog"
        onClose={() => this.setState({ dialog: null })}>
        <Modal.Header>Settings</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <DevicesForm tracks={tracks} onChange={this._onDevicesChange} />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.setState({ dialog: null })}>Close</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  _onDevicesChange = (data, prevData) => {
    const changed =
      prevData.audioId !== data.audioId || prevData.videoId !== data.videoId;

    this.setState({ data }, () => {
      if (changed) {
        const constraints = this._getConstraints(data);
        this._getMediaTracks(constraints);
      }
    });
  };

  _onSubmit = () => {
    const { data, tracks } = this.state;
    const { onSubmit } = this.props;

    if (onSubmit) {
      onSubmit(tracks, data.sinkId);
    }
  };
}
export default LocalPreview;
