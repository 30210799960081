import { Button } from '@windmill/react-ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import useSavedGuides from '../../hooks/use-saved-guides';

function SaveGuideButton(props) {
  const { guide, ...remaining } = props;

  const [submitting, setSubmitting] = useState(false);

  const [savedGuides, toggleGuideSaved] = useSavedGuides();

  const isSaved = savedGuides.some((g) => g.id === guide.id);

  function onClick() {
    setSubmitting(true);
    toggleGuideSaved(guide)
      .then(() => {
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
      });
  }

  return (
    <Button
      icon={() => (
        <i
          className={`icon ${props.size || ''} lineawesome heart ${
            isSaved ? '' : 'outline'
          } text-red-500`}
          style={{ margin: 0 }}
        />
      )}
      layout="outline"
      aria-label="Save"
      disabled={submitting}
      {...remaining}
      onClick={onClick}
    />
  );
}
SaveGuideButton.propTypes = {
  guide: PropTypes.shape({
    id: PropTypes.string
  }),
  size: PropTypes.string
};
export default SaveGuideButton;
