import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import history from '../../../../../history';
import EditDialog from './edit';
import GuidesDialog from './guides';

const Dialogs = [
  {
    key: 'edit',
    component: EditDialog
  },
  {
    key: 'guides',
    component: GuidesDialog
  }
];

function AchievementDialogs(props) {
  const location = useLocation();
  const match = useRouteMatch();

  function onClose() {
    const { parentPath } = props;

    const search = qs.parse(location.search, { ignoreQueryPrefix: true });
    const url = search.returnUrl || parentPath;

    history.push({ pathname: url, search: '' });
  }

  function renderDialog({ key, component: Component }) {
    const open = key === match.params.name;

    return <Component key={key} open={open} onClose={onClose} />;
  }

  return Dialogs.map(renderDialog);
}
AchievementDialogs.propTypes = {
  parentPath: PropTypes.string.isRequired
};
export default AchievementDialogs;
