import './intro.css';

import { Button, Modal } from '@windmill/react-ui';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useUser from '../../hooks/use-user';

import {
  LanderHeroImage,
  LanderIconClock,
  LanderIconPointer,
  LanderIconStar,
  LanderPlayIcon,
  LanderLaptopUsingLg1,
  LanderLaptopUsingLg2,
  LanderLaptopUsingLg3,
  LanderManSmile,
  LanderQuoteOpen,
  LanderQuoteClose,
  LanderLogoSHRM,
  LanderLogoBBB,
  LanderLogoDriveTime,
  LanderLogoSXSW,
  LanderLogoKaiser,
  LanderSteps123
} from '../../../assets';
import history from '../../../history';

const DIALOGS = {
  VIDEO: 'VIDEO'
};

export default function Intro() {
  const [hoveringVideo, setHoveringVideo] = useState(false);
  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { data, loading } = useUser();

  const userIsLoggedIn = !loading && data && data.User;

  useEffect(
    function () {
      if (location.hash === '#topics') {
        const topics = document.getElementById('topics');
        if (topics) {
          topics.scrollIntoView();
        }
      }
    },
    [location]
  );

  function setDialog(dialog) {
    history.replace({
      pathname: location.pathname,
      search: qs.stringify({
        ...search,
        dialog
      })
    });
  }

  function openVideoModal() {
    setDialog(DIALOGS.VIDEO);
  }

  function onCloseDialog() {
    const { dialog, ...remaining } = search;
    history.replace({
      pathname: location.pathname,
      search: qs.stringify({ ...remaining })
    });
  }

  function renderDialogs() {
    const props = { isOpen: true, onClose: onCloseDialog };

    switch (search.dialog) {
      case DIALOGS.VIDEO:
        return (
          <Modal {...props} className="p-4 home-intro-dialog m-w-full">
            <iframe
              title="vimeo-player"
              src="https://player.vimeo.com/video/572280559?autoplay=1&autopause=0"
              className="w-screen"
              style={{
                maxWidth: '90vw',
                height: '50vw'
                //maxHeight: '320px'
              }}
              allowFullScreen
              frameBorder="0"
              allow="autoplay; fullscreen"
            />
          </Modal>
        );
      default:
        return null;
    }
  }

  const root = document.getElementById('root');
  if (root && search.dialog) {
    root.className = 'filter blur';
  } else {
    root.className = '';
  }

  const cta = !userIsLoggedIn ? (
    <div className="mt-4 h-12">
      <Button
        onClick={function onPressSignUp() {
          history.push({
            pathname: location.pathname,
            search: qs.stringify({
              dialog: 'REGISTER'
            })
          });
        }}>
        Sign Up in 20 seconds for Full Access
      </Button>
    </div>
  ) : (
    <div className="mt-4 h-12"> </div>
  );

  return (
    <>
      <div className="flex flex-row justify-center w-full pb-8 home-intro md:pt-10 sm:pb-20">
        <div className="flex flex-col w-full max-w-screen-xl px-4 lg:flex-row md:items-start">
          <div className="flex flex-col items-center flex-grow w-full h-full p-2 pr-2 mb-8 lg:pr-12 lg:items-start lg:w-6/12">
            <h1 className="flex flex-col max-w-2xl text-5xl font-medium text-center md:text-6xl lg:text-left lg:text-5xl mb-4 md:my-4 text-neutral">
              A Transformative Service for Career & Personal Growth.
            </h1>
            <div className="flex flex-col flex-grow max-w-2xl px-8 py-0 xl:py-4">
              <span className="max-w-2xl text-xl font-bold">
                Unlimited Access to:
              </span>
              <ul className="max-w-2xl px-8 py-2 list-disc text-lg">
                <li>Personalized 1-1 Mentors, Coaches & Guides</li>
                <li>Guides with Real Life Experience in 500+ Life Events</li>
                <li>Peak Life Performance</li>
                <li>Healthier Work-Life Balance</li>
                <li>Mental Fitness Experts vs. Mental Health</li>
                <li>Greater Happiness & Wellbeing</li>
              </ul>
              {cta}
            </div>
          </div>
          <div className="flex flex-col items-center w-full lg:w-6/12">
            <div className="relative max-w-2xl">
              <img
                src={LanderHeroImage}
                alt="How LifeGuides Works Intro"
                className="w-full rounded-3xl"
              />
              <div
                className="absolute inset-0 flex w-full h-full transition-all duration-1000 shadow-none cursor-pointer rounded-3xl hover:shadow"
                onClick={openVideoModal}
                onMouseEnter={() => setHoveringVideo(true)}
                onMouseLeave={() => setHoveringVideo(false)}
                style={{ marginTop: 0 }}>
                <div
                  style={{ height: 96 }}
                  className="absolute transition-all bottom-2 left-2">
                  <LanderPlayIcon
                    size={96}
                    active={hoveringVideo}
                    fill={hoveringVideo ? '#ffffff' : '#eeeeee'}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center w-full text-center ">
              <span className="p-2 text-xl">How LifeGuides Works Intro</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full max-w-screen-xl px-4 py-4 mt-16">
        <h2 className="justify-center my-8 text-3xl font-bold text-center md:text-4xl text-neutral">
          Employees & Leading Organizations Love Their Guides
        </h2>
        <div className="flex flex-col items-center justify-center gap-6 lg:flex-row mt-8">
          <TalkingPoint
            glyph={<LanderIconStar />}
            title="Average 5-Star Rating"
            readoutText="4.80"
            description="Employees rate their Guide sessions of 4.80 stars out of 5."
          />
          <TalkingPoint
            glyph={<LanderIconPointer />}
            title="Member Rebook Rate with their Guides"
            readoutText="88%"
            description="88%+ of members doing a session rebook again."
          />
          <TalkingPoint
            glyph={<LanderIconClock />}
            title="Average Sessions per Employee"
            readoutText="8"
            description="The average active user has 8 sessions per year."
          />
        </div>
      </div>
      <CorporateLogos />
      <BigQuote />
      <GettingStarted />
      {renderDialogs()}
    </>
  );
}

function GettingStarted() {
  return (
    <div className="flex flex-col w-full max-w-screen-xl px-4">
      <h2 className="text-4xl font-bold text-center md:text-5xl my-14 text-neutral">
        Three Steps to Get Started
      </h2>
      <div className="flex flex-col justify-center flex-grow gap-12 xl:justify-between lg:flex-row">
        <GettingStartedStep
          description="Select from 500 topics - to be matched to Guides with that life experience & expertise"
          title="Choose your Topics"
          stepNumber={1}
        />
        <GettingStartedStep
          description="Based on life experience, credentials, career and personal characteristics"
          title="Choose your Guide"
          stepNumber={2}
        />
        <GettingStartedStep
          description=""
          title="Schedule a Session"
          stepNumber={3}
        />
      </div>
      <div className="w-full flex items-center justify-center p-8">
        <div className="flex items-center justify-center w-full lg:w-8/12">
          <img src={LanderSteps123} className="rounded-3xl shadow-lg" />
        </div>
      </div>
    </div>
  );
}

function GettingStartedStep({ title, description, stepNumber }) {
  return (
    <div
      className="grid lg:w-4/12 w-full p-4"
      style={{ gridTemplateColumns: 'auto 1fr' }}>
      <div className="">
        <span className="text-primary text-8xl p-8">{stepNumber}</span>
      </div>
      <div className="grid" style={{ gridTemplateRows: 'auto auto' }}>
        <span className="text-3xl py-2">{title}</span>
        <span className="text-xl py-1">{description}</span>
      </div>
    </div>
  );
}

function OLDGettingStarted() {
  return (
    <div className="flex flex-col w-full max-w-screen-xl px-4">
      <h2 className="text-4xl font-bold text-center md:text-5xl my-14 text-neutral lg:text-left">
        Getting Started Is Easy!
      </h2>
      <div className="flex flex-col justify-center flex-grow gap-12 xl:justify-between lg:flex-row">
        <Card
          description="Select from the different topic areas of interest below."
          imageSrc={LanderLaptopUsingLg1}
          title="1. Choose Your Topics"
        />
        <Card
          description="Find the right Guide for you and schedule a call."
          imageSrc={LanderLaptopUsingLg2}
          title="2. Choose Your Guide"
        />
        <Card
          description="Build a trusted relationship with your Guide and thrive!"
          imageSrc={LanderLaptopUsingLg3}
          cornerImgSrc={LanderManSmile}
          title="3. Have A Conversation"
        />
      </div>
    </div>
  );
}

function CorporateLogos() {
  const logos = [
    LanderLogoSHRM,
    LanderLogoDriveTime,
    LanderLogoBBB,
    LanderLogoSXSW,
    LanderLogoKaiser
  ];

  return (
    <div className="flex flex-col items-center justify-center w-full max-w-screen-xl px-4 py-4">
      <div className="flex lg:gap-x-16 md:gap-x-24 gap-x-16 w-full items-center text-center justify-center px-12 flex-wrap">
        {logos.map((L, idx) => {
          return (
            <div
              className="h-48 flex items-center justify-center"
              key={`logo-${idx}`}>
              <L className="w-52" fill={'gray'} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

function BigQuote() {
  return (
    <div className="flex flex-col items-center justify-center w-full max-w-screen-xl px-4 mb-48 mt-48">
      <div className="grid w-8/12 m-8">
        <span className="flex text-3xl font-semibold text-center relative">
          <LanderQuoteOpen
            className="absolute m-1"
            style={{
              transformOrigin: 'center',
              transform: 'translateY(-19px) translateX(-48px)',
              top: 0,
              left: 0
            }}
          />
          <span className="z-10">
            My Guide sessions are always a highlight of my week. With my Guide’s
            experienced mentoring, I achieved the promotion without burning
            myself out
          </span>
          <LanderQuoteClose
            className="absolute m-1"
            style={{
              transformOrigin: 'center',
              transform: 'translateY(0px) translateX(48px)',
              bottom: 0,
              right: 0
            }}
          />
        </span>
      </div>
    </div>
  );
}

function TalkingPoint({ glyph, title, readoutText, description }) {
  return (
    <div
      className="grid w-full max-w-xl gap-4 p-4 text-center border-2 border-gray-300 rounded-3xl items-center justify-center"
      title={description || ''}
      style={{
        gridTemplateRows: 'auto 1fr'
      }}>
      <div
        className="flex items-center justify-center min-w-24 text-center"
        style={{ minWidth: 96 }}>
        <span className="text-xl font-bold">{title}</span>
      </div>
      <div
        className="grid flex-col gap-4 items-center justify-center text-left content-center"
        style={{
          gridTemplateColumns: 'auto auto'
        }}>
        <div>{glyph}</div>
        <h3 className="p-4 font-bold text-7xl text-neutral grid content-center mt-2">
          {readoutText}
        </h3>
      </div>
    </div>
  );
}

function Card({ description, imageSrc, title, cornerImgSrc }) {
  const cornerImg = cornerImgSrc ? (
    <div
      className="absolute origin-center top-1/2 left-1/2"
      style={{
        transform: 'translate(-50%, -50%)'
      }}>
      <img
        src={cornerImgSrc}
        alt={`${title}-cornerimage`}
        className="relative w-32 h-32 origin-center lg:w-36 lg:h-36 xl:w-48 xl:h-48"
        style={{
          transform: `translate(75%, -50%)`
        }}
      />
    </div>
  ) : null;

  return (
    <div
      className="grid items-center py-4 sm:flex-col sm:items-stretch"
      style={{
        gridTemplateRows: 'minmax(256px, auto) auto'
      }}>
      <div className="relative py-8 sm:w-auto">
        <img
          className="mx-auto border-2 border-gray-300 rounded-full h-80 w-80 sm:w-72 sm:h-72 lg:w-80 lg:h-80 xl:w-96 xl:h-96"
          src={imageSrc}
          alt={title}
        />
        {cornerImg}
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="w-72">
          <h3 className="py-4 text-3xl font-bold text-neutral whitespace-nowrap">
            {title}
          </h3>
          <p className="text-xl text-gray-500">{description}</p>
        </div>
      </div>
    </div>
  );
}
Card.propTypes = {
  description: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
