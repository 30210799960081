import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import { useEffect, useState } from 'react';

import { SAVED_GUIDES_KEY } from '../../consts';
import UpdateUserConnectSavedGuideMutation from '../../graphql/mutations/update-user-connect-saved-guide.graphql';
import UpdateUserDisconnectSavedGuideMutation from '../../graphql/mutations/update-user-disconnect-saved-guide.graphql';
import UserQuery from '../../graphql/queries/user.graphql';
import useUser from './use-user';

const storage = localStorage.getItem(SAVED_GUIDES_KEY);
const storedGuides = storage ? JSON.parse(storage).map((id) => ({ id })) : [];

let state = storedGuides;
const setters = [];

function setter(value) {
  state = value;
  setters.forEach((s) => {
    s(value);
  });
}

function useGlobal() {
  const [s, setState] = useState(state);

  useEffect(() => {
    setters.push(setState);
    return function cleanup() {
      const index = setters.indexOf(setState);
      setters.splice(index, 1);
    };
  }, []);

  return [s, setter];
}

export default function useSavedGuides() {
  const { data } = useUser();
  const User = get(data, 'User');

  const [savedGuides, setSavedGuides] = useGlobal();

  const [disconnectSavedGuide] = useMutation(
    UpdateUserDisconnectSavedGuideMutation,
    {
      refetchQueries: [{ query: UserQuery }]
    }
  );
  const [connectSavedGuide] = useMutation(UpdateUserConnectSavedGuideMutation, {
    refetchQueries: [{ query: UserQuery }]
  });

  function database(guide) {
    const isSaved = User.savedGuides.some((g) => g.id === guide.id);
    const mutation = isSaved ? disconnectSavedGuide : connectSavedGuide;
    const variables = {
      guideId: guide.id,
      userId: User.id
    };

    return mutation({ variables });
  }

  function local(guide) {
    const index = savedGuides.findIndex((g) => g.id === guide.id);
    if (index >= 0) {
      savedGuides.splice(index, 1);
    } else {
      savedGuides.push(guide);
    }

    localStorage.setItem(
      SAVED_GUIDES_KEY,
      JSON.stringify(savedGuides.map((g) => g.id))
    );
    setSavedGuides([...savedGuides]);

    return Promise.resolve();
  }

  function toggleGuide(guide) {
    if (User) {
      return database(guide);
    }
    return local(guide);
  }

  const guides = User ? User.savedGuides : savedGuides;

  return [guides, toggleGuide];
}
