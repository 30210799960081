import { Modal } from '@windmill/react-ui';
import qs from 'qs';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { REDIRECT_URL_KEY } from '../../../consts';
import history from '../../../history';
import Register from './index';

function RegisterDialog() {
  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  const isOpen = search.dialog === 'REGISTER';

  const modal = useRef(null);

  useEffect(
    function () {
      const redirectUrl = localStorage.getItem(REDIRECT_URL_KEY);
      const parsedRedirectUrl = redirectUrl
        ? new URL(redirectUrl, window.location.href)
        : null;

      if (!isOpen) {
        return;
      }
      if (location.pathname !== '/') {
        return;
      }
      if (!parsedRedirectUrl) {
        return;
      }
      // Auth0 returns to /register,
      // redirect to load the previous content behind the dialog
      const search = qs.parse(parsedRedirectUrl.search, {
        ignoreQueryPrefix: true
      });
      history.push({
        pathname: parsedRedirectUrl.pathname,
        search: qs.stringify({ ...search, dialog: 'REGISTER' }),
        hash: location.hash
      });
    },
    [location, isOpen]
  );

  function onClose() {
    const redirectUrl = localStorage.getItem(REDIRECT_URL_KEY);
    const parsedRedirectUrl = redirectUrl
      ? new URL(redirectUrl, window.location.href)
      : null;

    if (redirectUrl) {
      const { dialog, ...remainingUrl } = qs.parse(parsedRedirectUrl.search, {
        ignoreQueryPrefix: true
      });
      // Redirect to the URL,
      // but if it's the register route or dialog remove that to prevent a loop
      localStorage.removeItem(REDIRECT_URL_KEY);
      history.push({
        pathname:
          remainingUrl.pathname === '/register'
            ? '/'
            : parsedRedirectUrl.pathname,
        search: qs.stringify({
          ...remainingUrl,
          ...(dialog === 'REGISTER' ? null : { dialog: remainingUrl.dialog })
        })
      });
      return;
    }

    const { dialog: _, ...remaining } = search;

    history.push({
      pathname: location.pathname,
      search: qs.stringify({ ...remaining })
    });
  }

  function onError() {
    modal.current.scrollTop = 0;
  }

  return (
    <Modal
      ref={modal}
      className="relative p-8 max-w-lg overflow-y-auto max-h-screen bg-white rounded-t-lg sm:rounded-lg sm:m-4"
      isOpen={isOpen}
      onClose={onClose}>
      <Register onError={onError} />
    </Modal>
  );
}
export default RegisterDialog;
