import './index.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Header } from 'semantic-ui-react';

import { ROLES } from '../../../consts';
import ClientDashboardQuery from '../../../graphql/queries/client-dashboard.graphql';
import history from '../../../history';
import graphql from '../../hoc/graphql';
import withUser from '../../hoc/with-user';
import LoadingSpinner from '../../ui/loading-spinner';
import SessionListItem from '../session-tabs/session-list-item';

@withUser({ authenticated: [ROLES.CLIENT], showError: true })
@graphql(ClientDashboardQuery, {
  options: ({ user: { User } }) => ({ variables: { userId: User.id } })
})
@withRouter
class ClientSessions extends Component {
  static propTypes = {
    data: PropTypes.shape({
      completedSessions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      ),
      loading: PropTypes.bool.isRequired,
      scheduledSessions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      )
    }).isRequired,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string.isRequired,
        timezone: PropTypes.string
      }).isRequired
    }).isRequired
  };

  state = {
    dialog: null,
    session: null
  };

  _closeModals = () => {
    this.setState({
      session: null,
      route: ''
    });
  };

  render() {
    return (
      <div className="my-sessions-component p-4">{this._renderContent()}</div>
    );
  }

  _renderContent() {
    const { completedSessions, loading, scheduledSessions } = this.props.data;

    if (loading) {
      return <LoadingSpinner className="my-4 mx-auto w-48 h-48" />;
    }

    const hasSessions = completedSessions.length || scheduledSessions.length;
    return hasSessions ? this._renderSessions() : this._renderEmptyState();
  }

  _renderSessions() {
    return (
      <>
        {this._renderScheduledSessions()}
        {this._renderCompletedSessions()}
      </>
    );
  }

  _renderScheduledSessions() {
    const { scheduledSessions } = this.props.data;

    if (!scheduledSessions.length) {
      return null;
    }

    return (
      <div className="scheduled-sessions">
        <Header size="huge">Upcoming Calls</Header>
        <p>Need help? Call us at 1-877-532-3472</p>
        <div className="sessions-list">
          {scheduledSessions.map(this._renderSession)}
        </div>
      </div>
    );
  }

  _renderCompletedSessions() {
    const { completedSessions } = this.props.data;

    if (!completedSessions.length) {
      return null;
    }

    return (
      <div className="completed-sessions">
        <Header size="large">Call History</Header>
        <div className="sessions-list">
          {completedSessions.map(this._renderSession)}
        </div>
      </div>
    );
  }

  _renderSession = (session) => {
    return <SessionListItem key={`session-${session.id}`} session={session} />;
  };

  _renderEmptyState() {
    return (
      <div className="empty-state">
        <Header size="large">You have no upcoming sessions</Header>
        <Button
          primary
          onClick={() => {
            history.push('');
          }}>
          Explore Topics and find a Guide
        </Button>
      </div>
    );
  }
}
export default ClientSessions;
