import './sessions.css';

import Bugsnag from '@bugsnag/js';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Container,
  Dimmer,
  Dropdown,
  Icon,
  Input,
  Label,
  Loader,
  Message,
  Modal,
  Pagination,
  Popup,
  Segment,
  Table
} from 'semantic-ui-react';

import CancelSessionMutation from '../../../../graphql/mutations/cancel-session.graphql';
import SessionsByStatusQuery from '../../../../graphql/queries/sessions-by-status-pagination.graphql';
import graphql from '../../../hoc/graphql';
import ErrorDialog from '../../../ui/error-dialog';
import GuideContext from '../../guide/guide-context';
import RescheduleSessionDialog from '../../scheduler/reschedule-session-dialog';
import { PAGE_SIZE, SORT_DIRECTIONS, STATUSES, parseSearch } from './params';
import SessionRow from './session-row';
import dialogs from './dialogs';

const COLUMNS = [
  {
    name: 'client',
    title: 'Member',
    sortable: false
  },
  {
    name: 'clientTz',
    title: '',
    sortable: false
  },
  {
    name: 'company',
    title: 'Company',
    sortable: false
  },
  {
    name: 'guide',
    title: 'Guide',
    sortable: false
  },
  {
    name: 'guideTz',
    title: '',
    sortable: false
  },
  {
    name: 'topics',
    title: 'Topics',
    sortable: false
  },
  {
    name: 'createdAt',
    title: 'Time Created',
    sortable: true
  },
  {
    name: 'scheduledTime',
    title: 'Scheduled Time',
    sortable: true
  },
  {
    name: 'startedAt',
    title: 'Time Started',
    sortable: true
  },
  {
    name: 'duration',
    title: 'Duration',
    sortable: false
  },
  {
    name: 'updatedAt',
    title: 'Last Activity',
    sortable: true
  },
  {
    name: 'status',
    title: 'Status',
    sortable: false
  }
];

function searchToVariables(search) {
  const skip = search.page > 0 ? (search.page - 1) * PAGE_SIZE : 0;
  const first = PAGE_SIZE;
  const orderBy = `${search.sort}_${search.direction}`;

  return {
    first,
    skip,
    orderBy,
    status: search.status
  };
}

const USER_PROP_TYPE = PropTypes.shape({
  id: PropTypes.string,
  avatarUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  timezone: PropTypes.string
});

@withRouter
@graphql(SessionsByStatusQuery, {
  name: 'sessions',
  options: (props) => {
    const { location } = props;

    const search = parseSearch(location.search);
    const variables = searchToVariables(search);

    return { variables };
  },
  pollInterval: 1000 * 60
})
@graphql(CancelSessionMutation, {
  name: 'cancelSession',
  options: (props) => {
    const { location } = props;

    const search = parseSearch(location.search);
    const variables = searchToVariables(search);

    return { refetchQueries: [{ query: SessionsByStatusQuery, variables }] };
  }
})
class SessionsList extends Component {
  static propTypes = {
    cancelSession: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    sessions: PropTypes.shape({
      sessions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          createdAt: PropTypes.string,
          client: USER_PROP_TYPE,
          guide: USER_PROP_TYPE,
          status: PropTypes.string,
          timezone: PropTypes.string
        })
      ),
      sessionsConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      }),
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired
    }).isRequired
  };

  state = {
    dialog: null,
    error: null,
    keyword: '',
    loading: false,
    reason: '',
    session: null,
    submitting: false
  };

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    const search = parseSearch(location.search);
    const prevSearch = parseSearch(prevProps.location.search);

    if (
      search.page !== prevSearch.page ||
      search.sort !== prevSearch.sort ||
      search.direction !== prevSearch.direction ||
      search.status !== prevSearch.status
    ) {
      this._refetch();
    }
  }

  _refetch() {
    const { location, sessions } = this.props;

    const search = parseSearch(location.search);

    this.setState({ loading: true });
    const variables = searchToVariables(search);
    sessions.refetch({ ...variables }).then(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    const { loading: queryLoading } = this.props.sessions;
    const { error, keyword, loading: stateLoading } = this.state;

    const loading = queryLoading || stateLoading;

    return (
      <>
        <Dimmer.Dimmable
          className="concierge-calls-list"
          as={Segment}
          basic
          blurring={true}
          dimmed={loading}>
          <Dimmer active={loading} blurring="true" inverted>
            <Loader />
          </Dimmer>
          <Input
            fluid
            label="Keyword Filter"
            placeholder="Enter client name, client company, or guide name"
            value={keyword}
            onChange={(event, { value }) => {
              this.setState({ keyword: value });
            }}
          />
          {this._renderSessions()}
          {this._renderPagination()}
        </Dimmer.Dimmable>
        {this._renderDialogs()}
        <ErrorDialog
          error={error}
          onClose={() => {
            this.setState({ error: null });
          }}
        />
      </>
    );
  }

  _renderSessions() {
    const { sessions } = this.props.sessions;
    const { keyword } = this.state;

    if (!sessions) {
      return null;
    }

    const filteredSessions = sessions.filter((session) => {
      if (!keyword) {
        return true;
      }
      const lowerCasedKeyword = keyword.toLowerCase();
      const { client, guide } = session;
      const companyName =
        client.companyIds[0]?.company.name.toLowerCase() || '';
      const clientName = client.firstName.toLowerCase();
      const guideName = guide.firstName.toLowerCase();

      return (
        clientName.includes(lowerCasedKeyword) ||
        guideName.includes(lowerCasedKeyword) ||
        companyName.includes(lowerCasedKeyword)
      );
    });

    return (
      <Table striped>
        <Table.Header>
          <Table.Row>
            {COLUMNS.map(this._renderHeader)}
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {filteredSessions.length
            ? filteredSessions.map((session) => (
                <SessionRow
                  key={session.id}
                  session={session}
                  openDialog={(dialog, session) =>
                    this.setState({ dialog, session })
                  }
                />
              ))
            : this._renderEmptyState()}
        </Table.Body>
      </Table>
    );
  }

  _renderEmptyState() {
    const { location } = this.props;

    const search = parseSearch(location.search);
    const status = search.status || '';

    return (
      <Table.Row>
        <Table.Cell colspan={COLUMNS.length}>
          <Message>There are no {status.toLowerCase()} calls.</Message>
        </Table.Cell>
      </Table.Row>
    );
  }

  _renderHeader = ({ name, sortable, title }) => {
    const { location } = this.props;

    const search = parseSearch(location.search);
    const direction =
      search.direction === SORT_DIRECTIONS.ASC ? 'ascending' : 'descending';

    return (
      <Table.HeaderCell
        key={`column-header-${name}`}
        style={{ cursor: sortable ? 'pointer' : 'auto' }}
        sorted={search.sort === name ? direction : null}
        onClick={() => {
          if (sortable) {
            this._onSort(name);
          }
        }}>
        {title}
        {search.sort === name && (
          <Icon name={direction === 'ascending' ? 'sort down' : 'sort up'} />
        )}
        {name === 'status' && (
          <Dropdown icon="filter">
            <Dropdown.Menu>
              <Dropdown.Item
                text="All"
                value={undefined}
                onClick={() => {
                  this._search({ status: undefined });
                }}
              />
              {Object.values(STATUSES).map((status) => (
                <Dropdown.Item
                  key={status}
                  text={startCase(status.replace('_', ' '))}
                  value={status}
                  onClick={() => {
                    this._search({ page: 1, status });
                  }}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Table.HeaderCell>
    );
  };

  _renderPagination() {
    const { location } = this.props;
    const { sessionsConnection, loading } = this.props.sessions;

    if (loading) {
      return null;
    }

    const search = parseSearch(location.search);

    const count = sessionsConnection.aggregate.count;
    const totalPages = Math.ceil(count / PAGE_SIZE);

    return (
      <Container textAlign="center">
        <Pagination
          activePage={search.page}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true
          }}
          firstItem={null}
          lastItem={null}
          prevItem={
            count > PAGE_SIZE
              ? {
                  content: <Icon name="angle left" className="lineawesome" />,
                  icon: true
                }
              : null
          }
          nextItem={
            count > PAGE_SIZE
              ? {
                  content: <Icon name="angle right" className="lineawesome" />,
                  icon: true
                }
              : null
          }
          onPageChange={this._onPageChange}
          totalPages={totalPages}
        />
      </Container>
    );
  }

  _renderDialogs() {
    const { dialog, session } = this.state;

    switch (dialog) {
      case dialogs.CANCEL_SESSION:
        return this._renderConfirmCancel();
      case dialogs.RESCHEDULE_SESSION:
        return (
          <GuideContext.Provider value={session.guide}>
            <RescheduleSessionDialog
              isOpen={true}
              session={session}
              timezone={session.client.timezone}
              onClose={this._onCloseDialog}
              onSubmit={this._onRescheduleSession}
            />
          </GuideContext.Provider>
        );
    }
    return null;
  }

  _renderConfirmCancel() {
    const { reason, session, submitting } = this.state;

    return (
      <Modal open size="small">
        <Modal.Content>
          <Modal.Description>
            <p>
              Are you sure you want to cancel the call between{' '}
              {session.guide.firstName} and {session.client.firstName}?
            </p>
            <Input
              fluid
              label="Reason:"
              value={reason}
              onChange={(event, { value }) => {
                this.setState({ reason: value });
              }}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={submitting}
            onClick={() => {
              this._onCloseDialog();
            }}>
            Nevermind
          </Button>
          <Button
            primary
            disabled={submitting}
            loading={submitting}
            onClick={this._onConfirmCancelSession}>
            Yes, please cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  _renderTopicsCell = (topics) => {
    const topicsLabels = topics.map((topic) => (
      <Label key={topic.id} className="topic-label" color="teal">
        {topic.title}
      </Label>
    ));
    const showMorePopup = (
      <Popup content={topicsLabels} trigger={<Label>...</Label>} />
    );
    const firstThreeTopicsLabels = topicsLabels.slice(0, 3);

    return (
      <>
        {firstThreeTopicsLabels}
        {topics.length > 3 && showMorePopup}
      </>
    );
  };

  _onSort = (name) => {
    const { location } = this.props;

    const search = parseSearch(location.search);

    const direction =
      search.sort === name
        ? search.direction === SORT_DIRECTIONS.ASC
          ? SORT_DIRECTIONS.DESC
          : SORT_DIRECTIONS.ASC
        : SORT_DIRECTIONS.ASC;

    this._search({
      sort: name,
      direction
    });
  };

  _onPageChange = (e, { activePage }) => {
    this._search({
      page: activePage
    });
  };

  _onCloseDialog = () => {
    this.setState({ dialog: null });
  };

  _onConfirmCancelSession = () => {
    const { cancelSession } = this.props;
    const { reason, session } = this.state;

    const variables = {
      id: session.id,
      reason
    };

    this.setState({ error: null, submitting: true, success: false });

    cancelSession({ variables })
      .then(() => {
        this.setState({
          dialog: null,
          reason: '',
          submitting: false
        });
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'SessionsList._onConfirmCancelSession';
          event.request.variables = variables;
        });
      });
  };

  _onRescheduleSession = () => {
    this._onCloseDialog();
    this._refetch();
  };

  _search(params) {
    const { history, location } = this.props;

    const search = parseSearch(location.search);
    const updated = {
      ...search,
      ...params
    };

    const url = `${location.pathname}?${qs.stringify(updated)}`;
    history.push(url);
  }
}
export default SessionsList;
