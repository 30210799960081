import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { BUGSNAG_API_KEY } from './consts';

function onError(event) {
  if (!process.env.REACT_APP_LOG_BUGS) {
    // eslint-disable-next-line
    console.log('Bugsnag error report', event);
    return false;
  }
  return true;
}

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  appVersion: process.env.REACT_APP_VERSION,
  onError,
  plugins: [new BugsnagPluginReact()],
  redactedKeys: [/^password$/i],
  releaseStage: process.env.REACT_APP_HOST_ENV
});
