import { Button, Dropdown, DropdownItem } from '@windmill/react-ui';
import * as Add2Calendar from 'add2calendar';
import debounce from 'lodash/debounce';
import isBoolean from 'lodash/isBoolean';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ROLES } from '../../../consts';
import useUser from '../../hooks/use-user';
import { CALL_LENGTH } from './index';

function AddSessionToCalendar(props) {
  const { session } = props;

  const [isOpen, setIsOpen] = useState(false);

  const { data: userData } = useUser();

  if (!session || !session.emailLinks || !session.emailLinks.length) {
    return null;
  }

  if (!userData || !userData.User) {
    return null;
  }

  const { User } = userData;

  const isGuide = User.roles.some((role) => role.name === ROLES.GUIDE);

  const otherUser = [session.client, session.guide]
    .filter((x) => !!x)
    .find((user) => user.id !== User.id);

  const [companyConnection] =
    session.client && session.client.companyIds
      ? session.client.companyIds
      : [];
  const [link] = session.emailLinks;

  const icsUrl = `${process.env.REACT_APP_WEBHOOKS_URL}/sessionICS?id=${session.id}&userId=${User.id}`;
  const eventUrl = `${window.location.origin}/get-started/${link.id}`;

  const timezone = User.timezone || moment.tz.guess();
  const startDate = moment(session.scheduledTime).tz(timezone);
  const endDate = startDate.clone().add(CALL_LENGTH, 'minutes');

  function description() {
    const description = `Your LifeGuides call with ${otherUser.firstName}: ${eventUrl}`;
    if (isGuide) {
      return (
        description +
        `

Guide: ${User.firstName} ${User.lastName}
Member: ${otherUser.firstName} ${otherUser.lastName}
Member's Company: ${companyConnection ? companyConnection.company.name : ''}
  `
      );
    }
    return description;
  }

  const options = {
    title: 'LifeGuides Session',
    start: startDate.toDate(),
    end: endDate.toDate(),
    description: description(),
    timezone,
    isAllDay: false
  };
  const singleEvent = new Add2Calendar(options);

  const toggleOpen = debounce((value) => {
    setIsOpen(isBoolean(value) ? value : !isOpen);
  }, 50);

  function onClose() {
    toggleOpen(false);
  }

  function onClick(event) {
    toggleOpen(false);
  }

  const calendars = [
    // {
    //   icon: 'apple',
    //   label: 'Apple Calendar',
    //   name: 'ical',
    //   url: icsUrl // singleEvent.getICalUrl()
    // },
    {
      icon: 'google',
      label: 'Google',
      name: 'google',
      url: singleEvent.getGoogleUrl()
    },
    // {
    //   icon: 'windows',
    //   label: 'Outlook',
    //   name: 'outlook',
    //   url: icsUrl // singleEvent.getOutlookUrl()
    // },
    // Outlook Online is disabled in v1.1 of add2calendar
    // {
    //   icon: 'windows',
    //   label: 'Outlook Online',
    //   name: 'outlook.com',
    //   url: singleEvent.getOutlookOnlineUrl()
    // },
    {
      icon: 'yahoo',
      label: 'Yahoo',
      name: 'yahoo',
      url: singleEvent.getYahooUrl()
    },
    {
      icon: 'file-download',
      label: 'Download .ics file',
      name: 'download',
      url: icsUrl
    }
  ];

  return (
    <span className="relative">
      <Button layout="outline" onClick={toggleOpen}>
        <i className="icon lineawesome calendar" />
        &nbsp;Add to Calendar
      </Button>
      <Dropdown
        className="z-10 bottom-0"
        align="right"
        isOpen={isOpen}
        onClose={onClose}>
        {calendars.map(({ icon, label, name, url }) => (
          <DropdownItem
            key={name}
            className="text-base"
            tag="a"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClick}>
            <i className={`icon lineawesome large ${icon}`} />
            &nbsp;
            {label}
          </DropdownItem>
        ))}
      </Dropdown>
    </span>
  );
}
AddSessionToCalendar.propTypes = {
  session: PropTypes.shape({
    id: PropTypes.string.isRequired,
    client: PropTypes.shape({
      id: PropTypes.string,
      companyIds: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          company: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          })
        })
      )
    }),
    emailLinks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string
      })
    ),
    guide: PropTypes.shape({
      id: PropTypes.string
    }),
    scheduledTime: PropTypes.string
  }).isRequired
};
export default AddSessionToCalendar;
