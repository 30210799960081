import { useQuery } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { useLocation } from 'react-router-dom';
import { Button, Dimmer, Icon, Loader } from 'semantic-ui-react';

import TopicGroupByIdQuery from '../../../../../graphql/queries/topic-group-by-id.graphql';

const DEFAULT_IMAGE_URL =
  'https://react.semantic-ui.com/images/wireframe/image.png';

const ref = React.createRef();

function TopicGroupImageForm(props) {
  const { onError, onFileSelect, submitting } = props;

  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { data } = useQuery(TopicGroupByIdQuery, {
    skip: !search.id,
    variables: { id: search.id }
  });

  function renderDropzoneContents({
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject
  }) {
    const fileUrl =
      filePreview ||
      (file && (file.preview || file.url)) ||
      (data && data.topicGroup && data.topicGroup.imageUrl) ||
      DEFAULT_IMAGE_URL;

    let overlay = null;

    if (isDragActive) {
      overlay = <Icon name="upload" color="green" size="huge" />;
    }
    if (isDragReject) {
      overlay = <Icon name="cancel" color="red" size="huge" />;
    }
    if (submitting) {
      overlay = (
        <Dimmer
          inverted
          active
          style={{
            zIndex: '0',
            display: 'flex',
            height: '175px',
            background: 'transparent'
          }}>
          <Loader />
        </Dimmer>
      );
    }

    let style = {
      display: 'flex',
      height: '175px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: `url(${fileUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'contain'
    };

    if (submitting) {
      style.backgroundImage = ``;
    }

    return (
      <div {...getRootProps()} style={style}>
        <input {...getInputProps()} />

        {overlay}
      </div>
    );
  }

  function onDrop([file]) {
    setFile(file);
    setFilePreview(null);

    if (onFileSelect) {
      onFileSelect(file);
    }

    const reader = new FileReader();
    reader.onload = () => {
      setFilePreview(reader.result);
    };

    // eslint-disable-next-line no-console
    reader.onabort = () => console.log('file reading was aborted');
    // eslint-disable-next-line no-console
    reader.onerror = () => console.log('file reading has failed');

    try {
      reader.readAsDataURL(file);
    } catch (err) {
      const variables = { file };
      Bugsnag.notify(err, function (event) {
        event.context = 'TopicGroupImageForm._onDrop';
        event.request.variables = variables;
      });
    }
  }

  function onDropRejected() {
    if (onError) {
      onError(
        new Error('The file was too large. Choose a file smaller than 2 MB.')
      );
    }
  }

  return (
    <>
      <Dropzone
        ref={ref}
        className="logo-dropzone"
        acceptStyle={{
          borderStyle: 'solid',
          borderColor: '#21ba45'
        }}
        rejectStyle={{
          borderStyle: 'solid',
          borderColor: '#db2828'
        }}
        maxSize={2000000}
        disabled={submitting}
        multiple={false}
        onDrop={onDrop}
        onDropRejected={onDropRejected}>
        {renderDropzoneContents}
      </Dropzone>

      <div className="image-dropzone-cta-container">
        <Button.Group
          fluid
          vertical
          labeled
          icon
          className="image-dropzone-cta">
          <Button
            icon="camera"
            content="Select an image"
            aria-placeholder="Select an image"
            className="image-dropzone-cta upload"
            disabled={submitting}
            onClick={() => {
              ref.current.open();
            }}
          />
        </Button.Group>
      </div>
    </>
  );
}
TopicGroupImageForm.propTypes = {
  onError: PropTypes.func,
  onFileSelect: PropTypes.func,
  submitting: PropTypes.bool
};
export default TopicGroupImageForm;
