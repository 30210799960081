import PropTypes from 'prop-types';
import React from 'react';

import ImageDropzone from '../../../ui/image-dropzone';
import { useEditing } from './actions';

function GuideProfileCoverPhoto(props) {
  const { data, onChange } = props;

  const [isEditing] = useEditing();

  const img = data.headerImageUrl ? (
    <img src={data.headerImageUrl} className="w-full object-cover h-52" />
  ) : (
    <div className="w-full h-52 bg-gradient-to-b from-green-400 to-blue-500" />
  );

  if (isEditing) {
    return <ImageDropzone onDrop={onChange}>{img}</ImageDropzone>;
  }
  return img;
}
GuideProfileCoverPhoto.propTypes = {
  data: PropTypes.shape({
    headerImageUrl: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired
};
export default GuideProfileCoverPhoto;
