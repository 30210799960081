import './index.css';

import { graphql } from '@apollo/client/react/hoc';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Icon, Modal, Popup } from 'semantic-ui-react';

import apolloClient from '../../../apollo';
import TogglePresentMutation from '../../../graphql/mutations/toggle-present.graphql';
import UserQuery from '../../../graphql/queries/user.graphql';
import UserToggledPresenceSubscription from '../../../graphql/subscriptions/user-toggled-presence.graphql';
import ErrorDialog from '../../ui/error-dialog';

const DIALOGS = {
  CONFIRM_PRESENT: 'CONFIRM_PRESENT'
};

function PresenceControls(props) {
  const { User } = props.user;

  const [dialog, setDialog] = useState(
    User ? (User.isPresent ? DIALOGS.CONFIRM_PRESENT : null) : null
  );
  const [error, setError] = useState(null);
  const [online, setOnline] = useState(
    'onLine' in navigator ? navigator.onLine : true
  );
  const [present, setPresent] = useState(User ? User.isPresent : false);
  const [refreshing, setRefreshing] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(
    function () {
      if (User) {
        if (User.isPresent !== present) {
          setPresent(User.isPresent);
        }
      } else if (present) {
        setPresent(false);
      }
    },
    [User]
  );

  useEffect(function () {
    const subscription = apolloClient
      .subscribe({
        query: UserToggledPresenceSubscription
      })
      .subscribe({
        next({
          data: {
            userToggledPresence: { node }
          }
        }) {
          if (User && node.id === User.id) {
            setPresent(node.isPresent);
          }
        }
      });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(
    function () {
      window.addEventListener('offline', onOffline);
      window.addEventListener('online', onOnline);

      return function cleanup() {
        window.removeEventListener('offline', onOffline);
        window.removeEventListener('online', onOnline);
      };
    },
    [online]
  );

  function onOffline() {
    setOnline(false);
  }

  function onOnline() {
    if (!online) {
      setRefreshing(true);
      props.user
        .refetch()
        .then(function () {
          setRefreshing(false);
        })
        .catch(function (error) {
          setRefreshing(false);
        });
    }

    setOnline(true);
  }

  function togglePresent() {
    const variables = { present: !present };

    setError(null);
    setSubmitting(true);
    apolloClient
      .mutate({ mutation: TogglePresentMutation, variables })
      .then(function ({ data }) {
        const { User } = apolloClient.readQuery({ query: UserQuery });
        apolloClient.writeQuery({
          query: UserQuery,
          data: {
            User: {
              ...User,
              isPresent: data.togglePresent.present
            }
          }
        });

        setPresent(data.togglePresent.present);
        setSubmitting(false);
      })
      .catch(function (error) {
        setError(error);
        setSubmitting(false);
      });
  }

  function renderConfirmPresentDialog() {
    return (
      <Modal
        open
        size="mini"
        onClick={function () {
          setDialog(null);
        }}
        onClose={function () {
          setDialog(null);
        }}>
        <Modal.Header>
          Enable Ring Audio <Icon name="volume up" className="lineawesome" />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            You are currently present, click anywhere to enable ring
            notification audio.
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }

  function renderDialogs() {
    switch (dialog) {
      case DIALOGS.CONFIRM_PRESENT:
        return renderConfirmPresentDialog();
      default:
        return null;
    }
  }

  return (
    <>
      <div className="presence-controls">
        {online ? (
          <Popup
            trigger={
              <Button
                onClick={togglePresent}
                color={present ? 'green' : 'red'}
                disabled={submitting}
                loading={refreshing || submitting}>
                {present ? 'On Demand' : 'Away'}
              </Button>
            }
            content={
              present
                ? 'Click to remove yourself from the on demand list.'
                : 'Click to become available for on demand calls.'
            }
            position="bottom left"
          />
        ) : (
          <Popup
            trigger={
              <div>
                <Button disabled>
                  <Icon name="wifi" className="lineawesome" />
                  Offline
                </Button>
              </div>
            }
            content="You are currently offline, please check your internet settings."
            position="bottom left"
          />
        )}
      </div>
      {renderDialogs()}
      <ErrorDialog
        error={error}
        onClose={function () {
          setError(null);
        }}
      />
    </>
  );
}

PresenceControls.propTypes = {
  user: PropTypes.shape({
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    refetch: PropTypes.func.isRequired,
    networkStatus: PropTypes.number.isRequired,
    User: PropTypes.shape({
      id: PropTypes.string.isRequired,
      isPresent: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired
};

const withUserQuery = graphql(UserQuery, {
  name: 'user',
  pollInterval: 1000 * 60
});

export default flow([withUserQuery])(PresenceControls);
