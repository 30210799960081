import './auth0-registration-form.css';

import { Button } from '@windmill/react-ui';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Checkbox, Form, Input } from 'semantic-ui-react';

import withAuth0 from '../../hoc/with-auth0';
import withUser from '../../hoc/with-user';
import MyForm, { FormField, PhoneNumberInput } from '../../ui/form';
import LoadingSpinner from '../../ui/loading-spinner/index';
import PrivacyPolicy from '../../ui/privacy-policy/dialog';
import TermsAndConditions from '../../ui/terms-and-conditions/dialog';

@withAuth0()
@withUser()
class PhoneForm extends Component {
  static propTypes = {
    auth0: PropTypes.object.isRequired,
    children: PropTypes.node,
    clientInvite: PropTypes.shape({
      user: PropTypes.shape({
        phoneNumber: PropTypes.string
      })
    }),
    onClosePrivacyPolicy: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    showPrivacyPolicy: PropTypes.bool,
    submitting: PropTypes.bool,
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        emailAddress: PropTypes.string,
        phoneNumber: PropTypes.string
      })
    }).isRequired
  };

  state = {
    data: {
      agreedToTermsAndConditions: false,
      companyName: '',
      currentlyEmployed: true,
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: ''
    },
    isValid: false,
    showPrivacyPolicy: false,
    showTermsAndConditions: false
  };

  constructor(props) {
    super(props);

    const data = this._userData(props);
    this.state.data = this._mergeUserData(data);
  }

  componentDidUpdate(prevProps) {
    const auth0Changed =
      (!prevProps.auth0.user && this.props.auth0.user) ||
      prevProps.auth0.user !== this.props.auth0.user;
    const clientInviteChanged =
      (!prevProps.clientInvite && this.props.clientInvite) ||
      prevProps.clientInvite !== this.props.clientInvite;
    const userChanged =
      (!prevProps.user.User && this.props.user.User) ||
      prevProps.user.User !== this.props.user.User;
    if (!auth0Changed && !clientInviteChanged && !userChanged) {
      return;
    }

    const data = this._userData(this.props, prevProps);
    this.setState({
      data: this._mergeUserData(data)
    });
  }

  _userData(props) {
    const data = {
      currentlyEmployed: true
    };

    const { auth0, clientInvite } = props;
    const { User } = props.user;

    if (auth0.user) {
      data.emailAddress = auth0.user.email;
    }

    if (clientInvite) {
      if (clientInvite.company) {
        data.companyName = clientInvite.company.name;
      }
      if (clientInvite.user) {
        if (clientInvite.isSpouse) {
          data.emailAddress = clientInvite.user.emailAddress;
        }
        if (clientInvite.user.phoneNumber) {
          data.phoneNumber = clientInvite.user.phoneNumber;
        }
      }
    }

    if (User) {
      if (User.firstName) {
        data.firstName = User.firstName;
      }
      if (User.lastName) {
        data.lastName = User.lastName;
      }
      if (User.emailAddress) {
        data.emailAddress = User.emailAddress;
      }
      if (User.phoneNumber) {
        data.phoneNumber = User.phoneNumber;
      }
    }

    return data;
  }

  _mergeUserData(userData) {
    const { data } = this.state;

    return {
      ...data,
      companyName: userData.companyName || data.companyName,
      emailAddress: userData.emailAddress || data.emailAddress,
      phoneNumber: userData.phoneNumber || data.phoneNumber
    };
  }

  render() {
    const { children, submitting } = this.props;
    const { data, isValid } = this.state;

    return (
      <MyForm
        className="auth0-registration-form"
        data={data}
        onChange={this._onChange}
        onValidate={(errors) => {
          this.setState({ isValid: isEmpty(errors) });
        }}>
        <Form.Group widths="equal">
          <FormField
            component={Input}
            name="firstName"
            label="First Name *"
            aria-label="First Name"
            aria-required="true"
            validator={({ firstName }) => {
              if (!firstName) {
                throw new Error('First name is required');
              }
            }}
          />
          <FormField
            component={Input}
            name="lastName"
            label="Last Name *"
            aria-label="Last Name"
            aria-required="true"
            validator={({ lastName }) => {
              if (!lastName) {
                throw new Error('Last name is required');
              }
            }}
          />
        </Form.Group>
        <FormField
          component={PhoneNumberInput}
          name="phoneNumber"
          label="Personal Mobile Number *"
          aria-label="Personal Mobile Number"
          aria-required="true"
          validator={({ phoneNumber }) => {
            if (!phoneNumber) {
              throw new Error('Must enter a mobile number');
            }
          }}
        />
        {children}
        <FormField
          component={Input}
          name="emailAddress"
          label="Personal Email *"
          aria-label="Personal Email"
          aria-required="true"
          type="email"
          validator={({ emailAddress }) => {
            if (!emailAddress) {
              throw new Error('Must enter a personal e-mail address');
            }
          }}
        />
        <Form.Group className="tos-pp-container">
          <FormField
            className="tos-privacy-agreement-container"
            component={Checkbox}
            name="agreedToTermsAndConditions"
            aria-label="Check to agree to the LifeGuides' Terms and Conditions"
            aria-required="true"
            label={
              <label
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                {`I am 18+ and agree to the`}
                <a
                  href="#"
                  onClick={() => {
                    this.setState({
                      showTermsAndConditions: true
                    });
                  }}>
                  {` Terms & Conditions `}
                </a>
                {`and `}
                <a
                  href="#"
                  onClick={() => {
                    this.setState({
                      showPrivacyPolicy: true
                    });
                  }}>
                  {`Privacy Policy`}
                </a>
              </label>
            }
            validator={({ agreedToTermsAndConditions }) => {
              if (!agreedToTermsAndConditions) {
                throw new Error('Must agree to terms and conditions');
              }
            }}
          />
        </Form.Group>
        <Button
          block
          size="larger"
          className="mt-4"
          disabled={!isValid || submitting}
          onClick={this._onSubmit}>
          {submitting ? (
            <>
              <LoadingSpinner className="w-6 h-6" />
              &nbsp;
            </>
          ) : (
            'Create Account'
          )}
        </Button>
        <PrivacyPolicy
          visible={this.props.showPrivacyPolicy || this.state.showPrivacyPolicy}
          onClose={() => {
            this.setState({
              showPrivacyPolicy: false
            });
            if (isFunction(this.props.onClosePrivacyPolicy)) {
              this.props.onClosePrivacyPolicy();
            }
          }}
        />
        <TermsAndConditions
          visible={this.state.showTermsAndConditions}
          onClose={() => {
            this.setState({
              showTermsAndConditions: false
            });
          }}
        />
      </MyForm>
    );
  }

  _onChange = (data) => {
    this.setState({ data });
  };

  _onSubmit = () => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    const formatted = {
      ...data,
      phoneNumber: data.phoneNumber.replace(/\D/g, '')
    };

    onSubmit(formatted);
  };
}
export default PhoneForm;
