import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Dialogs from './dialogs';

export default function AchievementRoutes() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${match.url}/dialog/:name`}
        render={() => <Dialogs parentPath={match.url} />}
      />
    </Switch>
  );
}
