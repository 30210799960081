export default {
  backdrop: {
    base:
      'fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50'
  },
  button: {
    base:
      'align-bottom leading-0 inline-flex items-center justify-center cursor-pointer rounded-full transition-colors duration-150 focus:outline-none',
    outline: {
      base: 'text-primary border-gray-300 border',
      active:
        'active:bg-transparent hover:border-gray-500 focus:border-gray-500 focus:shadow-outline-gray'
    },
    link: {
      base:
        'text-primary dark:text-gray-400 focus:outline-none border border-transparent'
    },
    primary: {
      base: 'text-white bg-primary border border-transparent',
      active: 'active:opacity-90 hover:opacity-90',
      disabled: 'opacity-50 cursor-not-allowed'
    },
    size: {
      larger: 'py-5 px-12 text-xl rounded-lg',
      large: 'py-3 px-10 text-lg rounded-lg',
      regular: 'px-8 py-2.5 text-md rounded-lg'
    }
  },
  input: {
    base:
      'block w-full text-sm focus:outline-none dark:text-gray-300 leading-5 p-2 border rounded-md'
  },
  modalFooter: {
    base:
      'max-h-full overflow-y-auto flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row'
  }
};
