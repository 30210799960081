import './index.css';

import React from 'react';

export default function TermsAndConditionsCopy() {
  return (
    <>
      <h1 className="tac-title">LifeGuides Terms of Use</h1>
      <h2 className="tac-subtitle">Last updated March 27, 2023</h2>
      <h2 className="tac-subtitle">AGREEMENT TO TERMS</h2>
      <p>
        These Terms of Use constitute a legally binding agreement made between
        you, whether personally or on behalf of an entity (“you”) and
        LifeGuides, PBC, a public benefit corporation (“LifeGuides,” “we,” “us”
        or “our”), concerning your access to and use of the www.lifeguides.com
        website, including all subpages or any site branded LifeGuides such as
        my.lifeguides.com, as well as any other media form, media channel,
        mobile website or mobile application related, linked, or otherwise
        connected thereto, and all services provided through our website or such
        media forms (collectively, the “Site”). The Site provides access to
        LifeGuides our online platform for “Service” or “Services” means the
        online, web-based and phone-based services provided by LifeGuides®
        (sometimes referred to as The LifeGuides Network®) which connects
        “Participants” (any eligible person authorized to use the Site, whether
        they are the payor or an employer / work association is the payor) with
        Guides,Well-Being Concierges (“WBC”) and live Chat, and provides access
        to the Life Challenge Channels™ and other services. The Service includes
        Guides and WBC’s who leverage curated content, and our Guide community’s
        resources, and our database of customized content that is relevant to a
        Participant’s needs, which is supported by our Intelligence Assistance®
        software platform. However, to the extent provided by a Guide, WBC or
        any member of our team, no Service shall constitute mental health
        counseling, addiction counseling, therapy, medical care, medical
        direction, tax services, legal services, an ERISA covered service, any
        service requiring licensure in the jurisdiction of the Participant, or
        any in-person face-to-face services or the like, (collectively, the
        “LifeGuides Offerings”).
      </p>
      <p>
        In the course of providing the LifeGuides Offerings, we may direct you
        to general articles, online content or provide other general advice, but
        you understand that we are not providing medical advice, nor are we
        licensed professionals, and that we are not acting as a business
        associate or covered entity as such terms are defined by the Health
        Insurance Portability and Accountability Act of 1996, as amended by the
        Health Information Technology for Economic and Clinical Health Act, and
        regulations promulgated thereunder (collectively, “HIPAA”) or ERISA.
      </p>
      <p>
        Our Guides often have no specific credentials or certifications (except
        those available in our LifeGuides training, and other programs, for
        Guides and WBC’s) , and any counsel they provide is based upon such
        Guides’ personal experience and the materials provided through the
        LifeGuides platform. Any medical content, services, information, advice
        or materials of any kind accessed through the LifeGuides Offerings is
        for general information or reference purposes only and shall not be
        relied upon or be a substitute for any professional, clinical or medical
        advice or judgment provided by a healthcare professional. Always seek
        the advice of your physician or other qualified healthcare provider with
        any questions you may have regarding a medical condition. Never
        disregard professional or medical advice or delay seeking professional
        help because of anything you have read on, or advice you have received
        through, the Site.
      </p>
      <p>
        If you think you may have a medical emergency, call your doctor or 911
        immediately. We do not recommend or endorse any specific tests, health
        care providers, products, procedures, opinions or other information that
        may be mentioned on the Site. Reliance on any information provided
        through the Site is solely at your own risk.
      </p>
      <p>
        In order to help make the Site a secure environment for the full use of
        LifeGuides Offerings, all users are required to accept and comply with
        these Terms of Use, which are incorporated into these Terms of Use. You
        agree that by accessing the Site and/or the LifeGuides Offerings, you
        have read, understood, and agree to be bound by all of these Terms of
        Use.
      </p>
      <p>
        IF YOU DO NOT AGREE WITH ALL OF THE TERMS OF USE HEREIN, THEN YOU ARE
        EXPRESSLY PROHIBITED FROM USING THE SITE AND/OR THE LIFEGUIDES OFFERINGS
        AND YOU MUST DISCONTINUE USE IMMEDIATELY. PLEASE NOTE THAT THESE TERMS
        OF USE CONTAIN A MANDATORY ARBITRATION OF DISPUTES PROVISION THAT
        REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE
        DISPUTES IN CERTAIN CIRCUMSTANCES, RATHER THAN JURY TRIALS OR CLASS
        ACTION LAWSUITS.
      </p>
      <p>
        Supplemental terms and conditions or documents that may be posted on the
        Site from time to time are hereby expressly incorporated herein by
        reference. In the event any supplemental terms conflict with the terms
        of these Terms of Use, the supplemental terms will control to the extent
        of such conflict. We reserve the right, in our sole discretion, to make
        changes or modifications to these Terms of Use at any time and for any
        reason. We will alert you about any changes by updating the “Last
        updated” date of these Terms of Use, and you waive any right to receive
        specific notice of each such change. It is your responsibility to
        periodically review these Terms of Use to stay informed of updates. You
        will be subject to, and will be deemed to have been made aware of and to
        have accepted, the changes in any revised Terms of Use by your continued
        use of the Site five business days after the date such revised Terms of
        Use are posted. If you, as an individual Participant, have prepaid any
        amounts for a membership or sessions that occur after the date such
        revised Terms of Use are posted and you do not agree to such revised
        Terms of Use, you may notify us using the contact information below and
        we will provide you with refund of the amounts you have prepaid.
      </p>
      <p>
        The information provided on the Site is not intended for distribution to
        or use by any person or entity in any jurisdiction or country where such
        distribution or use would be contrary to law or regulation or which
        would subject us to any registration requirement within such
        jurisdiction or country. Accordingly, those persons who choose to access
        the Site from other locations do so on their own initiative and are
        solely responsible for compliance with local laws, if and to the extent
        local laws are applicable.
      </p>
      <p>
        The Site is intended for users who are at least 18 years old. If you are
        under the age of 18, you are not permitted to use or register for the
        Site or use the LifeGuides Offerings.
      </p>
      <h2 className="tac-subtitle">INTELLECTUAL PROPERTY RIGHTS</h2>
      <p>
        Unless otherwise indicated, the Site and the LifeGuides Offerings are
        our proprietary property and all source code, databases, functionality,
        software, website designs, audio, video, text, photographs, and graphics
        on the Site or in the LifeGuides Offerings, to the extent that it is our
        original content (collectively, the “Content”) and the trademarks,
        service marks, and logos contained therein (the “Marks”) are owned or
        controlled by us or licensed to us, and are protected by copyright and
        trademark laws and various other intellectual property rights and unfair
        competition laws of the United States, foreign jurisdictions, and
        international conventions. The Content and the Marks are provided on the
        Site “AS IS” for your information and personal use only.
      </p>
      <p>
        Except as expressly provided in these Terms of Use, no part of the Site
        or the LifeGuides Offerings and no Content or Marks may be copied,
        reproduced, aggregated, republished, uploaded, posted, publicly
        displayed, encoded, translated, transmitted, distributed, sold,
        licensed, or otherwise exploited for any commercial purpose whatsoever,
        without our express prior written permission. Provided that you are
        eligible to use the Site, you are granted a limited license to, during
        your membership period, to access and use the Content for which you paid
        and are entitled to access, solely for your personal, non-commercial use
        and solely in accordance with these Terms of Use. You acknowledge that
        as between the parties, we own all right, title and interest in and to
        the Site, LifeGuides Offerings, the Content and the Marks, and all
        related documentation, including all intellectual property rights
        therein, and you hereby assign to us all right, title and interest that
        you may acquire in or to such materials. We reserve all rights not
        expressly granted to you in and to the Site, LifeGuides Offerings, the
        Content and the Marks.
      </p>
      <h2 className="tac-subtitle">USER REPRESENTATIONS</h2>
      <p>
        By using the Site or the LifeGuides Offerings, you represent and warrant
        that: (1) all registration information you submit will be true,
        accurate, current, and complete; (2) you will maintain the accuracy of
        such information and promptly update such registration information as
        necessary; (3) you have the legal capacity and you agree to comply with
        these Terms of Use; (4) you are not a minor in the jurisdiction in which
        you reside; (5) you will not access the Site or the LifeGuides Offerings
        through automated or non-human means, whether through a bot, script or
        otherwise; (6) you will not use the Site for any illegal or unauthorized
        purpose; and (7) your use of the Site or the LifeGuides Offerings will
        not violate any applicable law or regulation. If you provide any
        information that is untrue, inaccurate, we have the right to suspend or
        terminate your account and refuse any and all current or future use of
        the Site (or any portion thereof). You may not use the Site or the
        LifeGuides Offerings for any illegal or unauthorized purpose nor may
        you, in the use of the Site or LifeGuides Offerings, violate any laws.
      </p>
      <h2 className="tac-subtitle">USER REGISTRATION</h2>
      <p>
        You will be required to register with the Site in order to access the
        LifeGuides Offerings. You agree to keep your password confidential and
        will be responsible for all use of your account and password. We reserve
        the right to remove, reclaim, or change a username you select if we
        determine, in our sole discretion, that such username is inappropriate,
        obscene, or otherwise objectionable.
      </p>
      <h2 className="tac-subtitle">LIFEGUIDES OFFERINGS</h2>
      <p>
        We reserve the right to limit the quantities of the LifeGuides Offerings
        offered or available on the Site. All descriptions or pricing of the
        LifeGuides Offerings are subject to change at any time without notice,
        at our sole discretion. We reserve the right to discontinue any
        LifeGuides Offerings at any time for any reason. We do not warrant that
        the quality of any of the LifeGuides Offerings purchased by you will
        meet your expectations or that any errors in the Site will be corrected
        in a timely fashion.
      </p>
      <h2 className="tac-subtitle">PURCHASES AND PAYMENT</h2>
      <p>
        While most Participants or Users are engaging with the Site and the
        Services via an employer paid benefit plan, we accept the following
        forms of payment: Visa, American Express, Discover, and PayPal.
      </p>
      <p>
        You agree to provide current, complete, and accurate purchase and
        account information for all purchases of the LifeGuides Offerings made
        via the Site. You further agree to promptly update account and payment
        information, including email address, payment method, and payment card
        expiration date, so that we can complete your transactions and contact
        you as needed.
      </p>
      <p>
        Sales tax will be added to the price of purchases as deemed required by
        us under applicable law. We may change prices at any time. All payments
        shall be in U.S. dollars. You agree to pay all charges at the prices
        then in effect for your purchases and any applicable shipping fees, and
        you authorize us to charge your chosen payment provider for any such
        amounts upon placing your order. If your order is subject to recurring
        charges, then you consent to our charging your payment method on a
        recurring basis without requiring your prior approval for each recurring
        charge, until such time as you cancel the applicable order. We reserve
        the right to correct any errors or mistakes in pricing, even if we have
        already requested or received payment. We reserve the right to refuse
        any order placed through the Site. We may, in our sole discretion, limit
        or cancel quantities purchased per person, per household, or per order.
        These restrictions may include orders placed by or under the same
        customer account, the same payment method, and/or orders that use the
        same billing or shipping address. We reserve the right to limit or
        prohibit orders that, in our sole judgment, appear to be placed by
        dealers, resellers, or distributors, except as separately contracted
        with LifeGuides, PBC.
      </p>
      <h2 className="tac-subtitle">SATISFACTION GUARANTEE</h2>
      <p>
        If you are not fully satisfied with any session with a Guide, you may
        notify us using the contact information below within a week of the
        session, and we will provide a replacement Guide of your choosing from
        our community of Guides. You agree to answer our reasonable questions
        regarding your experience with your Guide.
      </p>
      <h2 className="tac-subtitle">PROHIBITED ACTIVITIES</h2>
      <p>
        You may not access or use the Site for any purpose other than that for
        which we make the Site available. The Site may not be used in connection
        with any commercial endeavors except those that are specifically
        endorsed or approved by us.
      </p>
      <p>As a user of the Site, you agree not to:</p>
      <ol>
        <li>
          Systematically retrieve data or other content from the Site to create
          or compile, directly or indirectly, a collection, compilation,
          database, or directory without written permission from us.
        </li>
        <li>
          Make any unauthorized use of the LifeGuides Offerings, including
          collecting usernames and/or email addresses of users by electronic or
          other means for the purpose of sending unsolicited email, or creating
          user accounts by automated means or under false pretenses.
        </li>
        <li>
          Use a buying agent or purchasing agent to make purchases on the Site.
        </li>
        <li>Use the Site to advertise or offer to sell goods and services.</li>
        <li>
          Circumvent, disable, or otherwise interfere with security-related
          features of the Site, including features that prevent or restrict the
          use or copying of any Content or enforce limitations on the use of the
          Site and/or the Content contained therein.
        </li>
        <li>Engage in unauthorized framing of or linking to the Site.</li>
        <li>
          Trick, defraud, or mislead us and other users, especially in any
          attempt to learn sensitive account information such as user passwords.
        </li>
        <li>
          Make improper use of our support services or submit false reports of
          abuse or misconduct.
        </li>
        <li>
          Engage in any automated use of the system, such as using scripts to
          send comments or messages, or using any data mining, robots, or
          similar data gathering and extraction tools.
        </li>
        <li>
          Interfere with, disrupt, or create an undue burden on the Site or the
          networks or services connected to the Site.
        </li>
        <li>
          Attempt to impersonate another user or person or use the username of
          another user.
        </li>
        <li>Sell or otherwise transfer your profile.</li>
        <li>
          Use any information obtained from the Site in order to harass, abuse,
          or harm another person.
        </li>
        <li>
          Use the LifeGuides Offerings as part of any effort to compete with us
          or otherwise use the Site and/or the Content for any
          revenue-generating endeavor or commercial enterprise.
        </li>
        <li>
          Decipher, decompile, disassemble, or reverse engineer any of the
          software comprising or in any way making up a part of the Site.
        </li>
        <li>
          Attempt to bypass any measures of the Site designed to prevent or
          restrict access to the Site, or any portion of the Site.
        </li>
        <li>
          Harass, annoy, intimidate, or threaten any of our employees or agents
          engaged in providing any portion of the LifeGuides Offerings to you.
        </li>
        <li>
          Delete the copyright or other proprietary rights notice from any
          Content.
        </li>
        <li>
          Copy or adapt the Site’s software, including but not limited to Flash,
          PHP, HTML, JavaScript, or other code.
        </li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) viruses,
          Trojan horses, or other material, including excessive use of capital
          letters and spamming (continuous posting of repetitive text), that
          interferes with any party’s uninterrupted use and enjoyment of the
          Site or modifies, impairs, disrupts, alters, or interferes with the
          use, features, functions, operation, or maintenance of the LifeGuides
          Offerings.
        </li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) any material
          that acts as a passive or active information collection or
          transmission mechanism, including without limitation, clear graphics
          interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other
          similar devices (sometimes referred to as “spyware” or “passive
          collection mechanisms” or “pcms”).
        </li>
        <li>
          Except as may be the result of standard search engine or Internet
          browser usage, use, launch, develop, or distribute any automated
          system, including without limitation, any spider, robot, cheat
          utility, scraper, or offline reader that accesses the Site, or using
          or launching any unauthorized script or other software.
        </li>
        <li>
          Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
          Site.
        </li>
        <li>
          Use the Site in a manner inconsistent with any applicable laws or
          regulations.
        </li>
      </ol>
      <h2 className="tac-subtitle">USER GENERATED CONTRIBUTIONS</h2>
      <p>
        The Site may invite you to chat or interact with others or contribute to
        or participate in blogs, message boards, online forums, or other
        functionalities, and may provide you with the opportunity to create,
        submit, post, display, transmit, perform, publish, distribute, or
        broadcast content and materials to us or on the Site, including but not
        limited to text, writings, video, audio, photographs, graphics,
        comments, suggestions, or personal information or other material
        (collectively, “Contributions”). For clarity, Contributions do not
        include your Submissions (defined below). Contributions may be viewable
        by other users of the Site and the LifeGuides Offerings and through
        third-party websites. As such, any Contributions you transmit may be
        treated as non-confidential and non-proprietary. When you create or make
        available any Contributions, you thereby represent and warrant that:
      </p>
      <ol>
        <li>
          Your Contributions do not contain any personal medical information
          that would require us to comply with the Health Insurance Portability
          and Accountability Act of 1996 (“HIPAA”) with respect to such
          information.
        </li>
        <li>
          The creation, distribution, transmission, public display, or
          performance, and the accessing, downloading, or copying of your
          Contributions do not and will not infringe the proprietary rights,
          including but not limited to the copyright, patent, trademark, trade
          secret, or moral rights of any third party.
        </li>
        <li>
          You are the creator and owner of or have the necessary licenses,
          rights, consents, releases, and permissions to use and to authorize
          us, the Site, and other users of the Site to use your Contributions in
          any manner contemplated by the Site and these Terms of Use.
        </li>
        <li>
          You have the written consent, release, and/or permission of each and
          every identifiable individual person in your Contributions to use the
          name or likeness of each and every such identifiable individual person
          to enable inclusion and use of your Contributions in any manner
          contemplated by the Site and these Terms of Use.
        </li>
        <li>Your Contributions are not false, inaccurate, or misleading.</li>
        <li>
          Your Contributions are not unsolicited or unauthorized advertising,
          promotional materials, pyramid schemes, chain letters, spam, mass
          mailings, or other forms of solicitation, and do not contain
          solicitations or advertisements for alcoholic beverages or intoxicants
          of any sort, games of chance, or illegal materials.
        </li>
        <li>
          Your Contributions are not pornographic, obscene, lewd, lascivious,
          filthy, violent, harassing, libelous, slanderous, or otherwise
          objectionable (as determined by us).
        </li>
        <li>
          Your Contributions do not ridicule, mock, disparage, intimidate, or
          abuse anyone.
        </li>
        <li>
          Your Contributions do not advocate the violent overthrow of any
          government or incite, encourage, or threaten physical harm against
          another.
        </li>
        <li>
          Your Contributions do not violate any applicable law, regulation, or
          rule.
        </li>
        <li>
          Your Contributions do not violate the privacy or publicity rights of
          any third party.
        </li>
        <li>
          Your Contributions do not contain any material that solicits personal
          information from anyone under the age of 18 or exploits people under
          the age of 18 in a sexual or violent manner.
        </li>
        <li>
          Your Contributions do not violate any federal or state law concerning
          child pornography, or otherwise intended to protect the health or
          well-being of minors;
        </li>
        <li>
          Your Contributions do not include any offensive comments that are
          connected to race, national origin, gender, sexual preference, or
          physical handicap.
        </li>
        <li>
          Your Contributions do not otherwise violate, or link to material that
          violates, any provision of these Terms of Use, or any applicable law
          or regulation.
        </li>
      </ol>
      <p>
        Any use of the Site or the LifeGuides Offerings in violation of the
        foregoing violates these Terms of Use and may result in, among other
        things, termination or suspension of your rights to use the Site and the
        LifeGuides Offerings.
      </p>
      <h2 className="tac-subtitle">CONTRIBUTION LICENSE</h2>
      <p>
        By posting your Contributions to any part of the Site or making
        Contributions accessible to the Site by linking your account from the
        Site to any of your social networking accounts, you automatically grant,
        and you represent and warrant that you have the right to grant, to us an
        unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
        transferable, royalty-free, fully-paid, worldwide right, and license to
        host, use, copy, reproduce, disclose, sell, resell, publish, broadcast,
        retitle, archive, store, cache, publicly perform, publicly display,
        reformat, translate, transmit, excerpt (in whole or in part), distribute
        and fully use such Contributions (including, without limitation, your
        image and voice, for non-cofidential video Contributions, as an example)
        for any purpose, commercial, advertising, or otherwise, and to prepare
        derivative works of, or incorporate into other works, such
        Contributions, and grant and authorize sublicenses of the foregoing. The
        use and distribution may occur in any media formats and through any
        media channels. This license will apply to any form, media, or
        technology now known or hereafter developed, and includes our use of
        your name in our promotional and advertising materials. You waive all
        moral rights in your Contributions, and you warrant that moral rights
        have not otherwise been asserted in your Contributions.
      </p>
      <p>
        We do not assert any ownership over your Contributions. You retain full
        ownership of all of your Contributions and any intellectual property
        rights or other proprietary rights associated with your Contributions.
        We are not liable for any statements or representations in your
        Contributions provided by you in any area on the Site. You are solely
        responsible for your Contributions to the Site and you expressly agree
        to indemnify us from any and all liabilities, claims, damages, losses
        and other expenses relating to or arising out of your Contributions, and
        to refrain from any legal action against us regarding your
        Contributions. We have the right, in our sole and absolute discretion,
        (1) to edit, redact, or otherwise change any Contributions; (2) to
        re-categorize any Contributions to place them in more appropriate
        locations on the Site; and (3) to pre-screen or delete any Contributions
        at any time and for any reason, without notice. We have no obligation to
        monitor your Contributions.
      </p>
      <h2 className="tac-subtitle">GUIDELINES FOR REVIEWS</h2>
      <p>
        We may provide you areas on the Site to leave reviews or ratings. When
        posting a review, you must comply with the following criteria:
        <br /> (1) you should have firsthand experience with the person/entity
        being reviewed;
        <br /> (2) your reviews should not contain offensive profanity, or
        abusive, racist, offensive, or hate language;
        <br /> (3) your reviews should not contain discriminatory references
        based on religion, race, gender, national origin, age, marital status,
        sexual orientation or disability;
        <br /> (4) your reviews should not contain references to illegal
        activity;
        <br /> (5) you should not be affiliated with competitors if posting
        negative reviews;
        <br /> (6) you should not make any conclusions as to the legality of
        conduct;
        <br /> (7) you may not post any false or misleading statements; and
        <br /> (8) you may not organize a campaign encouraging others to post
        reviews, whether positive or negative.
      </p>
      <p>
        We may accept, reject, or remove reviews in our sole discretion. We have
        absolutely no obligation to screen reviews or to delete reviews, even if
        anyone considers reviews objectionable or inaccurate. Reviews are not
        endorsed by us, and do not necessarily represent our opinions or the
        views of any of our affiliates or partners. We do not assume liability
        for any review or for any claims, liabilities, or losses resulting from
        any review. Any review you post is considered a Contribution under these
        Terms of Use and is exclusively owned by us.
      </p>
      <h2 className="tac-subtitle">SOCIAL MEDIA</h2>
      <p>
        As part of the functionality of the Site, you may link your account with
        online accounts you have with certain third-party service providers
        (each such account, a “Third-Party Account”) by either: providing your
        Third-Party Account login information through the Site; or (2) allowing
        us to access your Third-Party Account, as is permitted under the
        applicable terms and conditions that govern your use of each Third-Party
        Account. You represent and warrant that you are entitled to disclose
        your Third-Party Account login information to us and/or grant us access
        to your Third-Party Account, without breach by you of any of the terms
        and conditions that govern your use of the applicable Third-Party
        Account, and without obligating us to pay any fees or making us subject
        to any usage limitations imposed by the third-party service provider of
        the Third-Party Account. By granting us access to any Third-Party
        Accounts, you understand that (1) we may access, make available, and
        store (if applicable) any content that you have access to through your
        Third-Party Account (the “Social Network Content”) so that it is
        available on and through the Site via your account, including without
        limitation any friend or contacts lists that you authorized to be shared
        with us, and (2) we may submit to and receive from your Third-Party
        Account additional information to the extent you are notified when you
        link your account with the Third-Party Account. Depending on the
        Third-Party Accounts you choose and subject to the privacy settings that
        you have set in such Third-Party Accounts, personally identifiable
        information that you post to your Third-Party Accounts may be available
        on and through your account on the Site. Please note that if a
        Third-Party Account or associated service becomes unavailable or our
        access to such Third-Party Account is terminated by the third-party
        service provider, then Social Network Content may no longer be available
        on and through the Site.
      </p>
      <p>
        You will have the ability to disable the connection between your account
        on the Site and your Third-Party Accounts at any time. PLEASE NOTE THAT
        YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH
        YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH
        SUCH THIRD-PARTY SERVICE PROVIDERS. We make no effort to review any
        Social Network Content for any purpose, including but not limited to,
        for accuracy, legality, or non-infringement, and we are not responsible
        for any Social Network Content. You acknowledge and agree that we may
        access your email address book associated with a Third-Party Account and
        your contacts list stored on your mobile device or tablet computer for
        purposes of identifying and informing you of those contacts who have
        also registered to use the Site. You can deactivate the connection
        between the Site and your Third-Party Account by contacting us using the
        contact information below or through your account settings (if
        applicable). We will make reasonable commercial efforts to delete any
        Social Network Content stored on our servers that was obtained through
        such Third-Party Account, except the username and profile picture that
        become associated with your account.
      </p>
      <h2 className="tac-subtitle">SUBMISSIONS</h2>
      <p>
        You acknowledge and agree that any questions, comments, suggestions,
        ideas, reviews, or other feedback regarding the Site or the LifeGuides
        Offerings provided by you to us (collectively, “Submissions”) are
        non-confidential and are our sole property. It is noted that Submissions
        do not include confidential information shared directly in a Guide or
        Wellbeing Concierge session. We own all exclusive rights, including all
        intellectual property rights, in your Submissions and shall be entitled
        to the unrestricted use and dissemination of these Submissions for any
        lawful purpose, commercial or otherwise, without acknowledgment or
        compensation to you. You hereby assign to us all of your right, title
        and interest in and to your Submissions. You hereby waive all moral
        rights to any such Submissions, and you hereby warrant that any such
        Submissions are original with you or that you have the right to submit
        such Submissions. You agree that you shall have no recourse against us
        for any alleged or actual infringement or misappropriation by us of any
        proprietary right in your Submissions.
      </p>
      <h2 className="tac-subtitle">THIRD-PARTY WEBSITES AND CONTENT</h2>
      <p>
        The Site may contain (or you may be sent via the Site or the LifeGuides
        Offerings) links to other websites (“Third-Party Websites”) as well as
        articles, photographs, text, graphics, pictures, designs, music, sound,
        video, information, applications, software, and other content or items
        belonging to or originating from third parties (“Third-Party Content”).
        Such Third-Party Websites and Third-Party Content are not regularly
        investigated, monitored, or checked for accuracy, appropriateness, or
        completeness by us, and we are not responsible for any Third Party
        Websites accessed through the Site or any Third-Party Content posted on,
        available through, or installed from the Site, including the content,
        accuracy, offensiveness, opinions, reliability, privacy practices, or
        other policies of or contained in the Third-Party Websites or the
        Third-Party Content. Inclusion of, linking to, or permitting the use or
        installation of any Third-Party Websites or any Third-party content does
        not imply approval or endorsement thereof by us.
      </p>
      <p>
        If you decide to leave the Site and access the Third-Party Websites or
        to use or install any Third-Party Content, you do so at your own risk,
        and you should be aware these Terms of Use no longer govern. You should
        review the applicable terms and policies, including privacy and data
        gathering practices, of any website to which you navigate from the Site
        or relating to any applications you use or install from the Site. Any
        purchases you make through Third-Party Websites will be through other
        websites and from other companies, and we take no responsibility
        whatsoever in relation to such purchases which are exclusively between
        you and the applicable third party. You agree and acknowledge that we do
        not endorse the products or services offered on Third-Party Websites and
        you shall hold us harmless from any harm caused by your purchase of such
        products or services. Additionally, you shall hold us harmless from any
        losses sustained by you or harm caused to you relating to or resulting
        in any way from any Third-Party Content or any contact with Third-Party
        Websites.
      </p>
      <h2 className="tac-subtitle">SITE MANAGEMENT</h2>
      <p>
        We reserve the right, but not the obligation, to: (1) monitor the Site
        for violations of these Terms of Use; (2) take appropriate legal action
        against anyone who, in our sole discretion, violates the law or these
        Terms of Use, including without limitation, reporting such user to law
        enforcement authorities; (3) in our sole discretion and without
        limitation, refuse, restrict access to, limit the availability of, or
        disable (to the extent technologically feasible) any of your
        Contributions or any portion thereof; (4) in our sole discretion and
        without limitation, notice, or liability, to remove from the Site or
        otherwise disable all files and content that are excessive in size or
        are in any way burdensome to our systems; and (5) otherwise manage the
        Site in a manner designed to protect our rights and property and to
        facilitate the proper functioning of the Site and the LifeGuides
        Offerings.
      </p>
      <h2 className="tac-subtitle">PRIVACY POLICY</h2>
      <p>
        We care about data privacy and security. Please review our Privacy
        Policy. By using the Site or the LifeGuides Offerings, you understand
        and consent to the terms of our Privacy Policy, which describes how we
        collect, use and disclose your information. Please be advised the Site
        and the LifeGuides Offerings are hosted in the United States. If you
        access the Site or the LifeGuides Offerings from the European Union,
        Asia, or any other region of the world with laws or other requirements
        governing personal data collection, use, or disclosure that differ from
        applicable laws in the United States, then through your continued use of
        the Site, you are transferring your data to the United States, and you
        expressly consent to have your data transferred to and processed in the
        United States. Further, we do not knowingly accept, request, or solicit
        information from children or knowingly market to children. Therefore, in
        accordance with the U.S. Children’s Online Privacy Protection Act, if we
        receive actual knowledge that anyone under the age of 13 has provided
        personal information to us without the requisite and verifiable parental
        consent, we will delete that information from the Site as quickly as is
        reasonably practical.
      </p>
      <h2 className="tac-subtitle">COPYRIGHT INFRINGEMENTS</h2>
      <p>
        We respect the intellectual property rights of others. If you believe
        that any material available on or through the Site infringes upon any
        copyright you own or control, please immediately notify us using the
        contact information provided below (a “Notification”). A copy of your
        Notification will be sent to the person who posted or stored the
        material addressed in the Notification. Please be advised that pursuant
        to federal law you may be held liable for damages if you make material
        misrepresentations in a Notification. Thus, if you are not sure that
        material located on or linked to by the Site infringes your copyright,
        you should consider first contacting an attorney.
      </p>
      <h2 className="tac-subtitle">TERM AND TERMINATION</h2>
      <p>
        These Terms of Use shall remain in full force and effect while you use
        the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE
        RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
        LIABILITY, DENY ACCESS TO AND USE OF THE SITE AND THE LIFEGUIDES
        OFFERINGS (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR
        ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF
        ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF
        USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
        PARTICIPATION IN THE SITE AND THE LIFEGUIDES OFFERINGS OR DELETE YOUR
        ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME,
        WITHOUT WARNING, IN OUR SOLE DISCRETION.
      </p>
      <p>
        If we terminate or suspend your account for any reason, you are
        prohibited from registering and creating a new account under your name,
        a fake or borrowed name, or the name of any third party, even if you may
        be acting on behalf of the third party. In addition to terminating or
        suspending your account, we reserve the right to take appropriate legal
        action, including without limitation pursuing civil, criminal, and
        injunctive redress.
      </p>
      <h2 className="tac-subtitle">MODIFICATIONS AND INTERRUPTIONS</h2>
      <p>
        We reserve the right to change, modify, or remove the contents of the
        Site at any time or for any reason at our sole discretion without
        notice. However, we have no obligation to update any information on our
        Site. We also reserve the right to modify or discontinue all or part of
        the LifeGuides Offerings without notice at any time. We will not be
        liable to you or any third party for any modification, price change,
        suspension, or discontinuance of the Site or the LifeGuides Offerings.
        We cannot guarantee the Site and the LifeGuides Offerings will be
        available at all times. We may experience hardware, software, or other
        problems or need to perform maintenance related to the Site, resulting
        in interruptions, delays, or errors. We reserve the right to change,
        revise, update, suspend, discontinue, or otherwise modify the Site or
        the LifeGuides Offerings at any time or for any reason without notice to
        you. You agree that we have no liability whatsoever for any loss,
        damage, or inconvenience caused by your inability to access or use the
        Site or the LifeGuides Offerings during any downtime or discontinuance
        of the Site or the LifeGuides Offerings. Nothing in these Terms of Use
        will be construed to obligate us to maintain and support the Site or the
        LifeGuides Offerings or to supply any corrections, updates, or releases
        in connection therewith.
      </p>
      <h2 className="tac-subtitle">GOVERNING LAW</h2>
      <p>
        These Terms of Use and your use of the Site and the LifeGuides Offerings
        are governed by and construed in accordance with the laws of the State
        of Delaware applicable to agreements made and to be entirely performed
        within the State of Delaware, without regard to its conflict of law
        principles.
      </p>
      <h2 className="tac-subtitle">DISPUTE RESOLUTION</h2>
      <h3 className="tac-small-title">Informal Negotiations</h3>
      <p>
        To expedite resolution and control the cost of any dispute, controversy,
        or claim related to these Terms of Use (each a “Dispute” and
        collectively, the “Disputes”) brought by either you or us (individually,
        a “Party” and collectively, the “Parties”), the Parties agree to first
        attempt to negotiate any Dispute (except those Disputes expressly
        provided below) informally for at least 30-days before initiating
        arbitration. Such informal negotiations commence upon written notice
        from one Party to the other Party.
      </p>
      <h3 className="tac-small-title">Binding Arbitration</h3>
      <p>
        If the Parties are unable to resolve a Dispute through informal
        negotiations, the Dispute (except those Disputes expressly excluded
        below) will be finally and exclusively resolved by binding arbitration.
        YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO
        SUE IN COURT AND HAVE A JURY TRIAL. The arbitration shall be commenced
        and conducted under the Commercial Arbitration Rules of the American
        Arbitration Association (“AAA”) and, where appropriate, the AAA’s
        Supplementary Procedures for Consumer-Related Disputes (“AAA Consumer
        Rules”), both of which are available at the AAA website www.adr.org.
        Your arbitration fees and your share of arbitrator compensation shall be
        governed by the AAA Consumer Rules and, where appropriate, limited by
        the AAA Consumer Rules. If such costs are determined to by the
        arbitrator to be excessive, we will pay all arbitration fees and
        expenses. The arbitration may be conducted in person, through the
        submission of documents, by phone, or online. The arbitrator will make a
        decision in writing, but need not provide a statement of reasons unless
        requested by either Party. The arbitrator must follow applicable law,
        and any award may be challenged if the arbitrator fails to do so. Except
        where otherwise required by the applicable AAA rules or applicable law,
        the arbitration will take place in United States of America in the
        county of San Francisco. Except as otherwise provided herein, the
        Parties may litigate in court to compel arbitration, stay proceedings
        pending arbitration, or to confirm, modify, vacate, or enter judgment on
        the award entered by the arbitrator. If for any reason, a Dispute
        proceeds in court rather than arbitration, the Dispute shall be
        commenced or prosecuted in the state and federal courts located in
        Delaware, and the Parties hereby consent to, and waive all defenses of
        lack of personal jurisdiction, and forum non conveniens with respect to
        venue and jurisdiction in such state and federal courts. Application of
        the United Nations Convention on Contracts for the International Sale of
        Goods and the Uniform Computer Information Transaction Act (UCITA) are
        excluded from these Terms of Use.
      </p>
      <p>
        In no event shall any Dispute brought by either Party related in any way
        to the Site be commenced more than two (2) years after the cause of
        action arose. If this provision is found to be illegal or unenforceable,
        then neither Party will elect to arbitrate any Dispute falling within
        that portion of this provision found to be illegal or unenforceable and
        such Dispute shall be decided by a court of competent jurisdiction
        within the courts listed for jurisdiction above, and the Parties agree
        to submit to the personal jurisdiction of that court.
      </p>
      <h3 className="tac-small-title">Restrictions</h3>
      <p>
        The Parties agree that any arbitration shall be limited to the Dispute
        between the Parties individually. To the full extent permitted by law,
        (a) no arbitration shall be joined with any other proceeding; (b) there
        is no right or authority for any Dispute to be arbitrated on a
        class-action basis or to utilize class action procedures; and (c) there
        is no right or authority for any Dispute to be brought in a purported
        representative capacity on behalf of the general public or any other
        persons. Exceptions to Informal Negotiations and Arbitration The Parties
        agree that the following Disputes are not subject to the above
        provisions concerning informal negotiations and binding arbitration: (a)
        any Disputes seeking to enforce or protect, or concerning the validity
        of, any of the intellectual property rights of a Party; (b) any Dispute
        related to, or arising from, allegations of theft, piracy, invasion of
        privacy, or unauthorized use; and (c) any claim for injunctive relief.
        If this provision is found to be illegal or unenforceable, then neither
        Party will elect to arbitrate any Dispute falling within that portion of
        this provision found to be illegal or unenforceable and such Dispute
        shall be decided by a court of competent jurisdiction within the courts
        listed for jurisdiction above, and the Parties agree to submit to the
        personal jurisdiction of that court.
      </p>
      <h2 className="tac-subtitle">CORRECTIONS</h2>
      <p>
        There may be information on the Site that contains typographical errors,
        inaccuracies, or omissions that may relate to the LifeGuides Offerings,
        including descriptions, pricing, availability, and various other
        information. We reserve the right to correct any errors, inaccuracies,
        or omissions and to change or update the information on the Site at any
        time, without prior notice.
      </p>
      <h2 className="tac-subtitle">DISCLAIMER</h2>
      <p>
        THE SITE AND THE LIFEGUIDES OFFERINGS ARE PROVIDED ON AN AS-IS AND
        AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES
        WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE
        DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE
        AND THE LIFEGUIDES OFFERINGS AND YOUR USE THEREOF, INCLUDING, WITHOUT
        LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, TITLE, VALIDITY AND NON-INFRINGEMENT. WE MAKE NO
        WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE
        SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE
        WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES,
        OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR
        PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO
        AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
        SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
        INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
        TRANSMISSION TO OR FROM THE SITE OR THE LIFEGUIDES OFFERINGS, (5) ANY
        BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
        THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS
        IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND
        INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR
        OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE,
        GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
        ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED
        WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR
        OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
        RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
        THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A
        PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
        USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
        LIMITATIONS OF LIABILITY.
      </p>
      <p>
        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO
        YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST REVENUE OR LOSS OF
        DATA OR FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL,
        OR PUNITIVE DAMAGES, ARISING FROM YOUR USE OF OR ACCESS TO THE SITE OR
        THE LIFEGUIDES OFFERINGS, EVEN IF WE HAVE BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY
        CONTAINED HEREIN, OUR TOTAL AGGREGATE LIABILITY TO YOU IN CONNECTION
        WITH THIS AGREEMENT FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM
        OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY,
        BY YOU TO US PURSUANT TO THIS AGREEMENT DURING THE SIX (6) MONTH PERIOD
        PRIOR TO THE INITIAL CAUSE OF ACTION ARISING. CERTAIN STATE LAWS DO NOT
        ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION
        OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
        DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
        ADDITIONAL RIGHTS.
      </p>
      <h2 className="tac-subtitle">INDEMNIFICATION</h2>
      <p>
        You agree to defend, indemnify, and hold us harmless, including our
        subsidiaries, affiliates, and all of our respective officers, agents,
        partners, and employees, from and against any loss, damage, liability,
        claim, or demand, including reasonable attorneys’ fees and expenses,
        made by any third party due to or arising out of: (1) your
        Contributions; (2) your use of or access to the Site or the LifeGuides
        Offerings; (3) breach of these Terms of Use; (4) any breach of your
        representations and warranties set forth in these Terms of Use; (5) your
        violation of the rights of a third party, including but not limited to
        intellectual property rights; or (6) any overt harmful act toward any
        other user of the Site or the LifeGuides Offerings with whom you
        connected via the Site. Notwithstanding the foregoing, we reserve the
        right, at your expense, to assume the exclusive defense and control of
        any matter for which you are required to indemnify us, and you agree to
        cooperate, at your expense, with our defense of such claims. We will use
        reasonable efforts to notify you of any such claim, action, or
        proceeding which is subject to this indemnification upon becoming aware
        of it.
      </p>
      <p>
        In addition, subject to the terms of the LifeGuides Master Service
        Agreement, LifeGuides® shall at its expense defend you, your affiliated
        entities, and each of their respective officers, directors, agents and
        employees against a claim made or brought against any Client Indemnified
        Party by a third party alleging that LifeGuides Service caused harm, or
        infringes the intellectual property rights of a third party, or violates
        applicable law, or alternatively, penalties, fines, damages or claims,
        and in which LifeGuides® has been given the opportunity for timely
        direct representation to contest any such claim, and solely for
        liabilities resulting entirely from LifeGuides Services.
      </p>
      <p>
        In the event a claim is awarded by a court, or agreed by LifeGuides, we
        provide up to $1,000,000 for any one (a Participant’s entire family
        shall be considered one claim), or $5,000,000 for any series of related
        claims, with the aggregate lifetime maximum being $5,000,000; provided
        you comply with conditions as outlined in the LifeGuides MSA.
      </p>
      <h2 className="tac-subtitle">USER DATA</h2>
      <p>
        We will maintain certain data that you transmit to the Site for the
        purpose of managing the performance of the LifeGuides Offerings, as well
        as data relating to your use of the LifeGuides Offerings. Although we
        perform regular routine backups of data, you are solely responsible for
        all data that you transmit or that relates to any activity you have
        undertaken using the LifeGuides Offerings. You agree that we shall have
        no liability to you for any loss or corruption of any such data, and you
        hereby waive any right of action against us arising from any such loss
        or corruption of such data.
      </p>
      <h2 className="tac-subtitle">
        ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
      </h2>
      <p>
        Visiting the Site, sending us emails, and completing online forms
        constitute electronic communications. You consent to receive electronic
        communications, and you agree that all agreements, notices, disclosures,
        and other communications we provide to you electronically, via email and
        on the Site, satisfy any legal requirement that such communication be in
        writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
        CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
        NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY
        US OR VIA THE SITE. You hereby waive any rights or requirements under
        any statutes, regulations, rules, ordinances, or other laws in any
        jurisdiction which require an original signature or delivery or
        retention of non-electronic records, or to payments or the granting of
        credits by any means other than electronic means.
      </p>
      <h2 className="tac-subtitle">CALIFORNIA USERS AND RESIDENTS</h2>
      <p>
        If any complaint with us is not satisfactorily resolved, you can contact
        the Complaint Assistance Unit of the Division of Consumer Services of
        the California Department of Consumer Affairs in writing at 1625 North
        Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone
        at (800) 952-5210 or (916) 445-1254.
      </p>
      <h2 className="tac-subtitle">MISCELLANEOUS</h2>
      <p>
        These Terms of Use and any policies or operating rules posted by us on
        the Site or in respect to the LifeGuides Offerings constitute the entire
        agreement and understanding between you and us. Our failure to exercise
        or enforce any right or provision of these Terms of Use shall not
        operate as a waiver of such right or provision. These Terms of Use
        operate to the fullest extent permissible by law. We may assign any or
        all of our rights and obligations to others at any time. We shall not be
        responsible or liable for any loss, damage, delay, or failure to act
        caused by any cause beyond our reasonable control. If any provision or
        part of a provision of these Terms of Use is determined to be unlawful,
        void, or unenforceable, that provision or part of the provision is
        deemed severable from these Terms of Use and does not affect the
        validity and enforceability of any remaining provisions. There is no
        joint venture, partnership, employment or agency relationship created
        between you and us as a result of these Terms of Use or use of the
        LifeGuides Offerings. You agree that these Terms of Use will not be
        construed against us by virtue of having drafted them. You hereby waive
        any and all defenses you may have based on the electronic form of these
        Terms of Use and the lack of signing by the parties hereto to execute
        these Terms of Use.
      </p>
      <h2 className="tac-subtitle">CONTACT US</h2>
      <p>
        In order to resolve a complaint regarding the Site or the LifeGuides
        Offerings or to receive further information regarding use of the Site or
        the LifeGuides Offerings, please contact us at:
      </p>
      <p>
        LifeGuides, PBC, a public benefit corporation
        <br />
        Mac 6 c/o LifeGuides 1438 W Broadway Rd., #101 Tempe, AZ 85282
        <br />
        (415) 294-0592
        <br />
        Support at LifeGuides.com
      </p>
    </>
  );
}
