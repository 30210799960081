import PropTypes from 'prop-types';

export const USER_PROP_TYPE = PropTypes.shape({
  id: PropTypes.string,
  avatarUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  timezone: PropTypes.string,
  companyIds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      company: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    })
  )
});

export const COMPANY_PROP_TYPE = PropTypes.shape({
  id: PropTypes.string
});

export const TIMES_PROP_TYPE = PropTypes.shape({
  id: PropTypes.string,
  call: PropTypes.shape({
    scheduledTime: PropTypes.string
  })
});
