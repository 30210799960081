import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

import ErrorMessage from '../error-message';
export { friendlyError } from '../error-message';

function ErrorDialog(props) {
  const { error, onClose } = props;

  if (!error) {
    return null;
  }

  return (
    <Modal open size="mini" onClose={onClose}>
      <Modal.Content>
        <Modal.Description>
          <ErrorMessage error={error} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>OK</Button>
      </Modal.Actions>
    </Modal>
  );
}
ErrorDialog.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string
    })
  ]),
  onClose: PropTypes.func
};
export default ErrorDialog;
