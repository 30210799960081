import './index.css';

import React, { Component } from 'react';
import { Divider, Header } from 'semantic-ui-react';

import Avatar from '../../ui/avatar';

const GUIDES = [
  {
    avatarUrl:
      'https://files.graph.cool/cj4omzjwvzkcf01497ldiw56s/cjhgd3pvg028d01567oum9j0k',
    firstName: 'Marie'
  },
  {
    avatarUrl:
      'https://s3.amazonaws.com/lifeguides.production/ck0ifl96i000012ulw8mnjzhr',
    firstName: 'Kathryn'
  }
];

class LiveChatStandalone extends Component {
  render() {
    return (
      <div className="live-chat-standalone">
        <div className="live-chat-fullscreen-copy">
          <Header>
            Chat with a Matching Guide
            <Header.Subheader>
              Our Matching Guides can answer your questions and match you to the
              best guide for your specific life challenges and stressors. Start
              the conversation by sending us a message.
            </Header.Subheader>
          </Header>
          <Divider />
          <div className="guides">
            <Header>The LifeGuides Chat Team</Header>
            <ul>{GUIDES.map(this._renderGuide)}</ul>
          </div>
          <div className="hours">
            <Header size="small">Hours of Operation</Header>
            <dl>
              <dt>Monday - Friday</dt>
              <dd>6am - 6pm PST</dd>

              <dt>Saturday</dt>
              <dd>6am - 10am PST</dd>

              <dt>Sunday</dt>
              <dd>10am - 3pm PST</dd>
            </dl>
          </div>
        </div>
      </div>
    );
  }

  _renderGuide = (guide, i) => {
    return (
      <li key={`guide-${i}`} className="guide">
        <Avatar user={guide} />
        <span className="name">{guide.firstName}</span>
      </li>
    );
  };
}
export default LiveChatStandalone;
