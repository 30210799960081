import { useQuery } from '@apollo/client';
import qs from 'qs';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';

import { ROLES } from '../../../../consts';
import AllCompaniesQuery from '../../../../graphql/queries/all-companies.graphql';
import history from '../../../../history';
import useUser from '../../../hooks/use-user';
import List from './list';
import SearchBar from './search-bar';

const ROLE_OPTIONS = [
  {
    key: 'all',
    text: 'All Roles',
    value: 'All Roles'
  },
  {
    key: 'clients',
    text: 'Members',
    value: ROLES.CLIENT
  },
  {
    key: 'guest',
    text: 'Guests',
    value: ROLES.GUEST
  },
  {
    key: 'guides',
    text: 'Guides',
    value: ROLES.GUIDE
  },
  {
    key: 'banned',
    text: 'Banned',
    value: ROLES.BANNED
  }
];

function AdminUsers() {
  const { data, error, loading } = useQuery(AllCompaniesQuery);

  const { data: userData } = useUser();

  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  function onCompanyChange(event, { value }) {
    const { companyId, ...remaining } = search;

    history.replace({
      pathname: location.pathname,
      search: qs.stringify({
        ...remaining,
        page: 1,
        ...(value === 'All Companies' ? null : { companyId: value })
      })
    });
  }

  function onRoleChange(event, { value }) {
    const { roles, ...remaining } = search;

    history.replace({
      pathname: location.pathname,
      search: qs.stringify({
        ...remaining,
        page: 1,
        ...(value === 'All Roles' ? null : { roles: value.split(',') })
      })
    });
  }

  function renderCompanyOptions() {
    if (error) {
      return [{ key: 'error', text: error.message, value: '' }];
    }
    if (data) {
      return [
        {
          key: 'all',
          text: 'All Companies',
          value: 'All Companies'
        }
      ].concat(
        data.companies
          .filter((company) => !company.isDeleted)
          .map((company) => {
            return {
              key: company.id,
              text: company.name,
              value: company.id
            };
          })
      );
    }
    return [];
  }

  function renderFilters() {
    if (!userData || !userData.User) {
      return null;
    }

    const isAdmin = userData.User.roles.some(
      (role) => role.name === ROLES.ADMIN
    );
    if (!isAdmin) {
      return null;
    }

    return (
      <>
        <Dropdown
          button
          basic
          floating
          options={ROLE_OPTIONS}
          defaultValue={search.roles ? search.roles.join(',') : 'All Roles'}
          onChange={onRoleChange}
        />
        <Dropdown
          button
          basic
          floating
          loading={loading}
          options={renderCompanyOptions()}
          defaultValue={search.companyId || 'All Companies'}
          onChange={onCompanyChange}
        />
      </>
    );
  }

  return (
    <div className="w-full p-4">
      <h1>Users</h1>
      <SearchBar>{renderFilters()}</SearchBar>
      <List />
    </div>
  );
}
export default AdminUsers;
