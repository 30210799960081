const ERRORS = [/*'NotAllowedError', */ 'NotFoundError'];

export default function makeStream(constraints) {
  if (navigator.mediaDevices) {
    return navigator.mediaDevices.getUserMedia(constraints).catch((error) => {
      if (ERRORS.includes(error.name)) {
        return navigator.mediaDevices.getUserMedia({
          audio: constraints.audio
        });
      } else {
        throw error;
      }
    });
  }

  const getUserMedia =
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia;

  if (!getUserMedia) {
    return null;
  }

  return new Promise((resolve, reject) =>
    getUserMedia.call(navigator, constraints, resolve, (error) => {
      if (ERRORS.includes(error.name)) {
        getUserMedia.call(
          navigator,
          { audio: constraints.audio },
          resolve,
          reject
        );
      } else {
        reject(error);
      }
    })
  );
}
