import './index.css';

import { graphql } from '@apollo/client/react/hoc';
import { debounce } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Label, Segment } from 'semantic-ui-react';

import { ROLES } from '../../../consts';
import CreateOrUpdateSessionNoteMutation from '../../../graphql/mutations/create-or-update-session-note.graphql';
import ClientSessionNotesQuery from '../../../graphql/queries/client-session-notes.graphql';
import ClientSessionNoteCreatedOrUpdatedSubscription from '../../../graphql/subscriptions/client-session-note-created-or-updated.graphql';
import withUser from '../../hoc/with-user';
import RichTextEditor from '../../ui/rich-text-editor';
import SessionNotesDialog from '../session/session-notes-dialog';

@withUser({ authenticated: [ROLES.GUIDE] })
@graphql(ClientSessionNotesQuery, {
  name: 'clientSessionNotes',
  options: ({ session: { client } }) => ({ variables: { clientId: client.id } })
})
@graphql(CreateOrUpdateSessionNoteMutation, {
  name: 'createOrUpdateSessionNote',
  options: ({ clientSessionNotes: { variables } }) => {
    return {
      update: (proxy, { data: { upsertSessionNote } }) => {
        const data = proxy.readQuery({
          query: ClientSessionNotesQuery,
          variables
        });

        const index = data.sessionNotes.findIndex(
          (sessionNote) => sessionNote.id === upsertSessionNote.id
        );
        if (index >= 0) {
          data.sessionNotes.splice(index, 1, upsertSessionNote);
        } else {
          data.sessionNotes.push(upsertSessionNote);
        }

        proxy.writeQuery({ query: ClientSessionNotesQuery, variables, data });
      }
    };
  }
})
class SessionNotes extends Component {
  static propTypes = {
    clientSessionNotes: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      sessionNotes: PropTypes.array,
      subscribeToMore: PropTypes.func.isRequired,
      variables: PropTypes.object
    }),
    session: PropTypes.shape({
      id: PropTypes.string.isRequired,
      client: PropTypes.shape({
        id: PropTypes.string
      })
    }).isRequired,
    createOrUpdateSessionNote: PropTypes.func.isRequired,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  };

  constructor(props) {
    super(props);

    const note = this._getNote(props);

    this.state = {
      sessionNotesOpen: false,
      dirty: false,
      submitting: false,
      value: (note && note.text) || ''
    };
  }

  componentDidMount() {
    this._subscribeToMore();
  }

  componentDidUpdate(prevProps) {
    const { dirty, submitting } = this.state;

    if (submitting) {
      return;
    }

    const oldNote = this._getNote(prevProps);
    const newNote = this._getNote(this.props);

    if (!newNote) {
      return;
    }

    if (!oldNote || moment(newNote.updatedAt).isAfter(oldNote.updatedAt)) {
      if (dirty) {
        this.setState({ dirty: false });
      } else {
        this.setState({ value: newNote.text });
      }
    }
  }

  componentWillUnmount() {
    if (this._unsubscribeClientSessionNotes) {
      this._unsubscribeClientSessionNotes();
    }
  }

  _getNote(props) {
    const { session } = props;
    const { sessionNotes } = props.clientSessionNotes;

    if (!sessionNotes) {
      return null;
    }

    return sessionNotes.find(
      (note) => note.session && note.session.id === session.id
    );
  }

  _subscribeToMore() {
    const { clientSessionNotes } = this.props;

    this._unsubscribeClientSessionNotes = clientSessionNotes.subscribeToMore({
      document: ClientSessionNoteCreatedOrUpdatedSubscription,
      variables: clientSessionNotes.variables,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }

        const { node } = subscriptionData.data.sessionNote;
        const sessionNotes = prev.sessionNotes.slice(0);

        const index = sessionNotes.findIndex((note) => note.id === node.id);
        if (index >= 0) {
          sessionNotes.splice(index, 1, node);
        } else {
          sessionNotes.push(node);
        }

        return {
          ...prev,
          sessionNotes
        };
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.log('subscription error', error);
      }
    });
  }

  render() {
    const { loading } = this.props.clientSessionNotes;
    const { value } = this.state;

    if (loading) {
      return <Segment loading />;
    }

    return (
      <div className="session-notes">
        <RichTextEditor format="html" value={value} onChange={this._onChange} />

        {this._renderPreviousNotes()}
      </div>
    );
  }

  _renderPreviousNotes() {
    const { sessionNotes } = this.props.clientSessionNotes;
    const { User } = this.props.user;
    const { session } = this.props;
    const { sessionNotesOpen } = this.state;

    if (!sessionNotes) {
      return null;
    }

    const previousSessionNotes = sessionNotes.filter(
      (sessionNote) => sessionNote.session.id !== session.id
    );

    if (!previousSessionNotes.length) {
      return null;
    }

    return (
      <Segment
        className="previous-notes"
        onClick={() => {
          this.setState({ sessionNotesOpen: true });
        }}>
        Previous Call Notes
        <Label circular color="blue">
          {previousSessionNotes.length}
        </Label>
        <SessionNotesDialog
          session={session}
          sessionNotes={previousSessionNotes}
          client={session.client}
          user={User}
          open={sessionNotesOpen}
          onClose={() => {
            this.setState({ sessionNotesOpen: false });
          }}
        />
      </Segment>
    );
  }

  _onChange = (newValue) => {
    const { value } = this.state;

    if (newValue === value) {
      return;
    }

    this.setState({ dirty: true, value: newValue }, () => {
      this._updateOrCreate(newValue);
    });
  };

  _updateOrCreate = debounce((value) => {
    const { sessionNotes } = this.props.clientSessionNotes;
    const { session, createOrUpdateSessionNote } = this.props;
    const { submitting } = this.state;

    if (submitting) {
      return;
    }

    const note =
      sessionNotes &&
      sessionNotes.find((note) => note.session.id === session.id);

    const variables = {
      id: (note && note.id) || '',
      sessionId: session.id,
      text: value
    };

    this.setState({ submitting: true }, () => {
      createOrUpdateSessionNote({
        variables
      }).then(() => {
        this.setState({ submitting: false });
      });
    });
  }, 500);
}
export default SessionNotes;
