import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Modal } from 'semantic-ui-react';

import AchievementByIdQuery from '../../../../../../graphql/queries/achievement-by-id.graphql';
import ErrorMessage from '../../../../../ui/error-message';
import LoadingSpinner from '../../../../../ui/loading-spinner';
import List from './list';
import Search from './search';

function AchievementGuidesDialog(props) {
  const { onClose, open } = props;

  const [page, setPage] = useState(1);

  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { data, error, loading } = useQuery(AchievementByIdQuery, {
    variables: { id: search.id }
  });

  function onPageChange(event, { activePage }) {
    setPage(activePage);
  }

  function renderContent() {
    if (error) {
      return <ErrorMessage error={error} />;
    }

    if (loading) {
      return <LoadingSpinner className="w-20 h-20 mx-auto" />;
    }

    return (
      <>
        <Search page={page} />
        <List page={page} onPageChange={onPageChange} />
      </>
    );
  }

  return (
    <>
      <Modal closeIcon open={open} onClose={onClose}>
        <Modal.Header>
          {data ? `${data.achievement.title} Guides` : null}
        </Modal.Header>
        <Modal.Content>{renderContent()}</Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Nevermind</Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
AchievementGuidesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool
};
export default AchievementGuidesDialog;
