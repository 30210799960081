import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

import { ROLES } from '../../../../consts';
import withUser from '../../../hoc/with-user';
import ResourceHeader from '../../../ui/resource-header';
import UserImports from './user-imports';

@withRouter
@withUser({ authenticated: [ROLES.ADMIN] })
class BulkImport extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  render() {
    return this._renderUserImports();
  }

  _renderUserImports() {
    const { history } = this.props;

    return (
      <div className="user-imports">
        <ResourceHeader
          title="Bulk Import History"
          parentPath={`/admin/users`}
        />
        <Button
          primary
          floated="right"
          onClick={() => {
            history.push('/admin/users/imports/upload');
          }}>
          <Icon name="plus" />
          Upload CSV
        </Button>
        <br />
        <UserImports />
      </div>
    );
  }
}
export default BulkImport;
