import EventEmitter from 'events';

import defer from 'lodash/defer';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import * as serviceWorker from '../serviceWorkerRegistration';

const UPDATE_INTERVAL = 1000 * 60 * 60;
const RELOAD_TIMEOUT = 30000;

const eventEmitter = new EventEmitter();
let registration = null;
let reloading = false;
let timeout = null;

function parseVersion(string) {
  if (!isString(string) || !/^\d+\.\d+(\.\d+)?$/.test(string)) {
    return {
      major: 0,
      minor: 0,
      patch: 0
    };
  }
  const [major, minor, patch] = string.split('.');
  return {
    major,
    minor,
    patch
  };
}

function getManifest() {
  return fetch('/manifest.json')
    .then((response) => response.json())
    .catch(() => ({}));
}

function startReload() {
  if (reloading) {
    return;
  }
  reloading = true;

  eventEmitter.emit('beforeReload');

  defer(() => {
    if (!reloading) {
      return;
    }
    eventEmitter.emit('startReload', RELOAD_TIMEOUT);
    timeout = window.setTimeout(reloadNow, RELOAD_TIMEOUT);
  });
}

function cancelReload() {
  reloading = false;

  if (timeout) {
    window.clearTimeout(timeout);
    timeout = null;
    eventEmitter.emit('cancelReload');
  }
}

function reloadNow() {
  window.location.reload();
}

serviceWorker.register({
  onRegister: (reg) => {
    registration = reg;
  },
  onUpdate: () => {
    if (!registration.waiting) {
      return;
    }

    registration.waiting.postMessage({
      type: 'SKIP_WAITING'
    });

    getManifest().then((manifest) => {
      const currentVersion = parseVersion(process.env.REACT_APP_VERSION);
      const incomingVersion = parseVersion(manifest.version);

      if (incomingVersion.major <= currentVersion.major) {
        return;
      }

      startReload();
    });
  }
});

function updateRegistration() {
  if (registration) {
    registration.update();
  }
}

window.setInterval(updateRegistration, UPDATE_INTERVAL);

export const childContextTypes = {
  addBeforeReloadListener: PropTypes.func,
  addCancelReloadListener: PropTypes.func,
  addStartReloadListener: PropTypes.func,
  cancelReload: PropTypes.func,
  reloadNow: PropTypes.func,
  removeBeforeReloadListener: PropTypes.func,
  removeCancelReloadListener: PropTypes.func,
  removeStartReloadListener: PropTypes.func,
  startReload: PropTypes.func,
  updateRegistration: PropTypes.func
};

class ServiceWorkerProvider extends Component {
  static propTypes = {
    children: PropTypes.node
  };

  static childContextTypes = childContextTypes;

  getChildContext() {
    return {
      addBeforeReloadListener: (func) => eventEmitter.on('beforeReload', func),
      addCancelReloadListener: (func) => eventEmitter.on('cancelReload', func),
      addStartReloadListener: (func) => eventEmitter.on('startReload', func),
      cancelReload,
      reloadNow,
      removeBeforeReloadListener: (func) =>
        eventEmitter.off('beforeReload', func),
      removeCancelReloadListener: (func) =>
        eventEmitter.off('cancelReload', func),
      removeStartReloadListener: (func) =>
        eventEmitter.off('startReload', func),
      startReload,
      updateRegistration
    };
  }

  render() {
    return React.Children.only(this.props.children);
  }
}
export default ServiceWorkerProvider;
