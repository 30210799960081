import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router';

import EditDialog from './edit';

const Dialogs = [
  {
    key: 'edit',
    component: EditDialog
  }
];

@withRouter
class AdminCompanyDialogs extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    parentPath: PropTypes.string.isRequired
  };

  render() {
    return Dialogs.map(this._renderDialog);
  }

  _renderDialog = ({ key, component: Component }) => {
    const { match } = this.props;

    const open = key === match.params.name;

    return (
      <Component key={`dialog-${key}`} open={open} onClose={this._onClose} />
    );
  };

  _onClose = () => {
    const { history, location, parentPath } = this.props;

    const search = qs.parse(location.search, { ignoreQueryPrefix: true });
    const url = search.returnUrl || parentPath;

    history.push({ pathname: url, search: '' });
  };
}
export default AdminCompanyDialogs;
