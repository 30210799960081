import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import { AVAILABILITY_DAYS, AVAILABILITY_TIMES } from '../../../../consts';
import { useEditing } from './actions';

function GuideProfileAvailabilities(props) {
  const { data, onChange } = props;

  const [isEditing] = useEditing();

  const availabilities = get(data, 'availabilities', []);

  if (!availabilities.length && !isEditing) {
    return null;
  }

  function onAvailabilityDaysClick(days) {
    const newAvailabilities = [...data.availabilities];
    const index = newAvailabilities.findIndex(
      (availability) => availability.days === days
    );
    if (index >= 0) {
      newAvailabilities.splice(index, 1);
    } else {
      newAvailabilities.push({
        days,
        timesOfDay: []
      });
    }

    onChange(newAvailabilities);
  }

  function onAvailabilityTimesClick(availability, times) {
    const newAvailabilities = [...data.availabilities];
    const index = newAvailabilities.indexOf(availability);

    const newTimesOfDay = [...availability.timesOfDay];
    const i = newTimesOfDay.indexOf(times);
    if (i >= 0) {
      newTimesOfDay.splice(i, 1);
    } else {
      newTimesOfDay.push(times);
    }

    newAvailabilities.splice(index, 1, {
      ...availability,
      timesOfDay: newTimesOfDay
    });

    onChange(newAvailabilities);
  }

  function renderAvailabilityCheckbox({ isSelected, label, onClick }) {
    const iconClasses = ['icon square large bg-white'];
    if (isSelected) {
      iconClasses.push('check text-primary');
    } else {
      iconClasses.push('outline text-gray-400');
    }

    return (
      <button
        className="flex flex-row my-1 space-x-5 items-center focus:outline-none"
        onClick={onClick}>
        <i className={iconClasses.join(' ')} style={{ margin: '0' }} />{' '}
        <span className="text-left text-lg">{capitalize(label)}</span>
      </button>
    );
  }

  function renderAvailabilities() {
    if (isEditing) {
      return Object.keys(AVAILABILITY_DAYS).map((days) => {
        const availability = data.availabilities.find(
          (availability) => availability.days === days
        );
        const isDaySelected = !!availability;
        const button = renderAvailabilityCheckbox({
          isSelected: isDaySelected,
          label: days,
          onClick: () => onAvailabilityDaysClick(days)
        });
        return isDaySelected ? (
          Object.keys(AVAILABILITY_TIMES).map((times, i) => {
            const isSelected = availability.timesOfDay.includes(times);
            return (
              <tr key={days + times} className="align-top">
                {i === 0 && (
                  <td rowSpan={Object.keys(AVAILABILITY_TIMES).length}>
                    {button}
                  </td>
                )}
                <td>
                  {renderAvailabilityCheckbox({
                    isSelected,
                    label: times,
                    onClick: () => onAvailabilityTimesClick(availability, times)
                  })}
                </td>
              </tr>
            );
          })
        ) : (
          <tr key={days}>
            <td>{button}</td>
          </tr>
        );
      });
    }

    return availabilities.map(({ days, timesOfDay }, index) => {
      const padding = index > 0 ? 'md:pt-4' : '';
      return (
        <tr key={days} className="flex flex-col md:table-row">
          <td className={`align-top ${padding}`}>
            <span className="text-lg">{capitalize(days)}</span>
          </td>
          <td className={padding}>
            <ul className="list-disc list-inside">
              {timesOfDay.map((times) => (
                <li key={times} className="text-lg">
                  {capitalize(times)}
                </li>
              ))}
            </ul>
          </td>
        </tr>
      );
    });
  }

  return (
    <table className="my-1 mx-4">
      <tbody>{renderAvailabilities()}</tbody>
    </table>
  );
}
GuideProfileAvailabilities.propTypes = {
  data: PropTypes.shape({
    availabilities: PropTypes.arrayOf(
      PropTypes.shape({
        days: PropTypes.string,
        timesOfDay: PropTypes.arrayOf(PropTypes.string)
      })
    ).isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired
};
export default GuideProfileAvailabilities;
