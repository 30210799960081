import { Button } from '@windmill/react-ui';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useCallback } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { ROLES } from '../../../../consts';
import history from '../../../../history';
import useUser from '../../../hooks/use-user';
import LoadingSpinner from '../../../ui/loading-spinner/index';
import { DIALOGS, useDialogs } from './dialogs';

export function useEditing() {
  const location = useLocation();
  const match = useRouteMatch();

  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  const callback = useCallback(
    (editing) => {
      const search = qs.parse(location.search, {
        arrayLimit: 512,
        ignoreQueryPrefix: true
      });

      const { edit, ...remaining } = search;

      if (editing === edit) {
        return;
      }

      return history.replace({
        pathname: match.url,
        search: qs.stringify({
          ...remaining,
          ...(editing ? { edit: true } : null)
        })
      });
    },
    [location, match]
  );

  return [!!search.edit, callback];
}

function GuideProfileActions(props) {
  const { dirty, guideProfile, onPublish, onSubmit, submitting } = props;

  const match = useRouteMatch();

  const [isEditing, setEditing] = useEditing();

  const [, setDialog] = useDialogs();

  const { data: userData } = useUser();

  function hasRole(name) {
    return get(userData, 'User.roles', []).some((role) => role.name === name);
  }

  const isAdmin = hasRole(ROLES.ADMIN);
  const isGuideAdmin = hasRole(ROLES.GUIDE_ADMIN);
  const isGuideContentManager = hasRole(ROLES.GUIDE_CONTENT_MANAGER);
  const isUser = get(userData, 'User.id') === match.params.id;

  const canEdit = isAdmin || isGuideAdmin || isGuideContentManager || isUser;
  const canPublish = isAdmin || isGuideAdmin || isGuideContentManager;
  const isPublished = get(guideProfile, 'status') === 'PUBLISHED';

  function onEditClick() {
    setEditing(true);
  }

  function onCancelClick() {
    setEditing(false);
  }

  function onAssignAchievementsClick() {
    setDialog(DIALOGS.ASSIGN_ACHIEVEMENTS);
  }

  function onRevisionsClick() {
    setDialog(DIALOGS.REVISIONS);
  }

  function onSetGuideStatusClick() {
    setDialog(DIALOGS.GUIDE_STATUS);
  }

  const actions = [
    {
      button: (
        <Button
          key="achievements"
          layout="outline"
          className="bg-white"
          onClick={onAssignAchievementsClick}>
          Assign Achievements
        </Button>
      ),
      shouldShow:
        (isAdmin || isGuideAdmin || isGuideContentManager) && !isEditing
    },
    {
      button: (
        <Button
          key="status"
          layout="outline"
          className="bg-white"
          onClick={onSetGuideStatusClick}>
          Set Guide Status
        </Button>
      ),
      shouldShow: (isAdmin || isGuideAdmin) && !isEditing
    },
    {
      button: (
        <Button
          key="revisions"
          layout="outline"
          iconLeft={() => <i className="icon lineawesome list" />}
          className="bg-white"
          onClick={onRevisionsClick}>
          Revisions
        </Button>
      ),
      shouldShow: canEdit && !isEditing
    },
    {
      button: (
        <Button
          key="edit"
          layout="outline"
          iconLeft={() => <i className="icon lineawesome pencil" />}
          className="bg-white"
          onClick={onEditClick}>
          Edit
        </Button>
      ),
      shouldShow: canEdit && !isEditing
    },
    {
      button: (
        <Button
          key="publish"
          iconLeft={() => <i className="icon lineawesome save" />}
          className="bg-white"
          onClick={onPublish}>
          {submitting ? (
            <>
              <LoadingSpinner className="w-6 h-6" />
              &nbsp;
            </>
          ) : (
            'Publish'
          )}
        </Button>
      ),
      shouldShow:
        canPublish &&
        guideProfile &&
        guideProfile.id &&
        !isPublished &&
        !isEditing
    },
    {
      button: (
        <Button
          key="cancel"
          layout="outline"
          iconLeft={() => <i className="icon lineawesome times" />}
          className="bg-white"
          disabled={submitting}
          onClick={onCancelClick}>
          Cancel
        </Button>
      ),
      shouldShow: canEdit && isEditing
    },
    {
      button: (
        <Button
          key="save"
          iconLeft={() => <i className="icon lineawesome save" />}
          className="bg-white"
          disabled={!dirty || submitting}
          onClick={onSubmit}>
          {submitting ? (
            <>
              <LoadingSpinner className="w-6 h-6" />
              &nbsp;
            </>
          ) : (
            'Save'
          )}
        </Button>
      ),
      shouldShow: canEdit && isEditing
    }
  ];

  const buttons = actions
    .filter(({ shouldShow }) => shouldShow)
    .map(({ button }) => button);

  if (!buttons.length) {
    return null;
  }

  return (
    <div className="absolute z-10 top-0 right-0 p-4 m-6 flex flex-row space-x-4 bg-white bg-opacity-50 rounded-full">
      {buttons}
    </div>
  );
}
GuideProfileActions.propTypes = {
  dirty: PropTypes.bool,
  guideProfile: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string
  }),
  onPublish: PropTypes.func,
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool
};
export default GuideProfileActions;
