import PropTypes from 'prop-types';
import React from 'react';

import Avatar from '../../../ui/avatar';
import ImageDropzone from '../../../ui/image-dropzone';
import { useEditing } from './actions';

function GuideAvatar(props) {
  const { data, onChange } = props;
  const { guide } = data;

  const [isEditing] = useEditing();

  const s = Math.floor(160 / 7);

  const onDemandIcon = guide.isPresent ? (
    <div className={`light-holder show`}>
      <div
        className="light-anchor"
        style={{
          width: 200,
          height: 200,
          left: `calc(50% - ${200 / 2}px)`,
          cursor: 'pointer'
        }}>
        <div
          className="featured"
          style={{
            top: `-${s / 2}px`,
            right: `calc(50% - ${s / 2}px`,
            width: `${s}px`,
            height: `${s}px`
          }}
        />
      </div>
    </div>
  ) : (
    <div className="light-holder">
      <div
        className="light-anchor"
        style={{
          width: '200px',
          height: '200px',
          left: `calc(50% - ${250 / 2}px)`
        }}></div>
    </div>
  );

  const avatar = (
    <div className="avi-frame">
      {onDemandIcon}
      <Avatar
        user={data}
        style={{
          width: '200px',
          height: '200px'
        }}
      />
    </div>
  );

  if (isEditing) {
    return <ImageDropzone onDrop={onChange}>{avatar}</ImageDropzone>;
  }
  return avatar;
}
GuideAvatar.propTypes = {
  data: PropTypes.shape({
    avatarUrl: PropTypes.string,
    guide: PropTypes.shape({
      isPresent: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired
};
export default GuideAvatar;
