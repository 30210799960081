import { useMutation, useQuery } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@windmill/react-ui';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import UpdateUserAchievementsMutation from '../../../../../graphql/mutations/update-user-achievements.graphql';
import AchievementsByNamePaginationQuery from '../../../../../graphql/queries/achievements-by-name-pagination.graphql';
import AchievementsPaginationQuery from '../../../../../graphql/queries/achievements-pagination.graphql';
import GuideByIdQuery from '../../../../../graphql/queries/guide-by-id.graphql';
import history from '../../../../../history';
import ErrorDialog from '../../../../ui/error-dialog';
import ErrorMessage from '../../../../ui/error-message';
import LoadingSpinner from '../../../../ui/loading-spinner';
import GuideContext from '../../guide-context';

const PAGE_SIZE = 10;

const inputRef = React.createRef();

function GuideAchievementsSelectDialog(props) {
  const { guide, onClose } = props;

  const location = useLocation();
  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(search.searchTerm || '');
  const [selectedAchievements, setSelectedAchievments] = useState([
    ...guide.achievements
  ]);
  const [submitting, setSubmitting] = useState(false);

  const added = selectedAchievements.filter(({ id }) =>
    guide.achievements.every((a) => a.id !== id)
  );
  const removed = guide.achievements.filter(({ id }) =>
    selectedAchievements.every((a) => a.id !== id)
  );
  const isValid = !!(added.length || removed.length);

  const variables = {
    first: PAGE_SIZE,
    skip: search.page ? (search.page - 1) * PAGE_SIZE : 0,
    searchTerm: search.searchTerm
  };
  const {
    data: achievementsData,
    error: achievementsError,
    loading,
    refetch
  } = useQuery(
    search.searchTerm
      ? AchievementsByNamePaginationQuery
      : AchievementsPaginationQuery,
    {
      variables
    }
  );

  const [updateUserAchievements] = useMutation(UpdateUserAchievementsMutation, {
    refetchQueries: [{ query: GuideByIdQuery, variables: { id: guide.id } }]
  });

  const setSearchParam = debounce(function (value) {
    history.replace({
      pathname: location.pathname,
      search: qs.stringify({
        ...search,
        searchTerm: value
      })
    });
  }, 500);

  function onSearchChange({ target: { value } }) {
    setSearchTerm(value);
    setSearchParam(value);
  }

  function onClickAchievement(topic) {
    const newSelectedAchievements = [...selectedAchievements];
    const index = newSelectedAchievements.findIndex((t) => t.id === topic.id);
    if (index >= 0) {
      newSelectedAchievements.splice(index, 1);
    } else {
      newSelectedAchievements.push(topic);
    }
    setSelectedAchievments(newSelectedAchievements);
  }

  function onSubmit() {
    const connect = added.map(({ id }) => ({ id }));
    const disconnect = removed.map(({ id }) => ({ id }));

    const variables = {
      userId: guide.id,
      achievements: {
        ...(connect.length ? { connect } : null),
        ...(disconnect.length ? { disconnect } : null)
      }
    };

    setError(null);
    setSubmitting(true);
    updateUserAchievements({ variables })
      .then(() => {
        setSubmitting(false);
        onClose();
      })
      .catch((error) => {
        setError(error);
        setSubmitting(false);
        Bugsnag.notify(error, function (event) {
          event.context = 'ProfileView._onSelectAchievements';
          event.request.variables = variables;
        });
      });
  }

  function renderSearch() {
    return (
      <div className="relative my-2 flex flex-row items-center">
        <label>Search:</label>&nbsp;
        <Input ref={inputRef} value={searchTerm} onChange={onSearchChange} />
      </div>
    );
  }

  function renderAchievement(achievement) {
    const { id, imageUrl, title } = achievement;

    const isOnGuide = guide.achievements.some((a) => a.id === id);
    const isSelected = selectedAchievements.some((t) => t.id === id);

    const iconClasses = ['icon square large bg-white'];
    if (isSelected) {
      iconClasses.push('check text-primary');
    } else {
      iconClasses.push('outline');
      iconClasses.push(isOnGuide ? 'text-red-400' : 'text-gray-400');
    }

    return (
      <TableRow
        key={id}
        className="cusror-pointer"
        onClick={() => {
          onClickAchievement(achievement);
        }}>
        <TableCell>
          <i className={iconClasses.join(' ')} />
        </TableCell>
        <TableCell>
          <img src={imageUrl} alt={title} className="w-8" />
        </TableCell>
        <TableCell>{title}</TableCell>
      </TableRow>
    );
  }

  function renderContent() {
    if (achievementsError) {
      return <ErrorMessage error={achievementsError} retry={() => refetch()} />;
    }
    if (loading) {
      return <LoadingSpinner className="w-48 h-48 mx-auto" />;
    }

    const { achievements } = achievementsData;
    return (
      <>
        <Table>
          <TableBody>{achievements.map(renderAchievement)}</TableBody>
        </Table>
      </>
    );
  }

  return (
    <>
      <Modal isOpen={true} onClose={onClose}>
        <ModalHeader>Assign Aschievements</ModalHeader>
        <ModalBody>
          {renderSearch()}
          {renderContent()}
          <Pagination
            {...(achievementsData &&
            achievementsData.achievementsConnection.aggregate.count > PAGE_SIZE
              ? {}
              : { className: 'hidden' })}
            totalResults={
              achievementsData
                ? achievementsData.achievementsConnection.aggregate.count
                : 0
            }
            resultsPerPage={PAGE_SIZE}
            onChange={(page) => {
              history.replace({ search: qs.stringify({ ...search, page }) });
            }}
            label="Page navigation"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            className="w-full sm:w-auto"
            layout="outline"
            onClick={onClose}>
            Nevermind
          </Button>
          <Button
            className="w-full sm:w-auto"
            disabled={!isValid || submitting}
            onClick={onSubmit}>
            {submitting ? (
              <>
                <LoadingSpinner className="w-6 h-6" />
                &nbsp;
              </>
            ) : (
              'Apply Changes'
            )}
          </Button>
        </ModalFooter>
      </Modal>
      <ErrorDialog
        error={error}
        onClose={() => {
          setError(null);
        }}
      />
    </>
  );
}
GuideAchievementsSelectDialog.propTypes = {
  guide: PropTypes.shape({
    id: PropTypes.string,
    achievements: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        imageUrl: PropTypes.string,
        title: PropTypes.string
      })
    )
  }),
  onClose: PropTypes.func
};
function GuideAchievementsSelectDialogGuideContext(props) {
  return (
    <GuideContext.Consumer>
      {(guide) => <GuideAchievementsSelectDialog guide={guide} {...props} />}
    </GuideContext.Consumer>
  );
}
export default GuideAchievementsSelectDialogGuideContext;
