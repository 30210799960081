import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Icon, Input } from 'semantic-ui-react';

import history from '../../../../history';

const updateSearchFilter = debounce((filter, search) => {
  history.replace({
    pathname: location.pathname,
    search: qs.stringify({ ...search, filter, page: 0 })
  });
}, 500);

function UserSearchBar(props) {
  const { children } = props;

  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [filter, setFilter] = useState(search.filter || '');

  function onSearchChange({ target: { value } }) {
    setFilter(value);
    updateSearchFilter(value, search);
  }

  return (
    <Input
      icon="search"
      iconPosition="left"
      placeholder="Search..."
      fluid
      value={filter}
      onChange={onSearchChange}
      action={!!children}>
      <Icon name="search" className="lineawesome" />
      <input />
      {children}
    </Input>
  );
}
UserSearchBar.propTypes = {
  children: PropTypes.node
};
export default UserSearchBar;
