import './index.css';

import PropTypes from 'prop-types';
import React from 'react';

function LoadingSpinner(props) {
  const { className } = props;

  const classNames = 'loading-spinner ease-linear rounded-full border-4 border-gray-200'.split(
    ' '
  );
  const uniqueClassNames = new Set(
    className ? classNames.concat(className.split(' ')) : classNames
  );

  return <div className={Array.from(uniqueClassNames).join(' ')}></div>;
}
LoadingSpinner.propTypes = {
  className: PropTypes.string
};
export default LoadingSpinner;
