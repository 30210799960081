import './beta.css';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';

export default class BetaPage extends PureComponent {
  static propTypes = {
    content: PropTypes.node,
    icon: PropTypes.string,
    title: PropTypes.string
  };

  render() {
    const { title, icon, content } = this.props;
    return (
      <div className="beta-container">
        <Segment className="beta">
          <Header as="h2" icon>
            <Icon name={icon} />
            {title}
            <Header.Subheader>
              Currently, {title} is still in beta mode and is an upcoming
              feature.
            </Header.Subheader>
          </Header>
          <br />
          <p>{content}</p>
        </Segment>
      </div>
    );
  }
}
