import { useMutation, useQuery } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Confirm, Icon, Pagination, Table } from 'semantic-ui-react';

import UpdateUserDisconnectAchievementMutation from '../../../../../../graphql/mutations/update-user-disconnect-achievement.graphql';
import GuidesByAchievementsPaginationQuery from '../../../../../../graphql/queries/guides-by-achievements-pagination.graphql';
import Avatar from '../../../../../ui/avatar';
import ErrorDialog from '../../../../../ui/error-dialog';
import LoadingSpinner from '../../../../../ui/loading-spinner/index';
import { PAGE_SIZE, locationToVariables } from './params';

function AchievementGuidesList(props) {
  const { onPageChange, page } = props;

  const [error, setError] = useState(null);
  const [removingGuide, setRemovingGuide] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const location = useLocation();

  const { data, loading } = useQuery(GuidesByAchievementsPaginationQuery, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: locationToVariables(location, page)
  });

  const [disconnectGuide] = useMutation(
    UpdateUserDisconnectAchievementMutation,
    {
      refetchQueries: [
        {
          query: GuidesByAchievementsPaginationQuery,
          variables: locationToVariables(location, page)
        }
      ]
    }
  );

  function onConfirmRemove() {
    const search = qs.parse(location.search, { ignoreQueryPrefix: true });

    const variables = {
      userId: removingGuide.id,
      achievementId: search.id
    };

    setError(null);
    setSubmitting(true);
    disconnectGuide({ variables })
      .then(() => {
        setRemovingGuide(null);
        setSubmitting(false);
      })
      .catch((error) => {
        setError(error);
        setSubmitting(false);
        Bugsnag.notify(error, function (event) {
          event.context = 'AdminAchievementsGuidesList._onRemoveClick';
          event.request.variables = variables;
        });
      });
  }

  function renderGuide(guide) {
    return (
      <Table.Row key={guide.id}>
        <Table.Cell>
          <Avatar user={guide} />
        </Table.Cell>
        <Table.Cell>{guide.firstName}</Table.Cell>
        <Table.Cell>{guide.lastName}</Table.Cell>
        <Table.Cell textAlign="right">
          <Button
            disabled={submitting}
            onClick={() => {
              setRemovingGuide(guide);
            }}>
            <Icon name="delete" />
            Remove
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }

  function renderPagination() {
    const { usersConnection } = data;

    const count = usersConnection ? usersConnection.aggregate.count : 0;
    const totalPages = Math.ceil(count / PAGE_SIZE);

    return (
      <Pagination
        activePage={page}
        ellipsisItem={{
          content: <Icon name="ellipsis horizontal" className="lineawesome" />,
          icon: true
        }}
        firstItem={null}
        lastItem={null}
        prevItem={
          count > PAGE_SIZE
            ? {
                content: <Icon name="angle left" className="lineawesome" />,
                icon: true
              }
            : null
        }
        nextItem={
          count > PAGE_SIZE
            ? {
                content: <Icon name="angle right" className="lineawesome" />,
                icon: true
              }
            : null
        }
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    );
  }

  function renderRemoveConfirmation() {
    if (!removingGuide) {
      return null;
    }

    return (
      <Confirm
        open
        content={`Are you sure you want to remove ${removingGuide.firstName} from this achievement?`}
        onCancel={() => {
          setRemovingGuide(null);
        }}
        onConfirm={onConfirmRemove}
      />
    );
  }

  if (loading) {
    return <LoadingSpinner className="w-20 h-20 mx-auto" />;
  }

  return (
    <>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>{data.users.map((guide) => renderGuide(guide))}</Table.Body>
      </Table>
      {renderPagination()}
      {renderRemoveConfirmation()}
      <ErrorDialog
        error={error}
        onClose={() => {
          setError(null);
        }}
      />
    </>
  );
}
AchievementGuidesList.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired
};
export default AchievementGuidesList;
