import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import MyForm from '../../../../ui/form';

const CHARACTER_LIMIT = 300;

class ReasonForm extends Component {
  static propTypes = {
    onChange: PropTypes.func
  };

  state = {
    reason: ''
  };

  render() {
    const { reason } = this.state;

    return (
      <MyForm
        className="call-request-form-call-reason-input my-form-container"
        data={{ reason }}>
        <div className="field">
          <label>Reason for call</label>
          <textarea
            className="field-cell fluid"
            value={reason}
            onChange={this._onChange}
          />
        </div>
        <span className="characters">
          {CHARACTER_LIMIT - reason.length} characters remaining
        </span>
      </MyForm>
    );
  }

  _onChange = ({ target: { value } }) => {
    const { onChange } = this.props;

    const reason = value.substr(0, CHARACTER_LIMIT);

    this.setState({ reason });

    if (isFunction(onChange)) {
      onChange({ reason });
    }
  };
}
export default ReasonForm;
