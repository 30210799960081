import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Confirm, Header, Message, Table } from 'semantic-ui-react';

import CompanyByIdQuery from '../../../../../graphql/queries/company-by-id.graphql';
import AddMenuItemDialog from '../dialogs/edit-menu-item';

const DIALOGS = {
  EDIT: 'EDIT',
  REMOVE: 'REMOVE'
};

function CompanyMenuItems({ onChange, submitting }) {
  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { data } = useQuery(CompanyByIdQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !search.id,
    variables: { id: search.id }
  });

  const [dialog, setDialog] = useState(null);
  const [menuItem, setMenuItem] = useState(null);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const { company } = data;
    if (company.menuItems.length) {
      setMenuItems(company.menuItems);
    }
  }, [data]);

  useEffect(() => {
    if (!onChange) {
      return;
    }
    onChange(menuItems);
  }, [menuItems, onChange]);

  const onAddClick = useCallback(() => {
    setDialog(DIALOGS.EDIT);
  }, []);

  const onEditClick = useCallback((menuItem) => {
    setMenuItem(menuItem);
    setDialog(DIALOGS.EDIT);
  }, []);

  const onRemoveClick = useCallback((menuItem) => {
    setMenuItem(menuItem);
    setDialog(DIALOGS.REMOVE);
  }, []);

  const onClose = useCallback(() => {
    setMenuItem(null);
    setDialog(null);
  }, []);

  const onSubmit = useCallback(
    (data) => {
      const updated = menuItems.slice(0);
      if (menuItem) {
        const index = updated.findIndex((m) => m.id === menuItem.id);
        if (index >= 0) {
          updated.splice(index, 1, {
            id: menuItem.id,
            label: data.label,
            url: data.url
          });
        }
      } else {
        updated.push({
          label: data.label,
          url: data.url
        });
      }
      setMenuItems(updated);
      onClose();
    },
    [menuItem, menuItems, onClose]
  );

  const onRemove = useCallback(() => {
    if (!menuItem) {
      return;
    }
    const index = menuItems.findIndex((m) => m.id === menuItem.id);
    if (index >= 0) {
      const updated = menuItems.slice(0);
      updated.splice(index, 1);
      setMenuItems(updated);
    }
    onClose();
  }, [menuItem, menuItems, onClose]);

  function renderMenuItem(menuItem) {
    const { label, url } = menuItem;
    return (
      <tr key={`${label}-${url}`}>
        <td>{label}</td>
        <td>{url}</td>
        <td>
          <Button onClick={() => onEditClick(menuItem)}>Edit</Button>
        </td>
        <td>
          <Button onClick={() => onRemoveClick(menuItem)}>Remove</Button>
        </td>
      </tr>
    );
  }

  function renderContent() {
    if (!data) {
      return null;
    }
    return (
      <div>
        <div style={{ marginBottom: '2em' }}>
          <Button primary floated="right" onClick={onAddClick}>
            Add
          </Button>
          <Header>Menu Items</Header>
        </div>
        {menuItems.length ? (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Label</Table.HeaderCell>
                <Table.HeaderCell>URL</Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>{menuItems.map(renderMenuItem)}</Table.Body>
          </Table>
        ) : (
          <Message>No menu items for this company</Message>
        )}
      </div>
    );
  }

  function renderAddDialog() {
    return (
      <AddMenuItemDialog
        menuItem={menuItem}
        onClose={onClose}
        onSubmit={onSubmit}
        submitting={submitting}
      />
    );
  }

  function renderConfirmRemoveDialog() {
    if (!menuItem) {
      return;
    }
    return (
      <Confirm
        open
        content={`Are you sure you want to remove menu item "${menuItem.label}"?`}
        onCancel={onClose}
        onConfirm={onRemove}
      />
    );
  }

  function renderDialog() {
    switch (dialog) {
      case DIALOGS.EDIT:
        return renderAddDialog();
      case DIALOGS.REMOVE:
        return renderConfirmRemoveDialog();
      default:
        return null;
    }
  }

  return (
    <div>
      {renderContent()}
      {renderDialog()}
    </div>
  );
}
CompanyMenuItems.propTypes = {
  onChange: PropTypes.func,
  submitting: PropTypes.bool
};
export default CompanyMenuItems;
