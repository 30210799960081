import qs from 'qs';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import history from '../../../../../history';

export const DIALOGS = {
  ADD_TOPICS: 'ADD_TOPICS',
  ASSIGN_ACHIEVEMENTS: 'ASSIGN_ACHIEVMENTS',
  AVATAR_CROPPER: 'AVATAR_CROPPER',
  CONFIRM_SESSION: 'CONFIRM_SESSION',
  GUIDE_STATUS: 'GUIDE_STATUS',
  REGISTER: 'REGISTER',
  REQUESTING_SESSION: 'REQUESTING_SESSION',
  REVISIONS: 'REVISIONS',
  SCHEDULE: 'SCHEDULE',
  VIDEO: 'VIDEO'
};

export function useDialogs() {
  const location = useLocation();
  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  const callback = useCallback(
    (name) => {
      const search = qs.parse(location.search, {
        arrayLimit: 512,
        ignoreQueryPrefix: true
      });
      const { dialog, ...remaining } = search;

      if (dialog === name) {
        return;
      }

      return history.replace({
        pathname: location.pathname,
        search: qs.stringify({
          ...remaining,
          ...(name ? { dialog: name } : null)
        })
      });
    },
    [location]
  );

  return [search.dialog, callback];
}
