import { Button, Modal, ModalBody } from '@windmill/react-ui';
import get from 'lodash/get';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { SchedulingSuccess } from '../../../../../assets';
import history from '../../../../../history';
import useUser from '../../../../hooks/use-user';
import { CALL_LENGTH } from '../../../session';
import AddToCalendar from '../../../session/add-to-calendar';
import GuideContext from '../../guide-context';

function ConfirmSessionDialog(props) {
  const { guide, isOpen, onClose, session } = props;

  const { data: userData } = useUser();

  const timezone = get(userData, 'User.timezone', moment.tz.guess());

  function onClickSession() {
    history.push({
      pathname: '/my-sessions',
      search: ''
    });
  }

  const scheduledTime = moment(session.scheduledTime).tz(timezone);

  return (
    <Modal
      className="scheduler-dialog max-h-full overflow-y-auto w-full px-10 py-4 bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-6xl"
      isOpen={isOpen}
      onClose={onClose}>
      <ModalBody>
        <div className="flex flex-col-reverse md:flex-row md:space-x-4">
          <div className="flex flex-col items-start space-y-8 text-xl">
            <h2 className="text-4xl font-bold">Congratulations!</h2>
            <div>
              <span>Your session with {guide.firstName} is confirmed for</span>
              <span className="block font-bold">
                {scheduledTime.format('dddd, MMM Do')} -{' '}
                {scheduledTime.format('h:mm A')} to{' '}
                {scheduledTime
                  .clone()
                  .add(CALL_LENGTH, 'minutes')
                  .format('h:mm A')}
              </span>
            </div>
            <div>
              <h3>What to expect</h3>
              <ul className="list-disc list-inside">
                <li>
                  We&apos;ll send you an email confirmation and text message if
                  you opt in.
                </li>
                <li>You can add the session invite to your calendar.</li>
                <li>
                  You&apos;ll receive a reminder with a direct link to your
                  session.
                </li>
              </ul>
            </div>
            <div>
              <h3>Tips</h3>
              <ul className="list-disc list-inside">
                <li>
                  Login 5 minutes before your session to test your audio and
                  video.
                </li>
                <li>Keep a pencil and paper handy to take any notes.</li>
                <li>
                  If you need to reschedule your session, go to the{' '}
                  <span className="italic">My Sessions</span> section on the
                  LifeGuides platform.
                </li>
              </ul>
            </div>
            <AddToCalendar session={session} />
            <Button onClick={onClickSession}>View your session details</Button>
          </div>
          <img
            className="mb-8 md:mb-0 w-96 object-contain"
            src={SchedulingSuccess}
            alt="Success!"
          />
        </div>
      </ModalBody>
    </Modal>
  );
}
ConfirmSessionDialog.propTypes = {
  guide: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string
  }).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  session: PropTypes.shape({
    id: PropTypes.string,
    scheduledTime: PropTypes.string
  }).isRequired
};
function ConfirmSessionDialogWithGuideContext(props) {
  return (
    <GuideContext.Consumer>
      {(guide) => <ConfirmSessionDialog guide={guide} {...props} />}
    </GuideContext.Consumer>
  );
}
export default ConfirmSessionDialogWithGuideContext;
