import React from 'react';
import Bugsnag from '@bugsnag/js';
import moment from 'moment-timezone';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Dropdown, Label, Popup, Table } from 'semantic-ui-react';
import Avatar from '../../../ui/avatar/index';
import { STATUSES } from './params';
import dialogs from './dialogs';
import FIRST_SESSION_FOR_CLIENT from '../../../../graphql/queries/first-session-for-client.graphql';
import FIRST_COMPLETED_SESSION_FOR_CLIENT from '../../../../graphql/queries/first-completed-session-for-client.graphql';
import formatSeconds from '../../../../utils/formatSeconds';

const STATUS_DESCRIPTION = {
  CANCELLED: 'This session was cancelled',
  COMPLETED: 'This session has been marked as completed by the guide',
  CONNECTING:
    'The guide has accepted this on demand session but both parties have not yet joined',
  ERRORED: 'An error occurred',
  IN_PROGRESS: 'Both the guide and member joined the session',
  MISSED:
    "The guide didn't accept this on demand session before the 30 second timeout was reached",
  RESCHEDULED: 'This session has been rescheduled to another time',
  REQUESTING:
    'The member has requested an on demand session but it has not yet been accepted or timed out',
  UPCOMING: 'The session has been scheduled for a specific date and time'
};

SessionRow.propTypes = {
  session: PropTypes.object.isRequired,
  openDialog: PropTypes.func.isRequired
};
function SessionRow({ session, openDialog }) {
  const {
    client,
    createdAt,
    duration,
    guide,
    scheduledTime,
    startedAt,
    status,
    topics,
    updatedAt
  } = session;

  const {
    data: firstSessionData,
    loading: firstSessionLoading,
    error: firstSessionError
  } = useQuery(FIRST_SESSION_FOR_CLIENT, {
    variables: { clientId: client.id }
  });

  const {
    data: firstCompletedSessionData,
    loading: firstCompletedSessionLoading,
    error: firstCompletedSessionError
  } = useQuery(FIRST_COMPLETED_SESSION_FOR_CLIENT, {
    variables: { clientId: client.id }
  });

  let isFirstSession = false;
  let isFirstCompletedSession = false;

  if (firstSessionLoading || firstCompletedSessionLoading) {
    return <div>Loading...</div>;
  }

  if (!firstSessionError) {
    isFirstSession = firstSessionData.sessions[0]?.id === session.id;
  } else {
    Bugsnag.notify(firstSessionError, function (event) {
      event.context = 'SessionRow.FIRST_SESSION_FOR_CLIENT';
      event.request.variables = { clientId: client.id };
    });
  }

  if (!firstCompletedSessionError) {
    isFirstCompletedSession =
      firstCompletedSessionData.sessions[0]?.id === session.id;
  } else {
    Bugsnag.notify(firstSessionError, function (event) {
      event.context = 'SessionRow.FIRST_COMPLETED_SESSION_FOR_CLIENT';
      event.request.variables = { clientId: client.id };
    });
  }

  // Only show one first session ribbon label
  if (isFirstCompletedSession) {
    isFirstSession = false;
  }

  const canCancel = ![
    'CANCELLED',
    'COMPLETED',
    'ERRORED',
    'MISSED',
    'RESCHEDULED'
  ].includes(status);

  const canReschedule = ['UPCOMING'].includes(status);

  const _renderTopicsCell = (topics) => {
    const topicsLabels = topics.map((topic) => (
      <Label key={topic.id} className="topic-label" color="teal">
        {topic.title}
      </Label>
    ));
    const showMorePopup = (
      <Popup content={topicsLabels} trigger={<Label>...</Label>} />
    );
    const firstThreeTopicsLabels = topicsLabels.slice(0, 3);

    return (
      <>
        {firstThreeTopicsLabels}
        {topics.length > 3 && showMorePopup}
      </>
    );
  };

  const topicsCell = _renderTopicsCell(topics);

  const statusPopupContent = () => {
    if (status === STATUSES.CANCELLED && session.logs[0]) {
      const { firstName, lastName, roles } = session.logs[0].createdBy;
      const name =
        firstName && lastName ? `${firstName} ${lastName[0]}` : roles[0].name;

      return `${STATUS_DESCRIPTION[status]}${name && ` by ${name}`}.`;
    }

    return STATUS_DESCRIPTION[status];
  };

  return (
    <Table.Row key={`session-${session.id}`}>
      <Table.Cell>
        {isFirstSession && (
          <Label ribbon color="blue">
            First Session
          </Label>
        )}
        {isFirstCompletedSession && (
          <Label ribbon color="green">
            First Completed Session
          </Label>
        )}
        <Avatar user={client} /> {client?.firstName}{' '}
        {client?.lastName?.[0]?.concat('.') || ''}{' '}
      </Table.Cell>
      <Table.Cell>
        {client.timezone && (
          <Label>{moment.tz(client.timezone).format('z')}</Label>
        )}
      </Table.Cell>
      <Table.Cell>
        {client.companyIds[0] && <div>{client.companyIds[0].company.name}</div>}
      </Table.Cell>
      <Table.Cell>
        <Avatar user={guide} /> {guide?.firstName}{' '}
        {guide?.lastName?.[0]?.concat('.') || ''}{' '}
      </Table.Cell>
      <Table.Cell>
        {guide.timezone && (
          <Popup
            trigger={
              <Label
                basic={client.timezone !== guide.timezone}
                color={client.timezone !== guide.timezone ? 'red' : null}>
                {moment.tz(guide.timezone).format('z')}
              </Label>
            }
            content={
              client.timezone !== guide.timezone
                ? 'Guide and Member are in the different Timezones.'
                : 'Guide and Member are in the same Timezone.'
            }
          />
        )}
      </Table.Cell>
      <Table.Cell>{topicsCell}</Table.Cell>
      <Table.Cell>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start'
          }}>
          <Label style={{ marginRight: 4 }}>
            {moment.tz(client.timezone).format('z')}
          </Label>
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'space-between'
            }}>
            <Popup
              content="This was the time the session record was created"
              trigger={
                <Label color="green">
                  {moment.tz(createdAt, client.timezone).format('M/D/YY h:mma')}
                </Label>
              }
            />
          </div>
        </div>
      </Table.Cell>
      <Table.Cell>
        {scheduledTime && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}>
            <Label style={{ marginRight: 4 }}>
              {moment.tz(client.timezone).format('z')}
            </Label>
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'space-between'
              }}>
              <Popup
                content="This was the chosen time between member and guide"
                trigger={
                  <Label color="green">
                    {moment
                      .tz(scheduledTime, client.timezone)
                      .format('M/D/YY h:mma')}
                  </Label>
                }
              />
            </div>
          </div>
        )}
      </Table.Cell>
      <Table.Cell>
        {startedAt && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}>
            <Label style={{ marginRight: 4 }}>
              {moment.tz(client.timezone).format('z')}
            </Label>
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'space-between'
              }}>
              <Popup
                content="This time when both members were first on the session"
                trigger={
                  <Label color="green">
                    {moment
                      .tz(startedAt, client.timezone)
                      .format('M/D/YY h:mma')}
                  </Label>
                }
              />
            </div>
          </div>
        )}
      </Table.Cell>
      <Table.Cell>
        {duration && <div>{formatSeconds(duration)}</div>}
      </Table.Cell>
      <Table.Cell>
        {updatedAt && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}>
            <Label style={{ marginRight: 4 }}>
              {moment.tz(client.timezone).format('z')}
            </Label>
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'space-between'
              }}>
              <Popup
                content="The time of last activity on this session"
                trigger={
                  <Label color="green">
                    {moment
                      .tz(updatedAt, client.timezone)
                      .format('M/D/YY h:mma')}
                  </Label>
                }
              />
            </div>
          </div>
        )}
      </Table.Cell>
      <Table.Cell>
        <Popup
          content={statusPopupContent()}
          trigger={
            <Label color="blue">{startCase(status.replace('_', ' '))}</Label>
          }
        />
      </Table.Cell>
      <Table.Cell>
        {(canCancel || canReschedule) && (
          <Dropdown icon="cog" direction="left">
            <Dropdown.Menu>
              {canCancel && (
                <Dropdown.Item
                  text="Cancel"
                  onClick={() => {
                    openDialog(dialogs.CANCEL_SESSION, session);
                  }}
                />
              )}
              {canReschedule && (
                <Dropdown.Item
                  text="Reschedule"
                  onClick={() => {
                    openDialog(dialogs.RESCHEDULE_SESSION, session);
                  }}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Table.Cell>
    </Table.Row>
  );
}

export default SessionRow;
