import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Dropdown, Input } from 'semantic-ui-react';

import TopicByIdQuery from '../../../../../graphql/queries/topic-by-id.graphql';
import TopicGroupsQuery from '../../../../../graphql/queries/topic-groups.graphql';
import MyForm, { FormField } from '../../../../ui/form';

function mapData(topic) {
  return {
    description: '',
    groupIds: [],
    imageUrl: '',
    topic: '',
    ...(topic && {
      groupIds: topic.groups.map((group) => group.id),
      description: topic.description,
      imageUrl: topic.imageUrl,
      title: topic.title
    })
  };
}

@withRouter
@graphql(TopicByIdQuery, {
  name: 'topic',
  options: ({ location }) => {
    const search = qs.parse(location.search, { ignoreQueryPrefix: true });
    const variables = {
      id: search.id
    };
    return { variables };
  }
})
@graphql(TopicGroupsQuery, { name: 'groups' })
class AdminTopicEditForm extends Component {
  static propTypes = {
    groups: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      topicGroups: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string
        })
      )
    }).isRequired,
    location: PropTypes.object.isRequired,
    onDataChange: PropTypes.func,
    onValidate: PropTypes.func,
    topic: PropTypes.shape({
      topic: PropTypes.shape({
        id: PropTypes.string,
        description: PropTypes.string,
        groups: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string
          })
        ),
        imageUrl: PropTypes.string,
        title: PropTypes.string
      }),
      loading: PropTypes.bool.isRequired
    }).isRequired
  };

  constructor(props) {
    super(props);

    const { topic } = this.props.topic;

    this.state = {
      data: mapData(topic)
    };
  }

  componentDidUpdate(prevProps) {
    const { topic } = this.props.topic;

    if (topic !== prevProps.topic.topic) {
      const data = mapData(topic);
      this.setState({ data });
    }
  }

  render() {
    const { onValidate } = this.props;
    const { loading } = this.props.groups;
    const { data } = this.state;

    return (
      <MyForm data={data} onChange={this._onDataChange} onValidate={onValidate}>
        <FormField
          component={Input}
          name="title"
          label="Title"
          type="text"
          autoComplete="off"
          validator={({ title }) => {
            if (!title) {
              throw new Error('Title is required');
            }
          }}
        />
        <FormField
          component={Input}
          name="description"
          label="Description"
          type="text"
          autoComplete="off"
          validator={({ description }) => {
            if (!description) {
              throw new Error('Description is required');
            }
          }}
        />
        <FormField
          component={Dropdown}
          name="groupIds"
          label="Groups"
          fluid
          selection
          loading={loading}
          multiple
          options={this._groupsToOptions()}
        />
      </MyForm>
    );
  }

  _groupsToOptions() {
    const { topicGroups } = this.props.groups;

    if (!topicGroups) {
      return [];
    }

    return topicGroups.map((group) => ({
      key: `group-${group.id}`,
      text: group.title,
      value: group.id
    }));
  }

  _onDataChange = (data) => {
    const { onDataChange } = this.props;
    const { topic } = this.props.topic;

    this.setState({ data });

    if (onDataChange) {
      const connectGroupIds = data.groupIds.filter(
        (id) => !topic || !topic.groups.some((group) => group.id === id)
      );
      const disconnectGroupIds = topic
        ? topic.groups
            .filter((group) => !data.groupIds.includes(group.id))
            .map((group) => group.id)
        : [];

      onDataChange({
        ...data,
        connectGroupIds: connectGroupIds.map((id) => ({ id })),
        disconnectGroupIds: disconnectGroupIds.map((id) => ({ id }))
      });
    }
  };
}
export default AdminTopicEditForm;
