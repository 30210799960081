
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"InvoiceInfo"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Invoice"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"attemptCount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"attempted"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"collectionMethod"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"currency"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"nextPaymentAttempt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"paid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"periodEnd"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"periodStart"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"status"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"total"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":195}};
    doc.loc.source = {"body":"fragment InvoiceInfo on Invoice {\n  id\n  attemptCount\n  attempted\n  collectionMethod\n  createdAt\n  currency\n  description\n  nextPaymentAttempt\n  paid\n  periodEnd\n  periodStart\n  status\n  total\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
