import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import countries from 'react-select-country-list';
import { Checkbox, Dropdown, Form, Input } from 'semantic-ui-react';

import ScrollToTop from '../../../components/routes/scroll-to-top';
import MyForm, { FormField, PhoneNumberInput } from '../../ui/form';
import PrivacyPolicy from '../../ui/privacy-policy/dialog';
import TermsAndConditions from '../../ui/terms-and-conditions/dialog';

export default class GuideApplicationForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
  };

  state = {
    data: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      city: '',
      state: '',
      phoneNumber: '',
      agreedToTermsAndConditions: false,
      showTermsAndConditions: false,
      showPrivacyPolicy: false
    },
    isValid: false
  };

  render() {
    const { onSubmit, submitting } = this.props;
    const { data, isValid } = this.state;

    return (
      <ScrollToTop>
        <MyForm
          data={data}
          onChange={(data) => {
            this.setState({ data });
          }}
          onValidate={(errors) => {
            this.setState({ isValid: isEmpty(errors) });
          }}>
          <Form.Group widths="equal">
            <FormField
              component={Input}
              name="firstName"
              placeholder="First Name"
              aria-label="First Name"
              aria-required="true"
              validator={({ firstName }) => {
                if (!firstName) {
                  throw new Error('First name is required');
                }
              }}
            />
            <FormField
              component={Input}
              name="lastName"
              aria-label="Last Name"
              placeholder="Last Name"
              aria-required="true"
              validator={({ lastName }) => {
                if (!lastName) {
                  throw new Error('Last name is required');
                }
              }}
            />
          </Form.Group>
          <FormField
            component={Input}
            name="emailAddress"
            type="email"
            aria-label="Email"
            placeholder="Email"
            aria-required="true"
            validator={({ emailAddress }) => {
              if (!emailAddress) {
                throw new Error('Must enter an e-mail address');
              }
            }}
          />
          <Form.Group widths="equal">
            <FormField
              component={Input}
              name="city"
              placeholder="City"
              aria-label="City"
              aria-required="true"
              validator={({ city }) => {
                if (!city) {
                  throw new Error('Must enter a city');
                }
              }}
            />
            <FormField
              component={Input}
              name="state"
              placeholder="State/Territory"
              aria-required="true"
              validator={({ state }) => {
                if (!state) {
                  throw new Error('Must enter a state');
                }
              }}
            />
            <FormField
              component={Dropdown}
              name="country"
              placeholder="Country"
              aira-required="true"
              fluid
              selection
              search
              options={countries()
                .getData()
                .map(({ label, value }) => ({
                  key: value,
                  text: label,
                  value
                }))}
              validator={({ country }) => {
                if (!country) {
                  throw new Error('Must select a country');
                }
              }}
            />
          </Form.Group>
          <FormField
            component={PhoneNumberInput}
            name="phoneNumber"
            aria-required="true"
            aria-label="Personal Mobile Number"
            placeholder="Personal Mobile Number"
            validator={({ phoneNumber }) => {
              if (!phoneNumber) {
                throw new Error('Must enter a phone number');
              }
            }}
          />
          <FormField
            component={Checkbox}
            name="agreedToTermsAndConditions"
            aria-required="true"
            aria-label="Check to agree to the LifeGuides Terms and Conditions."
            label={
              <label>
                I am 18 (or older) and agree to the{' '}
                <a
                  href="#"
                  onClick={() => {
                    this.setState({
                      showTermsAndConditions: true
                    });
                  }}>
                  Terms & Conditions
                </a>{' '}
                and{' '}
                <a
                  href="#"
                  onClick={() => {
                    this.setState({
                      showPrivacyPolicy: true
                    });
                  }}>
                  Privacy Policy
                </a>
              </label>
            }
            validator={({ agreedToTermsAndConditions }) => {
              if (!agreedToTermsAndConditions) {
                throw new Error('Must agree to terms and conditions');
              }
            }}
          />
          <Form.Button
            primary
            loading={submitting}
            disabled={!isValid || submitting}
            onClick={() => {
              onSubmit(data);
            }}>
            Sign Up
          </Form.Button>
          <PrivacyPolicy
            visible={this.state.showPrivacyPolicy}
            onClose={() => {
              this.setState({
                showPrivacyPolicy: false
              });
            }}
          />
          <TermsAndConditions
            visible={this.state.showTermsAndConditions}
            onClose={() => {
              this.setState({
                showTermsAndConditions: false
              });
            }}
          />
        </MyForm>
      </ScrollToTop>
    );
  }
}
