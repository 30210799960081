import React from 'react';
export function GetStartedStepTitle({ stepNumber, title, description }) {
  return (
    <div className="flex flex-row items-center">
      <span className="text-primary text-7xl p-8">{stepNumber}</span>
      <div className="p-4">
        <h2 className="py-4 text-5xl font-extrabold text-center text-neutral lg:text-left">
          {title}
        </h2>
        <span className="text-xl">{description}</span>
      </div>
    </div>
  );
}
