import { graphql } from '@apollo/client/react/hoc';
import result from 'lodash/result';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import ChannelBySlugQuery from '../../graphql/queries/channel-by-slug.graphql';

function getSlug({ location, match }) {
  let slug = match.params.channelName;
  if (!slug) {
    // For child routes that don't know parent route params
    // May be a better solution
    const channelRegex = new RegExp('^\\/([\\w-]+)\\/');
    let match = location.pathname.match(channelRegex);
    if (match) {
      slug = match[1];
    }
  }
  return slug;
}

export default function withChannel(options = {}) {
  return function componentWrapper(Component) {
    @withRouter
    @graphql(ChannelBySlugQuery, {
      name: 'channel',
      skip: (props) => !result(options, 'slug') && !getSlug(props),
      options: (props) => ({
        variables: { slug: result(options, 'slug') || getSlug(props) }
      })
    })
    class WithUser extends React.Component {
      static displayName = `withChannel(${
        Component.displayName || Component.name
      })`;

      static propTypes = {
        channel: PropTypes.shape({
          channel: PropTypes.shape({
            id: PropTypes.string,
            isPublished: PropTypes.bool,
            slug: PropTypes.string
          }),
          loading: PropTypes.bool
        }),
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
          params: PropTypes.shape({
            channelName: PropTypes.string
          }).isRequired
        }).isRequired
      };

      render() {
        const { channel, history } = this.props;

        if (channel) {
          if (channel.loading) {
            if (options.loader) {
              return options.loader;
            }
          } else if (channel.channel && channel.channel.isPublished) {
            //localStorage.setItem(LAST_CHANNEL_KEY, channel.channel.slug);
          } else if (
            !options.preventRedirect &&
            (!channel.channel || !channel.channel.isPublished)
          ) {
            history.replace('/');
          } else {
            if (options.required) {
              history.replace('/404');
              return null;
            }
          }
        }

        return <Component {...this.props} />;
      }
    }

    return WithUser;
  };
}
