import './index.css';

import React from 'react';

export default function PrivacyPolicyCopy() {
  return (
    <>
      <h1 className="pp-title">LifeGuides Privacy Policy</h1>
      <p className="updated-date">Last updated March 1, 2024</p>
      <p className="pp-section">
        LifeGuides, PBC, a public benefit corporation (“we” or “us” or “our”)
        respects the privacy of our users (“user” or “you”). This Privacy Policy
        explains how we collect, use, disclose, and safeguard your information
        when you visit our website www.lifeguides.com and our mobile
        application, and any other media form, media channel, mobile website, or
        mobile application related or connected thereto, or use any services
        provided through our website or such media forms (collectively, the
        “Site”). Please read this Privacy Policy carefully. IF YOU DO NOT AGREE
        WITH THE TERMS OF THIS PRIVACY POLICY, YOU MAY NOT ACCESS OR USE THE
        SITE.
      </p>
      <p className="pp-section">
        We reserve the right to make changes to this Privacy Policy at any time.
        We will alert you about any changes by updating the “Revised” date of
        this Privacy Policy. Any changes or modifications will be effective
        immediately upon posting the updated Privacy Policy on the Site, and you
        waive the right to receive specific notice of each such change or
        modification. You are encouraged to periodically review this Privacy
        Policy to stay informed of updates. You will be deemed to have been made
        aware of, will be subject to, and will be deemed to have accepted the
        changes in any revised Privacy Policy by use of the Site ten (10)
        business days after the date such revised Privacy Policy is posted.
      </p>
      <p className="pp-section">
        “Data Privacy Laws” mean laws and regulations relating to data privacy,
        transborder data flow and/or data protection, data breaches, and
        information security, as applicable to customer data processed under the
        terms of our site, including, without limitation, the Gramm Leach Bliley
        Act of 1999 (“GLBA”) and its implementing regulations, the Fair and
        Accurate Credit Transactions Act of 2003 (“FACTA”), the Health Insurance
        Portability and Accountability Act of 1996 and its implementing
        regulations (45 C.F.R. Parts 160 and 164) (“HIPAA”) (if applicable), the
        implementing legislation and regulations of the European Union member
        states under the European Union General Data Protection Regulation
        (“GDPR”) (if applicable), the California Consumer Privacy Act (“CCPA”)
        (if applicable) and all other similar federal, state or international
        laws (if applicable) and regulations relating to data privacy,
        transborder data flows and/or data protection, data breaches or
        information security.
      </p>
      <p className="pp-section">
        LifeGuides will comply with all applicable Data Privacy Laws.
      </p>
      <h2 className="pp-subtitle">USE OF YOUR INFORMATION</h2>
      <p className="pp-section">
        Having accurate information about you permits us to provide you with a
        smooth, efficient, and customized experience. Specifically, we may use
        information collected about you via the Site to:
      </p>
      <ul>
        <li>
          Provide and support the LifeGuides services, which are generally
          offered via your employer and often paid in full by your employer.
        </li>
        <li>Create and manage your account.</li>
        <li>
          Generate a personal profile about you to make future visits to the
          Site more personalized.
        </li>
        <li>Email or text you regarding your account or services.</li>
        <li>
          Fulfill and manage purchases, orders, payments, and other transactions
          related to the Site.
        </li>
        <li>Make improvements to the LifeGuides services.</li>
        <li>
          Monitor and analyze usage and trends to improve your experience.
        </li>
        <li>
          Notify you of updates to the Site, as well as provide customer
          service.
        </li>
        <li>Offer new products, services, and/or recommendations to you.</li>
        <li>
          Administer promotions, plus micro-activities for intended behavioral
          change.
        </li>
        <li>Resolve disputes and troubleshoot problems.</li>
        <li>Make improvements to the services provided</li>
      </ul>
      <p className="pp-section">
        We also use anonymized, non-personal information collected from our
        users in the aggregate to improve our services and for other business
        and administrative purposes.
      </p>
      <h2 className="pp-subtitle">Our Data Partners</h2>
      <p className="pp-section">
        We use the following data partners to store your digital data related to
        providing services to any individual uses our services, such as an
        employee of a client enterprise. We do not let any of these partners
        sell or otherwise use your personal data for their gain.
      </p>
      <ul>
        <li>
          Heroku, a wholly-owned subsidiary of SalesForce. See:{' '}
          <a href="https://www.heroku.com/policy/security">
            https://www.heroku.com/policy/security
          </a>
        </li>
        <li>
          Stripe. (if you are paying directly as an individual) See:{' '}
          <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>
        </li>
        <li>
          Intercom: See:{' '}
          <a href="https://www.intercom.com/legal/privacy">
            https://www.intercom.com/legal/privacy
          </a>
        </li>
        <li>
          Twillio. See:{' '}
          <a href="https://www.twilio.com/legal/privacy">
            https://www.twilio.com/legal/privacy
          </a>
        </li>
        <li>
          Google Drive. See:{' '}
          <a href="https://www.google.com/drive/terms-of-service/">
            https://www.google.com/drive/terms-of-service/
          </a>
        </li>
        <li>
          Dropbox. See:{' '}
          <a href="https://www.dropbox.com/privacy">
            https://www.dropbox.com/privacy
          </a>
        </li>
        <li>
          Sentry:{' '}
          <a href="https://sentry.io/privacy/ ">https://sentry.io/privacy/</a>
        </li>
        <li>
          Grafana:{' '}
          <a href="https://grafana.com/legal/privacy-policy/ ">
            https://grafana.com/legal/privacy-policy/{' '}
          </a>
        </li>
        <li>
          Neon.Tech:{' '}
          <a href="hhttps://neon.tech/privacy-policy  ">
            https://neon.tech/privacy-policy{' '}
          </a>
        </li>
        <li>
          Clerk:{' '}
          <a href="https://clerk.com/legal/privacy ">
            https://clerk.com/legal/privacy{' '}
          </a>
        </li>
      </ul>
      <h2 className="pp-subtitle">
        Prompt Deletion of Your Data – Upon Your Request
      </h2>
      <p className="pp-section">
        Within five business days of your written request or a call to our
        customer service, LifeGuides will delete all your personally
        identifiable data. Of note, if you are using our services as part of an
        employer-provided plan, your employer might continue to provide us
        periodic data from their updated “employee census” for all covered
        Participants via our services provided under your employer’s plan. In
        such case, we will make commercially reasonable efforts to delete such
        data, even if it is provided on a date subsequent to your request for
        data deletion. Importantly, since spouses or civil unions are covered
        under our employer plans, your request will not delete your spouse or
        civil union’s account and data, which that person needs to request
        separately.
      </p>
      <h2 className="pp-subtitle">COLLECTION OF INFORMATION</h2>
      <p className="pp-section">
        We may collect information about you in a variety of ways. The
        information we may collect on the Site includes:
      </p>
      <h3 className="pp-small-title">Personal Data You Provide</h3>
      <p className="pp-section">
        Personally identifiable information, such as your name, shipping
        address, email address, and telephone number, and demographic
        information, such as your age, gender, hometown, and interests, that you
        voluntarily, or any other party, provides to us when you register with
        the Site, or when you choose to participate in various activities
        related to the Site, such as online chat and message boards, or when an
        employer, or other relationship, pays for you to access our services as
        a benefit of such relationship. Furthermore, your Guide™ will
        periodically collect information during any phone calls and some of that
        information is entered into our database, so that, among other purposes,
        we can optimize the delivery of content and services. You are under no
        obligation to provide us with personal information of any kind, but your
        refusal to do so might prevent you from using certain features of the
        Site.
      </p>
      <h3 className="pp-small-title">Financial Data We Collect From You</h3>
      <p className="pp-section">
        Financial information, such as data related to your payment method (e.g.
        valid credit card number, card brand, expiration date) that we may
        collect when you purchase, order, return, exchange, or request
        information about our services from the Site. We do not store any
        financial information that we collect from individual users. All
        financial information is stored by our payment processors: PayPal and
        Stripe, and you are encouraged to review their privacy policies and
        contact them directly for responses to your questions.
      </p>

      <h3 className="pp-small-title">Data From Third-Party Social Networks</h3>
      <p className="pp-section">
        User information from social networking sites, such as Facebook,
        Google+, including your name, your social network username, location,
        preferred gender, birth date, email address, profile picture, and public
        data for contacts, if you link or connect your account to such social
        networks. This information may also include the contact information of
        anyone you invite to use and/or join our Site.
      </p>
      <h3 className="pp-small-title">
        Data From Contests, Giveaways, and Surveys
      </h3>
      <p className="pp-section">
        Personal and other information you may provide when entering contests or
        giveaways and/or responding to surveys.
      </p>
      <h3 className="pp-small-title">Mobile Application Information</h3>
      <p className="pp-section">If you connect using our mobile application:</p>
      <ul>
        <li>
          Mobile Device Access. We may request access or permission to certain
          features from your mobile device, including your mobile device’s
          microphone, camera, location data, SMS messages, and other features.
          If you wish to change our access or permissions, you may do so in your
          device’s settings.
        </li>
        <li>
          Mobile Device Data. We may collect usage data from your mobile device,
          including the following types of information: operating system, device
          serial number, log files, firmware version, battery and signal
          strength, settings, error rates, session counts, technical
          specifications, pages viewed, time spent viewing pages, time spent in
          mobile application, features used, internet and mobile data connection
          information, browser type, language and time zone, mobile phone number
          and IP address.
        </li>
      </ul>
      <h2 className="pp-subtitle">TRACKING TECHNOLOGIES</h2>
      <p className="pp-section">
        We also collect information through the use of tracking software on our
        Site and our mobile application in the following ways:
      </p>
      <h3 className="pp-small-title">Cookies</h3>
      <p className="pp-section">
        We may use cookies, web beacons, tracking pixels, and other tracking
        technologies on the Site to help customize the Site and improve your
        experience. For more information on how we use cookies, please refer to
        our Cookie Policy posted on the Site, which is incorporated into this
        Privacy Policy. By using the Site, you consent to the terms of our
        Cookie Policy.
      </p>
      <h3 className="pp-small-title">Website Analytics</h3>
      <p className="pp-section">
        We may also partner with selected third-party vendors, such as Google
        Analytics, to allow tracking technologies and remarketing services on
        the Site through the use of first party cookies and third party cookies,
        to, among other things, analyze and track users’ use of the Site,
        determine the popularity of certain content and better understand online
        activity. By accessing the Site, you consent to the collection and use
        of your information by these third party vendors. You are encouraged to
        review their privacy policy and contact them directly for responses to
        your questions.
      </p>
      <p className="pp-section">
        We do not transfer personal information to these third party vendors.
        However, If you do not want any information to be collected and used by
        tracking technologies, you can visit the third-party vendor or Network
        <a href="https://developers.facebook.com/docs/permissions">
          Advertising Initiative Opt-Out Tool
        </a>{' '}
        or
        <a href="https://optout.aboutads.info/?c=2&lang=EN">
          Digital Advertising Alliance Opt-Out Tool
        </a>
        . Please be aware that getting a new computer, installing a new browser,
        upgrading an existing browser, or erasing or otherwise altering your
        browser’s cookies files may also clear certain opt-out cookies,
        plug-ins, or settings.
      </p>
      <h2 className="pp-subtitle">DISCLOSURE OF YOUR INFORMATION</h2>
      <p className="pp-section">
        We may share information we have collected about you in certain
        situations. Your information may be disclosed as follows:
      </p>
      <h3 className="pp-small-title">By Law or to Protect Rights</h3>
      <p className="pp-section">
        If we believe the release of information about you is necessary to
        respond to legal process, to investigate or remedy potential violations
        of our policies, or to protect the rights, property, and safety of
        ourselves or others, we may share your information. This includes
        exchanging information with other entities for fraud protection and
        credit risk reduction.
      </p>
      <h3 className="pp-small-title">Interactions with Other Users</h3>
      <p className="pp-section">
        If you interact with other users of the Site, those users may see your
        name, profile photo, and descriptions of your activity, including
        sending invitations to other users, chatting with other users, liking
        posts, following blogs.
      </p>
      <h3 className="pp-small-title">Online Postings</h3>
      <p className="pp-section">
        When you post comments, contributions or other content to the Site, your
        posts may be viewed by all users and may be publicly distributed outside
        the Site in perpetuity.
      </p>
      <h3 className="pp-small-title">Affiliates</h3>
      <p className="pp-section">
        We may share your information with our affiliates, in which case we will
        require those affiliates to honor this Privacy Policy. Affiliates
        include our parent company and any subsidiaries, joint venture partners
        or other companies that we control or that are under common control with
        us.
      </p>
      <h3 className="pp-small-title">Business Partners</h3>
      <p className="pp-section">
        We may share your information with our business partners to offer you
        select products, services or promotions, including third-party service
        providers to facilitate our provision of services to you and to support
        such services. We require such business partners to comply with the
        terms of this Privacy Policy. Naturally, all information in the public
        domain may be shared freely by us with any party.
      </p>
      <h3 className="pp-small-title">Social Media Contacts</h3>
      <p className="pp-section">
        If you connect to the Site through a social network, other contacts on
        the social network will see your name, profile photo, and description of
        your activity, as you make posts or otherwise engage.
      </p>
      <h3 className="pp-small-title">Other Third Parties</h3>
      <p className="pp-section">
        We may share your information with advertisers and investors for the
        purpose of conducting general business analysis. We may also share your
        information with such third parties for marketing purposes, as permitted
        by law and subject to the terms herein.
      </p>
      <h3 className="pp-small-title">Sale or Reorganization</h3>
      <p className="pp-section">
        If we reorganize or sell all or a portion of our assets, undergo a
        merger, or are acquired by another entity, we may transfer your
        information to the successor entity. If we go out of business or enter
        bankruptcy, your information would be an asset transferred or acquired
        by a third party. You acknowledge that such transfers may occur and that
        the transferee conceivably could decline to honor commitments we made in
        this Privacy Policy.
      </p>
      <p className="pp-section">
        We are not responsible for the actions of third parties with whom you
        share personal or sensitive data, and we have no authority to manage or
        control third party solicitations, in this circumstance. If you no
        longer wish to receive correspondence, emails or other communications
        from third parties, you are responsible for contacting the third party
        directly and/or simply clicking on their opt-out link in communications.
      </p>
      <h3 className="pp-small-title">Anonymized Data</h3>
      <p className="pp-section">
        We may share anonymized, non-personal data collected from our users in
        the aggregate with outside analysts and other business partners.
      </p>
      <h2 className="pp-subtitle">THIRD-PARTY WEBSITES</h2>
      <p className="pp-section">
        The Site may contain links to third party websites and applications of
        interest, including advertisements and external services, that are not
        affiliated with us. Once you have used these links to leave the Site,
        any information you provide to these third parties is not covered by
        this Privacy Policy, and we cannot guarantee the safety and privacy of
        your information. Before visiting and providing any information to any
        third party websites, you should inform yourself of the privacy policies
        and practices (if any) of the third party responsible for that website,
        and should take those steps necessary to, in your discretion, protect
        the privacy of your information. We are not responsible for the content
        or privacy and security practices and policies of any third parties,
        including other sites, services or applications that may be linked to or
        from the Site.
      </p>
      <h2 className="pp-subtitle">SECURITY OF YOUR INFORMATION</h2>
      <p className="pp-section">
        We use administrative, technical, and physical security measures to help
        protect your personal information, which we believe meets all reasonable
        and customary standards. While we have taken reasonable steps to secure
        the personal information you provide to us, please be aware that despite
        our efforts, no security measures are perfect or impenetrable, and no
        method of data transmission can be guaranteed against any interception
        or other type of misuse. Any information disclosed online is vulnerable
        to interception and misuse by unauthorized parties. Therefore, we cannot
        guarantee complete security if you provide personal information.
      </p>
      <h2 className="pp-subtitle">POLICY FOR CHILDREN</h2>
      <p className="pp-section">
        We do not knowingly accept, request, or solicit information from or
        market to children under the age of 18. Therefore, in accordance with
        the U.S. Children’s Online Privacy Protection Act, if we receive actual
        knowledge that anyone under the age of 13 has provided personal
        information to us without the requisite and verifiable parental consent,
        we will delete that information from the Site as quickly as is
        reasonably practical. If you become aware of any data we have collected
        from children under age 13, please contact us using the contact
        information provided below. We do not allow users under the age of 18.
      </p>
      <h2 className="pp-subtitle">CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
      <p className="pp-section">
        Most web browsers and some mobile operating systems include a Do Not
        Track (“DNT”) feature or setting you can activate to signal your privacy
        preference not to have data about your online browsing activities
        monitored and collected. No uniform technology standard for recognizing
        and implementing DNT signals has been finalized. As such, we do not
        currently respond to DNT browser signals or any other mechanism that
        automatically communicates your choice not to be tracked online. If a
        standard for online tracking is adopted that we must follow in the
        future, we will inform you about that practice in a revised version of
        this Privacy Policy.
      </p>
      <h2 className="pp-subtitle">OPTIONS REGARDING YOUR INFORMATION</h2>
      <h3 className="pp-small-title">Account Information</h3>
      <p className="pp-section">
        You may at any time review or change the information in your account or
        terminate your account by:
      </p>
      <ul>
        <li>
          Logging into user account settings and updating the user account.
        </li>
        <li>
          Contacting us using the contact information provided on our site.
        </li>
      </ul>
      <p className="pp-section">
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        some information may be retained in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our Terms
        of Use and/or comply with legal requirements.
      </p>
      <h3 className="pp-small-title">Emails and Communications</h3>
      <p className="pp-section">
        If you no longer wish to receive correspondence, emails, or other
        communications from us, you may opt¬out by:
      </p>
      <ul>
        <li>
          Noting your preferences at the time you register your account with the
          Site.
        </li>
        <li>
          Logging into your account settings and updating your preferences.
        </li>
        <li>Contacting us using the contact information provided.</li>
      </ul>
      <p className="pp-section">
        If you no longer wish to receive correspondence, emails, or other
        communications from third parties, you are responsible for contacting
        the third party directly.
      </p>
      <h2 className="pp-subtitle">CALIFORNIA PRIVACY RIGHTS</h2>
      <p className="pp-section">
        California residents may request and obtain from us, once a year and
        free of charge, information about categories of personal information (if
        any) we disclosed to third parties for direct marketing purposes and the
        names and addresses of all third parties with which we shared personal
        information in the immediately preceding calendar year. If you are a
        California resident and would like to make such a request, please submit
        your request in writing to us using the contact information provided
        below.
      </p>
      <p className="pp-section">
        If you are under 18 years of age (which is not allowed under our terms
        of use), reside in California, and have a registered account with the
        Site, you have the right to request removal of unwanted data that you
        publicly post on the Site. To request removal of such data, please
        contact us using the contact information provided below, and include the
        email address associated with your account and a statement that you
        reside in California. We will make sure the data is not publicly
        displayed on the Site, but please be aware that the data may not be
        completely or comprehensively removed from our systems.
      </p>
      <h2 className="pp-subtitle">CONTACT US</h2>
      <p className="pp-section">
        If you have questions or comments about this Privacy Policy, please
        contact us at:
      </p>
      <p className="pp-section">
        LifeGuides, PBC, a Public Benefit Corporation
        <br />
        LifeGuides c/o Mac Offices
        <br />
        1438 W Broadway Rd., #101 Tempe, AZ 85282
        <br />
        (415) 294-0592
        <br />
        support at lifeguides.com <br />
      </p>
    </>
  );
}
