import './upload.css';

import Bugsnag from '@bugsnag/js';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Dimmer,
  Dropdown,
  Icon,
  Loader,
  Modal,
  Segment
} from 'semantic-ui-react';

import { ROLES } from '../../../../consts';
import ImportUserCSVMutation from '../../../../graphql/mutations/import-user-csv.graphql';
import AllCompaniesQuery from '../../../../graphql/queries/all-companies.graphql';
import graphql from '../../../hoc/graphql';
import withUser from '../../../hoc/with-user';
import ErrorDialog from '../../../ui/error-dialog';
import MyForm, { FormField } from '../../../ui/form';
import ResourceHeader from '../../../ui/resource-header';
import Parser from './parser';

const FIELDS = [
  {
    name: 'employeeId',
    label: 'Unique ID'
  },
  {
    name: 'firstName',
    label: 'First Name',
    required: true
  },
  {
    name: 'lastName',
    label: 'Last Name',
    required: true
  },
  {
    name: 'emailAddress',
    label: 'E-Mail',
    required: true
  },
  {
    name: 'phoneNumber',
    label: 'Phone'
  },
  {
    name: 'address',
    label: 'Address'
  },
  {
    name: 'city',
    label: 'City'
  },
  {
    name: 'state',
    label: 'State'
  },
  {
    name: 'zip',
    label: 'Zip Code'
  },
  {
    name: 'dateOfBirth',
    label: 'DOB'
  }
];

@graphql(AllCompaniesQuery, { name: 'companies' })
@graphql(ImportUserCSVMutation, {
  name: 'importUserCSV'
})
@withRouter
@withUser({ authenticated: [ROLES.ADMIN] })
class UserImportUploadCSV extends Component {
  static propTypes = {
    companies: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      companies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      )
    }).isRequired,
    history: PropTypes.object.isRequired,
    importUserCSV: PropTypes.func.isRequired
  };

  state = {
    columns: {},
    companyId: null,
    error: null,
    file: null,
    isCompanyValid: false,
    parseOptions: {},
    showCompanyDialog: false,
    submitting: false
  };

  render() {
    const { error } = this.state;

    return (
      <div className="admin-user-import-upload">
        <ResourceHeader
          title="Upload CSV"
          parentPath={`/admin/users/imports`}
        />
        {this._renderDropzone()}
        {this._renderParser()}
        {this._renderCompanyDialog()}
        <ErrorDialog
          error={error}
          onClose={() => {
            this.setState({ error: null });
          }}
        />
      </div>
    );
  }

  _renderDropzone() {
    const { file } = this.state;

    if (file) {
      return null;
    }

    return (
      <Dropzone
        ref={(ref) => {
          this._dropzone = ref;
        }}
        maxSize={2000000}
        multiple={false}
        onDropAccepted={this._onDropAccepted}
        onDropRejected={this._onDropRejected}>
        {this._renderDropzoneContents}
      </Dropzone>
    );
  }

  _renderDropzoneContents = (props) => {
    const { isDragActive, isDragReject, getRootProps, getInputProps } = props;
    const { file } = this.state;

    const classNames = ['dropzone'];
    if (isDragActive) {
      classNames.push('active');
    }
    if (isDragReject) {
      classNames.push('reject');
    }
    const overlay = this._renderDropzoneOverlay(props);

    return (
      <div className={classNames.join(' ')} {...getRootProps()}>
        <input {...getInputProps()} />
        {file ? file.name : 'Drag file here or click to select file'}
        {overlay && <div className="overlay">{overlay}</div>}
      </div>
    );
  };

  _renderDropzoneOverlay = (props) => {
    const { isDragActive, isDragReject } = props;
    const { uploading } = this.state;

    if (uploading) {
      return (
        <Dimmer inverted active>
          <Loader />
        </Dimmer>
      );
    }

    if (isDragReject) {
      return <Icon name="cancel" color="red" size="huge" />;
    }
    if (isDragActive) {
      return <Icon name="upload" color="green" size="huge" />;
    }
  };

  _renderParser() {
    const { columns, submitting, text } = this.state;

    if (!text) {
      return null;
    }

    const requiredFields = FIELDS.filter((field) => field.required);
    const completed = requiredFields.every((field) =>
      Object.values(columns).includes(field.name)
    );

    return (
      <Segment basic>
        <Button
          primary
          disabled={!completed || submitting}
          loading={submitting}
          onClick={this._onSubmit}>
          Upload &amp; Import Data
        </Button>

        <Parser
          fields={FIELDS}
          onChange={({ columns, parseOptions }) => {
            this.setState({ columns, parseOptions });
          }}
          text={text}
        />
      </Segment>
    );
  }

  _renderCompanyDialog() {
    const { companyId, isCompanyValid, showCompanyDialog } = this.state;
    const { companies, loading: companiesLoading } = this.props.companies;

    if (!showCompanyDialog) {
      return null;
    }

    const publishedCompanies = companies
      ? companies.filter((company) => !company.isDeleted)
      : [];

    return (
      <Modal
        open
        size="tiny"
        onClose={() => {
          this.setState({ showCompanyDialog: false });
        }}>
        <Modal.Content>
          <Modal.Description>
            <MyForm
              data={{ companyId }}
              onChange={(data) => this.setState({ companyId: data.companyId })}
              onValidate={(errors) => {
                this.setState({ isCompanyValid: isEmpty(errors) });
              }}>
              <FormField
                component={Dropdown}
                name="companyId"
                label="Company"
                fluid
                selection
                loading={companiesLoading}
                options={[{ text: '', value: null }].concat(
                  publishedCompanies.map((company) => ({
                    text: company.name,
                    value: company.id
                  }))
                )}
              />
            </MyForm>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              this.setState({ showCompanyDialog: false });
            }}>
            Cancel
          </Button>
          <Button
            primary
            disabled={!isCompanyValid}
            onClick={() => {
              this._uploadCSV();
            }}>
            Upload CSV
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  _onDropAccepted = ([file]) => {
    this.setState({ error: null, file });

    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ text: reader.result });
    };

    try {
      reader.readAsText(file);
    } catch (error) {
      this.setState({ error });
    }
  };

  _onDropRejected = ([file]) => {
    this.setState({ error: new Error(`Rejected file: ${file.name}`) });
  };

  _onSubmit = () => {
    this.setState({ showCompanyDialog: true });
  };

  _uploadCSV = () => {
    const { history } = this.props;
    const { columns, companyId, file, parseOptions } = this.state;

    const { importUserCSV } = this.props;
    const variables = {
      columns,
      companyId,
      file,
      parseOptions
    };
    this.setState({ error: null, uploading: true });
    return importUserCSV({ variables })
      .then(({ data: { importUserCSV } }) => {
        this.setState({ file: null, uploading: false });
        history.push(`/admin/users/import/${importUserCSV.id}`);
      })
      .catch((error) => {
        this.setState({ error, uploading: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'UserImport._onSubmit';
        });
      });
  };
}
export default UserImportUploadCSV;
