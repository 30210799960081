import * as React from 'react';
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={98}
    height={104}
    fill="none"
    {...props}>
    <path
      fill="#F5BE18"
      d="M28.852.542c2.139-.421 4.212.959 4.631 3.084l2.53 12.824c.42 2.124-.974 4.189-3.113 4.61-2.139.423-4.212-.958-4.631-3.082l-2.53-12.824c-.42-2.125.974-4.19 3.113-4.612ZM6.163 15.516a3.965 3.965 0 0 1 5.477-1.08l10.806 7.193a3.903 3.903 0 0 1 1.074 5.44 3.965 3.965 0 0 1-5.477 1.081L7.237 20.957a3.903 3.903 0 0 1-1.074-5.441Zm49.364-9.739a3.903 3.903 0 0 1 1.074 5.441l-7.265 10.76a3.965 3.965 0 0 1-5.476 1.08 3.903 3.903 0 0 1-1.074-5.44l7.265-10.76a3.965 3.965 0 0 1 5.476-1.08ZM.831 42.043c-.42-2.125.974-4.19 3.113-4.611l12.908-2.547c2.139-.422 4.213.959 4.632 3.083.419 2.125-.975 4.19-3.114 4.611L5.462 45.126c-2.139.422-4.212-.959-4.631-3.083Zm31.725-2.331c-1.14-5.78 5.07-10.245 10.229-7.355L93.81 60.94c5.488 3.074 4.322 11.252-1.813 12.718l-19.672 4.7a8.052 8.052 0 0 0-5.043 3.697L56.74 99.708c-3.255 5.451-11.55 4.044-12.775-2.167l-11.409-57.83Z"
    />
  </svg>
);
export default SvgComponent;
