import 'react-big-scheduler/lib/css/style.css';

import './index.css';
import '../create-session-form/session-select-form-layout.css';

import _ from 'lodash';
import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component, Suspense, lazy } from 'react';
import { Icon, Label } from 'semantic-ui-react';

import GuidesByTopicIdsQuery from '../../../../../graphql/queries/guides-by-topic-ids.graphql';
import SessionsByGuideIdsQuery from '../../../../../graphql/queries/sessions-by-guide-ids.graphql';
import graphql from '../../../../hoc/graphql';
import withDnDContext from '../../../../hoc/with-dnd-context';
import FullscreenLoader from '../../../../ui/fullscreen-loader';
import BookingResourceItem from './booking-resource-item';
import BookingTimezoneSelector from './booking-timezone-selector';
import statusToColor from './status-color-map';

const Scheduler = lazy(() => import('react-big-scheduler'));

const moment = extendMoment(Moment);

const DATE_FORMAT = moment.HTML5_FMT.DATE;
const TIME_FORMAT = 'HH:mm';
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const ROW_HEIGHT = 50;
const CALL_DURATION_IN_MINUTES = 60;
const CALL_SLOTS_STEP = 15;

function nextWeekDay(d) {
  return (d + 1) % 7;
}

function dayRange(start, end) {
  const days = [];
  let i = start;
  do {
    days.push(i);
    i = nextWeekDay(i);
  } while (i != nextWeekDay(end));
  return days;
}

function mergeDateAndTime(date, time) {
  const d = moment(date.format(DATE_FORMAT), DATE_FORMAT);
  const t = moment(time.format(TIME_FORMAT), TIME_FORMAT);
  return d.hours(t.hours()).minutes(t.minutes()).toDate();
}

@graphql(GuidesByTopicIdsQuery, {
  name: 'guides',
  options: ({ selectedTopics }) => ({
    variables: { topicIds: selectedTopics.map((topic) => topic.id) }
  })
})
@graphql(SessionsByGuideIdsQuery, {
  name: 'sessions',
  skip: ({ guides }) => !guides.users,
  options: ({ guides }) => {
    const ids = guides.users ? guides.users.map((g) => g.id) : [];
    return {
      variables: {
        guideIds: ids,
        //prevent totally unbounded set
        lowerTimeBound: moment(new Date())
          .subtract(1, 'month')
          .startOf('day')
          .format()
      }
    };
  }
})
class BookingTimeSelector extends Component {
  static propTypes = {
    currentBooking: PropTypes.shape({}),
    guides: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      )
    }).isRequired,
    onSelectBooking: PropTypes.func,
    onSelectGuide: PropTypes.func,
    onSelectTimezone: PropTypes.string,
    selectedClient: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      timezone: PropTypes.string
    }),
    selectedDayOf: PropTypes.date,
    selectedGuide: PropTypes.shape({}),
    sessions: PropTypes.shape({
      sessions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      )
    })
  };

  _slotAvailabilities = {};

  constructor(props) {
    super(props);

    const { selectedClient, selectedDayOf } = this.props;

    const config = {
      views: [],
      eventItemLineHeight: ROW_HEIGHT,
      eventItemHeight: ROW_HEIGHT / 2,
      resourceName: 'Guides',
      schedulerMaxHeight: 400
    };

    const behaviors = {
      isNonWorkingTimeFunc: this._isNonWorkingTime,
      getNonAgendaViewBodyCellBgColorFunc: this._colorCellByAvailabilty
    };

    this.state = {
      selectedDateTime: new Date(),
      selectedTimezone:
        (selectedClient && selectedClient.timezone) || moment.tz.guess(),
      currentBooking: null
    };

    import('react-big-scheduler').then(({ SchedulerData, ViewTypes }) => {
      const schedulerData = new SchedulerData(
        selectedDayOf,
        ViewTypes.Day,
        false,
        false,
        config,
        behaviors
      );
      schedulerData.localeMoment.locale('en');
      schedulerData.setMinuteStep(CALL_SLOTS_STEP);

      this.setState(
        {
          schedulerData
        },
        () => {
          const resources = this._generateResources();
          const events = this._getBookings();
          if (resources.length) {
            this._slotAvailabilities = this._getAvailabilities();
            schedulerData.setResources(resources);
          }
          if (events.length) {
            schedulerData.setEvents(events);
          }
        }
      );
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.sessions) {
      return;
    }

    const { selectedClient, selectedDayOf } = this.props;
    const { sessions } = this.props.sessions;
    const { users } = this.props.guides;
    const { currentBooking, schedulerData, selectedTimezone } = this.state;

    const sessionsChanged =
      sessions && sessions !== prevProps.sessions?.sessions;
    const currentBookingChanged =
      currentBooking && currentBooking !== prevState.currentBooking;
    const selectedClientChanged =
      selectedClient && selectedClient !== prevProps.selectedClient;
    const selectedDayChanged = selectedDayOf !== prevProps.selectedDayOf;
    const selectedGuidesChanged = users && users !== prevProps.guides.users;
    const selectedTimezoneChanged =
      selectedTimezone !== prevState.selectedTimezone;

    if (selectedClientChanged) {
      this.setState({ selectedTimezone: selectedClient.timezone });
    }

    if (!schedulerData) {
      return;
    }

    const resources = this._generateResources();
    const events = this._getBookings();

    if (
      selectedGuidesChanged ||
      selectedTimezoneChanged ||
      selectedDayChanged
    ) {
      this._slotAvailabilities = this._getAvailabilities();
    }
    if (selectedDayChanged) {
      schedulerData.setDate(selectedDayOf);
    }
    if (selectedGuidesChanged) {
      schedulerData.setResources(resources);
    }
    if (
      currentBookingChanged ||
      sessionsChanged ||
      selectedDayChanged ||
      selectedTimezoneChanged
    ) {
      schedulerData.setEvents(events);
    }

    if (
      sessionsChanged ||
      currentBookingChanged ||
      selectedDayChanged ||
      selectedGuidesChanged ||
      selectedTimezoneChanged
    ) {
      this.forceUpdate();
    }
  }

  _generateResources = () => {
    const { users } = this.props.guides;

    if (!users) {
      return [];
    }

    return users.map((guide) => {
      const { id, firstName, lastName } = guide;
      return {
        id: id,
        name: `${firstName} ${lastName}`,
        guide
      };
    });
  };

  render() {
    return (
      <div className="call-request-form-bookingtime-selector ui form">
        <BookingTimezoneSelector
          selectedTimezone={this.state.selectedTimezone}
          onChange={this._onTimezoneChange}
        />
        {this._renderScheduler()}
      </div>
    );
  }

  _renderScheduler() {
    const { schedulerData } = this.state;

    if (!this.props.guides || !this.props.sessions) {
      return null;
    }

    return (
      <div className="bookingtime-selector-timeline field-container">
        <Suspense fallback={<FullscreenLoader />}>
          {schedulerData && (
            <Scheduler
              className="scheduler"
              schedulerData={schedulerData}
              newEvent={this._newEvent}
              slotItemTemplateResolver={this._renderResource}
              calendarPopoverEnabled="false"
              conflictOccurred={this._conflictOccurred}
              movable="true"
              eventItemTemplateResolver={this._eventItemTemplateResolver}
              eventItemPopoverTemplateResolver={
                this._eventItemPopoverTemplateResolver
              }
              moveEvent={this.moveEvent}
              nextClick={() => {}}
              prevClick={() => {}}
              onViewChange={() => {}}
              onSelectDate={() => {}}
              //onViewChange={this.onViewChange}
              //eventItemClick={this.eventClicked}
            />
          )}
        </Suspense>
      </div>
    );
  }

  _availableColor = `#ffffff`;
  _unavailableColor = `#e9e9e9`;

  _colorCellByAvailabilty = (schedulerData, slotId, header) => {
    const guide = schedulerData.getResourceById(slotId).guide;
    const available = !!(
      this._slotAvailabilities[guide.id] &&
      this._slotAvailabilities[guide.id][header.time]
    );

    return available ? this._availableColor : this._unavailableColor;
  };

  _renderResource = (schedulerData, slot, slotClickedFunc, width, clsName) => {
    const { onSelectGuide, selectedClient, selectedGuide } = this.props;
    const resource = schedulerData.getResourceById(slot.slotId);
    const { guide } = resource;

    return (
      <BookingResourceItem
        key={`booking-resource-item-${guide.id}`}
        onSelectGuide={onSelectGuide}
        selectedClient={selectedClient}
        selectedGuide={selectedGuide}
        guide={guide}
        rowHeight={ROW_HEIGHT}
      />
    );
  };

  _newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    const { selectedClient, onSelectGuide, onSelectBooking } = this.props;

    const { selectedTimezone } = this.state;

    if (!selectedClient) {
      alert('You need to select a call member before a call time.');
      return;
    }

    const resource = schedulerData.getResourceById(slotId);
    const available = !!(
      this._slotAvailabilities[resource.guide.id] &&
      this._slotAvailabilities[resource.guide.id][start]
    );

    if (!available) {
      return;
    }

    let _color = statusToColor('NEW');
    let _start = moment.tz(start, selectedTimezone);

    let newEvent = {
      id: `temp-id-${Math.random().toString(36).substr(2, 9)}`,
      title: `Schedule a call between ${selectedClient.firstName} and ${resource.guide.firstName}`,
      participants: {
        client: selectedClient,
        guide: resource.guide
      },
      start: _start.format(),
      end: _start.clone().add(CALL_DURATION_IN_MINUTES, 'minutes').format(),
      resourceId: slotId,
      borderColor: _color.border,
      bgColor: _color.background
    };

    this.setState({
      currentBooking: newEvent
    });

    if (_.isFunction(onSelectGuide)) {
      onSelectGuide({
        selectedGuide: resource.guide
      });
    }

    if (_.isFunction(onSelectBooking)) {
      onSelectBooking({
        selectedDateTime: _start
      });
    }
  };

  _conflictOccurred = (
    schedulerData,
    action,
    event,
    type,
    slotId,
    slotName,
    start,
    end
  ) => {
    // eslint-disable-next-line no-console
    console.log('conflict occured');
  };

  _getBookings = () => {
    if (!this.props.sessions) {
      return [];
    }

    const { selectedDayOf } = this.props;
    const { sessions } = this.props.sessions;
    const { currentBooking, schedulerData, selectedTimezone } = this.state;

    if (!sessions) {
      return [];
    }

    let bookings = sessions.reduce((acc, session) => {
      let newBookings = [];

      const { client, guide, id, scheduledTime, status } = session;
      const resource = schedulerData.getResourceById(guide.id);
      const guideName = `${resource?.guide?.firstName} ${
        resource?.guide?.lastName?.[0]?.concat('.') || ''
      }`;
      const clientName = `${client?.firstName} ${
        client?.lastName?.[0]?.concat('.') || ''
      }`;

      let onDay = moment(scheduledTime)
        .tz(selectedTimezone)
        .isSame(selectedDayOf, 'day');
      if (!onDay) {
        //switching to eastern time they fall in here.
        return acc;
      }
      let start = moment(scheduledTime)
        .tz(selectedTimezone)
        .format(DATE_TIME_FORMAT);
      //TODO: add duration if you have it.
      let end = moment(scheduledTime)
        .add(CALL_DURATION_IN_MINUTES, 'minutes')
        .tz(selectedTimezone)
        .format(DATE_TIME_FORMAT);
      let resourceId = guide.id;
      let title = `${_.capitalize(
        status
      )} call between ${clientName} and ${guideName}`;
      let _color = statusToColor(status);

      newBookings = [
        {
          id,
          start,
          end,
          resourceId,
          participants: {
            client,
            guide
          },
          title,
          borderColor: _color.border,
          bgColor: _color.background
        }
      ];
      return [...acc, ...newBookings];
    }, []);

    if (currentBooking) {
      let _cb = {
        ...currentBooking,
        ...{
          start: moment(currentBooking.start)
            .tz(selectedTimezone)
            .format(DATE_TIME_FORMAT),
          end: moment(currentBooking.end)
            .tz(selectedTimezone)
            .format(DATE_TIME_FORMAT)
        }
      };

      return [...bookings, _cb];
    }

    return bookings;
  };

  _getAvailabilities() {
    const { users } = this.props.guides;

    return users.reduce((acc, guide) => {
      const availabilities = this._getGuideAvailabilities(guide);

      const slots = availabilities.reduce((acc, availability) => {
        const range = moment.range(
          availability.start,
          moment(availability.end).subtract(CALL_SLOTS_STEP, 'minutes')
        );
        const slots = Array.from(
          range.by('minute', { step: CALL_SLOTS_STEP })
        ).reduce((acc, slot) => {
          return {
            ...acc,
            [slot.format(DATE_TIME_FORMAT)]: true
          };
        }, {});
        return {
          ...acc,
          ...slots
        };
      }, {});

      return {
        ...acc,
        [guide.id]: slots
      };
    }, {});
  }

  _getGuideAvailabilities(guide) {
    const { selectedDayOf } = this.props;
    const { selectedTimezone } = this.state;

    const day = moment(selectedDayOf);

    return guide.availability.reduce((acc, availability) => {
      const baseEndTime = moment(availability.endTime);
      const endDate = baseEndTime.clone().tz(selectedTimezone).endOf('day');
      const endDay = endDate.day();

      const baseStartTime = moment(availability.startTime);
      const startDate = baseStartTime
        .clone()
        .tz(selectedTimezone)
        .startOf('day');
      const startDay = startDate.day();

      const daysOfWeek = dayRange(startDay, endDay);

      if (availability.isRecurring) {
        const isOnDay = daysOfWeek.includes(day.day());

        const isEffectiveEndDateAfterDay =
          !availability.effectiveEndDate ||
          moment(availability.effectiveEndDate)
            .tz(guide.timezone || selectedTimezone)
            .endOf('day')
            .isSameOrAfter(day);
        const isEffectiveStartDateBeforeDay =
          !availability.effectiveStartDate ||
          moment(availability.effectiveStartDate)
            .tz(guide.timezone || selectedTimezone)
            .startOf('day')
            .isSameOrBefore(day);

        if (
          !isOnDay ||
          !isEffectiveEndDateAfterDay ||
          !isEffectiveStartDateBeforeDay
        ) {
          return acc;
        }

        const shift = (m, ref) => {
          return moment(day)
            .tz(selectedTimezone)
            .day(ref)
            .hours(m.hours())
            .minutes(m.minutes());
        };

        const endTime = shift(baseEndTime.clone().tz(selectedTimezone), endDay);
        const startTime = shift(
          baseStartTime.clone().tz(selectedTimezone),
          startDay
        );

        return acc.concat([
          {
            end: mergeDateAndTime(endTime, endTime),
            start: mergeDateAndTime(startTime, startTime)
          }
        ]);
      } else {
        const endTime = baseEndTime.clone().tz(selectedTimezone);
        const startTime = baseStartTime.clone().tz(selectedTimezone);

        if (!moment.range(startDate, endDate).contains(day)) {
          return acc;
        }

        return acc.concat([
          {
            end: mergeDateAndTime(endDate, endTime),
            start: mergeDateAndTime(startDate, startTime)
          }
        ]);
      }
    }, []);
  }

  _onTimezoneChange = (event, { value }) => {
    const { onSelectTimezone } = this.props;
    const { schedulerData } = this.state;

    this.setState({ selectedTimezone: value }, () => {
      if (schedulerData) {
        schedulerData.setEvents(this._getBookings());
        this.forceUpdate();
      }
    });

    onSelectTimezone({ selectedTimezone: value });
  };

  _eventItemTemplateResolver = (
    schedulerData,
    event,
    bgColor,
    isStart,
    isEnd,
    mustAddCssClass,
    mustBeHeight,
    agendaMaxEventWidth
  ) => {
    let rightBorderWidth = isEnd ? 56 : 0;
    let leftBorderWidth = isStart ? 2 : 0;
    let borderColor = event.borderColor || event.bgColor;
    let backgroundColor = event.bgColor;

    let titleText = schedulerData.behaviors.getEventTextFunc(
      schedulerData,
      event
    );

    if (event.participants && event.participants.client) {
      let nm = `${
        event?.participants?.client?.lastName?.[0]?.concat('.') || ''
      }`;
      let fn = event?.participants?.client?.firstName;
      titleText = `${fn} ${nm}`;
    }

    let divStyle = {
      borderLeft: leftBorderWidth + 'px solid ' + borderColor,
      borderRight: rightBorderWidth + 'px solid rgba(50, 50, 50, 0.4)',
      backgroundColor: backgroundColor,
      height: mustBeHeight
    };

    if (agendaMaxEventWidth) {
      divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
    }
    divStyle = { ...divStyle, textOverflow: 'clip' };

    return (
      <div key={event.id} className={mustAddCssClass} style={divStyle}>
        <span style={{ marginLeft: '4px', lineHeight: `${mustBeHeight}px` }}>
          {titleText}
        </span>
      </div>
    );
  };

  _eventItemPopoverTemplateResolver = (
    schedulerData,
    eventItem,
    title,
    start,
    end,
    statusColor
  ) => {
    const { selectedTimezone } = this.state;

    return (
      <div>
        <div>{title}</div>
        <div>
          <Label basic>
            <Icon name="clock" />
            {/* {`${user.timezone}`} */}
            {` (${moment.tz(selectedTimezone).format('z')})`}
          </Label>{' '}
          {start.format('h:mm A')} on {start.format('ddd')},{' '}
          {start.format('MMM Do')}
        </div>
      </div>
    );
  };

  _isNonWorkingTime = (schedulerData, time) => false;

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    //{eventId: ${event.id}, eventTitle: ${event.title}, newSlotId: ${slotId}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}
    if (confirm(`At this time, the event cannot be moved.`)) {
      // schedulerData.moveEvent(event, slotId, slotName, start, end);
      // this.forceUpdate()
      //TODO: call server and make change there.
    }
  };
}

export default withDnDContext(BookingTimeSelector);
