import debounce from 'lodash/debounce';
import upperFirst from 'lodash/upperFirst';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import { Button, Dropdown, Icon, Label, Modal } from 'semantic-ui-react';

import { ROLES } from '../../../consts';
import withUser from '../../hoc/with-user';
import { isWidthDown } from '../../hoc/with-width';
import DevicesForm from './devices-form';
import { TWILIO_ERRORS } from './errors';
import LocalTrackControls from './local-track-controls';
import LocalTracksView from './local-tracks-view';
import makeStream from './make-stream';
import NetworkQualityIndicator from './network-quality-indicator';
import Participant from './participant';

const EVENT_NAMES = [
  'disconnected',
  'participantConnected',
  'participantDisconnected',
  'reconnected',
  'reconnecting',
  'recordingStarted',
  'recordingStopped',
  'trackDimensionsChanged',
  'trackDisabled',
  'trackEnabled',
  'trackMessage',
  'trackPublished',
  'trackPublishPriorityChanged',
  'trackStarted',
  'trackSubscribed',
  'trackSwitchedOff',
  'trackSwitchedOn',
  'trackUnpublished',
  'trackUnsubscribed'
];

@withUser()
class TwilioRoom extends Component {
  static propTypes = {
    room: PropTypes.object.isRequired,
    sinkId: PropTypes.string,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string,
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          })
        )
      })
    }),
    width: PropTypes.number
  };

  constructor(props) {
    super(props);

    this._ref = React.createRef();

    const { room } = this.props;

    this.state = {
      devicesDialogOpen: false,
      networkQualityLevel: 0,
      networkQualityStats: null,
      notification: null,
      participants: [...room.participants.values()],
      receivedWidth: false,
      sinkId: props.sinkId,
      width: 0
    };
  }

  componentDidMount() {
    this._enableRoomListeners(true);
    this._ref.current.onfullscreenchange = this._onFullscreenChange;
    window.addEventListener('resize', this._onWindowResize);
    this._onWindowResize();
  }

  componentDidUpdate() {
    const { User } = this.props.user;
    const { receivedWidth, width } = this.state;

    if (!width || receivedWidth) {
      return;
    }
    this.setState({ receivedWidth: true });

    const isClient = User.roles.some((role) => role.name === ROLES.CLIENT);
    const isMobile = isWidthDown('tablet', width);

    if (!isClient || !isMobile) {
      return;
    }

    this._toggleFullscreen(true);
  }

  componentWillUnmount() {
    this._enableRoomListeners(false);
    this._ref.current.onfullscreenchange = null;
    window.removeEventListener('resize', this._onWindowResize);
    this._disconnect();
  }

  _onWindowResize = debounce(() => {
    if (!this._ref.current) {
      return;
    }
    const width = this._ref.current.getBoundingClientRect().width;
    if (width === this.state.width) {
      return;
    }

    this.setState({ width });
  }, 250);

  _toggleFullscreen = (enabled) => {
    if (!this._ref.current) {
      return;
    }
    if (enabled) {
      const requestFullscreen =
        this._ref.current.requestFullScreen ||
        this._ref.current.mozRequestFullScreen ||
        this._ref.current.webkitRequestFullScreen;

      if (requestFullscreen) {
        try {
          requestFullscreen.call(this._ref.current);
        } catch (error) {
          /* no-op */
        }
      }
    } else if (document.fullscreenElement) {
      try {
        document.exitFullscreen();
      } catch (error) {
        /* no-op */
      }
    }
  };

  _onFullscreenChange = () => {
    this.forceUpdate();
  };

  _enableRoomListeners(enabled) {
    const { room } = this.props;

    EVENT_NAMES.forEach((eventName) => {
      const handlerName = `_on${upperFirst(eventName)}`;
      const handler = this[handlerName];
      if (!handler) {
        return;
      }
      const func = enabled ? room.on : room.off;
      func.call(room, eventName, handler);
    });

    const func = enabled ? room.localParticipant.on : room.localParticipant.off;
    func.call(
      room.localParticipant,
      'networkQualityLevelChanged',
      this._localNetworkQualityLevelChanged
    );
  }

  _disconnect() {
    const { room } = this.props;

    if (!room) {
      return;
    }

    room.disconnect();
  }

  _localNetworkQualityLevelChanged = (
    networkQualityLevel,
    networkQualityStats
  ) => {
    this.setState({ networkQualityLevel, networkQualityStats });
  };

  _onParticipantConnected = (participant) => {
    const { participants } = this.state;

    const updated = participants.concat([participant]);
    this.setState({ participants: updated });
  };

  _onParticipantDisconnected = (participant) => {
    const { participants } = this.state;

    const index = participants.findIndex((p) => p.sid === participant.sid);
    if (index >= 0) {
      participants.splice(index, 1);
      this.setState({ participants });
    }
  };

  _onReconnected = () => {
    this.setState({ notification: null });
  };

  _onReconnecting = (error) => {
    if (error.code === TWILIO_ERRORS.MEDIA_CONNECTION_FAILED.code) {
      this.setState({ notification: 'Reconnecting media...' });
    } else {
      this.setState({ notification: 'Reconnecting...' });
    }
  };

  _onDevicesChange = (data, prevData) => {
    if (data.sinkId !== prevData.sinkId) {
      this.setState({ sinkId: data.sinkId });
    }

    if (data.audioId !== prevData.audioId) {
      this._publishTrack('audio', data.audioId);
    }

    if (data.videoId !== prevData.videoId) {
      this._publishTrack('video', data.audioId);
    }
  };

  _publishTrack(kind, id) {
    const { room } = this.props;

    const localTracks = [...room.localParticipant.tracks.values()].map(
      (publication) => publication.track
    );

    const audioTracks = localTracks.filter((track) => track.kind === kind);
    audioTracks.forEach((track) => room.localParticipant.unpublishTrack(track));

    const constraints = {
      [kind]: id ? { deviceId: { exact: id } } : true
    };
    makeStream(constraints).then((mediaStream) => {
      const tracks = mediaStream.getTracks();
      const promises = tracks.map((track) =>
        room.localParticipant.publishTrack(track)
      );
      Promise.all(promises).then(() => this.forceUpdate());
    });
  }

  render() {
    const { room } = this.props;
    const {
      participants,
      networkQualityLevel,
      networkQualityStats,
      width
    } = this.state;

    const localTracks = [...room.localParticipant.tracks.values()].map(
      (publication) => publication.track
    );
    const localMediaStreamTracks = localTracks.map(
      (track) => track.mediaStreamTrack
    );

    const classNames = ['twilio-video-room-component', room.state];
    if (!participants.length) {
      classNames.push('waiting');
    } else {
      classNames.push(`participants${participants.length}`);
    }
    if (document.fullscreenElement) {
      classNames.push('fullscreen');
    }

    const localWidth = Math.round(width * 0.25);

    return (
      <>
        <div ref={this._ref} className={classNames.join(' ')}>
          {this._renderNotification()}
          {this._renderParticipants()}
          <LocalTracksView
            tracks={localMediaStreamTracks}
            style={{ ...(localWidth ? { width: `${localWidth}px` } : null) }}>
            <NetworkQualityIndicator
              networkQualityLevel={networkQualityLevel}
              networkQualityStats={networkQualityStats}
            />
          </LocalTracksView>
          <LocalTrackControls tracks={localTracks} room={room} />
          {this._renderSettings()}
        </div>
        {this._renderDevicesDialog()}
        <Prompt message="Leaving this page will end the call, are you sure you want to do this?" />
      </>
    );
  }

  _renderNotification() {
    const { notification } = this.state;

    if (!notification) {
      return;
    }

    return (
      <Label className="notification" color="black">
        {notification}
      </Label>
    );
  }

  _renderParticipants() {
    const { participants } = this.state;

    if (!participants.length) {
      return this._renderEmptyParticipants();
    }

    return (
      <div className="participants">
        {participants.map(this._renderParticipant)}
      </div>
    );
  }

  _renderParticipant = (participant) => {
    const { sinkId } = this.state;

    return (
      <Participant
        key={`participant-${participant.sid}`}
        participant={participant}
        sinkId={sinkId}
      />
    );
  };

  _renderEmptyParticipants() {
    const { User } = this.props.user;

    const isGuide = User.roles.some((role) => role.name === ROLES.GUIDE);

    return (
      <div className="waiting-message">
        Waiting for {isGuide ? 'participant' : 'your LifeGuide'}
      </div>
    );
  }

  _renderSettings() {
    const supportsGetCapabilities =
      window.MediaStreamTrack &&
      'getCapabilities' in window.MediaStreamTrack.prototype;

    const supportsFullscreen = !!(
      this._ref.current &&
      (this._ref.current.requestFullScreen ||
        this._ref.current.mozRequestFullScreen ||
        this._ref.current.webkitRequestFullScreen)
    );

    const isFullscreen = document.fullscreenElement === this._ref.current;

    const listItems = [
      {
        icon: 'cog',
        name: 'settings',
        supported: supportsGetCapabilities,
        text: 'Settings',
        onClick: () => {
          this._toggleFullscreen(false);
          this.setState({ devicesDialogOpen: true });
        }
      },
      {
        icon: isFullscreen ? 'compress' : 'expand',
        name: 'fullscreen',
        supported: supportsFullscreen,
        text: isFullscreen ? 'Exit Fullscreen' : 'Go Fullscreen',
        onClick: () => this._toggleFullscreen(!isFullscreen)
      }
    ].filter((i) => i.supported);

    if (!listItems.length) {
      return null;
    }

    if (listItems.length === 1) {
      const [listItem] = listItems;
      return (
        <Button
          icon={
            <Icon
              name={listItem.icon}
              inverted
              color="grey"
              className="lineawesome"
            />
          }
          circular
          basic
          size="large"
          className="settings"
          onClick={listItem.onClick}
        />
      );
    }

    return (
      <Dropdown
        className="settings"
        icon={null}
        upward
        direction="left"
        pointing="bottom right"
        trigger={
          <Button
            icon={
              <Icon
                name="ellipsis vertical"
                inverted
                color="grey"
                className="lineawesome"
              />
            }
            circular
            basic
            size="large"
          />
        }>
        <Dropdown.Menu>
          {listItems.map((listItem) => (
            <Dropdown.Item
              key={listItem.name}
              icon={listItem.icon}
              text={listItem.text}
              onClick={listItem.onClick}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  _renderDevicesDialog() {
    const { room } = this.props;
    const { devicesDialogOpen, sinkId } = this.state;

    const localMediaStreamTracks = [
      ...room.localParticipant.tracks.values()
    ].map((publication) => publication.track.mediaStreamTrack);

    return (
      <Modal
        open={devicesDialogOpen}
        size="small"
        className="media-devices-dialog"
        onClose={() => this.setState({ devicesDialogOpen: false })}>
        <Modal.Header>Settings</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <DevicesForm
              sinkId={sinkId}
              tracks={localMediaStreamTracks}
              onChange={this._onDevicesChange}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.setState({ devicesDialogOpen: false })}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
export default TwilioRoom;
