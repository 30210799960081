import React from 'react';

import Copy from './copy';

export default function TermsAndConditions() {
  return (
    <div className="w-full max-w-screen-xl px-8 my-8">
      <Copy />
    </div>
  );
}
