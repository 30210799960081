import './subchannel.css';

import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { withRouter } from 'react-router-dom';
import { Card, Checkbox, Segment } from 'semantic-ui-react';

import ArticleLinksBySubChannelIdQuery from '../../../graphql/queries/article-links-by-sub-channel-id.graphql';
import SubChannelByIdQuery from '../../../graphql/queries/sub-channel-by-id.graphql';
import ResourceHeader from '../../ui/resource-header';
import { ref as WorkspaceRef } from '../app-workspace';
import ArticleLink from './article-link';

const CardsPerRow = 2;

@withRouter
@graphql(ArticleLinksBySubChannelIdQuery, {
  name: 'articleLinks',
  options: ({ channel, match }) => {
    // If we reach over 100 articles in any sub channel,
    // we'll need to refactor to use fetchMore to do paging
    // Done this way to share query with the article link count on the sub channel card
    const variables = {
      subChannelId: match.params.id
    };
    return { variables };
  }
})
@graphql(SubChannelByIdQuery, {
  name: 'subChannel',
  options: ({ channel, match }) => {
    const variables = {
      id: match.params.id
    };
    return { variables };
  }
})
class SubChannel extends Component {
  static propTypes = {
    articleLinks: PropTypes.shape({
      articleLinks: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          body: PropTypes.string,
          tags: PropTypes.arrayOf(PropTypes.string)
        })
      ),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    channel: PropTypes.shape({
      slug: PropTypes.string.isRequired
    }).isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    subChannel: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      subChannel: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string
      })
    }).isRequired,
    isTablet: PropTypes.bool.isRequired
  };

  state = {
    articleLinks: [],
    page: 0,
    selectedTags: [],
    visibleArticleLinks: []
  };

  componentDidMount() {
    const { articleLinks } = this.props.articleLinks;
    if (articleLinks) {
      this._updateState({ articleLinks });
    }
  }

  componentDidUpdate(previousProps) {
    const { articleLinks } = this.props.articleLinks;
    if (articleLinks && !previousProps.articleLinks.articleLinks) {
      this._updateState({ articleLinks });
    }
  }

  _updateState(nextState, callback) {
    if (this.props.articleLinks.loading) {
      return;
    }

    const currentState = this.state;
    const updatedState = {
      ...currentState,
      ...nextState
    };

    const { articleLinks, page, selectedTags } = updatedState;

    const selectedLinks = articleLinks.filter((articleLink) => {
      if (selectedTags.length === 0) {
        return true;
      }
      return articleLink.tags.some((tag) => selectedTags.includes(tag));
    });
    const updatedVisibleArticleLinks = selectedLinks.slice(
      0,
      (page + 1) * CardsPerRow
    );

    updatedState.visibleArticleLinks = updatedVisibleArticleLinks;
    updatedState.hasMore =
      updatedVisibleArticleLinks.length < selectedLinks.length;

    this.setState(updatedState, callback);
  }

  render() {
    const { channel, history, isTablet } = this.props;
    const { loading, subChannel } = this.props.subChannel;
    const { hasMore } = this.state;

    if (loading) {
      return <Segment basic loading />;
    }

    if (!subChannel) {
      history.push('/404');
      return null;
    }

    let content = (
      <div className="subchannel-content">
        <div className="subchannel-articles">{this._renderArticleLinks()}</div>
        <div className="subchannel-facets">{this._renderTags()}</div>
      </div>
    );

    if (isTablet) {
      content = (
        <div className="subchannel-content tablet">
          <div className="subchannel-facets tablet collapseable">
            {this._renderTags()}
          </div>
          <div className="subchannel-articles tablet">
            {this._renderArticleLinks()}
          </div>
        </div>
      );
    }

    return (
      <InfiniteScroll
        pageStart={0}
        initialLoad
        loadMore={this._loadMore}
        hasMore={hasMore}
        loader={<Segment basic loading />}
        useWindow={false}
        getScrollParent={() => WorkspaceRef.current}>
        <div className="resource-subchannels resource-container">
          <ResourceHeader
            title={subChannel.title}
            parentPath={`/${channel.slug}/resources`}
          />
          {content}
        </div>
      </InfiniteScroll>
    );
  }

  _loadMore = () => {
    const { page } = this.state;
    this._updateState({ page: page + 1 });
  };

  _renderArticleLinks() {
    const { loading } = this.props.articleLinks;
    const { visibleArticleLinks } = this.state;

    if (loading) {
      return (
        <Segment basic loading size={`massive`} className="resources-loader" />
      );
    }

    let columns = CardsPerRow;

    if (visibleArticleLinks.length === 1) {
      columns = 1;
    }

    return (
      <Card.Group itemsPerRow={columns} stackable>
        {visibleArticleLinks.map(this._renderArticleLink)}
      </Card.Group>
    );
  }

  _renderArticleLink = (articleLink) => {
    const { channel } = this.props;

    return (
      <ArticleLink
        key={`article-link-${articleLink.id}`}
        articleLink={articleLink}
        channel={channel}
      />
    );
  };

  _renderTags() {
    const { articleLinks } = this.props.articleLinks;

    if (!articleLinks) {
      return null;
    }

    const tags = articleLinks.reduce((acc, articleLink) => {
      articleLink.tags.forEach((tag) => {
        let group = acc.find((g) => g.tag === tag);
        if (!group) {
          group = {
            tag,
            count: 1
          };
          acc.push(group);
        } else {
          group.count += 1;
        }
      });
      return acc;
    }, []);

    tags.sort((a, b) => {
      return a.tag < b.tag ? -1 : 1;
    });

    return <div>{tags.map(this._renderTagGroup)}</div>;
  }

  _renderTagGroup = (group) => {
    const { selectedTags } = this.state;
    const isSelected = selectedTags.includes(group.tag);

    return (
      <div key={`tag-group-${group.tag}`}>
        <Checkbox
          checked={isSelected}
          label={`${group.tag} (${group.count})`}
          style={{ margin: '.5em 0', fontSize: '1.2em' }}
          onChange={() => this._onTagToggle(group.tag)}
        />
      </div>
    );
  };

  _onTagToggle = (tag) => {
    const { selectedTags } = this.state;

    const index = selectedTags.indexOf(tag);
    if (index < 0) {
      selectedTags.push(tag);
    } else {
      selectedTags.splice(index, 1);
    }

    this._updateState({ page: 0, selectedTags }, () => {
      // eslint-disable-next-line react/no-find-dom-node
      const workspace = ReactDOM.findDOMNode(WorkspaceRef.current);
      if (workspace) {
        workspace.scrollTop = 0;
      }
    });
  };
}
export default SubChannel;
