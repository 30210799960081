import PropTypes from 'prop-types';
import React from 'react';

import getVideoInfo from './video-info';

function GuideDetailsVideo({ url }) {
  const vs = getVideoInfo(url);
  if (!vs) {
    return null;
  }

  return (
    <iframe
      src={vs.url}
      className="w-screen"
      style={{
        maxWidth: '640px',
        height: '50vw',
        maxHeight: '320px'
      }}
      frameBorder="0"
      allow="autoplay; encrypted-media"
      allowFullScreen
    />
  );
}
GuideDetailsVideo.propTypes = {
  url: PropTypes.string
};
export default GuideDetailsVideo;
