import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';

import ErrorBoundary from '../../../ui/error-boundary';
import LoadingSpinner from '../../../ui/loading-spinner';
import { useEditing } from './actions';
import GuideVideoPreview from './rich-text-editor/guide-video-preview';

const RichTextEditor = React.lazy(() => import('./rich-text-editor/index.js'));

function GuideProfileBiography(props) {
  const { data, onChange, onGuideVideoClick } = props;

  const [isEditing] = useEditing();

  function renderContent() {
    const options = {
      replace: (node) => {
        if (!node.attribs) {
          return;
        }
        if (node.attribs.class === 'guide-video') {
          const label = node.attribs['data-label'];
          const url = node.attribs['data-url'];
          return (
            <section className="guide-video">
              <GuideVideoPreview
                label={label}
                onClick={() => onGuideVideoClick(url)}
                url={url}
              />
            </section>
          );
        }
      }
    };

    return (
      <div className="rich-text my-4 text-xl leading-8">
        {parse(data.biography, options)}
      </div>
    );
  }

  return isEditing ? (
    <div className="my-4">
      <ErrorBoundary>
        <Suspense
          fallback={<LoadingSpinner className="w-48 h-48 my-4 mx-auto" />}>
          <RichTextEditor
            format="html"
            value={data.biography}
            onChange={onChange}
            onGuideVideoClick={onGuideVideoClick}
          />
        </Suspense>
      </ErrorBoundary>
    </div>
  ) : (
    <div>{renderContent()}</div>
  );
}
GuideProfileBiography.propTypes = {
  data: PropTypes.shape({
    biography: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onGuideVideoClick: PropTypes.func.isRequired
};
export default GuideProfileBiography;
