import './index.css';

import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Label, List, Modal } from 'semantic-ui-react';

import { PhoneWav } from '../../../assets';
import UpdateSessionStatusMutation from '../../../graphql/mutations/update-session-status.graphql';
import SessionsByGuideIdStatusQuery from '../../../graphql/queries/sessions-by-guide-id-status.graphql';
import SessionsCreatedOrUpdatedSubscription from '../../../graphql/subscriptions/sessions-created-or-updated.graphql';
import withUser from '../../hoc/with-user';
import Avatar from '../../ui/avatar';

@withUser()
@graphql(SessionsByGuideIdStatusQuery, {
  name: 'sessions',
  options: ({ user: { User } }) => ({
    variables: { guideId: User.id, status: 'REQUESTING' }
  })
})
@graphql(UpdateSessionStatusMutation, {
  name: 'updateSessionStatus'
})
@withRouter
class Sessions extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    sessions: PropTypes.shape({
      error: PropTypes.shape({
        message: PropTypes.string
      }),
      loading: PropTypes.bool.isRequired,
      sessions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          status: PropTypes.string
        })
      ),
      subscribeToMore: PropTypes.func.isRequired
    }).isRequired,
    updateSessionStatus: PropTypes.func.isRequired,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string
      }).isRequired
    }).isRequired
  };

  constructor(props) {
    super(props);

    this._audioRef = React.createRef();

    this.state = {
      audioPlaying: false,
      open: false,
      ringing: false
    };
  }

  componentDidMount() {
    this._initializeSubscription();

    this._audioRef.current.addEventListener('play', this._onAudioPlay);
    this._audioRef.current.addEventListener('pause', this._onAudioPause);
  }

  componentDidUpdate(prevProps, prevState) {
    this._toggleAudioState(prevProps, prevState);
    this._toggleOpenState(prevProps, prevState);
  }

  componentWillUnmount() {
    this._unsubscribe();
    delete this._unsubscribe;

    this._audioRef.current.removeEventListener('play', this._onAudioPlay);
    this._audioRef.current.removeEventListener('pause', this._onAudioPause);
  }

  _initializeSubscription() {
    const { User } = this.props.user;

    this._unsubscribe = this.props.sessions.subscribeToMore({
      document: SessionsCreatedOrUpdatedSubscription,
      variables: {
        userId: User.id
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }

        if (!subscriptionData.data.session) {
          return prev;
        }
        const { node } = subscriptionData.data.session;

        const sessions = prev.sessions ? prev.sessions.slice() : [];
        const index = sessions.findIndex((session) => session.id === node.id);

        if (index < 0 && node.status === 'REQUESTING') {
          this.setState({ open: true, ringing: true });
          sessions.push(node);
        } else if (index >= 0) {
          sessions[index] = {
            ...sessions[index],
            status: node.status
          };
          if (!sessions.some((session) => session.status === 'REQUESTING')) {
            this.setState({ ringing: false });
          }
        }

        return {
          ...prev,
          sessions
        };
      }
    });
  }

  _onAudioPlay = () => {
    this.setState({ audioPlaying: true });
  };

  _onAudioPause = () => {
    this.setState({ audioPlaying: false });
  };

  _toggleAudioState() {
    const { audioPlaying, ringing } = this.state;

    if (ringing === audioPlaying) {
      return;
    }

    try {
      const element = this._audioRef.current;
      if (ringing) {
        element.play();
      } else {
        element.pause();
      }
    } catch (error) {
      /* no-op */
    }
  }

  _toggleOpenState(prevProps, prevState) {
    const { sessions } = this.props.sessions;
    const { open } = this.state;

    if (!sessions || prevProps.sessions.sessions) {
      return;
    }

    const requestingSessions = sessions.filter(
      (session) => session.status === 'REQUESTING'
    );
    if (requestingSessions.length && !open) {
      this.setState({ open: true });
    }
  }

  render() {
    return (
      <>
        {this._renderDialog()}
        <audio ref={this._audioRef} src={PhoneWav} loop />
      </>
    );
  }

  _renderDialog() {
    const { open } = this.state;

    return (
      <Modal className="presence-sessions-component" open={open} size="tiny">
        <Modal.Header>Immediate Sessions</Modal.Header>
        <Modal.Content>
          <Modal.Description>{this._renderSessions()}</Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this._onCloseDialog}>Close</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  _renderSessions() {
    const { error, loading, sessions } = this.props.sessions;

    if (error || loading) {
      return null;
    }

    return (
      <List verticalAlign="middle">
        {sessions && sessions.map(this._renderSession)}
      </List>
    );
  }

  _renderSession = (session) => {
    //const [companyConnection] = session.client.companyIds;
    const name = `${session?.client?.firstName} ${
      session?.client?.lastName?.[0]?.concat('.') || ''
    }`;

    return (
      <List.Item key={`session-${session.id}`}>
        {session.status === 'REQUESTING' && (
          <List.Content floated="right">
            <Button
              primary
              onClick={() => {
                this._acceptSession(session);
              }}>
              Accept Session
            </Button>
          </List.Content>
        )}
        <List.Content floated="left">
          <Avatar user={session.client} />
        </List.Content>
        <List.Content>
          <List.Header>
            {name}
            {/*companyConnection && (
              <>
                &nbsp;<Label>{companyConnection.company.name}</Label>
              </>
            )*/}
            {session.topics.map((topic) => (
              <Label key={`topic-${topic.id}`} basic>
                {topic.title}
              </Label>
            ))}
          </List.Header>
          {session.status} {moment(session.updatedAt).format('h:mma')}{' '}
        </List.Content>
      </List.Item>
    );
  };

  _acceptSession = (session) => {
    const { history, updateSessionStatus } = this.props;

    const variables = {
      id: session.id,
      status: 'CONNECTING'
    };

    this.setState({ error: null, ringing: false, submitting: true });
    updateSessionStatus({ variables })
      .then(() => {
        this.setState({ open: false, submitting: false });
        history.push(`/session/${session.id}`);
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
      });
  };

  _onCloseDialog = () => {
    this.setState({ open: false, ringing: false });
  };
}
export default Sessions;
