import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';

import EditMenuItemForm from '../forms/edit-menu-item';

function EditMenuItemDialog({ menuItem, onClose, onSubmit, submitting }) {
  const [data, setData] = useState(null);
  const [isValid, setIsValid] = useState(false);

  const onDataChange = useCallback((data) => {
    setData(data);
  }, []);

  const onValidate = useCallback((errors) => {
    setIsValid(isEmpty(errors));
  }, []);

  const onClickSubmit = useCallback(() => {
    if (onSubmit) {
      onSubmit(data);
    }
  }, [data, onSubmit]);

  return (
    <Modal open size="tiny" closeOnDimmerClick={false} onClose={onClose}>
      <Modal.Header>{menuItem ? 'Edit' : 'Add'} Company Menu Item</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <EditMenuItemForm
            menuItem={menuItem}
            onDataChange={onDataChange}
            onValidate={onValidate}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={submitting} onClick={onClose}>
          Cancel
        </Button>
        <Button
          primary
          loading={submitting}
          disabled={!isValid || submitting}
          onClick={onClickSubmit}>
          {menuItem ? 'Update' : 'Add'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
EditMenuItemDialog.propTypes = {
  menuItem: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string
  }),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool
};
export default EditMenuItemDialog;
