import { useMutation } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select
} from '@windmill/react-ui';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import UpdateUserGuideStatus from '../../../../../graphql/mutations/update-user-guide-status.graphql';
import GuideByIdQuery from '../../../../../graphql/queries/guide-by-id.graphql';
import ErrorDialog from '../../../../ui/error-dialog';
import LoadingSpinner from '../../../../ui/loading-spinner';
import GuideContext from '../../guide-context';

const STATUSES = ['ACTIVE', 'TRIAL', 'DEACTIVATED'];

function GuideStatusDialog(props) {
  const { guide, isOpen, onClose } = props;

  const [error, setError] = useState(null);
  const [status, setStatus] = useState(guide.guideStatus);
  const [submitting, setSubmitting] = useState(false);

  const [updateUserGuideStatus] = useMutation(UpdateUserGuideStatus, {
    refetchQueries: [
      {
        query: GuideByIdQuery,
        variables: { id: guide.id }
      }
    ]
  });

  function onSubmit() {
    const variables = {
      id: guide.id,
      status
    };

    setError(null);
    setSubmitting(true);
    updateUserGuideStatus({ variables })
      .then(() => {
        setSubmitting(false);
        onClose();
      })
      .catch((error) => {
        setError(error);
        setSubmitting(false);

        Bugsnag.notify(error, function (event) {
          event.context = 'GuideStatusDialog._onSubmit';
          event.request.variables = variables;
        });
      });
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalHeader>Update Guide Status</ModalHeader>
        <ModalBody>
          <Label className="my-4 flex flex-row items-center space-x-4">
            <span>Status</span>
            <Select
              className="p-2.5 border"
              value={status || STATUSES[0]}
              onChange={({ target: { value } }) => {
                setStatus(value === STATUSES[0] ? null : value);
              }}>
              {STATUSES.map((status) => (
                <option key={status} value={status}>
                  {capitalize(status)}
                </option>
              ))}
            </Select>
          </Label>
        </ModalBody>
        <ModalFooter>
          <Button
            className="w-full sm:w-auto"
            layout="outline"
            disabled={submitting}
            onClick={onClose}>
            Nevermind
          </Button>
          <Button
            disabled={status === guide.guideStatus || submitting}
            onClick={onSubmit}>
            {submitting ? (
              <>
                <LoadingSpinner className="w-6 h-6" />
                &nbsp;
              </>
            ) : (
              'Update'
            )}
          </Button>
        </ModalFooter>
      </Modal>
      <ErrorDialog
        error={error}
        onClose={() => {
          setError(null);
        }}
      />
    </>
  );
}
GuideStatusDialog.propTypes = {
  guide: PropTypes.shape({
    id: PropTypes.string,
    guideStatus: PropTypes.string
  }).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};
function GuideStatusDialogWithGuideContext(props) {
  return (
    <GuideContext.Consumer>
      {(guide) => <GuideStatusDialog guide={guide} {...props} />}
    </GuideContext.Consumer>
  );
}
export default GuideStatusDialogWithGuideContext;
