import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { LogoNavyWithTag } from '../../../assets';
import AppNav from '../app-nav';
import Presence from '../presence';

const ref = React.createRef();

export { ref };

export default function AppHeader() {
  const location = useLocation();

  const isHome = location.pathname === '/';

  return (
    <div
      ref={ref}
      className={`w-full flex justify-center ${
        isHome ? 'py-12' : 'py-3 border-b-2'
      }`}>
      <div className="flex flex-row items-center justify-between w-full max-w-screen-xl px-4 sm:justify-evenly">
        <div className="sm:w-4/12">
          <Link to={{ pathname: '/', search: '', hash: '' }}>
            <img className="h-11" src={LogoNavyWithTag} alt="LifeGuides Logo" />
          </Link>
        </div>
        <Presence />
        <AppNav />
      </div>
    </div>
  );
}
