import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, Header } from 'semantic-ui-react';

import ChannelsQuery from '../../../graphql/queries/all-channels.graphql';
import history from '../../../history';
import graphql from '../../hoc/graphql';
import FullscreenLoader from '../../ui/fullscreen-loader/index';

@graphql(ChannelsQuery, { name: 'channels' })
class Resources extends Component {
  static propTypes = {
    channels: PropTypes.shape({
      channels: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          slug: PropTypes.string,
          title: PropTypes.string
        })
      ),
      loading: PropTypes.bool.isRequired
    }).isRequired
  };

  render() {
    return (
      <div className="resources px-4">
        <Header>Resources</Header>
        {this._renderChannels()}
      </div>
    );
  }

  _renderChannels() {
    const { channels, loading } = this.props.channels;

    if (loading) {
      return <FullscreenLoader />;
    }

    return (
      <Card.Group itemsPerRow={4}>
        {channels.map(this._renderChannel)}
      </Card.Group>
    );
  }

  _renderChannel = (channel) => {
    const url = `/${channel.slug}/resources`;

    return (
      <Card
        key={`channel-${channel.id}`}
        href={url}
        onClick={(event) => {
          event.preventDefault();
          history.push(url);
        }}>
        <Card.Content>
          <Card.Header>{channel.title}</Card.Header>
        </Card.Content>
      </Card>
    );
  };
}
export default Resources;
