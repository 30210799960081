import PropTypes from 'prop-types';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { ROLES } from '../../../consts';
import withUser from '../../hoc/with-user';

@withUser({ authenticated: true, roles: [ROLES.CLIENT] })
@withRouter
class ClientDashboard extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { history } = this.props;

    history.replace('/');
  }

  render() {
    return null;
  }
}
export default ClientDashboard;
