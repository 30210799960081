import { useQuery } from '@apollo/client';
import qs from 'qs';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Icon, Table } from 'semantic-ui-react';

import AchievementsQuery from '../../../../graphql/queries/achievements.graphql';
import history from '../../../../history';
import ErrorMessage from '../../../ui/error-message';
import LoadingSpinner from '../../../ui/loading-spinner';

const DEFAULT_IMAGE_URL =
  'https://react.semantic-ui.com/images/wireframe/image.png';

export default function AchievementsList() {
  const location = useLocation();
  const { data, error, loading, refetch } = useQuery(AchievementsQuery, {
    notifyOnNetworkStatusChange: true
  });

  function createUrl(path, params) {
    const returnUrl = `${location.pathname}${location.search}`;
    const paramsStr = qs.stringify(params);

    return `${location.pathname}${path}${location.search}${
      location.search ? '&' : '?'
    }${paramsStr}&returnUrl=${encodeURIComponent(returnUrl)}`;
  }

  function renderAchievement(achievement) {
    return (
      <Table.Row key={achievement.id}>
        <Table.Cell>
          <img
            src={achievement.imageUrl || DEFAULT_IMAGE_URL}
            alt={`${achievement.title} image`}
            style={{ width: '50px' }}
          />
        </Table.Cell>
        <Table.Cell>{achievement.title}</Table.Cell>
        <Table.Cell textAlign="right">
          <Button
            onClick={() => {
              history.push(createUrl('/dialog/guides', { id: achievement.id }));
            }}>
            <Icon name="users" className="lineawesome" />
            View Guides
          </Button>
        </Table.Cell>
        <Table.Cell textAlign="right">
          <Button
            onClick={() => {
              history.push(createUrl('/dialog/edit', { id: achievement.id }));
            }}>
            <Icon name="edit" />
            Edit Achievement
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }

  function renderAchievements() {
    if (error) {
      return (
        <Table.Row>
          <Table.Cell colSpan={3}>
            <ErrorMessage error={error} retry={() => refetch()} />
          </Table.Cell>
        </Table.Row>
      );
    }

    if (loading) {
      return (
        <Table.Row>
          <Table.Cell colSpan={3}>
            <LoadingSpinner className="mx-auto w-48 h-48" />
          </Table.Cell>
        </Table.Row>
      );
    }

    return data.achievements.map(renderAchievement);
  }

  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Title</Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderAchievements()}</Table.Body>
    </Table>
  );
}
