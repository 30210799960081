import { useQuery } from '@apollo/client';
import qs from 'qs';
import React, { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import GuideByIdQuery from '../../../graphql/queries/guide-by-id.graphql';
import UserToggledPresenceSubscription from '../../../graphql/subscriptions/user-toggled-presence.graphql';
import history from '../../../history';
import ErrorMessage from '../../ui/error-message/index';
import LoadingSpinner from '../../ui/loading-spinner/index';
import GuideContext from './guide-context';
import ProfileView from './profile-view';

export default function GuideProfile() {
  const location = useLocation();
  const match = useRouteMatch();

  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  const { data, error, loading, refetch, subscribeToMore } = useQuery(
    GuideByIdQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables: { id: match.params.id }
    }
  );

  useEffect(
    function () {
      if (!data) {
        return null;
      }

      const subscription = subscribeToMore({
        document: UserToggledPresenceSubscription,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData) {
            return prev;
          }
          const { node } = subscriptionData.data.userToggledPresence;

          if (node.id !== prev.user.id) {
            return prev;
          }

          const user = { ...prev.user, isPresent: node.isPresent };

          return {
            ...prev,
            user
          };
        }
      });

      return function cleanUp() {
        subscription();
      };
    },
    [data, subscribeToMore]
  );

  function renderProfileView(id) {
    return (
      <GuideContext.Provider value={data && data.user}>
        <ProfileView id={id} />
      </GuideContext.Provider>
    );
  }

  function renderContent() {
    if (error) {
      return <ErrorMessage error={error} retry={() => refetch()} />;
    }
    if (loading) {
      return <LoadingSpinner className="w-48 h-48 my-4 mx-auto" />;
    }

    if (!data.user) {
      history.push('/404');
      return null;
    }

    if (search.revisionId) {
      return renderProfileView(search.revisionId);
    }

    const [guideProfile] = data.user.guideProfiles;

    return renderProfileView(guideProfile ? guideProfile.id : null);
  }

  return renderContent();
}
