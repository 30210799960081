import Bugsnag from '@bugsnag/js';
import PropTypes from 'prop-types';
import React from 'react';

import { childContextTypes } from '../../ServiceWorkerProvider';
import ErrorMessage from '../../ui/error-message';

class ErrorBoundary extends React.Component {
  static contextTypes = childContextTypes;

  static getDerivedStateFromError(error) {
    return { error };
  }

  state = {
    error: null
  };

  componentDidCatch(error, errorInfo) {
    Bugsnag.notify(error, function (event) {
      event.context = 'ErrorBoundary.componentDidCatch';
      event.severity = 'info';
    });

    if (error.name === 'ChunkLoadError') {
      this.context.reloadNow();
    }
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return <ErrorMessage error={error} />;
    }

    return children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node
};
export default ErrorBoundary;
