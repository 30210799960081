import React, { Component } from 'react';
import { Container, List, Segment } from 'semantic-ui-react';

import crisisQuestions from '../../session/crisis-refer-questions';
import assessQuestions from '../../session/crisis-assess-questions';
import debriefActions from '../../session/crisis-debrief-actions';

class ConciergeCrisisProtocol extends Component {
  render() {
    return (
      <Container>
        <Segment className={`crisis`}>
          <div className="p-2">{this._renderCrisisPolicyIntro()}</div>
          <div className="p-4 call-client-header">Steps to Take</div>
          <div className="p-2">{this._renderCrisisAssess()}</div>
          <div className="p-2">{this._renderCrisisRefer()}</div>
          <div className="p-2">{this._renderCrisisAfterCallDebrief()}</div>
          <div className="p-2">{this._renderCrisisPolicyConclusion()}</div>
        </Segment>
      </Container>
    );
  }

  _renderCrisisAfterCallDebrief() {
    return (
      <div>
        <div className="p-4 call-client-header">After the Call Debrief</div>
        {this._renderDebriefActions()}
      </div>
    );
  }

  _renderProtocolQuestions() {
    return crisisQuestions.map((q, qidx) => {
      return (
        <List.Item key={q.id} className="p-4">
          <List.Header as="">
            <span className="mr-2 text-gray-600">{qidx + 1}.</span>
            <span
              className="text-gray-800"
              dangerouslySetInnerHTML={{ __html: q.title }}
            />
            <span>:</span>
          </List.Header>
          <List.List>
            {q.questions.map((qI) => {
              return (
                <List.Item key={qI} className="flex p-1">
                  <div className="mr-2 text-gray-600">•</div>
                  <div className="text-gray-800">
                    <span dangerouslySetInnerHTML={{ __html: qI }} />
                  </div>
                </List.Item>
              );
            })}
          </List.List>
        </List.Item>
      );
    });
  }

  _renderCrisisRefer() {
    return (
      <div>
        <div className="p-4 call-client-header">Refer</div>
        <p className="p-4">
          If a member responds <i>Yes</i> to the <b>Plan</b> and <b>Means</b>
          &nbsp; Questions, immediately refer to a Crisis Specialist by doing
          the following:
        </p>
        {this._renderProtocolQuestions()}
      </div>
    );
  }

  _renderAssessQuestions() {
    return (
      <List>
        {assessQuestions.map((q, i) => {
          return (
            <List.Item key={`question-${i}`} className="flex p-1">
              <span className="ml-4 mr-2 text-gray-600">{`${i + 1}.`}</span>
              <span dangerouslySetInnerHTML={{ __html: q }}></span>
            </List.Item>
          );
        })}
      </List>
    );
  }

  _renderDebriefActions() {
    return debriefActions.map((q, qidx) => {
      return (
        <List.Item key={q.id} className="p-4">
          <List.Header as="">
            <span className="mr-2 text-gray-600">{qidx + 1}.</span>
            <span
              className="text-gray-800"
              dangerouslySetInnerHTML={{ __html: q.title }}
            />
            <span>:</span>
          </List.Header>
          <List.List>
            {q.actions.map((qI) => {
              return (
                <List.Item key={qI} className="flex p-1">
                  <div className="mr-2 text-gray-600">•</div>
                  <div className="text-gray-800">
                    <span dangerouslySetInnerHTML={{ __html: qI }} />
                  </div>
                </List.Item>
              );
            })}
          </List.List>
        </List.Item>
      );
    });
  }

  _renderCrisisAssess() {
    return (
      <div>
        <div className="p-4 call-client-header">Assess</div>
        <p className="p-4">
          In order to effectively assess if a member is in crisis, and to
          determine the next steps, please employ yes/no assessment questions.
          Begin by gathering essential information through direct inquiries that
          elicit clear yes or no responses.
        </p>
        {this._renderAssessQuestions()}
      </div>
    );
  }

  _renderCrisisPolicyIntro() {
    return (
      <div>
        <div className="p-4 call-client-header">Crisis Protocol</div>
        <p className="p-4">
          While we don’t expect numerous crisis events, if a member in a session
          indicates that they are in active crisis / suicidal, it is crucial for
          us to be prepared.
        </p>
        <p className="p-4">
          If a member is in crisis, remain calm and composed while speaking to
          the member. Offer reassurance and let them know that you are there to
          help. Please note, if your member is a veteran and he/she begins to
          speak about their time in service, Guides should refrain from taking
          notes.
        </p>
        <p className="p-4">
          The Crisis Button is located on your dashboard in the upper right
          corner. Click the Crisis Button and follow the steps. What is outlined
          below, is what you will see when you click on the Crisis Button.
        </p>
      </div>
    );
  }

  _renderCrisisPolicyConclusion() {
    return (
      <div>
        <p className="p-4">
          The incident will be documented. LifeGuides will need to document the
          general details of the conversation, including the member’s
          statements, actions taken, and resources provided. Accurate
          documentation is essential for follow-up, accountability, and ensuring
          the member’s safety.
        </p>
        <p className="p-4">
          If possible, follow up with the member after the initial conversation
          to check on their well-being. Express genuine concern and offer
          ongoing support, encouraging them to continue seeking help from mental
          health professionals.
        </p>
        <div className="p-4 call-client-header">Follow-up care for Guides:</div>
        <p className="p-4">
          It is the policy of LifeGuides to provide support and resources for
          any Guides involved in the call. Dealing with members in crisis or
          members who are suicidal can be emotionally challenging; Guides will
          have access to continued debriefing sessions, as needed, with a Guide
          Mentor, Dr. Craig C., or Dr. Dave Hester, VP Guides Community.
        </p>
      </div>
    );
  }
}
export default ConciergeCrisisProtocol;
