import PropTypes from 'prop-types';
import React from 'react';
import { Header, Modal } from 'semantic-ui-react';

import Copy from './copy';

function PrivacyPolicyDialog(props) {
  const { visible, onClose } = props;

  return (
    <Modal size="large" closeIcon onClose={onClose} open={visible}>
      <Modal.Content>
        <Modal.Description style={{ textAlign: 'center' }}>
          <Header as="h1" style={{ fontWeight: 'normal' }}>
            Privacy Policy
          </Header>
          <div style={{ padding: '1em 2em' }}>
            <Copy />
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}
PrivacyPolicyDialog.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool
};
export default PrivacyPolicyDialog;
