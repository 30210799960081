import './send-message-dialog.css';

import { graphql } from '@apollo/client/react/hoc';
import Bugsnag from '@bugsnag/js';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Header, Icon, Message, Modal } from 'semantic-ui-react';

import SendMessageMutation from '../../../graphql/mutations/send-message.graphql';
import withUser from '../../hoc/with-user';
import Avatar from '../../ui/avatar';
import ErrorDialog from '../../ui/error-dialog';
import MessageForm from './send-message-form';

@withUser({ authenticated: true })
@graphql(SendMessageMutation, { name: 'sendMessage' })
@withRouter
class SendMessage extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    sendMessage: PropTypes.func.isRequired,
    session: PropTypes.shape({
      id: PropTypes.string,
      client: PropTypes.object,
      guide: PropTypes.object
    }),
    user: PropTypes.object.isRequired
  };

  state = {
    error: null,
    isMessageValid: false,
    message: '',
    submitting: false,
    success: false
  };

  _getOtherUser() {
    const { session } = this.props;
    const { User } = this.props.user;

    return [session.client, session.guide]
      .filter((x) => !!x)
      .find((user) => user.id !== User.id);
  }

  render() {
    const { session } = this.props;
    const { error } = this.state;

    if (!session) {
      return null;
    }

    return (
      <>
        {this._renderMessageModal()}
        {this._renderSuccessModal()}
        <ErrorDialog
          error={error}
          onClose={() => {
            this.setState({ error: null });
          }}
        />
      </>
    );
  }

  _renderMessageModal() {
    const { open, onClose } = this.props;
    const { isMessageValid, submitting } = this.state;

    if (!open) {
      return null;
    }

    const otherUser = this._getOtherUser();

    return (
      <Modal
        open
        size="small"
        className="send-message-modal"
        onClose={() => {
          this.setState({ messaging: false });
        }}>
        <Modal.Content>
          <div className="header-container">
            <Avatar user={otherUser} />
            <Header as="h3">
              Send a one-way SMS message to {otherUser.firstName}
            </Header>
          </div>
          <Message
            icon={<Icon name="sms" className="lineawesome" />}
            header="Your number stays private"
            content={`This will send a single text (SMS) message to ${otherUser.firstName}. You can send additional messages by submitting this form again. Replies can only be made from within the application by submitting this form. We use technology to hide your personal phone number so your information stays private.`}
          />
          <MessageForm
            onChange={({ message }) => {
              this.setState({ message });
            }}
            onValidate={(isValid) => {
              this.setState({ isMessageValid: isValid });
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={submitting} onClick={onClose}>
            Nevermind
          </Button>
          <Button
            primary
            disabled={!isMessageValid || submitting}
            loading={submitting}
            onClick={this._onSendMessageClick}>
            <Icon size="large" name="paper plane" />
            Send Text
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  _renderSuccessModal() {
    const { onClose } = this.props;
    const { success } = this.state;

    const otherUser = this._getOtherUser();

    return (
      <Modal
        open={success}
        size="tiny"
        header={
          <Header>
            <Icon name="checkmark" circular color="green" />
            <Header.Content>Your Message Has Been Sent</Header.Content>
          </Header>
        }
        content={
          <Message>
            Your text message has been sent
            {otherUser ? ` to ${otherUser.firstName}` : ''}. Please use this
            form again if you need to communicate further.
          </Message>
        }
        actions={[
          {
            key: 'ok',
            content: 'OK'
          }
        ]}
        onActionClick={() => {
          this.setState({ success: false });

          if (onClose) {
            onClose();
          }
        }}
      />
    );
  }

  _onSendMessageClick = () => {
    const { sendMessage } = this.props;
    const { message } = this.state;

    const otherUser = this._getOtherUser();

    const variables = {
      message,
      toId: otherUser.id
    };

    this.setState({ error: null, submitting: true, success: false });
    sendMessage({ variables })
      .then(() => {
        this.setState({
          messaging: false,
          submitting: false,
          success: true
        });
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'SendMessage._onSendMessageClick';
          event.request.variables = variables;
        });
      });
  };
}

export default SendMessage;
