import './profile-step.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

@withRouter
class ProfileStep extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired,
    step: PropTypes.object.isRequired
  };

  render() {
    const { step, index, history } = this.props;

    return (
      <Button
        key={`profile-step-${index}`}
        className="profile-step incomplete-btn"
        onClick={() => {
          history.push(step.to);
        }}>
        <Icon name={step.icon} size="large" className="lineawesome circular" />
        <div className="incomplete-text-container">
          <span className="incomplete-btn-title">{step.title}</span>
          <span className="incomplete-btn-subtitle">{step.subtitle}</span>
        </div>
      </Button>
    );
  }
}

export default ProfileStep;
