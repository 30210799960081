import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Checkbox, Dropdown, Form, Input } from 'semantic-ui-react';

import CompanyByIdQuery from '../../../../../graphql/queries/company-by-id.graphql';
import PricesQuery from '../../../../../graphql/queries/prices.graphql';
import MyForm, { FormField, PhoneNumberInput } from '../../../../ui/form';
import RichTextEditor from '../../../../ui/rich-text-editor/index';

function mapData(company) {
  return {
    employeeAssistanceProgram: '',
    name: '',
    menuItems: [],
    paysEmployeeSubscription: false,
    priceIds: [],
    primaryPhone: '',
    ...(company && {
      employeeAssistanceProgram: company.employeeAssistanceProgram || '',
      name: company.name || '',
      menuItems: company.menuItems,
      paysEmployeeSubscription: company.paysEmployeeSubscription || false,
      priceIds: company.priceIds,
      primaryPhone: company.primaryPhone || ''
    })
  };
}

@withRouter
@graphql(CompanyByIdQuery, {
  name: 'company',
  skip: ({ location }) => {
    const search = qs.parse(location.search, { ignoreQueryPrefix: true });
    return !search.id;
  },
  options: ({ location }) => {
    const search = qs.parse(location.search, { ignoreQueryPrefix: true });
    const variables = {
      id: search.id
    };
    return { variables };
  }
})
@graphql(PricesQuery, { name: 'prices' })
class AdminCompanyEditForm extends Component {
  static propTypes = {
    company: PropTypes.shape({
      company: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      }),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    onDataChange: PropTypes.func,
    onValidate: PropTypes.func,
    prices: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      prices: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      )
    })
  };

  constructor(props) {
    super(props);

    const { company } = this.props.company || {};

    this.state = {
      data: mapData(company)
    };
  }

  componentDidMount() {
    this._onDataChange(this.state.data);
  }

  componentDidUpdate(prevProps) {
    const { company } = this.props.company || {};

    if (
      company &&
      (!prevProps.company ||
        !prevProps.company.company ||
        company !== prevProps.company.company)
    ) {
      const data = mapData(company);
      this._onDataChange(data);
    }
  }

  render() {
    const { onValidate } = this.props;
    const { loading: pricesLoading, prices } = this.props.prices;
    const { data } = this.state;

    return (
      <MyForm data={data} onChange={this._onDataChange} onValidate={onValidate}>
        <FormField
          component={Input}
          name="name"
          label="Name"
          type="text"
          autoComplete="off"
          validator={({ name }) => {
            if (!name) {
              throw new Error('Name is required');
            }
          }}
        />
        <FormField
          component={PhoneNumberInput}
          name="primaryPhone"
          label="Primary Phone #"
          aria-label="Primary Phone Number"
          validator={({ primaryPhone }) => {
            if (!primaryPhone) {
              throw new Error('Primary Phone # is required');
            }
          }}
        />
        <FormField
          component={Checkbox}
          name="paysEmployeeSubscription"
          label="Company Payment"
          toggle
          checked={data.paysEmployeeSubscription}
        />
        {!data.paysEmployeeSubscription && (
          <FormField
            component={Dropdown}
            name="priceIds"
            label="Prices"
            fluid
            selection
            multiple
            loading={pricesLoading}
            options={
              prices
                ? prices.map((price) => ({
                    key: `price-${price.id}`,
                    text: `${price.nickname}`,
                    value: price.id
                  }))
                : []
            }
          />
        )}
        <Form.Field>
          <label>Employee Assistance Program offerings</label>
          <RichTextEditor
            format="html"
            value={data.employeeAssistanceProgram}
            onChange={(value) => {
              this._onDataChange({ ...data, employeeAssistanceProgram: value });
            }}
          />
        </Form.Field>
      </MyForm>
    );
  }

  _onDataChange = (data) => {
    const { onDataChange } = this.props;

    this.setState({ data });

    const parsed = {
      ...data,
      priceIds: data.paysEmployeeSubscription ? [] : data.priceIds
    };

    if (onDataChange) {
      onDataChange(parsed);
    }
  };
}
export default AdminCompanyEditForm;
