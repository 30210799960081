export default [
  {
    id: 1,
    title: 'Contact LifeGuides Crisis Coordinator',
    actions: [
      'crisis@lifeguides.com & CC your team lead',
      'Your name & immediate contact information',
      'Member name & contact information',
      'Date and time of the call',
      'Content of concerns relayed to crisis professional services',
      'Follow up agreement (Text, phone call) and timeframe (24/48 hrs)'
    ]
  },
  {
    id: 2,
    title: 'Debrief process',
    actions: [
      'Contact your team lead to schedule a debriefing session with them',
      'Schedule a meeting with the LifeGuides Crisis Manager / VP Guides Community (Your team lead will coordinate this) so that the incident can be documented within an incident report.',
      'An incident report will be completed during the debrief.'
    ]
  }
];
