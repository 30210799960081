import { useMutation } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Confirm, Modal } from 'semantic-ui-react';

import DeleteAchievementMutation from '../../../../../graphql/mutations/delete-achievement.graphql';
import UploadFileMutation from '../../../../../graphql/mutations/upload-file.graphql';
import UpsertAchievementMutation from '../../../../../graphql/mutations/upsert-achievement.graphql';
import AchievementsQuery from '../../../../../graphql/queries/achievements.graphql';
import ErrorDialog from '../../../../ui/error-dialog';
import EditForm from '../forms/edit';
import ImageForm from '../forms/image';

function AchievementEditDialog(props) {
  const { onClose, open } = props;

  const [data, setData] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [deleteAchievement] = useMutation(DeleteAchievementMutation, {
    options: {
      refetchQueries: [{ query: AchievementsQuery }]
    }
  });
  const [uploadFile] = useMutation(UploadFileMutation);
  const [upsertAchievement] = useMutation(UpsertAchievementMutation, {
    options: {
      refetchQueries: [{ query: AchievementsQuery }]
    }
  });

  const location = useLocation();

  const search = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isNew = !search.id;

  const onDataChange = useCallback((data) => {
    setData(data);
  }, []);

  function onValidate(errors) {
    setIsValid(isEmpty(errors));
  }

  function onError(error) {
    setError(error);
  }

  function onFileSelect(file) {
    setFile(file);
  }

  function onClickDelete() {
    setIsDeleting(true);
  }

  function onDelete() {
    const search = qs.parse(location.search, { ignoreQueryPrefix: true });

    const variables = {
      id: search.id
    };

    setError(null);
    setSubmitting(true);

    return deleteAchievement({ variables })
      .then(() => {
        setSubmitting(false);
        onClose();
      })
      .catch((error) => {
        setError(error);
        setSubmitting(false);

        Bugsnag.notify(error, function (event) {
          event.context = 'AdminAchievementEditDialog._onDelete';
        });
      });
  }

  function uploadImage() {
    const variables = { file };
    return uploadFile({ variables }).then(({ data }) => {
      setFile(null);

      return data.uploadFile;
    });
  }

  function onSubmit() {
    const search = qs.parse(location.search, { ignoreQueryPrefix: true });

    const variables = {
      id: search.id || '',
      ...data
    };

    setError(null);
    setSubmitting(true);

    const fileRequest = file
      ? uploadImage().then((img) => {
          if (img) {
            variables.imageUrl = img.url;
          }
        })
      : Promise.resolve();

    fileRequest
      .then(() => {
        return upsertAchievement({ variables }).then(() => {
          setSubmitting(false);
          onClose();
        });
      })
      .catch((error) => {
        setError(error);
        setSubmitting(false);

        Bugsnag.notify(error, function (event) {
          event.context = 'AdminAchievementEditDialog._onSubmit';
        });
      });
  }

  return (
    <>
      <Modal closeIcon open={open} onClose={onClose}>
        <Modal.Header>{isNew ? 'Create' : 'Edit'} Achievement</Modal.Header>
        <Modal.Content>
          <ImageForm
            onError={onError}
            onFileSelect={onFileSelect}
            submitting={submitting}
          />
          <br />
          <EditForm
            onDataChange={onDataChange}
            onValidate={onValidate}
            submitting={submitting}
          />
        </Modal.Content>
        <Modal.Actions>
          {!isNew && (
            <Button
              disabled={submitting}
              floated="left"
              onClick={onClickDelete}>
              Delete
            </Button>
          )}
          <Button disabled={submitting} onClick={onClose}>
            Nevermind
          </Button>
          <Button
            primary
            disabled={!isValid || submitting}
            loading={submitting}
            onClick={onSubmit}>
            {isNew ? 'Create' : 'Update'}
          </Button>
        </Modal.Actions>
      </Modal>
      <Confirm
        open={isDeleting}
        content="Are you sure you want to delete this achievement?"
        onCancel={() => setIsDeleting(false)}
        onConfirm={onDelete}
      />
      <ErrorDialog
        error={error}
        onClose={function () {
          setError(null);
        }}
      />
    </>
  );
}
AchievementEditDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool
};
export default AchievementEditDialog;
