import '../../../ui/powerful-cta/index.css';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal, Segment } from 'semantic-ui-react';

import { ROLES } from '../../../../consts';
import InviteClientMutation from '../../../../graphql/mutations/invite-client.graphql';
import AllUsersCorpAdminPaginationQuery from '../../../../graphql/queries/all-users-corp-admin-pagination.graphql';
import ClientInviteUsersCorpAdminPaginationQuery from '../../../../graphql/queries/client-invite-users-corp-admin-pagination.graphql';
import ClientUsersCorpAdminPaginationQuery from '../../../../graphql/queries/client-users-corp-admin-pagination.graphql';
import ClientUsersInactiveCorpAdminPaginationQuery from '../../../../graphql/queries/client-users-inactive-corp-admin-pagination.graphql';
import graphql from '../../../hoc/graphql';
import withCompanies from '../../../hoc/with-companies';
import withUser from '../../../hoc/with-user';
import ErrorDialog from '../../../ui/error-dialog';
import Form from './invite-client-form';
import { paginationVariables, skipUnlessStatus } from './params';

@withUser({ authenticated: [ROLES.CORP_ADMIN] })
@withCompanies({ loader: <Segment basic loading /> })
@graphql(InviteClientMutation, {
  name: 'inviteClient',
  options: (props) => ({
    refetchQueries: [
      ...(skipUnlessStatus('all')(props)
        ? []
        : [
            {
              query: AllUsersCorpAdminPaginationQuery,
              variables: paginationVariables(props).variables
            }
          ]),
      ...(skipUnlessStatus('invited')(props)
        ? []
        : [
            {
              query: ClientInviteUsersCorpAdminPaginationQuery,
              variables: paginationVariables(props).variables
            }
          ]),
      ...(skipUnlessStatus('registered')(props)
        ? []
        : [
            {
              query: ClientUsersCorpAdminPaginationQuery,
              variables: paginationVariables(props).variables
            }
          ]),
      ...(skipUnlessStatus('inactive')(props)
        ? []
        : [
            {
              query: ClientUsersInactiveCorpAdminPaginationQuery,
              variables: paginationVariables(props).variables
            }
          ])
    ]
  })
})
class InviteClientDialog extends Component {
  static propTypes = {
    companies: PropTypes.shape({
      companies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      ).isRequired
    }).isRequired,
    inviteClient: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string
      }).isRequired
    }).isRequired
  };

  state = {
    data: null,
    error: null,
    isValid: false,
    submitting: false
  };

  render() {
    const { error, isValid, submitting } = this.state;

    return (
      <Modal
        className="corp-admin-invite-client-dialog"
        open
        size="tiny"
        closeIcon={true}
        onClose={this._onClose}>
        <Modal.Header>Invite New User</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form
              onDataChange={this._onDataChange}
              onValidate={this._onValidate}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="powerful"
            loading={submitting}
            disabled={!isValid || submitting}
            onClick={this._onSubmit}>
            Send Client Invite
          </Button>
          <ErrorDialog
            error={error}
            onClose={() => {
              this.setState({ error: null });
            }}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  _onClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  _onDataChange = (data) => {
    this.setState({ data });
  };

  _onValidate = (errors) => {
    this.setState({ isValid: isEmpty(errors) });
  };

  _onSubmit = () => {
    const { inviteClient, onSubmit } = this.props;
    const { companies } = this.props.companies;
    const { data } = this.state;

    const [company] = companies;

    const variables = {
      ...data,
      companyId: company.id,
      corporateEmailAddress: data.emailAddress,
      isSpouse: false
    };

    this.setState({ error: null, submitting: true });
    inviteClient({ variables })
      .then(() => {
        this.setState({ submitting: false });

        if (onSubmit) {
          onSubmit(data);
        }
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
      });
  };
}
export default InviteClientDialog;
