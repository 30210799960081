import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Button, Container, Header } from 'semantic-ui-react';

import history from '../../../../history';
import List from './list';
import Routes from './routes';

export default function Achievements() {
  const location = useLocation();
  const match = useRouteMatch();

  function onClickCreate() {
    const returnUrl = `${location.pathname}${location.search}`;

    history.push(
      `${match.url}/dialog/edit${location.search}${
        location.search ? '&' : '?'
      }returnUrl=${encodeURIComponent(returnUrl)}`
    );
  }

  return (
    <div className="my-8">
      <div className="flex flex-row justify-between items-center">
        <Header>Achievements</Header>
        <Button
          icon="plus"
          content="Create Achievement"
          onClick={onClickCreate}
        />
      </div>
      <br />
      <Container textAlign="center">
        <List />
      </Container>
      <Routes />
    </div>
  );
}
