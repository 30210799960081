import './index.css';

import { Button, Modal, ModalBody, ModalFooter } from '@windmill/react-ui';
import moment from 'moment-timezone';
import React, { Component } from 'react';

import { LG } from '../../../assets';
import { childContextTypes } from '../../ServiceWorkerProvider';

class ServiceWorkerUpdate extends Component {
  static contextTypes = childContextTypes;

  state = {
    open: false,
    start: null,
    timeout: null
  };

  componentDidMount() {
    const { addStartReloadListener } = this.context;
    if (addStartReloadListener) {
      addStartReloadListener(this._onStartReload);
    }
  }

  componentWillUnmount() {
    const { removeStartReloadListener } = this.context;
    if (removeStartReloadListener) {
      removeStartReloadListener(this._onStartReload);
    }
  }

  _onStartReload = (timeout) => {
    this.setState({ open: true, start: new Date(), timeout });
    window.setInterval(() => this.forceUpdate(), 500);
  };

  render() {
    const { open, start, timeout } = this.state;

    const diff = new Date() - start;
    const remaining = timeout - diff;
    const duration = moment.duration(remaining);
    const seconds = duration.seconds();

    return (
      <Modal
        isOpen={open}
        onClose={() => {}}
        className="service-worker-update-dialog relative overflow-hidden w-full max-w-xl bg-white rounded-t-lg sm:rounded-lg sm:m-4">
        <ModalBody style={{ marginBottom: 0 }}>
          <div className="p-4 flex flex-col items-center justify-center bg-primary">
            <img src={LG} alt="LifeGuides Logo" />
            <h2 className="my-4 text-xl text-white">
              Application Updated <i className="icon check circle text-white" />
            </h2>
          </div>
          <p className="p-4 text-center text-lg">
            The latest update has been installed and requires a page refresh.
          </p>
        </ModalBody>
        <ModalFooter style={{ padding: 0, margin: 0 }}>
          <div className="p-4 w-full flex flex-col text-center space-y-2">
            <span>
              {seconds > 0
                ? `Refreshing in ${seconds} second${seconds === 1 ? '' : 's'}`
                : 'Refreshing...'}
            </span>
            <Button block onClick={this._onReloadClick}>
              Refresh
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }

  _onReloadClick = () => {
    this.context.reloadNow();
  };
}
export default ServiceWorkerUpdate;
