import './index.css';

import qs from 'qs';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ROLES } from '../../consts';
import AccessCode from '../container/access-code';
import AccessCodes from '../container/admin/access-codes';
import AdminAchievements from '../container/admin/achievements';
import Companies from '../container/admin/companies';
import GuideApplications from '../container/admin/guide-applications';
import AdminReporting from '../container/admin/reporting';
import AdminTopicGroups from '../container/admin/topic-groups';
import AdminTopics from '../container/admin/topics';
import AdminUser from '../container/admin/user';
import UserImport from '../container/admin/user-import';
import UserImports from '../container/admin/user-imports';
import UserImportUpload from '../container/admin/user-imports/upload';
import AdminUsers from '../container/admin/users';
import BioclinicaLanding from '../container/bioclinica-landing';
import ConciergeCrisisProtocol from '../container/concierge/crisis-protocol';
import ConciergeSessions from '../container/concierge/sessions';
import CorpAdminUsers from '../container/corp-admin/users';
import Dashboard from '../container/dashboard';
import GetStarted from '../container/get-started';
import Guide from '../container/guide';
import GuideApplication from '../container/guide-application';
import GuideAvailability from '../container/guide-availability';
import Guides from '../container/guides';
import SavedGuides from '../container/guides/saved';
import Home from '../container/home';
import LiveChatStandalone from '../container/live-chat-standalone';
import Login from '../container/login';
import Logout from '../container/logout';
import MySessions from '../container/my-sessions';
import GuideOnboard from '../container/onboard';
import Resources from '../container/resources';
import ChannelResources from '../container/resources/channel';
import Session from '../container/session';
import SessionTabs from '../container/session-tabs';
import Settings from '../container/settings';
import PaymentOptions from '../container/settings/payment-options';
import Profile from '../container/settings/profile';
import TopicSelection from '../container/topic-selection';
import NotAuthorizedPage from '../ui/error-pages/401';
import NotFoundPage from '../ui/error-pages/404';
import ServerErrorPage from '../ui/error-pages/500';
import BetaPage from '../ui/error-pages/beta';
import PrivacyPolicy from '../ui/privacy-policy';
import TermsAndConditions from '../ui/terms-and-conditions';
import AuthRoute from './auth-route';
import {
  BenefitsAppInjestion,
  BenefitsAppInjestionDebug
} from './benefits-app-injestion';

export default function Routes() {
  const callMatch = useRouteMatch('/call/:id');

  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/how-it-works" exact component={Home} />
      <Route path="/group/:id" exact component={Home} />

      {/* Unauthenticated routes */}
      <Route path="/application" exact component={GuideApplication} />
      <Route path="/application/:id" exact component={GuideApplication} />
      <Route path="/bioclinica" component={BioclinicaLanding} />
      <Route path="/get-started/:id" component={GetStarted} />
      <Route path="/guides" component={Guides} />
      <Route path="/guide/:id" component={Guide} />
      <Route path="/my-guides" component={SavedGuides} />
      <Route path="/login" component={Login} />
      <Route path="/onboard" exact component={GuideOnboard} />
      <Route path="/privacy-policy" exact component={PrivacyPolicy} />
      <Route
        path="/register"
        render={({ location }) => (
          <Redirect
            to={{
              pathname: '/',
              search: qs.stringify({
                ...qs.parse(location.search, { ignoreQueryPrefix: true }),
                dialog: 'REGISTER'
              }),
              hash: location.hash
            }}
          />
        )}
      />
      <Route path="/terms-and-conditions" component={TermsAndConditions} />
      <Route path="/topic-selection" component={TopicSelection} />

      {/* Special Marketing Redirection */}
      <Route
        path="/servedwithhonor"
        render={({ location }) => (
          <Redirect
            to={{
              pathname: '/guides',
              search: qs.stringify({
                // this is a specific id for a marketing promotion
                // see https://github.com/lifeguides/lifeguides-web/issues/5298
                achievementId: ['clmgm4dd707ee0697p69xpqux'],
                accessCode: 'servedwithhonor'
              })
            }}
          />
        )}
      />

      {/* Concierge routes */}
      {/* Deprecated */}
      <Route
        path="/concierge/call-scheduling"
        render={() => (
          <Redirect
            to={{
              pathname: '/concierge/session-scheduling'
            }}
          />
        )}
      />
      <Route
        path="/concierge/session-scheduling"
        render={() => <ConciergeSessions />}
      />
      <Route
        path="/concierge/crisis-protocol"
        render={() => <ConciergeCrisisProtocol />}
      />
      <Route path="/guide-availability" render={() => <GuideAvailability />} />
      <Route path="/dashboard" render={() => <Dashboard />} />
      {/* Deprecated */}
      <Route
        path="/my-calls"
        render={() => (
          <Redirect
            to={{
              pathname: '/my-sessions'
            }}
          />
        )}
      />
      <Route path="/my-sessions" render={() => <MySessions />} />
      {/* Deprecated */}
      <Route
        path="/calls"
        render={() => (
          <Redirect
            to={{
              pathname: '/sessions'
            }}
          />
        )}
      />
      {/* Deprecated */}
      <Route
        path="/call/:id"
        render={() => (
          <Redirect
            to={{
              pathname: '/session/' + callMatch.params.id
            }}
          />
        )}
      />
      <Route path="/logout" component={Logout} />
      <Route path="/session/:id" render={() => <Session />} />
      <Route path="/sessions" render={() => <SessionTabs />} />
      <Route path="/settings" exact render={() => <Settings />} />
      <Route
        path="/settings/payment-options"
        exact
        render={() => <PaymentOptions />}
      />
      <Route path="/settings/profile" render={() => <Profile />} />
      <Route path="/resources" exact component={Resources} />
      <Route path="/:channelName/resources" component={ChannelResources} />
      {/* Admin routes */}
      <Route path="/admin/access-codes" render={() => <AccessCodes />} />
      <AuthRoute
        path="/admin/achievements"
        authenticated={[ROLES.ADMIN, ROLES.GUIDE_ADMIN]}>
        <AdminAchievements />
      </AuthRoute>
      <Route path="/admin/companies" render={() => <Companies />} />
      <Route
        path="/admin/guide-applications"
        render={() => <GuideApplications />}
      />
      <Route path="/admin/reporting" render={() => <AdminReporting />} />
      <Route path="/admin/topics" render={() => <AdminTopics />} />
      <AuthRoute path="/admin/topic-groups" authenticated={[ROLES.ADMIN]}>
        <AdminTopicGroups />
      </AuthRoute>
      <Route path="/admin/users" exact render={() => <AdminUsers />} />
      <Route path="/admin/user/:id" render={() => <AdminUser />} />
      <Route path="/admin/users/imports" exact render={() => <UserImports />} />
      <Route
        path="/admin/users/imports/upload"
        exact
        render={() => <UserImportUpload />}
      />
      <Route path="/admin/users/import/:id" render={() => <UserImport />} />
      {/* Corp admin routes */}
      <Route path="/corp-admin/users" exact render={() => <CorpAdminUsers />} />
      {/* Beta features */}
      <Route
        path="/bank"
        render={() => (
          <BetaPage
            title="Earnings Reports"
            icon="usd lineawesome"
            content={
              'We look forward to allowing users to use this feature in the near future!'
            }
          />
        )}
      />
      <Route
        path="/chat-now"
        render={() => (
          <LiveChatStandalone
            title="Live Chat"
            icon="comments lineawesome"
            content="Chat now, with a LifeGuide"
          />
        )}
      />
      <Route
        path="/benefits-app-injestion"
        exact={true}
        render={() => <BenefitsAppInjestion />}
      />
      {/* <Route
        path="/benefits-app-injestion/debug"
        render={() => <BenefitsAppInjestionDebug />}
      /> */}
      {/* <Route
        path="/mobile-app-login-prompt"
        render={() => {
          return (
            <div
              style={{
                display: 'flex',
                minHeight: '95vh',
                alignItems: 'center'
              }}>
              <span>Mobile Log In Splash Screen</span>
            </div>
          );
        }}
      /> */}

      {/* Error Routes */}
      <Route path="/401" component={NotAuthorizedPage} />
      <Route path="/500" component={ServerErrorPage} />
      <Route path="/404" component={NotFoundPage} />
      <Route component={AccessCode} />
    </Switch>
  );
}
