import 'react-datepicker/dist/react-datepicker.css';

import './calendar-selector.css';

import { useQuery } from '@apollo/client';
import { Button } from '@windmill/react-ui';
import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import SessionsByGuideIdQuery from '../../../graphql/queries/sessions-by-guide-id.graphql';
import UserAvailabilitiesByUserIdQuery from '../../../graphql/queries/user-availabilities-by-user-id.graphql';
import LoadingSpinner from '../../ui/loading-spinner/index';
import { CALL_SLOTS_STEP } from '../guide/consts';
import GuideContext from '../guide/guide-context';
import { CALL_LENGTH } from '../session';

const moment = extendMoment(Moment);

//const DatePicker = lazy(() => import('react-datepicker'));

const DEFAULT_TIMEZONE = 'America/Los_Angeles';
const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm';

function nextWeekDay(d) {
  return (d + 1) % 7;
}

function dayRange(start, end) {
  const days = [];
  let i = start;
  do {
    days.push(i);
    i = nextWeekDay(i);
  } while (i != nextWeekDay(end));
  return days;
}

function mergeDateAndTime(date, time) {
  const d = moment(date.format(DATE_FORMAT), DATE_FORMAT);
  const t = moment(time.format(TIME_FORMAT), TIME_FORMAT);
  return d.hours(t.hours()).minutes(t.minutes()).toDate();
}

function CalendarSelector(props) {
  const { guide, selectedDate, selectedTime, timezone } = props;

  const getMinDate = useCallback(() => {
    const tomorrow = moment().add(1, 'day').startOf('day').toDate();
    const now = moment.tz(guide.timezone || DEFAULT_TIMEZONE);
    const cutoff = now.clone().hours(12).minutes(0).seconds(0);
    return now.isAfter(cutoff)
      ? moment(tomorrow).add(1, 'day').toDate()
      : tomorrow;
  }, [guide.timezone]);

  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [visibleDate, setVisibleDate] = useState(null);

  const { data: availabilitiesData } = useQuery(
    UserAvailabilitiesByUserIdQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables: { userId: guide.id }
    }
  );

  const { data: sessionsData } = useQuery(SessionsByGuideIdQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      afterDate: moment().subtract(1, 'month').startOf('day').toISOString(),
      guideId: guide.id
    }
  });

  const currentDate = selectedDate || getMinDate();

  const getAvailabilityRanges = useCallback(() => {
    if (!availabilitiesData) {
      return [];
    }

    const start = moment(getMinDate());
    const end = moment().add(2, 'months');
    const selectionRange = moment.range(start, end);

    return availabilitiesData.userAvailabilities.reduce((acc, availability) => {
      const baseEndTime = moment(availability.endTime);
      const endDate = baseEndTime.clone().tz(timezone).endOf('day');
      const endDay = endDate.day();

      const baseStartTime = moment(availability.startTime);
      const startDate = baseStartTime.clone().tz(timezone).startOf('day');
      const startDay = startDate.day();

      const daysOfWeek = dayRange(startDay, endDay);

      const included = {};

      if (availability.isRecurring) {
        const events = Array.from(selectionRange.by('day')).reduce(
          (acc, day) => {
            const week = day.week();
            const isOnDay = daysOfWeek.includes(day.day());

            const isEffectiveEndDateAfterDay =
              !availability.effectiveEndDate ||
              moment(availability.effectiveEndDate)
                .tz(guide.timezone || timezone)
                .endOf('day')
                .isSameOrAfter(day);
            const isEffectiveStartDateBeforeDay =
              !availability.effectiveStartDate ||
              moment(availability.effectiveStartDate)
                .tz(guide.timezone || timezone)
                .startOf('day')
                .isSameOrBefore(day);

            if (
              !isOnDay ||
              !isEffectiveEndDateAfterDay ||
              !isEffectiveStartDateBeforeDay ||
              included[week]
            ) {
              return acc;
            }

            included[week] = true;

            const shift = (m, ref) => {
              return moment(day)
                .tz(timezone)
                .day(ref)
                .hours(m.hours())
                .minutes(m.minutes());
            };

            const endTime = shift(baseEndTime.clone().tz(timezone), endDay);
            const startTime = shift(
              baseStartTime.clone().tz(timezone),
              startDay
            );

            return acc.concat([
              {
                end: mergeDateAndTime(endTime, endTime),
                start: mergeDateAndTime(startTime, startTime)
              }
            ]);
          },
          []
        );
        return acc.concat(events);
      } else {
        const endTime = baseEndTime.clone().tz(timezone);
        const startTime = baseStartTime.clone().tz(timezone);

        if (
          selectionRange.contains(startTime) ||
          selectionRange.contains(endTime)
        ) {
          return acc.concat({
            end: mergeDateAndTime(endDate, endTime),
            start: mergeDateAndTime(startDate, startTime)
          });
        }
        return acc;
      }
    }, []);
  }, [availabilitiesData, getMinDate, guide.timezone, timezone]);

  const getAvailableTimes = useCallback(
    (availabilityRanges) => {
      if (!sessionsData) {
        return [];
      }

      return availabilityRanges.reduce((acc, range) => {
        const availabilityRange = moment.range(
          range.start,
          moment(range.end).subtract(CALL_SLOTS_STEP, 'minutes')
        );
        const availabilitySlots = Array.from(
          availabilityRange.by('minutes', {
            excludeEnd: true,
            step: CALL_SLOTS_STEP * 2
          })
        );
        const unallocatedSlots = availabilitySlots.reduce((acc, time) => {
          const timeAllocated = sessionsData.sessions.some((session) => {
            const sessionStart = moment(session.scheduledTime)
              .tz(timezone)
              .subtract(CALL_LENGTH, 'minutes');
            const sessionEnd = moment(session.scheduledTime)
              .tz(timezone)
              .add(CALL_LENGTH, 'minutes');

            const sessionRange = moment.range(
              mergeDateAndTime(sessionStart, sessionStart),
              mergeDateAndTime(sessionEnd, sessionEnd)
            );
            return sessionRange.contains(time);
          });
          if (!timeAllocated) {
            return acc.concat([time]);
          }
          return acc;
        }, []);
        return acc.concat(unallocatedSlots);
      }, []);
    },
    [sessionsData, timezone]
  );

  function getAvailableTimesByDate(availableTimes, dateStr) {
    return availableTimes.filter((time) => {
      return time.format(DATE_FORMAT) === dateStr;
    });
  }

  const getAvailableDates = useCallback(
    (availabilityRanges, availableTimes) => {
      const now = moment().tz(timezone);

      const availableDates = availabilityRanges.reduce((acc, range) => {
        const dates = Array.from(
          moment
            .range(
              moment(range.start).startOf('day'),
              moment(range.end).endOf('day')
            )
            .by('day')
        ).filter((date) => {
          const times = getAvailableTimesByDate(
            availableTimes,
            date.format(DATE_FORMAT)
          );

          const hasTimes = !!times.length;
          const isBefore = date.isSameOrBefore(now, 'day');
          const isIncluded = acc.some((d) => d.isSame(date, 'day'));

          return hasTimes && !isBefore && !isIncluded;
        });
        return acc.concat(dates);
      }, []);

      availableDates.sort((a, b) => {
        return a.isBefore(b) ? -1 : 1;
      });

      return availableDates;
    },
    [timezone]
  );

  useEffect(
    function () {
      if (!availabilitiesData || !sessionsData) {
        return;
      }

      const availabilityRanges = getAvailabilityRanges();
      const availableTimes = getAvailableTimes(availabilityRanges);
      const availableDates = getAvailableDates(
        availabilityRanges,
        availableTimes
      );

      setAvailableDates(availableDates.map((m) => m.toDate()));
      setAvailableTimes(availableTimes);
      setLoading(false);
    },
    [
      availabilitiesData,
      getAvailableDates,
      getAvailabilityRanges,
      getAvailableTimes,
      sessionsData,
      timezone
    ]
  );

  function onChange(date) {
    props.onChange(date);
    setDirty(true);
  }

  function onClickTime(time) {
    props.onChange(selectedDate, time);
  }

  function renderCustomHeader({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  }) {
    return (
      <div className="flex flex-row justify-between">
        <div className="text-lg">{moment(date).format('MMMM')}</div>
        <div className="flex flex-row space-x-4">
          <button
            className="focus:outline-none"
            disabled={prevMonthButtonDisabled}
            onClick={() => {
              decreaseMonth();
              setVisibleDate(
                moment(visibleDate || currentDate)
                  .subtract(1, 'month')
                  .toDate()
              );
            }}>
            <i
              className={`icon lineawesome angle left big ${
                prevMonthButtonDisabled
                  ? 'text-gray-200 cursor-not-allowed'
                  : 'text-primary'
              }`}
            />
          </button>
          <button
            className="focus:outline-none"
            disabled={nextMonthButtonDisabled}
            onClick={() => {
              increaseMonth();
              setVisibleDate(
                moment(visibleDate || currentDate)
                  .add(1, 'month')
                  .toDate()
              );
            }}>
            <i
              className={`icon lineawesome angle right big ${
                nextMonthButtonDisabled
                  ? 'text-gray-200 cursor-not-allowed'
                  : 'text-primary'
              }`}
            />
          </button>
        </div>
      </div>
    );
  }

  function renderDay(day) {
    return <span>{day}</span>;
  }

  function renderTimes() {
    if (!selectedDate) {
      return null;
    }

    const times = getAvailableTimesByDate(
      availableTimes,
      moment(selectedDate).format(DATE_FORMAT)
    )
      .reduce((acc, time) => {
        const existing = acc.some((t) => t.isSame(time));
        if (existing) {
          return acc;
        }
        return acc.concat(time);
      }, [])
      .sort((a, b) => {
        return a.isBefore(b) ? -1 : 1;
      });

    return (
      <div className="max-h-80 px-2 pt-0.5 overflow-hidden overflow-y-auto">
        <h4 className="px-4 py-2.5 bg-gray-200 rounded-md whitespace-nowrap">
          Times I&apos;m Available
        </h4>
        <ul className="mt-2 flex flex-col space-y-2">
          {times.map((time, i) => {
            const isSelected = time.isSame(selectedTime);
            return (
              <li key={i}>
                <Button
                  block
                  layout={isSelected ? 'primary' : 'outline'}
                  className="rounded-md"
                  onClick={() => onClickTime(time.toDate())}>
                  {time.format('h:mm A')}
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  function renderContent() {
    if (loading) {
      return <LoadingSpinner className="my-4 mx-auto w-48 h-48" />;
    }

    return (
      <>
        {/* <Suspense fallback={<LoadingSpinner className="w-48 h-48 mx-auto" />}> */}
        <DatePicker
          fixedHeight
          highlightDates={availableDates}
          includeDates={availableDates}
          inline
          minDate={getMinDate()}
          onChange={onChange}
          renderDayContents={renderDay}
          renderCustomHeader={renderCustomHeader}
          selected={selectedDate}
          useWeekdaysShort={false}
        />
        {/* </Suspense> */}
        {renderTimes()}
      </>
    );
  }

  const classNames = [
    'calendar-selector flex flex-col items-center sm:items-start sm:flex-row'
  ];
  if (dirty) {
    classNames.push('dirty');
  }
  return <div className={classNames.join(' ')}>{renderContent()}</div>;
}
CalendarSelector.propTypes = {
  guide: PropTypes.shape({
    id: PropTypes.string,
    timezone: PropTypes.string
  }),
  onChange: PropTypes.func,
  selectedDate: PropTypes.object,
  selectedTime: PropTypes.object,
  timezone: PropTypes.string
};
function CalendarSelectorWithGuideContext(props) {
  return (
    <GuideContext.Consumer>
      {(guide) => <CalendarSelector guide={guide} {...props} />}
    </GuideContext.Consumer>
  );
}
export default CalendarSelectorWithGuideContext;
