import { Button, Dropdown, DropdownItem } from '@windmill/react-ui';
import debounce from 'lodash/debounce';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Checkbox from './checkbox';

function MyDropdown(props) {
  const { options, multiple, trigger, value } = props;

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = debounce(function (value) {
    setIsOpen(isBoolean(value) ? value : !isOpen);
  }, 50);

  function onClickTrigger() {
    toggleDropdown();
  }

  function onCloseDropdown() {
    toggleDropdown(false);
  }

  function onChange(value) {
    if (!props.onChange) {
      return;
    }
    props.onChange(value);
  }

  function renderOptions() {
    if (!options) {
      return null;
    }
    if (!isArray(options)) {
      throw new Error('Options must be an array');
    }
    return options.map((option) => {
      const isChecked = multiple
        ? value.includes(option.value)
        : option.value === value;
      return (
        <DropdownItem
          key={option.key}
          className="focus:outline-none"
          onClick={() => onChange(option.value)}>
          <Checkbox
            as="div"
            isChecked={isChecked}
            label={<span className="text-lg">{option.text}</span>}
          />
        </DropdownItem>
      );
    });
  }

  return (
    <div className="inline relative">
      {(multiple ? !!value.length : value) && (
        <div className="absolute -top-2 -right-2 bg-primary rounded-full w-6 h-6 text-center" />
      )}
      {React.cloneElement(trigger, { onClick: onClickTrigger })}
      <Dropdown
        className="p-6 w-auto z-10"
        align="left"
        isOpen={isOpen}
        onClose={onCloseDropdown}>
        {renderOptions()}
        <Button className="mt-6" block onClick={onCloseDropdown}>
          Done
        </Button>
      </Dropdown>
    </div>
  );
}
MyDropdown.propTypes = {
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string
    })
  ),
  trigger: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
};
export default MyDropdown;
