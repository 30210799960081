import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';

import MyForm, { FormField } from '../../ui/form';

class CallMeForm extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
  };

  render() {
    const { data, onChange } = this.props;

    return (
      <MyForm className="call-me-form" data={data} onChange={onChange}>
        <FormField
          component={Input}
          name="phoneNumber"
          label="Phone number to call"
          fluid
        />
      </MyForm>
    );
  }
}
export default CallMeForm;
