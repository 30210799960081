import './index.css';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Container, Header } from 'semantic-ui-react';

import { ROLES } from '../../../../consts';
import withUser from '../../../hoc/with-user';
import List from './list';
import { SEARCH_DEFAULTS, parseSearch } from './params';
import Routes from './routes';

@withRouter
@withUser({ authenticated: [ROLES.ADMIN] })
class AdminTopics extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  _filter = debounce((filter) => {
    this._search({ filter, page: SEARCH_DEFAULTS.page });
  }, 100);

  _search = (params) => {
    const { history, location, match } = this.props;

    const search = parseSearch(location.search);
    const updated = pickBy(
      {
        ...search,
        ...params
      },
      (value, key) => value && SEARCH_DEFAULTS[key] != value
    );
    const url =
      match.path + isEmpty(updated) ? `?${qs.stringify(updated)}` : '';

    history.push(url);
  };

  render() {
    return (
      <div className="admin-topics-component my-4">
        <div className="header-container">
          <Header>Topics</Header>
          <Button
            icon="plus"
            content="Create Topic"
            onClick={this._onClickCreate}
          />
        </div>
        <Container textAlign="center">
          <List onPageChange={this._onPageChange} />
        </Container>
        <Routes />
      </div>
    );
  }

  _onClickCreate = () => {
    const { history, location, match } = this.props;

    const returnUrl = `${location.pathname}${location.search}`;

    history.push(
      `${match.url}/dialog/edit${location.search}${
        location.search ? '&' : '?'
      }returnUrl=${encodeURIComponent(returnUrl)}`
    );
  };

  _onPageChange = (event, { activePage }) => {
    this._search({ page: activePage });
  };
}
export default AdminTopics;
