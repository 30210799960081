import './roadmap.css';
import './roadmap.css';

import { graphql } from '@apollo/client/react/hoc';
import marked from 'marked';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Image, Segment } from 'semantic-ui-react';

import RoadmapByIdQuery from '../../../graphql/queries/roadmap-by-id.graphql';
import ResourceHeader from '../../ui/resource-header';

@withRouter
@graphql(RoadmapByIdQuery, {
  name: 'roadmap',
  options: ({ match }) => {
    const variables = {
      id: match.params.id
    };
    return { variables };
  }
})
class Roadmap extends Component {
  static propTypes = {
    channel: PropTypes.shape({
      slug: PropTypes.string.isRequired
    }).isRequired,
    history: PropTypes.object.isRequired,
    roadmap: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      roadmap: PropTypes.shape({
        id: PropTypes.string,
        body: PropTypes.string,
        featuredImage: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string
          })
        }),
        title: PropTypes.string
      })
    }).isRequired
  };

  render() {
    const { channel, history } = this.props;
    const { loading, roadmap } = this.props.roadmap;

    if (loading) {
      return <Segment basic loading />;
    }

    if (!roadmap) {
      history.push('/404');
      return null;
    }

    const body = marked(roadmap.body);

    return (
      <div className="resource-roadmap-container">
        <div>
          <ResourceHeader
            title={roadmap.title}
            parentPath={`/${channel.slug}/resources/roadmaps`}
          />
          <div className="resource-roadmap">
            {roadmap.featuredImage && (
              <Image src={roadmap.featuredImage.file.url} />
            )}
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </div>
        </div>
      </div>
    );
  }
}
export default Roadmap;
