import './booking-resource-item.css';

import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Icon, Label, Popup } from 'semantic-ui-react';

class BookingResourceItem extends Component {
  static propTypes = {
    guide: PropTypes.shape({
      id: PropTypes.id,
      avatarUrl: PropTypes.string,
      firstName: PropTypes.string,
      isFeatured: PropTypes.bool,
      lastName: PropTypes.string,
      timezone: PropTypes.string
    }),
    onSelectGuide: PropTypes.func,
    rowHeight: PropTypes.number,
    selectedClient: PropTypes.shape({
      id: PropTypes.string,
      timezone: PropTypes.string
    }),
    selectedGuide: PropTypes.shape({
      id: PropTypes.string
    })
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { selectedClient, selectedGuide, guide } = this.props;
    const {
      selectedClient: _selectedClient,
      selectedGuide: _selectedGuide,
      guide: _guide
    } = nextProps;
    let _id = `${this._idOf(_selectedClient)}_${this._idOf(
      _selectedGuide
    )}_${this._idOf(_guide)}`;
    let id = `${this._idOf(selectedClient)}_${this._idOf(
      selectedGuide
    )}_${this._idOf(guide)}`;
    if (id === _id) {
      return false;
    }
    return true;
  }

  _idOf(entity) {
    return entity ? entity.id : '';
  }

  render() {
    const { onSelectGuide, selectedGuide, guide, rowHeight } = this.props;
    const url =
      guide.avatarUrl ||
      'https://react.semantic-ui.com/images/wireframe/image.png';

    let isSelected = false;

    if (selectedGuide && selectedGuide.id === guide.id) {
      isSelected = true;
    }

    let selectedClasses = isSelected ? 'active selected' : '';
    let guideNameShort = `${guide.firstName} ${guide.lastName.slice(0, 1)}.`;
    let guideNameFull = `${guide.firstName} ${guide.lastName}`;

    return (
      <div
        className={`booking-resource-item item ${selectedClasses}`}
        style={{
          minHeight: rowHeight
        }}
        onClick={() => {
          if (_.isFunction(onSelectGuide)) {
            //onSelectGuide({ selectedGuide: resource.guide });
          }
        }}>
        <img
          src={url}
          className="ui avatar image resource-item"
          style={{
            width: rowHeight - 8,
            height: rowHeight - 8,
            borderRadius: rowHeight * 0.5,
            marginRight: 4
          }}
        />
        <div className={`info`}>
          <Popup
            trigger={<span className="text">{guideNameShort}</span>}
            content={guideNameFull}></Popup>
          <div>
            {this._renderTzInfo()}
            {this._renderIsFeaturedInfo()}
          </div>
        </div>
      </div>
    );
  }

  _renderIsFeaturedInfo = () => {
    const { guide } = this.props;

    if (!guide.isFeatured) {
      return null;
    }

    return (
      <Popup
        trigger={
          <Label
            size="mini"
            basic
            color={'grey'}
            style={{
              width: 28
            }}>
            <Icon name="star" />
          </Label>
        }
        content={`This is a featured guide`}></Popup>
    );
  };

  _renderTzInfo = () => {
    const { selectedClient, guide } = this.props;

    const timezoneMismatch =
      selectedClient && selectedClient.timezone !== guide.timezone;

    let timeZoneMessage = `The Guide's timezone is ${guide.timezone}`;

    if (timezoneMismatch) {
      timeZoneMessage = `${timeZoneMessage} and does not match the member's timezone.`;
    }

    return (
      <Popup
        trigger={
          <Label size="mini" basic color={timezoneMismatch ? 'red' : 'grey'}>
            <Icon name="clock" />
            {moment.tz(guide.timezone).format('z')}
          </Label>
        }
        content={timeZoneMessage}></Popup>
    );
  };
}

export default BookingResourceItem;
