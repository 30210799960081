import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';

import { ROLE_LABELS } from '../../../../consts';

class ConfirmRoleToggleDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    role: PropTypes.string,
    submitting: PropTypes.bool,
    user: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      roles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string
        })
      )
    }).isRequired
  };

  render() {
    const { role, onClose, onSubmit, submitting, user } = this.props;

    const hasRole = !!user.roles.find((r) => r.name === role);

    return (
      <Modal open size="mini" onClose={onClose}>
        <Modal.Header>Are you sure?</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            Are you sure you want to {hasRole ? 'remove' : 'add'} the{' '}
            {ROLE_LABELS[role]} role {hasRole ? 'from' : 'to'} {user.firstName}?
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={submitting} onClick={onClose}>
            Nevermind
          </Button>
          <Button
            primary
            loading={submitting}
            disabled={submitting}
            onClick={onSubmit}>
            {hasRole ? 'Remove' : 'Add'} role
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
export default ConfirmRoleToggleDialog;
