import React from 'react';

export default function PlayIcon({
  size = 128,
  fill = '#fff',
  active = false
}) {
  const s = active ? 0 : 0; //can be a glow with 255
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      className=""
      viewBox="0 0 203 203">
      <g fill={fill} filter="url(#filter0_d_51_1142)" className="">
        <path
          className="transition-transform duration-500 ease-in-out origin-center transform"
          fill={fill}
          style={{ transform: active ? 'scale(0.95)' : 'scale(1.05)' }}
          d="M101.215 172.931c-9.613 0-18.936-1.885-27.72-5.599a70.922 70.922 0 01-22.634-15.264 70.994 70.994 0 01-15.262-22.637C31.885 120.651 30 111.322 30 101.708c0-9.613 1.885-18.937 5.599-27.722A70.91 70.91 0 0150.86 51.349a70.984 70.984 0 0122.634-15.264c8.78-3.714 18.107-5.6 27.72-5.6 9.612 0 18.935 1.886 27.72 5.6a70.909 70.909 0 0122.634 15.264 70.989 70.989 0 0115.262 22.637c3.714 8.78 5.599 18.109 5.599 27.722 0 9.614-1.885 18.943-5.599 27.723a70.992 70.992 0 01-15.262 22.637 71.012 71.012 0 01-22.634 15.264c-8.78 3.714-18.108 5.599-27.72 5.599zm0-136.998c-8.881 0-17.493 1.737-25.601 5.167-7.835 3.313-14.866 8.059-20.907 14.1a65.599 65.599 0 00-14.099 20.91c-3.43 8.109-5.167 16.722-5.167 25.603 0 8.882 1.738 17.495 5.167 25.604 3.313 7.836 8.058 14.868 14.099 20.909a65.586 65.586 0 0020.907 14.101c8.108 3.429 16.72 5.167 25.601 5.167s17.492-1.738 25.601-5.167c7.834-3.313 14.866-8.059 20.907-14.101a65.568 65.568 0 0014.098-20.909c3.43-8.109 5.167-16.722 5.167-25.604 0-8.881-1.737-17.494-5.167-25.604-3.312-7.835-8.057-14.867-14.098-20.909a65.595 65.595 0 00-20.907-14.1c-8.109-3.43-16.72-5.167-25.601-5.167z"></path>
        <path
          className="transition-transform duration-500 ease-in-out delay-500 origin-center transform"
          fill={fill}
          // style={{ transform: active ? 'scale(1.05)' : 'scale(0.95)' }}
          d="M131.76 107.907l-43.587 31.427c-5.05 3.644-12.102.031-12.102-6.193V70.286c0-6.224 7.052-9.837 12.102-6.194L131.76 95.52c4.227 3.05 4.227 9.34 0 12.388z"></path>
      </g>
      <defs>
        <filter
          id="filter0_d_51_1142"
          width="202.43"
          height="202.446"
          x="0"
          y="0.486"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="15"></feGaussianBlur>
          <feColorMatrix
            values={`
            0 0 0 ${s} 0 
            0 0 0 ${s} 0 
            0 0 0 ${s} 0 
            0 0 0 0.35 0`}></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_51_1142"></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_51_1142"
            result="shape"></feBlend>
        </filter>
      </defs>
    </svg>
  );
}
