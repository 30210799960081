import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Input } from 'semantic-ui-react';

import MyForm, { FormField } from '../../../../ui/form';

function EditMenuItemForm({ onDataChange, menuItem, onValidate }) {
  const defaultData = menuItem
    ? { label: menuItem.label, url: menuItem.url }
    : { label: '', url: '' };
  const [data, setData] = useState(defaultData);

  const onChange = useCallback(
    (d) => {
      setData(d);
      if (onDataChange) {
        onDataChange(d);
      }
    },
    [onDataChange]
  );

  return (
    <MyForm data={data} onChange={onChange} onValidate={onValidate}>
      <FormField
        component={Input}
        name="label"
        label="Label"
        type="text"
        autoComplete="off"
        validator={({ label }) => {
          if (!label) {
            throw new Error('Label is required');
          }
        }}
      />
      <FormField
        component={Input}
        name="url"
        label="URL"
        type="text"
        autoComplete="off"
        validator={({ url }) => {
          if (!url) {
            throw new Error('URL is required');
          }
        }}
      />
    </MyForm>
  );
}
EditMenuItemForm.propTypes = {
  menuItem: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string
  }),
  onDataChange: PropTypes.func,
  onValidate: PropTypes.func
};
export default EditMenuItemForm;
