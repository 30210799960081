import './reschedule-session-dialog.css';

import { useMutation } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import { Button, Modal } from '@windmill/react-ui';
import get from 'lodash/get';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import RescheduleSessionMutation from '../../../graphql/mutations/reschedule-session.graphql';
import ClientDashboardQuery from '../../../graphql/queries/client-dashboard.graphql';
import SessionsByGuideIdQuery from '../../../graphql/queries/sessions-by-guide-id.graphql';
import useUser from '../../hooks/use-user';
import ErrorDialog from '../../ui/error-dialog';
import LoadingSpinner from '../../ui/loading-spinner';
import GuideContext from '../guide/guide-context';
import Scheduler from './index';

function RescheduleSessionDialog(props) {
  const { guide, isOpen, onClose, session, timezone } = props;

  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const { data: userData } = useUser();

  const [rescheduleSession] = useMutation(RescheduleSessionMutation, {
    refetchQueries: [
      {
        query: ClientDashboardQuery,
        variables: { userId: get(userData, 'User.id', null) }
      },
      {
        query: SessionsByGuideIdQuery,
        variables: {
          afterDate: moment().subtract(1, 'month').startOf('day').toISOString(),
          guideId: guide.id
        }
      }
    ]
  });

  const isValid = !!data.scheduledTime;

  const onChange = useCallback((data) => {
    setData(data);
  }, []);

  function onSubmit() {
    const variables = {
      id: session.id,
      reason: data.reason,
      scheduledTime: data.scheduledTime
    };

    setError(null);
    setSubmitting(true);
    rescheduleSession({ variables })
      .then(({ data: { rescheduleSession } }) => {
        setSubmitting(false);
        if (props.onSubmit) {
          props.onSubmit(rescheduleSession);
        } else {
          onClose();
        }
      })
      .catch((error) => {
        setError(error);
        setSubmitting(false);

        Bugsnag.notify(error, function (event) {
          event.context = 'RescheduleSessionDialog._onSubmit';
          event.request.variables = variables;
        });
      });
  }

  const classNames = [
    'reschedule-session-dialog relative w-full max-h-full overflow-y-auto max-h-screen bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4'
  ];
  if (data.date) {
    classNames.push('sm:max-w-5xl');
  } else {
    classNames.push('sm:max-w-4xl');
  }

  return (
    <>
      <Modal className={classNames.join(' ')} isOpen={isOpen} onClose={onClose}>
        <Scheduler
          onChange={onChange}
          header="Reschedule your session"
          subHeader={`Pick a new time to talk${
            guide.firstName ? ` with ${guide.firstName}` : ''
          }`}
          timezone={timezone}>
          <div className="flex flex-row justify-end space-x-4">
            <Button
              className="w-full sm:w-auto"
              layout="outline"
              disabled={submitting}
              onClick={onClose}>
              Nevermind
            </Button>
            <Button
              className="w-full sm:w-auto"
              disabled={!isValid || submitting}
              onClick={onSubmit}>
              {submitting ? (
                <>
                  <LoadingSpinner className="w-6 h-6" />
                  &nbsp;
                </>
              ) : (
                'Confirm'
              )}
            </Button>
          </div>
        </Scheduler>
      </Modal>
      <ErrorDialog
        error={error}
        onClose={() => {
          setError(null);
        }}
      />
    </>
  );
}
RescheduleSessionDialog.propTypes = {
  guide: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  timezone: PropTypes.string,
  session: PropTypes.shape({
    id: PropTypes.string
  })
};
function RescheduleSessionDialogWithGuideContext(props) {
  return (
    <GuideContext.Consumer>
      {(guide) => <RescheduleSessionDialog guide={guide} {...props} />}
    </GuideContext.Consumer>
  );
}
export default RescheduleSessionDialogWithGuideContext;
