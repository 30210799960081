import qs from 'qs';

export const PAGE_SIZE = 10;
export const SORT_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC'
};
export const SEARCH_DEFAULTS = {
  page: 1,
  sort: 'createdAt',
  direction: SORT_DIRECTIONS.DESC,
  status: 'all'
};

export function parseSearch(str) {
  const search = qs.parse(str, { ignoreQueryPrefix: true });
  return {
    ...search,
    page: parseInt(search.page, 10) || SEARCH_DEFAULTS.page,
    sort: search.sort || SEARCH_DEFAULTS.sort,
    direction: search.direction || SEARCH_DEFAULTS.direction,
    status: search.status || SEARCH_DEFAULTS.status
  };
}

export function paginationVariables(props) {
  const { location } = props;
  const { companies } = props.companies;
  const { User } = props.user;
  const [company] = companies;

  const search = parseSearch(location.search);
  const skip = search.page > 0 ? (search.page - 1) * PAGE_SIZE : 0;
  const first = PAGE_SIZE;
  const orderBy = `${search.sort}_${search.direction}`;
  const variables = {
    first,
    skip,
    orderBy,
    companyId: company.id,
    searchTerm: search.filter ? search.filter.toLowerCase() : '',
    userId: User.id
  };

  return { fetchPolicy: 'network-only', variables };
}

export function skipUnlessStatus(status) {
  return ({ location }) => {
    const search = parseSearch(location.search);
    return search.status !== status;
  };
}
