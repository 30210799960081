import './index.css';
import './semantic-overrides.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Divider } from 'semantic-ui-react';

import BookingTimeSelector from '../booking-time-selector';
import CalendarSelector from '../calendar-selector';
import ClientSelector from '../client-selector';
import ReasonForm from '../reason-form';
import TopicSelector from '../topic-selector';
import { TIMES_PROP_TYPE } from './proptypes';

class CreateSessionForm extends Component {
  state = {
    data: {
      reason: '',
      selectedClient: null,
      selectedTopics: [],
      selectedGuide: null,
      selectedDayOf: new Date(),
      selectedDateTime: new Date(),
      selectedTimezone: null
    }
  };

  render() {
    const { data } = this.state;

    const {
      selectedClient,
      selectedTopics,
      selectedGuide,
      selectedDateTime,
      selectedDayOf
    } = data;

    let bookingSelector =
      selectedTopics.length > 0 ? (
        <>
          <div className="create-call-time-selector container-row">
            <BookingTimeSelector
              selectedGuide={selectedGuide}
              selectedClient={selectedClient}
              selectedDateTime={selectedDateTime}
              selectedDayOf={selectedDayOf}
              selectedTopics={selectedTopics}
              onSelectGuide={this._onChange}
              onSelectBooking={this._onChange}
              onSelectTimezone={this._onChange}
              onChange={(data) => {
                //this.setState(data);
              }}
            />
          </div>
          <div>
            <Divider />
          </div>
        </>
      ) : null;

    return (
      <div className="concierge-create-call-request-form">
        <div className="create-call-people-selector container-row">
          <ClientSelector onChange={this._onChange} />
          <TopicSelector onChange={this._onChange} />
          <CalendarSelector
            selectedDateTime={selectedDateTime}
            selectedTopics={selectedTopics}
            selectedClient={selectedClient}
            onChange={this._onChange}
          />
        </div>
        <div>
          <Divider />
        </div>
        {bookingSelector}
        <div className="create-call-reason container-row">
          <ReasonForm onChange={this._onChange} />
        </div>
      </div>
    );
  }

  _onChange = (_data) => {
    const { onDataChange } = this.props;
    const { data } = this.state;

    const updatedData = { ...data, ..._data };

    if (_.isFunction(onDataChange)) {
      const {
        reason,
        selectedClient,
        selectedTopics,
        selectedGuide,
        selectedDateTime,
        selectedTimezone
      } = updatedData;

      onDataChange({
        reason,
        topicIds: selectedTopics.map((topic) => topic.id),
        clientId: selectedClient ? selectedClient.id : null,
        guideId: selectedGuide ? selectedGuide.id : null,
        selectedClientTimezone: selectedClient ? selectedClient.timezone : null,
        selectedTimezone:
          selectedTimezone || (selectedClient && selectedClient.timezone),
        selectedDateTime
      });
    }

    this.setState({ data: updatedData });
  };

  static propTypes = {
    onDataChange: PropTypes.func,
    onValidate: PropTypes.func,
    guideBookedTimes: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      sessions: PropTypes.arrayOf(TIMES_PROP_TYPE)
    })
  };
}
export default CreateSessionForm;
