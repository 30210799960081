import PropTypes from 'prop-types';
import React, { Component } from 'react';
import countries from 'react-select-country-list';
import { Dropdown, Form, Input } from 'semantic-ui-react';

import { ROLES } from '../../../../consts';
import withUser from '../../../hoc/with-user';
import withWidth, { isWidthDown } from '../../../hoc/with-width';
import MyForm, { FormField, PhoneNumberInput } from '../../../ui/form';
import TimezoneSelector from '../../../ui/timezone-selector';

const MAXLENGTH_FIELD = 75;

const countryData = countries().getData();
const usaFirstReorderedCountries = [
  { value: 'US', label: 'United States' },
  ...countryData.filter((c) => c.value !== 'US' && c.label !== 'United States')
];

function getUserData(user) {
  if (!user) {
    return null;
  }
  return {
    address: user.address || '',
    age: user.age || '',
    bio: user.bio || '',
    city: user.city || '',
    country: user.country || '',
    educationLevel: user.educationLevel || null,
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber || '',
    shortIntro: user.shortIntro || '',
    state: user.state || '',
    timezone: user.timezone || '',
    zipcode: user.zipcode || ''
  };
}

@withUser({ authenticated: true })
@withWidth()
class BasicInfoForm extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    onValidate: PropTypes.func,
    user: PropTypes.shape({
      User: PropTypes.shape({
        age: PropTypes.number,
        address: PropTypes.string,
        bio: PropTypes.string,
        educationLevel: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        phoneNumber: PropTypes.string,
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          })
        ),
        timezone: PropTypes.string,
        zipcode: PropTypes.string
      })
    }).isRequired,
    width: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);

    const { User } = props.user;

    const data = {
      address: '',
      age: '',
      bio: '',
      city: '',
      country: '',
      educationLevel: null,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      shortIntro: '',
      state: '',
      timezone: '',
      zipcode: '',
      ...getUserData(User)
    };

    this.state = {
      data
    };
  }

  componentDidUpdate(prevProps) {
    const userChanged =
      (!prevProps.user.User && this.props.user.User) ||
      prevProps.user.User !== this.props.user.User;
    if (!userChanged) {
      return;
    }

    const { User } = this.props.user;
    const { data } = this.state;

    this._onChange({
      ...data,
      ...getUserData(User)
    });
  }

  render() {
    const { onValidate, width } = this.props;
    const { User } = this.props.user;
    const { data } = this.state;

    const isTablet = isWidthDown('computer', width);
    const isGuide = User.roles.some((role) => role.name === ROLES.GUIDE);

    return (
      <MyForm
        className="profile-form"
        data={data}
        onChange={this._onChange}
        onValidate={onValidate}>
        <Form.Group widths="equal" grouped={isTablet}>
          <FormField
            component={Input}
            name="firstName"
            label="First Name"
            aria-placeholder="First Name"
            validator={({ firstName }) => {
              if (!firstName) {
                throw new Error('First name is required');
              }
              if (firstName.length > MAXLENGTH_FIELD) {
                throw new Error(
                  `First name cannot be longer than ${MAXLENGTH_FIELD} letters`
                );
              }
            }}
          />
          <FormField
            component={Input}
            name="lastName"
            label="Last Name"
            aria-placeholder="Last Name"
            validator={({ lastName }) => {
              if (!lastName) {
                throw new Error('Last name is required');
              }
              if (lastName.length > MAXLENGTH_FIELD) {
                throw new Error(
                  `Last name cannot be longer than ${MAXLENGTH_FIELD} letters`
                );
              }
            }}
          />
        </Form.Group>
        {!isGuide && (
          <FormField
            component={Input}
            name="age"
            type="number"
            label="Age"
            aria-placeholder="Age"
            validator={({ age }) => {
              if ((age && age < 1) || age > 125) {
                throw new Error('Age needs to be between 1 and 125');
              }
            }}
          />
        )}
        {!isGuide && (
          <>
            <Form.Group
              widths="equal"
              aria-placeholder="Enter your city and state">
              <FormField
                component={Input}
                name="city"
                label="City"
                aria-placeholder="City"
              />
              <FormField
                component={Input}
                name="state"
                label="State/Territory"
                aria-placeholder="US States and Terrotories"
              />
            </Form.Group>
            <FormField
              component={Dropdown}
              name="country"
              label="Country"
              aira-required="true"
              fluid
              selection
              search
              options={usaFirstReorderedCountries.map((country) => ({
                key: country.label,
                value: country.label,
                text: country.label,
                selected: data.country === country.label
              }))}
              validator={({ country }) => {
                if (!country) {
                  throw new Error('Must select a country');
                }
              }}
            />
          </>
        )}
        <FormField
          component={PhoneNumberInput}
          name="phoneNumber"
          label="Personal Mobile Number"
          aria-label="Personal Mobile Number"
          aria-placeholder="Personal Mobile Number"
          validator={({ phoneNumber }) => {
            if (!phoneNumber) {
              throw new Error('Must enter a mobile number');
            }
          }}
        />
        <FormField
          component={TimezoneSelector}
          name="timezone"
          label="Time Zone"
          aria-placeholder="timezone"
          fluid
          selection
        />
      </MyForm>
    );
  }

  _onChange = (data) => {
    const { onChange } = this.props;

    this.setState({ data });

    if (onChange) {
      onChange(data);
    }
  };
}
export default BasicInfoForm;
