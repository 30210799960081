const ProfileSteps = [
  {
    title: 'Add a Photo',
    subtitle: 'Go to Profile Settings',
    icon: 'camera',
    to: '/settings/profile',
    condition: (user) => {
      return !user.avatarUrl;
    }
  },
  {
    title: 'Add a Location',
    subtitle: 'Go to Profile Settings',
    icon: 'globe',
    to: '/settings/profile',
    condition: (user) => {
      return !user.city || !user.state;
    }
  },
  {
    title: 'Share Your Story',
    subtitle: 'Go to Profile Settings',
    icon: 'pencil',
    to: '/settings/profile',
    condition: (user) => {
      return !user.bio;
    }
  },
  {
    title: 'Add Your Education',
    subtitle: 'Go to Profile Settings',
    icon: 'graduation cap',
    to: '/settings/profile',
    condition: (user) => {
      return !user.educationLevel;
    }
  }
];

export default ProfileSteps;
