
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PriceInfo"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Price"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"active"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"billingScheme"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"currency"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"nickname"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"recurringInterval"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"recurringIntervalCount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"tiersMode"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"trialPeriodDays"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"unitAmount"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":191}};
    doc.loc.source = {"body":"fragment PriceInfo on Price {\n  id\n  active\n  billingScheme\n  createdAt\n  currency\n  nickname\n  recurringInterval\n  recurringIntervalCount\n  tiersMode\n  trialPeriodDays\n  type\n  unitAmount\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
