export default [
  {
    id: 1,
    title: 'Clarify and confirm what they said',
    questions: [
      '“I thought I heard you say __________. Did I hear that right?”',
      '“Are you planning on hurting yourself/others?”',
      '“Do you have means (weapon) you intend to use?”'
    ]
  },
  {
    id: 2,
    title: 'Tell them',
    questions: [
      'I really appreciate your honesty with me, as your guide it is my duty to get you to proper resources.',
      'Please give me your phone number, so I may have another join our session',
      'Do I have your permission to provide your contact information to a Crisis Counselor?'
    ]
  },
  {
    id: 3,
    title: 'If the member <b>is an IMMINENT THREAT</b> to self or others',
    questions: [
      'Request the member’s address and <b>call 911</b>.',
      'Please give me your phone number, so I may have another join our session',
      'The Guide can stay on the call when calling 911 to offer a safe place for the member and to keep the situation from escalating.'
    ]
  },
  {
    id: 4,
    title: 'If the member is <b>NOT an imminent threat</b> to self or others',
    questions: [
      'Call Crisis Professional Services at 1-800-273-8255 on personal phone, put call on speaker',
      'Introduce yourself and member',
      'TRecord the Crisis Counselor’s Name/ID and time of the call (for privacy purposes, this information is not to be added to notes; however, it will be required for an incident report)',
      'Provide phone number of member to crisis professional',
      'Explain concerns in simple terms',
      'Request the Crisis Counselor to contact the member immediately',
      'Allow services to speak with member',
      'The Guide should <b>not stay on the line when a member is transferred to a Suicide Hotline or practitioner</b>. If they are asked by the member to stay on the line then they should omit any diagnosis or treatments from the notes as they would not normally be privy to the information'
    ]
  },
  {
    id: 5,
    title: 'If the Member does not acknowledge CRISIS',
    questions: [
      'Encourage them to use crisis services to get support and/or continue LifeGuides',
      'Reassure them they can get through this life challenge, do not give up- get help'
    ]
  }
];
