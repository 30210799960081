import { useEffect } from 'react';

import history from '../../../history';

export default function ConciergeDashboard() {
  useEffect(function () {
    history.push('/concierge/call-scheduling');
  }, []);

  return null;
}
