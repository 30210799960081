import PropTypes from 'prop-types';
import React from 'react';
import { useDropzone } from 'react-dropzone';

function ImageDropzone(props) {
  const { children, onDrop } = props;

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: 'image/*',
    maxSize: 20971520,
    onDrop
  });

  const overlayClasses = [
    'absolute inset-0 border-4 border-dotted flex items-center justify-center cursor-pointer z-10'
  ];
  const iconClasses = ['icon file upload large text-white'];
  if (isDragAccept) {
    overlayClasses.push('border-green-300');
    iconClasses.push('text-green-300');
  } else if (isDragReject) {
    overlayClasses.push('border-red-600');
    iconClasses.push('text-red-600');
  }

  return (
    <div className="relative w-full z-10" {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
      <div className={overlayClasses.join(' ')}>
        <i
          className={iconClasses.join(' ')}
          style={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 1)' }}
        />
      </div>
    </div>
  );
}
ImageDropzone.propTypes = {
  children: PropTypes.node,
  onDrop: PropTypes.func
};
export default ImageDropzone;
