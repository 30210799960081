import './index.css';

import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Header, Message } from 'semantic-ui-react';

import { setAuthToken } from '../../../auth';
import AuthenticateWithEmailLinkMutation from '../../../graphql/mutations/authenticate-with-email-link.graphql';
import EmailLinkByIdQuery from '../../../graphql/queries/session-email-link-by-id.graphql';
import graphql from '../../hoc/graphql';
import withUser from '../../hoc/with-user';
import FullscreenLoader from '../../ui/fullscreen-loader/index';

function GetStarted(props) {
  const { authenticate, history } = props;
  const { sessionEmailLink, loading: sessionLoading } = props.emailLink;
  const { User, loading: userLoading } = props.user;

  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  function authenticateUser() {
    const variables = {
      id: sessionEmailLink.id
    };

    setError(null);
    setSubmitting(true);
    return authenticate({ variables })
      .then(({ data }) => {
        setSubmitting(false);

        return setAuthToken(data.authenticateWithEmailLink.authToken).then(
          function () {
            history.replace(`/session/${sessionEmailLink.session.id}`);
          }
        );
      })
      .catch((error) => {
        setError(error);
        setSubmitting(false);
      });
  }

  useEffect(
    function () {
      if (sessionLoading || userLoading) {
        return;
      }

      if (!sessionEmailLink) {
        history.replace('/404');
        return;
      }

      if (User) {
        if (User.id !== sessionEmailLink.user.id) {
          const isSessionClient =
            sessionEmailLink.session.client.id === User.id;
          const isSessionGuide = sessionEmailLink.session.guide.id === User.id;
          if (!(isSessionClient || isSessionGuide)) {
            return;
          }
        }

        history.replace(`/session/${sessionEmailLink.session.id}`);
        return;
      }

      if (error || submitting) {
        return;
      }

      authenticateUser();
    },
    [sessionEmailLink, User]
  );

  if (sessionLoading || userLoading || submitting) {
    return <FullscreenLoader />;
  }

  const wasSentToclient =
    sessionEmailLink.user.id === sessionEmailLink.session.client.id;

  if (User && sessionEmailLink && User.id !== sessionEmailLink.user.id) {
    return (
      <div className="get-started">
        <Header>You followed a link belonging to a different account.</Header>
        <Message compact>
          If you received a system e-mail with the link then you may be logged
          in on different accounts on different devices.
        </Message>
        {wasSentToclient && (
          <div className="actions">
            <Button onClick={authenticate}>
              Continue as the account that received the e-mail
            </Button>
          </div>
        )}
      </div>
    );
  }

  return <FullscreenLoader />;
}

GetStarted.propTypes = {
  authenticate: PropTypes.func.isRequired,
  emailLink: PropTypes.shape({
    sessionEmailLink: PropTypes.shape({
      id: PropTypes.string,
      session: PropTypes.shape({
        id: PropTypes.string,
        client: PropTypes.shape({
          id: PropTypes.string
        }),
        guide: PropTypes.shape({
          id: PropTypes.string
        })
      }),
      user: PropTypes.shape({
        id: PropTypes.string
      })
    }),
    loading: PropTypes.bool.isRequired
  }).isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    User: PropTypes.shape({
      id: PropTypes.string,
      roles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string
        })
      )
    })
  }).isRequired
};

const withAuthenticateMutation = graphql(AuthenticateWithEmailLinkMutation, {
  name: 'authenticate'
});
const withEmailLinkQuery = graphql(EmailLinkByIdQuery, {
  name: 'emailLink',
  options: ({ match }) => {
    const variables = {
      id: match.params.id
    };
    return { variables };
  }
});
const withUserQuery = withUser({ loader: <FullscreenLoader /> });

export default flow([
  withRouter,
  withAuthenticateMutation,
  withEmailLinkQuery,
  withUserQuery
])(GetStarted);
