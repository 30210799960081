import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Segment,
  Input,
  Label,
  List,
  TextArea,
  Divider,
  Header,
  Message
} from 'semantic-ui-react';
import qs from 'qs';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import {
  AUTH_0_DOMAIN,
  AUTH_TOKEN_NAME,
  REACT_APP_GRAPHQL_URL,
  REACT_APP_AUTH_0_CLIENT_ID,
  REACT_APP_TOKEN_EXCHANGE_URL
} from '../../consts';
import LoadingSpinner from '../ui/loading-spinner/index';

export function BenefitsAppInjestionDebug() {
  return <BenefitsAppInjestion debug={true} />;
}

function ConstInfo({ title }) {
  const items = [
    { label: 'title', value: title },
    { label: 'AUTH_0_DOMAIN', value: AUTH_0_DOMAIN },
    { label: 'AUTH_TOKEN_NAME', value: AUTH_TOKEN_NAME },
    { label: 'REACT_APP_GRAPHQL_URL', value: REACT_APP_GRAPHQL_URL },
    { label: 'REACT_APP_AUTH_0_CLIENT_ID', value: REACT_APP_AUTH_0_CLIENT_ID },
    {
      label: 'REACT_APP_TOKEN_EXCHANGE_URL',
      value: REACT_APP_TOKEN_EXCHANGE_URL
    }
  ];

  return (
    <List
      style={{
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        textAlign: 'left',
        gap: '2fr'
      }}>
      {items.map((item) => {
        return (
          <List.Item key={item.label}>
            <List.Icon
              name="circle outline"
              size="large"
              verticalAlign="middle"
            />
            <List.Content>
              <List.Header>{item.value}</List.Header>
              <List.Description>{item.label}</List.Description>
            </List.Content>
          </List.Item>
        );
      })}
    </List>
  );
}

function BaIngestionDebugForm({
  isAuthenticated,
  user,
  email,
  setEmail,
  loginWithRedirect,
  loginWithRedirectConfig,
  logout,
  setAuth0Token,
  setSessionToken,
  params,
  auth0token,
  sessionToken,
  error,
  title
}) {
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const textAreaStyle = {
    width: '100%',
    minHeight: '15rem'
  };

  return (
    <div>
      <Segment style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
        <Header>Test Salesforce ID provider Integration</Header>
        <div
          className="info"
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            gap: '1rem'
          }}>
          <div>
            <br />
            {user?.picture && <img src={user.picture} alt={user.name} />}
          </div>
          <div>
            <label>Is Authenticated?</label>
            <Label>{isAuthenticated ? 'Yes' : 'No'}</Label>
            <Divider />
            <label>Email</label>
            <Input
              type="text"
              style={{ width: '100%' }}
              value={email}
              onChange={(eve) => {
                setEmail(eve.target.value);
              }}></Input>
            <Divider basic={'true'} />
            <Button
              onClick={async () => {
                loginWithRedirect(loginWithRedirectConfig);
              }}>
              Log in
            </Button>
            <Button onClick={logout}>Log out</Button>
          </div>
        </div>
      </Segment>
      <ConstInfo title={title} />
      <Segment placeholder>
        <label>Auth Config</label>
        <Divider basic={'true'} />
        <pre>
          <code>{JSON.stringify(loginWithRedirectConfig, null, 2)}</code>
        </pre>
      </Segment>
      <Segment className="info">
        <label>QueryParams</label>
        <Divider basic={'true'} />
        <pre>
          <code>{JSON.stringify(params, null, 2)}</code>
        </pre>
      </Segment>
      <Segment className="info">
        <label>USER</label>
        <Divider basic={'true'} />
        <TextArea
          readOnly
          value={JSON.stringify(user, null, 2)}
          style={textAreaStyle}
        />
      </Segment>
      <Segment className="info">
        <label>Auth 0 Token</label>
        <Divider basic={'true'} />
        <TextArea
          readOnly
          value={JSON.stringify(auth0token, null, 2)}
          style={textAreaStyle}
        />
      </Segment>
      <Segment className="info">
        <label>Session Token </label>
        <Divider basic={'true'} />
        <TextArea
          readOnly
          value={JSON.stringify(sessionToken, null, 2)}
          style={textAreaStyle}
        />
      </Segment>
    </div>
  );
}

export function BenefitsAppInjestion({ debug = false }) {
  //popup
  //let redirect_uri = window.location.origin;

  //redirect (this removes the initial params replaces them with "code")
  let redirect_uri = `${window.location.origin}${window.location.pathname}`;
  if (debug) {
    redirect_uri = window.location;
  }

  console.log({ redirect_uri });

  return (
    <Auth0Provider
      domain={AUTH_0_DOMAIN}
      clientId={REACT_APP_AUTH_0_CLIENT_ID}
      cacheLocation="localstorage"
      useRefreshTokensFallback={true}
      audience="https://lifeguides.auth0.com/api/v2/"
      scope="openid profile email user_metadata"
      authorizationParams={{
        redirect_uri: redirect_uri
      }}>
      <BenefitsAppInjestionLogic debug={debug} />
    </Auth0Provider>
  );
}

export function BenefitsAppInjestionLogic({ debug = false }) {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    loginWithPopup,
    logout,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    getIdTokenClaims
  } = useAuth0();

  const params = window?.location
    ? qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
        // let special chars pass through,
        // the other devs don't understand how to encode uri's
        decoder: (char) => char
      })
    : null;

  const connection = params?.connection || 'BenefitsAppProduction';

  const [email, setEmail] = useState(params?.email || '');
  //possible future use.
  const [accessCode, setAccessCode] = useState(params?.companyCode || '');

  const [auth0CodeParam, setAuth0CodeParam] = useState(params?.code || '');

  const [auth0token, setAuth0Token] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);
  const [err, setError] = useState(null);

  const loginWithRedirectConfig = {
    authorizationParams: {
      connection: connection,
      login_hint: email,
      email: email,
      response_type: 'profile id_token',
      scope: 'openid profile email user_metadata',
      acr_values: `email:${email}`,
      audience: 'https://lifeguides.auth0.com/api/v2/'
    }
  };

  useEffect(() => {
    if (debug === false && !user && email) {
      try {
        setTimeout(() => {
          console.log('loginWithPopup');
          //loginWithPopup(loginWithRedirectConfig);

          loginWithRedirect(loginWithRedirectConfig);
        }, 500);
      } catch (loginError) {
        console.log('LOGIN ERROR:');
        console.error(loginError);
        setError(loginError);
      }
    }

    if (user) {
      const hydrateAuthToken = async () => {
        try {
          const claims = await getIdTokenClaims();
          setAuth0Token(claims.__raw);
        } catch (err) {
          console.error(err);
          setError(err);
        }
      };

      const hydrateSessionToken = async () => {
        console.log('HYDRATING SESSION TOKEN');
        let sessionTokenResponse = await fetch(REACT_APP_TOKEN_EXCHANGE_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'ngrok-skip-browser-warning': 1
          },
          body: JSON.stringify({
            token: auth0token
            // companyCode: 'zoop-zoop'
            //?? was there a company code too? include it here
          })
        });

        let json = await sessionTokenResponse.json();

        //console.log(json);

        window.localStorage.setItem(AUTH_TOKEN_NAME, json.authToken);
        setSessionToken(json.authToken);
        if (!debug) {
          setTimeout(() => {
            window.location = window.location.origin;
          }, 500);
        }
      };

      if (!auth0token) {
        try {
          hydrateAuthToken();
        } catch (err) {
          console.error(err);
          setError(err);
        }
      }

      if (auth0token && !sessionToken) {
        try {
          hydrateSessionToken();
        } catch (err) {
          console.error(err);
          setError(err);
        }
      }
    }
  }, [
    user,
    auth0token,
    sessionToken,
    debug,
    email,
    loginWithRedirect,
    getIdTokenClaims
  ]);

  let debugForm = null;

  if (!email && !auth0CodeParam) {
    return (
      <Message warning={true}>
        <Message.Header>Could Not Authenticate</Message.Header>
        <p>
          Email is required to authenticate. Please contact your administrator
        </p>
      </Message>
    );
  }

  if (err) {
    return (
      <Message error={true}>
        <Message.Header>Could Not Authenticate</Message.Header>
        <p>Please contact your administrator</p>
        <p>{err.message || err}</p>
      </Message>
    );
  }

  if (debug) {
    debugForm = (
      <BaIngestionDebugForm
        isAuthenticated={isAuthenticated}
        user={user}
        error={error}
        email={email}
        setEmail={setEmail}
        loginWithRedirect={loginWithRedirect}
        loginWithRedirectConfig={loginWithRedirectConfig}
        logout={() => {
          logout();
          setAuth0Token(null);
          setSessionToken(null);
          window.localStorage.removeItem(AUTH_TOKEN_NAME);
        }}
        auth0token={auth0token}
        setAuth0Token={setAuth0Token}
        setSessionToken={setSessionToken}
        sessionToken={sessionToken}
        params={params}
        title={'Benefits App Injestion Debug Form'}
      />
    );
  }

  return (
    <Segment basic loading={isLoading}>
      {debugForm}
    </Segment>
  );
}
