import { useQuery } from '@apollo/client';
import { Button } from '@windmill/react-ui';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { GetStartedStepTitle } from './get-started-step-title';

import TopicGroupsQuery from '../../../graphql/queries/topic-groups-with-topics.graphql';
import history from '../../../history';
import LoadingSpinner from '../../ui/loading-spinner';

const DEFAULT_TOPIC_GROUP_COLOR = '#fff6e5';
const DEFAULT_TOPIC_GROUP_IMAGE_URL =
  'https://react.semantic-ui.com/images/wireframe/image.png';
const TOPIC_GROUP_LIMIT = 3;

function Topic({ id, onClick, title }) {
  const location = useLocation();
  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  const isSelected = search.topicId && search.topicId.includes(id);

  const iconClasses = ['icon square large bg-white'];
  if (isSelected) {
    iconClasses.push('check text-primary');
  } else {
    iconClasses.push('outline text-gray-400');
  }

  return (
    <button
      className="flex flex-row items-center px-5 my-5 space-x-5 focus:outline-none"
      onClick={onClick}>
      <i
        className={iconClasses.join(' ')}
        style={{ margin: '0 0 0 -.125rem' }}
      />
      <span className="text-lg text-left">{title}</span>
    </button>
  );
}
Topic.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string
};

function TopicGroup({ id, color, imageUrl, onTopicClick, title, topics }) {
  function renderTopic(topic) {
    return (
      <Topic
        key={topic.id}
        {...topic}
        onClick={function () {
          onTopicClick(topic);
        }}
      />
    );
  }

  function renderTopics() {
    return <div>{topics.map(renderTopic)}</div>;
  }

  return (
    <div className="border rounded-md md:w-4/12 border-gray-40">
      <h2
        className="flex flex-row items-center p-4 space-x-4 text-xl font-extrabold"
        style={{
          backgroundColor: color || DEFAULT_TOPIC_GROUP_COLOR
        }}>
        <img
          src={imageUrl || DEFAULT_TOPIC_GROUP_IMAGE_URL}
          alt={title}
          className="w-8 rounded-full"
        />
        <span>{title}</span>
      </h2>
      {renderTopics()}
    </div>
  );
}
TopicGroup.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  imageUrl: PropTypes.string,
  onTopicClick: PropTypes.func,
  title: PropTypes.string,
  topics: PropTypes.arrayOf(PropTypes.shape(Topic.propTypes))
};

export default function TopicSelection() {
  const { data, error, loading } = useQuery(TopicGroupsQuery);

  const location = useLocation();
  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  const selectedTopicGroupIds = search.topicGroupId || [];
  const selectedTopicIds = search.topicId || [];
  const canContinue = !!selectedTopicIds.length;

  function toggleTopic(topic) {
    const newSelectedTopicIds = [...selectedTopicIds];
    const index = newSelectedTopicIds.indexOf(topic.id);
    if (index >= 0) {
      newSelectedTopicIds.splice(index, 1);
    } else {
      newSelectedTopicIds.push(topic.id);
    }

    history.replace({
      pathname: location.pathname,
      search: qs.stringify({
        topicGroupId: selectedTopicGroupIds,
        topicId: newSelectedTopicIds
      })
    });
  }

  function renderTopicGroup(topicGroup) {
    return (
      <TopicGroup
        key={topicGroup.id}
        {...topicGroup}
        onTopicClick={toggleTopic}
      />
    );
  }

  function renderTopicGroups() {
    if (error) {
      return <div>Error</div>;
    }

    if (loading) {
      return <LoadingSpinner className="w-48 h-48 m-auto" />;
    }

    const { topicGroups } = data;

    const filteredTopicGroups = topicGroups
      .filter((topicGroup) => selectedTopicGroupIds.includes(topicGroup.id))
      .slice(0, TOPIC_GROUP_LIMIT);

    return (
      <div className="flex flex-col my-8 space-y-4 md:flex-row md:space-y-0 md:space-x-4 md:justify-center">
        {filteredTopicGroups.map(renderTopicGroup)}
      </div>
    );
  }

  function goBack() {
    history.push({
      pathname: '/',
      hash: 'topics',
      search: qs.stringify({
        topicGroupId: search.topicGroupId
      })
    });
  }

  function submit() {
    history.push({
      pathname: '/guides',
      search: qs.stringify({ topicId: selectedTopicIds })
    });
  }

  return (
    <div className="flex justify-center w-full px-4 pt-12 mb-16">
      <div className="w-full max-w-screen-xl">
        <GetStartedStepTitle
          stepNumber={2}
          title={"Let's dig a little deeper"}
          description={<span>Select your sub-topics to tell us more.</span>}
        />
        {renderTopicGroups()}
        <div className="flex flex-row items-center justify-between">
          <Button layout="outline" size="large" onClick={goBack}>
            Back
          </Button>
          <Button
            size="large"
            disabled={canContinue ? '' : true}
            onClick={submit}>
            View Guides
          </Button>
        </div>
      </div>
    </div>
  );
}
