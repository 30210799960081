import './session-notes-dialog.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Header, Modal } from 'semantic-ui-react';

import ClientSessionNotesQuery from '../../../graphql/queries/client-session-notes.graphql';
import graphql from '../../hoc/graphql';
import withUser from '../../hoc/with-user';
import Avatar from '../../ui/avatar';
import FullscreenLoader from '../../ui/fullscreen-loader';
import SessionNotesList from '../../ui/session-notes-list';

@graphql(ClientSessionNotesQuery, {
  name: 'clientSessionNotes',
  options: ({ session: { client } }) => ({
    variables: { clientId: client.id }
  })
})
@withUser()
export default class SessionNotesDialog extends Component {
  static propTypes = {
    clientSessionNotes: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      sessionNotes: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    session: PropTypes.shape({
      client: PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired
      }).isRequired
    }),
    user: PropTypes.shape({
      User: PropTypes.object.isRequired
    }).isRequired
  };

  render() {
    const { onClose, open, session } = this.props;
    const { loading, sessionNotes } = this.props.clientSessionNotes;
    const { User } = this.props.user;

    return (
      <Modal
        size="large"
        className="session-notes-dialog"
        open={open}
        onClose={onClose}>
        <Modal.Content>
          <Header size="medium">Notes</Header>
          <Header size="small">
            {session?.client?.firstName}{' '}
            {session?.client?.lastName?.[0]?.concat('.') || ''}
          </Header>
          <Avatar user={session.client} />
        </Modal.Content>
        <Modal.Content scrolling>
          {loading ? (
            <FullscreenLoader />
          ) : (
            <SessionNotesList
              session={session}
              notes={sessionNotes}
              user={User}
            />
          )}
        </Modal.Content>
      </Modal>
    );
  }
}
