import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import range from 'lodash/range';
import { useCallback, useContext, useEffect, useState } from 'react';

import GuideProfileByIdQuery from '../../../../graphql/queries/guide-profile-by-id.graphql';
import GuideContext from '../guide-context';
import { useEditing } from './actions';

const QUALIFICATIONS_COUNT = 3;

const GUIDE_DEFAULTS = {
  age: null,
  avatarUrl: '',
  city: '',
  country: '',
  state: ''
};
const GUIDE_PROFILE_DEFAULTS = {
  availabilities: [],
  biography: '',
  headerImageUrl: '',
  topics: [],
  qualifications: range(QUALIFICATIONS_COUNT).map(() => '')
};

export function useData(id) {
  const guide = useContext(GuideContext);

  const [data, setData] = useState({
    ...GUIDE_DEFAULTS,
    ...GUIDE_PROFILE_DEFAULTS
  });
  const [isDirty, setIsDirty] = useState(false);

  const [isEditing] = useEditing();

  const { data: guideProfileData } = useQuery(GuideProfileByIdQuery, {
    notifyOnNetworkStatusChange: true,
    skip: !id,
    variables: { id }
  });
  const guideProfile = get(
    guideProfileData,
    'guideProfile',
    GUIDE_PROFILE_DEFAULTS
  );

  useEffect(
    function () {
      if (isEditing) {
        return;
      }
      setIsDirty(false);
    },
    [isEditing]
  );

  useEffect(
    function () {
      if (isDirty) {
        return;
      }
      setData({
        ...GUIDE_DEFAULTS,
        ...GUIDE_PROFILE_DEFAULTS,
        ...(guide && {
          age: guide.age,
          avatarUrl: guide.avatarUrl,
          city: guide.city,
          country: guide.country,
          state: guide.state
        }),
        ...(guideProfile && {
          availabilities: guideProfile.availabilities,
          biography: guideProfile.biography,
          headerImageUrl: guideProfile.headerImageUrl,
          topics: guideProfile.topics.map((topicDescription) => ({
            ...topicDescription
          })),
          qualifications: [...guideProfile.qualifications]
        })
      });
    },
    [isDirty, guide, guideProfile]
  );

  const callback = useCallback((newData) => {
    setData((data) => ({
      ...data,
      ...newData
    }));
    setIsDirty(true);
  }, []);

  return { data, isDirty, setData: callback };
}
