import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Message
} from 'semantic-ui-react';

import { PhoneWav } from '../../../assets';
import MyForm, { FormField } from '../../ui/form';
import Video from './video';
import VolumeIndicator from './volume-indicator';

const CAN_SET_SINK_ID = 'setSinkId' in HTMLMediaElement.prototype;

class DevicesForm extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    sinkId: PropTypes.string,
    tracks: PropTypes.arrayOf(PropTypes.object).isRequired
  };

  constructor(props) {
    super(props);

    this._audioRef = React.createRef();

    this.state = {
      audioPlaying: false,
      data: {
        audioId: null,
        sinkId: props.sinkId,
        videoId: null
      },
      devices: []
    };
  }

  componentDidMount() {
    this._getMediaDevices();
    this._setDataFromTracks();

    this._audioRef.current.addEventListener('play', this._onAudioPlay);
    this._audioRef.current.addEventListener('ended', this._onAudioEnded);
  }

  componentDidUpdate(prevProps) {
    const { tracks } = this.props;
    if (tracks === prevProps.tracks) {
      return;
    }

    this._setDataFromTracks();
  }

  componentWillUnmount() {
    this._audioRef.current.removeEventListener('play', this._onAudioPlay);
    this._audioRef.current.removeEventListener('ended', this._onAudioEnded);
  }

  _setDataFromTracks() {
    const { tracks } = this.props;
    const { data } = this.state;

    const audioTrack = tracks.find((track) => track.kind === 'audio');
    const videoTrack = tracks.find((track) => track.kind === 'video');

    this.setState({
      data: {
        ...data,
        audioId: audioTrack && audioTrack.getCapabilities().deviceId,
        videoId: videoTrack && videoTrack.getCapabilities().deviceId
      }
    });
  }

  _getMediaDevices() {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const { data } = this.state;

      const speakerDevice = devices.find(
        (device) => device.kind === 'audiooutput'
      );

      this.setState({
        data: {
          ...data,
          sinkId: data.sinkId || (speakerDevice && speakerDevice.deviceId)
        },
        devices
      });
    });
  }

  _onAudioPlay = () => {
    this.setState({ audioPlaying: true });
  };

  _onAudioEnded = () => {
    this.setState({ audioPlaying: false });
  };

  render() {
    const { tracks } = this.props;
    const { audioPlaying, data, devices } = this.state;

    const audioTrack = tracks.find((track) => track.kind === 'audio');
    const videoTrack = tracks.find((track) => track.kind === 'video');

    const cameras = devices.filter((device) => device.kind === 'videoinput');
    const microphones = devices.filter(
      (device) => device.kind === 'audioinput'
    );
    const speakers = devices.filter((device) => device.kind === 'audiooutput');

    return (
      <MyForm
        className="devices-form"
        data={data}
        onChange={this._onDataChange}>
        <Divider horizontal>
          <Header as="h4">
            <Icon name="volume up" className="lineawesome" />
            Audio
          </Header>
        </Divider>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column width={12} verticalAlign="bottom">
              <FormField
                component={Dropdown}
                name="audioId"
                label="Microphone"
                fluid
                selection
                options={microphones.map((device) => ({
                  text: device.label,
                  value: device.deviceId
                }))}
              />
            </Grid.Column>
            <Grid.Column width={4} verticalAlign="bottom">
              {audioTrack && audioTrack.enabled ? (
                <div className="microphone-preview">
                  <Icon name="microphone" circular />
                  <VolumeIndicator track={audioTrack} />
                </div>
              ) : (
                <Message>Microphone is off</Message>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12} verticalAlign="bottom">
              <FormField
                component={Dropdown}
                name="sinkId"
                label="Speakers"
                disabled={!CAN_SET_SINK_ID}
                fluid
                selection
                options={speakers.map((device) => ({
                  text: device.label,
                  value: device.deviceId
                }))}
              />
            </Grid.Column>
            <Grid.Column width={4} verticalAlign="bottom">
              <Button
                basic
                circular
                disabled={audioPlaying}
                onClick={this._onTestAudioClick}>
                <Icon name="volume up" className="lineawesome" />
                {audioPlaying ? 'Playing...' : 'Test'}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider horizontal>
          <Header as="h4">
            <Icon name="video" className="lineawesome" />
            Video
          </Header>
        </Divider>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column width={12} verticalAlign="bottom">
              <FormField
                component={Dropdown}
                name="videoId"
                label="Camera"
                fluid
                selection
                options={cameras.map((device) => ({
                  text: device.label,
                  value: device.deviceId
                }))}
              />
            </Grid.Column>
            <Grid.Column width={4} verticalAlign="bottom">
              {videoTrack && videoTrack.enabled ? (
                <Video track={videoTrack} />
              ) : (
                <Message>Camera is off</Message>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <audio ref={this._audioRef} src={PhoneWav} />
      </MyForm>
    );
  }

  _onTestAudioClick = () => {
    const { data } = this.state;

    const promise =
      data.sinkId && CAN_SET_SINK_ID
        ? this._audioRef.current.setSinkId(data.sinkId)
        : Promise.resolve();

    promise.then(() => {
      this._audioRef.current.play();
    });
  };

  _onDataChange = (data) => {
    const { onChange } = this.props;
    const { data: prevData } = this.state;

    if (onChange) {
      onChange(data, prevData);
    }

    this.setState({ data });
  };
}
export default DevicesForm;
