import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dimmer, Icon, Label, Loader, Popup } from 'semantic-ui-react';

import GuideCountsByTopicIdsQuery from '../../../../../graphql/queries/guide-count-by-topic-ids.graphql';
import graphql from '../../../../hoc/graphql';

@graphql(GuideCountsByTopicIdsQuery, {
  name: 'guideCounts',
  skip: ({ topics }) => !topics.length,
  options: ({ topics }) => ({
    variables: { topicIds: topics.map((topic) => topic.id) }
  })
})
class TopicsInfo extends Component {
  static propTypes = {
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string
      })
    ),
    guideCounts: PropTypes.shape({
      loading: PropTypes.bool,
      usersConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      })
    })
  };

  render() {
    const { topics } = this.props;

    if (!topics.length) {
      return <div className="info-box empty" />;
    }

    const { loading, usersConnection } = this.props.guideCounts;

    if (loading) {
      return (
        <Dimmer active inverted style={{ position: 'relative' }}>
          <Loader />
        </Dimmer>
      );
    }

    const availableGuideAmnt = usersConnection.aggregate.count;
    const s = availableGuideAmnt !== 1 ? 's' : '';
    return (
      <div className="info-box">
        <Popup
          trigger={
            <Label basic>
              <Icon name="users" />
              {availableGuideAmnt}
            </Label>
          }
          header="Available Guides"
          content={`${availableGuideAmnt} 
            guide${s} 
            available in selected topics`}
        />
      </div>
    );
  }
}
export default TopicsInfo;
