import { useMutation } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import { Button } from '@windmill/react-ui';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import qs from 'qs';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ROLES } from '../../../../consts';
import UpdateUserProfileMutation from '../../../../graphql/mutations/update-user-profile.graphql';
import UploadFileMutation from '../../../../graphql/mutations/upload-file.graphql';
import history from '../../../../history';
import useUser from '../../../hooks/use-user';
import Avatar from '../../../ui/avatar';
import AvatarCropperDialog from '../../../ui/avatar-cropper-dialog';
import ErrorDialog from '../../../ui/error-dialog';
import ErrorMessage from '../../../ui/error-message/index';
import ImageDropzone from '../../../ui/image-dropzone';
import LoadingSpinner from '../../../ui/loading-spinner/index';
import Notification from '../../../ui/notification';
import BasicInfoForm from './basic-info-form';
import SpouseInfo from './spouse-info';

const DIALOGS = {
  AVATAR_CROPPER: 'AVATAR_CROPPER'
};

function Profile() {
  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [data, setData] = useState({});
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { data: userData, error: userError, loading, refetch } = useUser();

  const [updateUserProfile] = useMutation(UpdateUserProfileMutation);
  const [uploadFile] = useMutation(UploadFileMutation);

  function setDialog(dialog) {
    history.replace({
      pathname: location.pathname,
      search: qs.stringify({
        ...search,
        dialog
      })
    });
  }

  function readFile(file) {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onabort = () => reject(new Error('File reading was aborted'));
      reader.onerror = () => reject(new Error('File reading has failed'));

      try {
        reader.readAsDataURL(file);
      } catch (error) {
        const variables = { file };
        Bugsnag.notify(error, function (event) {
          event.context = 'Profile._onFileDrop';
          event.request.variables = variables;
          event.severity = 'info';
        });
        reject(error);
      }
    });
  }

  function uploadImage(file) {
    const variables = { file };
    return uploadFile({ variables }).then(({ data }) => data.uploadFile);
  }

  function uploadAvatar() {
    const { avatarFile } = data;

    return avatarFile ? uploadImage(avatarFile) : Promise.resolve(null);
  }

  function saveProfile(data) {
    const { User } = userData;

    const age = data?.age;
    const parsedAge = isString(age)
      ? parseInt(age.replace(/\D/g, ''), 10)
      : age;

    const variables = {
      id: User.id,
      ...User,
      ...data,
      age: parsedAge
    };

    return updateUserProfile({ variables }).then((res) => {
      refetch();
    });
  }

  function onCloseDialog() {
    const { dialog, ...remaining } = search;
    history.replace({
      pathname: location.pathname,
      search: qs.stringify({ ...remaining })
    });
  }

  function onAvatarFileDrop([avatarFile], rejectedFiles) {
    if (avatarFile) {
      setData((data) => ({
        ...data,
        avatarFile
      }));
      setDialog(DIALOGS.AVATAR_CROPPER);
    } else if (rejectedFiles && rejectedFiles.length) {
      const error = rejectedFiles[0].errors[0];
      setError(rejectedFiles[0].errors[0]);
      Bugsnag.notify(error, function (event) {
        event.context = 'Profile._onFileDrop';
        event.request.variables = { rejectedFiles };
        event.severity = 'info';
      });
    }
  }

  function onCropLoadFailure(...args) {
    onCloseDialog();
    setError(new Error('Image failed to load'));
  }

  function onCropAvatar(avatarFile) {
    setData((data) => ({
      ...data,
      avatarFile
    }));
    setDirty(true);
    readFile(avatarFile).then((result) => {
      setData((data) => ({
        ...data,
        avatarUrl: result
      }));
    });
    onCloseDialog();
  }

  function onDataChange(data) {
    // this setData is to update the avatar state when applicable, while still maintaining/updating the form's state. setData(data) overwrites the avatar's state, so prevState is needed here.
    setData((prevState) => ({
      ...prevState,
      ...data
    }));
    setDirty(true);
  }

  function onValidate(errors) {
    setIsValid(isEmpty(errors));
  }

  function onSubmit() {
    setError(null);
    setSubmitting(true);
    uploadAvatar()
      .then((response) => {
        const newData = {
          ...data,
          avatarUrl: response ? response.url : userData.User.avatarUrl
        };
        return saveProfile(newData);
      })
      .then(() => {
        setSubmitting(false);
        setSuccess(true);
      })
      .catch((error) => {
        setError(error);
        setSubmitting(false);
        Bugsnag.notify(error, function (event) {
          event.context = 'Profile._onSubmit';
          event.request.variables = data;
        });
      });
  }

  function renderDialogs() {
    const props = { isOpen: true, onClose: onCloseDialog };

    switch (search.dialog) {
      case DIALOGS.AVATAR_CROPPER:
        return data.avatarFile ? (
          <AvatarCropperDialog
            {...props}
            image={data.avatarFile}
            onLoadFailure={onCropLoadFailure}
            onSelect={onCropAvatar}
          />
        ) : null;
      default:
        return null;
    }
  }

  function renderContent() {
    if (userError) {
      return <ErrorMessage error={userError} />;
    }
    if (loading) {
      return <LoadingSpinner className="my-4 mx-auto w-48 h-48" />;
    }

    const { User } = userData;

    const isGuide = !!User.roles.find((role) => role.name === ROLES.GUIDE);
    const isClient = !!User.roles.find((role) => role.name === ROLES.CLIENT);
    const hasCompanies = !!User.companyIds.length;
    const isSpouse = !!(User.clientInvite && User.clientInvite.isSpouse);
    const shouldShowSpouse = isClient && hasCompanies && !isSpouse;

    const record = {
      ...User,
      avatarUrl: data.avatarUrl || User.avatarUrl
    };

    return (
      <>
        <div className="profile-settings p-8">
          <div className="flex flex-col-reverse sm:flex-row sm:space-x-4">
            <div className="mt-4 sm:mt-0">
              {isGuide && (
                <Button
                  layout="outline"
                  block
                  onClick={() => {
                    history.push({ pathname: `/guide/${User.id}` });
                  }}>
                  View Guide Profile
                </Button>
              )}
              <h1 className="my-4 text-xl">Your Profile</h1>
              <BasicInfoForm onChange={onDataChange} onValidate={onValidate} />
              <Button
                block
                size="larger"
                className="my-4"
                disabled={!dirty || !isValid || submitting}
                onClick={onSubmit}>
                {submitting ? (
                  <>
                    <LoadingSpinner className="w-6 h-6" />
                    &nbsp;
                  </>
                ) : (
                  'Save Profile'
                )}
              </Button>
              {shouldShowSpouse && (
                <>
                  <h2 className="my-2 text-lg">Spouse</h2>
                  <SpouseInfo />
                </>
              )}
            </div>
            <div>
              <ImageDropzone onDrop={onAvatarFileDrop}>
                <Avatar
                  user={record}
                  style={{
                    width: '200px',
                    height: '200px',
                    display: 'block',
                    margin: '0 auto'
                  }}
                />
                <div className="p-4">
                  <Button layout="outline" block>
                    Select Avatar
                  </Button>
                </div>
              </ImageDropzone>
            </div>
          </div>
        </div>
        <ErrorDialog
          error={error}
          onClose={() => {
            setError(null);
          }}
        />
        <Notification
          open={success}
          onClose={() => {
            setSuccess(false);
          }}>
          <i className="icon check green" />
          Your profile has been updated
        </Notification>
      </>
    );
  }

  return (
    <>
      {renderContent()}
      {renderDialogs()}
    </>
  );
}
export default Profile;
