import * as React from 'react';
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={85}
    height={85}
    fill="none"
    {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M63.93 50.878v-8.344H45.32v-20.8h-8.26v29.14l26.87.004ZM10.244 70.004a40.35 40.35 0 0 0 7.695 7.072l4.66-7.996-4.816-4.45-7.54 5.374Zm3.926-10.306-2.84-5.856-9.147 2.366 4.277 8.963 7.71-5.473Zm-4.338-11.78-.458-6.248H.04l.682 8.597 9.111-2.35Z"
      />
      <path
        fill="#3E85C6"
        d="M85 45.938c-.334 1.948-.6 3.91-1.012 5.839-3.624 16.94-17.765 30.177-34.93 32.725a42.196 42.196 0 0 1-24.921-3.71c-.302-.145-.597-.309-.994-.518 1.583-2.708 3.127-5.356 4.667-7.996 17.936 8.635 37.27-.018 44.65-15.398 7.202-15.036 1.99-33.337-12.122-42.32C45.366 5.02 27.25 10.106 18.685 18.94L26 26.233 0 30.988c1.597-8.746 3.163-17.342 4.742-26.013l7.078 7.107c2.556-1.865 4.884-3.791 7.422-5.383A43.517 43.517 0 0 1 39.9.167 3.78 3.78 0 0 0 40.57 0h4.115c1.48.195 2.96.373 4.437.586C66.109 3.053 80.4 16.414 83.985 33.21c.419 1.962.71 3.955 1.03 5.931L85 45.938Z"
      />
      <path
        fill="#3E85C6"
        d="M63.93 50.878H37.06v-29.14h8.278v20.796H63.93v8.344ZM10.244 70.004l7.54-5.373L22.6 69.08l-4.66 7.996a40.357 40.357 0 0 1-7.696-7.072ZM14.17 59.708l-7.692 5.473L2.2 56.218l9.147-2.366 2.822 5.856ZM9.832 47.928.735 50.266.053 41.67h9.321l.458 6.258Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h85v85H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
