import './index.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Header } from 'semantic-ui-react';

@withRouter
class ResourceHeader extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    parentPath: PropTypes.string,
    title: PropTypes.string.isRequired
  };

  state = {
    hovering: false
  };

  render() {
    const { title, parentPath, history } = this.props;

    const hoverClass = this.state.hovering && parentPath ? 'hovering' : '';

    return (
      <div
        className={`resource-header-container ${hoverClass}`}
        onMouseEnter={this._hoverIn}
        onMouseLeave={this._hoverOut}>
        <Header
          size="large"
          className="resource-header"
          onClick={() => {
            if (parentPath) {
              history.push(parentPath);
            }
          }}>
          {this._renderBackButton()}
          {title}
        </Header>
      </div>
    );
  }

  _hoverIn = () => {
    this.setState({
      hovering: true
    });
  };

  _hoverOut = () => {
    this.setState({
      hovering: false
    });
  };

  _renderBackButton() {
    const { parentPath } = this.props;

    if (!parentPath) {
      return null;
    }
    return (
      <Button
        primary
        circular
        className="back-button"
        icon="angle left lineawesome"
      />
    );
  }
}
export default ResourceHeader;
