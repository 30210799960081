import * as React from 'react';
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    id="Capa_1"
    x={0}
    y={0}
    style={{
      enableBackground: 'new 0 0 452 174.5'
    }}
    viewBox="0 0 452 174.5"
    {...props}>
    <style>{'.st1{fill:#969696}.st3{fill:#fff}'}</style>
    <defs>
      <path id="SVGID_1_" d="M0 0h452v174.5H0z" />
    </defs>
    <clipPath id="SVGID_00000090983878304205415700000000381509116858985116_">
      <use
        xlinkHref="#SVGID_1_"
        style={{
          overflow: 'visible'
        }}
      />
    </clipPath>
    <g
      style={{
        clipPath:
          'url(#SVGID_00000090983878304205415700000000381509116858985116_)'
      }}>
      <path d="M411.2 3.2H3.2v168.1h408V3.2z" className="st1" />
      <path
        d="M412.6 172.7H1.8V1.8h410.9v170.9zM3.2 171.3h408V3.2H3.2v168.1z"
        style={{
          fill: '#969696',
          stroke: '#969696',
          strokeMiterlimit: 10
        }}
      />
      <path d="M274.8 2.5H124V172h150.8V2.5z" className="st3" />
      <path
        d="M255 136.8c-2.9-1.6-3.7-8.7-3.3-16.4 1.2-25.1-9.2-31.8-24.5-36.4v-.3c12.5-1.3 27.8-5.2 27.8-26 0-7.2-3.9-23.2-29.4-23.2h-40.4v3.7h9.6v43.4h-36.6V38.2h9.4v-3.7h-33.7v3.7h9.6V137h-9.6v3.7h33.7V137h-9.4V85.2h36.6V137h-9.6v3.7H220V137h-10.5V85.2h6.3c16.8 0 18 11.9 18.6 19.2 1 9.5 1.7 22.4 2.4 25.2 1.7 7.5 8.8 13.5 18.2 13.4 3.5-.1 7.3-1.1 11.9-3v-4c-4.5 1.9-9.4 2.9-11.9.8m-40.1-55.2h-5.5V38.2h10.9c12.8 0 18.4 4.7 18.4 20.2 0 21.7-9.2 23.2-23.8 23.2z"
        className="st1"
      />
      <path
        d="M381.7 38.6h10.7v-4h-29.5l-23 79.2-22.6-79.2h-29.4v4h10.7v98.3h-10.7v3.6h26v-3.6h-10.6V41.3h.3l28.8 99.3h4.9l29-99.3h.4v95.6H356v3.6h36.4v-3.6h-10.7V38.6zM39.3 134c6.7 5 18.1 8 27 8 24.6 0 40.4-14.4 40.4-32.1 0-13.7-8.3-23.9-27.6-29.6-7.3-2.2-14.5-4.1-19.5-5.6-11.6-3.5-21-8-21-20 0-10.5 11.4-17.8 23.6-17.8 19.1 0 30.8 12.3 33 27.2h6.1V34.5h-3.4l-7.2 6.3C81.9 35.2 73.9 33 62.9 33c-19.8 0-34.7 12.2-34.7 28.6 0 14.8 10.5 23.2 28.2 27.6 3.8.9 7.6 2.1 11.4 3.1 17.3 4.6 28.4 9.4 28.4 24 0 12.8-13.3 21.9-28.4 21.9-20.2 0-31.1-13.7-35-28.6h-5.5v31.1h3.2l8.8-6.7z"
        className="st3"
      />
      <path
        d="M426 15.8C426 8.7 431.8 3 439 3c7.3 0 13 5.7 13 12.8 0 7.1-5.7 12.8-13 12.8-7.2-.1-13-5.7-13-12.8zm24 0C450 9.7 445 5 439 5c-5.9 0-11 4.8-11 10.8 0 6 5.1 10.8 11 10.8 6 0 11-4.8 11-10.8zm-4.8 6.9h-2.4l-4.2-6.5h-2.4v6.5h-2v-14h6.2c2.5 0 5 .7 5 3.7 0 2.7-1.9 3.8-4.4 3.8l4.2 6.5zm-6.6-8.4c1.8 0 4.8.3 4.8-1.9 0-1.4-1.9-1.7-3.5-1.7h-3.8v3.6h2.5z"
        className="st1"
      />
    </g>
  </svg>
);
export default SvgComponent;
