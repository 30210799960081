import './payment-options.css';

import Bugsnag from '@bugsnag/js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Confirm,
  Container,
  Dimmer,
  Dropdown,
  Form,
  Header,
  Icon,
  Label,
  Loader,
  Message,
  Modal,
  Table
} from 'semantic-ui-react';

import { STRIPE_DEFAULT_PRICE_IDS } from '../../../consts';
import CreateCardAndSubscribeMutation from '../../../graphql/mutations/create-card-and-subscribe.graphql';
import CreateCardMutation from '../../../graphql/mutations/create-card-with-customer.graphql';
import DeleteCardMutation from '../../../graphql/mutations/delete-card-with-customer.graphql';
import PayInvoiceMutation from '../../../graphql/mutations/pay-invoice.graphql';
import SubscribeWithCardMutation from '../../../graphql/mutations/subscribe-with-card.graphql';
import UpdateCustomerDefaultSourceMutation from '../../../graphql/mutations/update-customer-default-source.graphql';
import UpdateSubscriptionCancelMutation from '../../../graphql/mutations/update-subscription-cancel-at-period-end.graphql';
import PricesQuery from '../../../graphql/queries/prices-by-ids.graphql';
import UserPaymentInfoQuery from '../../../graphql/queries/user-payment-info.graphql';
import graphql from '../../hoc/graphql';
import withUser from '../../hoc/with-user';
import ErrorDialog from '../../ui/error-dialog';
import LoadingSpinner from '../../ui/loading-spinner';
import Notification from '../../ui/notification';
import StripeCardForm from '../stripe-card-form';

const DIALOGS = {
  ADD_CARD: 'ADD_CARD',
  CONFIRM_DELETE_CARD: 'CONFIRM_DELETE_CARD',
  CONFIRM_MAKE_CARD_DEFAULT: 'CONFIRM_MAKE_CARD_DEFAULT',
  CONFIRM_TOGGLE_SUBSCRIPTION: 'CONFIRM_TOGGLE_SUBSCRIPTION',
  PAYING_INVOICE: 'PAYING_INVOICE'
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

function getCardIconName(brand) {
  switch (brand) {
    case 'American Express':
      return 'cc amex card big';
    case 'Diners Club':
    case 'Discover':
    case 'JCB':
    case 'MasterCard':
    case 'Visa':
      return `cc ${brand.toLowerCase()} card big`;
    default:
      return 'credit card';
  }
}

function formatPrice(price, noInterval) {
  if (!price) {
    return '';
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: price.currency.toUpperCase()
  });

  const amount = formatter.format(price.unitAmount / 100);
  if (noInterval) {
    return amount;
  }

  const count =
    price.recurringIntervalCount === 1
      ? 'a'
      : `every ${price.recurringIntervalCount}`;
  const interval = price.recurringInterval;

  return `${amount} ${count} ${interval}${
    price.recurringIntervalCount === 1 ? '' : 's'
  }`;
}

@withUser({
  authenticated: true,
  loader: <LoadingSpinner className="my-4 mx-auto w-48 h-48" />,
  showError: true
})
@graphql(CreateCardMutation, {
  name: 'createCard',
  options: {
    refetchQueries: [{ query: UserPaymentInfoQuery }]
  }
})
@graphql(CreateCardAndSubscribeMutation, {
  name: 'createCardAndSubscribe',
  options: {
    refetchQueries: [{ query: UserPaymentInfoQuery }]
  }
})
@graphql(DeleteCardMutation, {
  name: 'deleteCard',
  options: {
    refetchQueries: [{ query: UserPaymentInfoQuery }]
  }
})
@graphql(PayInvoiceMutation, { name: 'payInvoice' })
@graphql(PricesQuery, {
  name: 'prices',
  options: {
    variables: { id_in: STRIPE_DEFAULT_PRICE_IDS }
  }
})
@graphql(SubscribeWithCardMutation, {
  name: 'subscribeWithCard',
  options: {
    refetchQueries: [{ query: UserPaymentInfoQuery }]
  }
})
@graphql(UpdateSubscriptionCancelMutation, {
  name: 'updateSubscriptionCancel',
  options: {
    refetchQueries: [{ query: UserPaymentInfoQuery }]
  }
})
@graphql(UserPaymentInfoQuery, {
  name: 'paymentInfo',
  otions: { fetchPolicy: 'no-cache' }
})
@graphql(UpdateCustomerDefaultSourceMutation, { name: 'updateDefaultSource' })
class PaymentOptions extends Component {
  static propTypes = {
    createCard: PropTypes.func.isRequired,
    createCardAndSubscribe: PropTypes.func.isRequired,
    deleteCard: PropTypes.func.isRequired,
    payInvoice: PropTypes.func.isRequired,
    paymentInfo: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func,
      User: PropTypes.shape({
        id: PropTypes.string,
        accessCode: PropTypes.shape({
          id: PropTypes.string,
          prices: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string
            })
          )
        }),
        cards: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            brand: PropTypes.string,
            expirationMonth: PropTypes.number,
            expirationYear: PropTypes.number,
            last4: PropTypes.string
          })
        ),
        charges: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            amount: PropTypes.number.isRequired
          })
        ),
        companyIds: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            company: PropTypes.shape({
              id: PropTypes.string,
              paysForEmployeeSubscription: PropTypes.bool,
              prices: PropTypes.arrayOf(
                PropTypes.shape({
                  id: PropTypes.string
                })
              )
            })
          })
        ),
        customer: PropTypes.shape({
          id: PropTypes.string,
          defaultSource: PropTypes.string
        }),
        subscriptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            status: PropTypes.string
          })
        )
      })
    }),
    prices: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      prices: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          currency: PropTypes.string,
          recurringInterval: PropTypes.string,
          recurringIntervalCount: PropTypes.number,
          unitAmount: PropTypes.number
        })
      )
    }),
    subscribeWithCard: PropTypes.func.isRequired,
    updateDefaultSource: PropTypes.func.isRequired,
    updateSubscriptionCancel: PropTypes.func.isRequired,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  };

  state = {
    card: null,
    error: null,
    openDialog: null,
    subscription: null,
    submitting: false,
    successMessage: null
  };

  _getAvailablePrices() {
    const { User: PaymentUser } = this.props.paymentInfo;
    const { prices } = this.props.prices;

    const accessCodePrices =
      PaymentUser.accessCode &&
      PaymentUser.accessCode.prices.length &&
      PaymentUser.accessCode.prices;
    const companyPrices = PaymentUser.companyIds
      .map((connection) => connection.company)
      .filter((company) => !company.paysForEmployeeSubscription)
      .map((company) => company.prices)
      .reduce((acc, prices) => acc.concat(prices), []);
    const defaultPrices = prices || [];
    const availablePrices =
      accessCodePrices ||
      (companyPrices.length && companyPrices) ||
      defaultPrices;

    const intervals = ['day', 'week', 'month', 'year'];
    return [...availablePrices].sort(
      (a, b) =>
        intervals.indexOf(a.recurringInterval) -
        intervals.indexOf(b.recurringInterval)
    );
  }

  render() {
    const { loading } = this.props.paymentInfo;
    const { error, successMessage } = this.state;

    if (loading) {
      return <LoadingSpinner className="my-4 mx-auto w-48 h-48" />;
    }

    return (
      <Container className="payment-options-component p-4">
        {this._renderSubscriptions()}
        {this._renderCards()}
        {this._renderCharges()}
        <ErrorDialog
          error={error}
          onClose={() => {
            this.setState({ error: null });
          }}
        />
        <Notification
          open={!!successMessage}
          onClose={() => {
            this.setState({ successMessage: null });
          }}>
          <Icon name="check" color="green" /> {successMessage}
        </Notification>
        {this._renderDialogs()}
      </Container>
    );
  }

  _renderSubscriptions() {
    const { User: PaymentUser } = this.props.paymentInfo;

    const prices = this._getAvailablePrices();
    const [defaultPrice] = prices;
    const card = PaymentUser.cards.find(
      (c) => c.id === PaymentUser.customer.defaultSource
    );
    const isActive = PaymentUser.subscriptions.some((subscription) =>
      ['ACTIVE', 'PAST_DUE', 'TRIALING', 'UNPAID'].includes(subscription.status)
    );

    return (
      <div className="subscriptions">
        <Header>Your Subscriptions</Header>
        {isActive ? (
          <Table striped unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Plan</Table.HeaderCell>
                <Table.HeaderCell>Started</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Card</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {PaymentUser.subscriptions.map(this._renderSubscription)}
          </Table>
        ) : (
          <Message>
            You currently don&apos;t have a LifeGuides subscription.
            <Button
              animated="fade"
              primary
              icon="plus"
              size="huge"
              onClick={() => {
                this.setState({ card, openDialog: DIALOGS.ADD_SUBSCRIPTION });
              }}>
              <Button.Content visible>Subscribe to LifeGuides</Button.Content>
              {defaultPrice && (
                <Button.Content hidden>
                  {formatPrice(defaultPrice)}
                </Button.Content>
              )}
            </Button>
          </Message>
        )}
      </div>
    );
  }

  _renderSubscription = (subscription) => {
    const { User: PaymentUser } = this.props.paymentInfo;
    const [price] = subscription.prices;

    const card =
      subscription.defaultSource &&
      PaymentUser.cards.find((c) => c.id === subscription.defaultSource);
    const isActive = ['ACTIVE', 'TRIALING'].includes(subscription.status);
    const isDue = subscription.status === 'PAST_DUE';
    const isCancelling = !!subscription.cancelAt;

    return (
      <Table.Row key={`subscription-${subscription.id}`}>
        <Table.Cell>{formatPrice(price)}</Table.Cell>
        <Table.Cell>
          {subscription.startDate && moment(subscription.startDate).fromNow()}
        </Table.Cell>
        <Table.Cell>
          {subscription.status}
          {subscription.cancelAt ? (
            <span className="note">
              Will cancel on{' '}
              {moment(subscription.cancelAt).format('MMM. D, YYYY')}
            </span>
          ) : null}
        </Table.Cell>
        <Table.Cell>
          {card ? (
            `${card.brand} ending in ${card.last4}`
          ) : isDue && !PaymentUser.cards.length ? (
            <Button
              primary
              onClick={() => {
                this.setState({ openDialog: DIALOGS.ADD_CARD });
              }}>
              <Icon name="plus" />
              Add Card and Charge
            </Button>
          ) : (
            <span className="note">Default Payment Source</span>
          )}
        </Table.Cell>
        <Table.Cell textAlign="right">
          {isActive && (
            <Button
              onClick={() => {
                this.setState({
                  openDialog: DIALOGS.CONFIRM_TOGGLE_SUBSCRIPTION,
                  subscription
                });
              }}>
              {isCancelling ? 'Reactivate' : 'Cancel'}
            </Button>
          )}
        </Table.Cell>
      </Table.Row>
    );
  };

  _renderCards() {
    const { User: PaymentUser } = this.props.paymentInfo;

    if (PaymentUser.subscriptions.length === 0) {
      return null;
    }

    return (
      <div className="cards">
        <Header>
          {PaymentUser.customer && (
            <Button
              floated="right"
              size="tiny"
              compact
              onClick={() => {
                this.setState({ openDialog: DIALOGS.ADD_CARD });
              }}>
              <Icon name="plus" />
              Add a new card
            </Button>
          )}
          Your Credit Cards
        </Header>
        {PaymentUser.cards.length ? (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Card</Table.HeaderCell>
                <Table.HeaderCell>Expires</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>{PaymentUser.cards.map(this._renderCard)}</Table.Body>
          </Table>
        ) : (
          <Message>You have no credit cards on file, click to add one.</Message>
        )}
      </div>
    );
  }

  _renderCard = (card) => {
    const { User: PaymentUser } = this.props.paymentInfo;

    const isDefault =
      PaymentUser.customer.defaultSource &&
      PaymentUser.customer.defaultSource === card.id;
    const hasMultiple = PaymentUser.cards.length > 1;
    const expiration = moment({
      year: card.expirationYear,
      month: card.expirationMonth - 1
    });

    return (
      <Table.Row key={`card-${card.id}`}>
        <Table.Cell>
          {isDefault && <Label ribbon>Default</Label>}
          <Icon name={getCardIconName(card.brand)} /> {card.brand} ending in{' '}
          {card.last4}
        </Table.Cell>
        <Table.Cell>
          {expiration.isBefore() ? 'Expired ' : ''}
          {expiration.format('MM/YY')}
        </Table.Cell>
        <Table.Cell textAlign="right">
          {!isDefault && (
            <Button
              onClick={() => {
                this.setState({
                  card,
                  openDialog: DIALOGS.CONFIRM_MAKE_CARD_DEFAULT
                });
              }}>
              Make Default
            </Button>
          )}
          {hasMultiple && (
            <Button
              onClick={() => {
                this.setState({
                  card,
                  openDialog: DIALOGS.CONFIRM_DELETE_CARD
                });
              }}>
              Delete
            </Button>
          )}
        </Table.Cell>
      </Table.Row>
    );
  };

  _renderCharges() {
    const { User: PaymentUser } = this.props.paymentInfo;

    if (PaymentUser.charges.length === 0) {
      return null;
    }

    return (
      <div className="charges">
        <Header>Payment History</Header>
        <Table striped unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Card</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{PaymentUser.charges.map(this._renderCharge)}</Table.Body>
        </Table>
      </div>
    );
  }

  _renderCharge = (charge) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: charge.currency.toUpperCase()
    });

    return (
      <Table.Row key={`charge-${charge.id}`}>
        <Table.Cell>
          {moment(charge.createdAt).format('MMM. D, YYYY')}
        </Table.Cell>
        <Table.Cell>
          {charge.paymentMethodDetails.card && (
            <>
              <Icon
                name={getCardIconName(charge.paymentMethodDetails.card.brand)}
              />{' '}
              {charge.paymentMethodDetails.card.brand} ending in{' '}
              {charge.paymentMethodDetails.card.last4}
            </>
          )}
        </Table.Cell>
        <Table.Cell>{charge.description}</Table.Cell>
        <Table.Cell>{formatter.format(charge.amount / 100)}</Table.Cell>
      </Table.Row>
    );
  };

  _renderDialogs() {
    const { openDialog } = this.state;

    switch (openDialog) {
      case DIALOGS.ADD_CARD:
        return this._renderAddCardDialog();
      case DIALOGS.ADD_SUBSCRIPTION:
        return this._renderAddSubscriptionDialog();
      case DIALOGS.CONFIRM_DELETE_CARD:
        return this._renderConfirmDeleteCard();
      case DIALOGS.CONFIRM_MAKE_CARD_DEFAULT:
        return this._renderConfirmMakeCardDefault();
      case DIALOGS.CONFIRM_TOGGLE_SUBSCRIPTION:
        return this._renderConfirmToggleSubscription();
      case DIALOGS.PAYING_INVOICE:
        return this._renderPayingInvoiceDialog();
      default:
        return null;
    }
  }

  _renderAddCardDialog() {
    const { submitting } = this.state;

    return (
      <Modal
        size="tiny"
        open={true}
        onClose={() => {
          if (!submitting) {
            this.setState({ openDialog: null });
          }
        }}>
        <Modal.Header>Enter Credit Card Details</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Elements stripe={stripePromise}>
              <ElementsConsumer>
                {({ stripe, elements }) => (
                  <StripeCardForm
                    elements={elements}
                    stripe={stripe}
                    submitButtonText="Save Credit Card"
                    submitting={submitting}
                    onSubmit={this._onSubmitCard}
                  />
                )}
              </ElementsConsumer>
            </Elements>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }

  _renderAddSubscriptionDialog() {
    const { User: PaymentUser } = this.props.paymentInfo;
    const { loading } = this.props.prices;
    const { card, priceId, submitting } = this.state;

    const hasCards = PaymentUser.cards.length > 0;
    const hasMultiple = PaymentUser.cards.length > 1;
    const prices = this._getAvailablePrices();
    const [defaultPrice] = prices;
    const value = priceId || (defaultPrice && defaultPrice.id) || '';
    const selectedPrice = prices.find((p) => p.id === value);
    const ctaText = selectedPrice
      ? `Checkout for ${formatPrice(selectedPrice, true)}`
      : 'Checkout';

    return (
      <Modal
        className="add-subscription-dialog"
        size="tiny"
        open={true}
        onClose={() => {
          if (!submitting) {
            this.setState({ openDialog: null });
          }
        }}>
        <Modal.Header>Add a Subscription</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {loading && (
              <Dimmer active={loading} blurring inverted>
                <Loader />
              </Dimmer>
            )}
            {hasCards && (
              <Form.Field>
                <label>Credit Card</label>
                <Dropdown
                  loading={loading}
                  fluid
                  selection
                  options={PaymentUser.cards
                    .map((card) => ({
                      key: `card-${card.id}`,
                      icon: getCardIconName(card.brand),
                      text: `${card.brand} ending in ${card.last4}${
                        hasMultiple &&
                        card.id === PaymentUser.customer.defaultSource
                          ? ' (default)'
                          : ''
                      }`,
                      value: card.id
                    }))
                    .concat([
                      {
                        key: 'new-card',
                        icon: 'plus',
                        text: 'Add a new card',
                        value: 'new'
                      }
                    ])}
                  value={(card && card.id) || 'new'}
                  onChange={(_, { value }) => {
                    const card = PaymentUser.cards.find((c) => c.id === value);
                    this.setState({ card });
                  }}
                />
              </Form.Field>
            )}
            {card ? (
              <>
                {this._renderSubscriptionPrices()}
                <Button
                  primary
                  fluid
                  size="huge"
                  disabled={submitting}
                  loading={submitting}
                  onClick={this._onSubmitSubscriptionWithCard}>
                  {ctaText}
                </Button>
              </>
            ) : (
              <Elements stripe={stripePromise}>
                <ElementsConsumer>
                  {({ stripe, elements }) => (
                    <StripeCardForm
                      elements={elements}
                      stripe={stripe}
                      submitButtonText={ctaText}
                      submitting={submitting}
                      onSubmit={this._onSubmitSubscriptionWithNewCard}>
                      {this._renderSubscriptionPrices()}
                    </StripeCardForm>
                  )}
                </ElementsConsumer>
              </Elements>
            )}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }

  _renderPayingInvoiceDialog() {
    return (
      <Modal size="tiny" open={true}>
        <Modal.Header>Activating LifeGuides Subscription</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Message>Your card is being charged.</Message>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }

  _renderSubscriptionPrices() {
    const { loading } = this.props.prices;
    const { priceId } = this.state;

    const prices = this._getAvailablePrices();
    const defaultPrice = prices.find((p) => p.recurringInterval === 'month');
    const value = priceId || (defaultPrice && defaultPrice.id) || '';

    return (
      <Form.Field>
        <label>Plan</label>
        <Dropdown
          loading={loading}
          fluid
          selection
          options={prices.map((price) => ({
            key: `price-${price.id}`,
            text: formatPrice(price),
            value: price.id
          }))}
          value={value}
          onChange={(_, { value }) => {
            this.setState({ priceId: value });
          }}
        />
      </Form.Field>
    );
  }

  _renderConfirmMakeCardDefault() {
    const { card } = this.state;

    if (!card) {
      return null;
    }

    return (
      <Confirm
        open
        content={`Are you sure you want to make the ${card.brand} card ending in ${card.last4} your new default payment method?`}
        cancelButton="Nevermind"
        confirmButton="Yes, make this card my default"
        onCancel={() => {
          this.setState({ card: null, openDialog: null });
        }}
        onConfirm={this._onConfirmMakeDefault}
      />
    );
  }

  _renderConfirmDeleteCard() {
    const { card } = this.state;

    if (!card) {
      return null;
    }

    return (
      <Confirm
        open
        content={`Are you sure you want to delete the ${card.brand} card ending in ${card.last4}?`}
        cancelButton="Nevermind"
        confirmButton="Yes, delete this card"
        onCancel={() => {
          this.setState({ card: null });
        }}
        onConfirm={this._onConfirmDeleteCard}
      />
    );
  }

  _renderConfirmToggleSubscription() {
    const { subscription } = this.state;

    if (!subscription) {
      return null;
    }

    const isCancelling = !!subscription.cancelAt;

    return (
      <Confirm
        open
        content={
          isCancelling
            ? 'Are you sure you want to reactivate this subscription?'
            : 'Are you sure you want to cancel your subscription?'
        }
        cancelButton="Nevermind"
        confirmButton={
          isCancelling
            ? 'Yes, reactivate my subscription'
            : 'Yes, cancel my subscription'
        }
        onCancel={() => {
          this.setState({ subscription: null });
        }}
        onConfirm={this._onConfirmCancelSubscription}
      />
    );
  }

  _onConfirmMakeDefault = () => {
    const { updateDefaultSource } = this.props;
    const { User: PaymentUser } = this.props.paymentInfo;
    const { card } = this.state;

    const variables = {
      id: PaymentUser.customer.id,
      defaultSource: card.id
    };

    this.setState({ submitting: true });
    updateDefaultSource({ variables })
      .then(() => {
        this.setState({
          card: null,
          openDialog: null,
          submitting: false,
          successMessage: `${card.brand} ending in ${card.last4} is now your default`
        });
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'PaymentOptions._onConfirmMakeDefault';
          event.request.variables = variables;
        });
      });
  };

  _onConfirmDeleteCard = () => {
    const { deleteCard } = this.props;
    const { User: PaymentUser } = this.props.paymentInfo;
    const { card } = this.state;

    const variables = {
      id: card.id,
      customerId: PaymentUser.customer.id
    };

    this.setState({ submitting: true });
    deleteCard({ variables })
      .then(() => {
        this.setState({
          card: null,
          openDialog: null,
          submitting: false,
          successMessage: `${card.brand} ending in ${card.last4} has been deleted`
        });
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'PaymentOptions._onConfirmDeleteCard';
          event.request.variables = variables;
        });
      });
  };

  _onConfirmCancelSubscription = () => {
    const { updateSubscriptionCancel } = this.props;
    const { subscription } = this.state;

    const isCancelling = !!subscription.cancelAt;

    const variables = {
      id: subscription.id,
      cancelAtPeriodEnd: !isCancelling
    };

    this.setState({ submitting: true });
    updateSubscriptionCancel({ variables })
      .then(({ data: { updateSubscription } }) => {
        const successMessage = isCancelling
          ? 'Your subscription has been reactivated'
          : `Your subscription will be cancelled on ${moment(
              updateSubscription.cancelAt
            ).format('MMM. D, YYYY')}`;

        this.setState({
          openDialog: null,
          subscription: null,
          submitting: false,
          successMessage
        });
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'PaymentOptions._onConfirmCancelSubscription';
          event.request.variables = variables;
        });
      });
  };

  _onSubmitCard = (source) => {
    const { createCard } = this.props;
    const { User: PaymentUser } = this.props.paymentInfo;

    const variables = {
      source: source.id,
      customerId: PaymentUser.customer.id
    };

    this.setState({ submitting: true });
    createCard({ variables })
      .then(() => {
        this.setState({
          openDialog: null,
          submitting: false,
          successMessage: `${source.card.brand} ending in ${source.card.last4} has been added to your account`
        });

        this._payInvoice();
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'PaymentOptions._onSubmitCard';
          event.request.variables = variables;
        });
      });
  };

  _onSubmitSubscriptionWithCard = () => {
    const { subscribeWithCard } = this.props;
    const { card, priceId } = this.state;

    const prices = this._getAvailablePrices();
    const defaultPrice = prices.find((p) => p.recurringInterval === 'month');

    const variables = {
      cardId: card.id,
      priceId: priceId || (defaultPrice && defaultPrice.id)
    };

    this.setState({ submitting: true });
    subscribeWithCard({ variables })
      .then(() => {
        this.setState({
          card: null,
          openDialog: null,
          submitting: false,
          successMessage: `LifeGuides subscription added with ${card.brand} card ending in ${card.last4}`
        });
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'PaymentOptions._onSubmitSubscriptionWithCard';
          event.request.variables = variables;
        });
      });
  };

  _onSubmitSubscriptionWithNewCard = (source) => {
    const { createCardAndSubscribe } = this.props;
    const { priceId } = this.state;

    const prices = this._getAvailablePrices();
    const defaultPrice = prices.find((p) => p.recurringInterval === 'month');

    const variables = {
      priceId: priceId || (defaultPrice && defaultPrice.id),
      source: source.id
    };

    this.setState({ submitting: true });
    createCardAndSubscribe({ variables })
      .then(() => {
        this.setState({
          openDialog: null,
          submitting: false,
          successMessage: `LifeGuides subscription added with ${source.card.brand} card ending in ${source.card.last4}`
        });
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'PaymentOptions._onSubmitSubscriptionWithNewCard';
          event.request.variables = variables;
        });
      });
  };

  _payInvoice = () => {
    const { payInvoice } = this.props;
    const { User: PaymentUser } = this.props.paymentInfo;

    const pastDueSubscription = PaymentUser.subscriptions.find(
      (subscription) => subscription.status === 'PAST_DUE'
    );
    if (!pastDueSubscription) {
      return;
    }

    const invoice = pastDueSubscription.latestInvoice;
    if (!invoice || invoice.status !== 'OPEN') {
      return;
    }

    const variables = {
      id: invoice.id
    };

    this.setState({ openDialog: DIALOGS.PAYING_INVOICE, submitting: true });
    payInvoice({ variables })
      .then(() => {
        this.setState({
          openDialog: null,
          submitting: false,
          successMessage: `LifeGuides subscription paid and activated`
        });

        setTimeout(() => {
          this.props.paymentInfo.refetch();
        }, 3000);
      })
      .catch((error) => {
        this.setState({ error, openDialog: null, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'PaymentOptions._payInvoice';
          event.request.variables = variables;
        });
      });
  };
}
export default PaymentOptions;
