import { useQuery } from '@apollo/client';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow
} from '@windmill/react-ui';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import GuideProfilesByGuideIdPaginationQuery from '../../../../../graphql/queries/guide-profiles-by-guide-id-pagination.graphql';
import history from '../../../../../history';
import ErrorMessage from '../../../../ui/error-message';
import LoadingSpinner from '../../../../ui/loading-spinner';

const PAGE_SIZE = 5;

function GuideProfileRevisionsDialog(props) {
  const { onClose } = props;

  const location = useLocation();
  const match = useRouteMatch();

  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  const variables = {
    guideId: match.params.id,
    first: PAGE_SIZE,
    skip: search.page ? (search.page - 1) * PAGE_SIZE : 0
  };
  const { data, error, loading, refetch } = useQuery(
    GuideProfilesByGuideIdPaginationQuery,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables
    }
  );

  function onGuideProfileClick(guideProfile) {
    const { dialog, page, ...remaining } = search;

    history.push({
      search: qs.stringify({ ...remaining, revisionId: guideProfile.id })
    });
  }

  function getName(user) {
    if (!user) {
      return null;
    }
    if (user.firstName || user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }
    return user.emailAddress;
  }

  function renderGuideProfile(guideProfile) {
    const {
      id,
      createdAt,
      createdBy,
      status,
      publishedAt,
      publishedBy
    } = guideProfile;

    return (
      <TableRow
        key={id}
        className="cursor-pointer"
        onClick={() => onGuideProfileClick(guideProfile)}>
        <TableCell>{moment(createdAt).format('M/D/YY h:mma')}</TableCell>
        <TableCell>{getName(createdBy)}</TableCell>
        <TableCell>{status}</TableCell>
        <TableCell>
          {status === 'PUBLISHED'
            ? moment(publishedAt).format('M/D/YY h:mma')
            : null}
        </TableCell>
        <TableCell>
          {status === 'PUBLISHED' ? getName(publishedBy) : null}
        </TableCell>
      </TableRow>
    );
  }

  function renderContent() {
    if (error) {
      return <ErrorMessage error={error} retry={() => refetch()} />;
    }
    if (loading) {
      return <LoadingSpinner className="w-48 h-48 mx-auto" />;
    }

    const { guideProfiles } = data;
    return (
      <>
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>Created</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Published</TableCell>
              <TableCell>Published By</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>{guideProfiles.map(renderGuideProfile)}</TableBody>
        </Table>
      </>
    );
  }

  return (
    <Modal
      className="max-h-full overflow-y-auto w-full px-6 py-4 bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-3xl"
      isOpen={true}
      onClose={onClose}>
      <ModalHeader>Revisions</ModalHeader>
      <ModalBody>
        {renderContent()}
        <Pagination
          {...(data && data.guideProfilesConnection.aggregate.count > PAGE_SIZE
            ? {}
            : { className: 'hidden' })}
          totalResults={data ? data.guideProfilesConnection.aggregate.count : 0}
          resultsPerPage={PAGE_SIZE}
          onChange={(page) => {
            history.replace({ search: qs.stringify({ ...search, page }) });
          }}
          label="Page navigation"
        />
      </ModalBody>
      <ModalFooter>
        <Button className="w-full sm:w-auto" layout="outline" onClick={onClose}>
          Nevermind
        </Button>
      </ModalFooter>
    </Modal>
  );
}
GuideProfileRevisionsDialog.propTypes = {
  onClose: PropTypes.func
};
export default GuideProfileRevisionsDialog;
