import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';

import { ROLES } from '../../../../consts';
import withCompanies from '../../../hoc/with-companies';
import withUser from '../../../hoc/with-user';
import Notification from '../../../ui/notification';
import InviteClientDialog from './invite-client-dialog';
import Users from './users';

@withCompanies({ loader: <Segment basic loading /> })
@withUser({ authenticated: [ROLES.CORP_ADMIN], showError: true })
class CorpAdminUsers extends Component {
  static propTypes = {
    companies: PropTypes.shape({
      companies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string
        })
      ).isRequired
    }).isRequired
  };

  state = {
    inviteDialogOpen: false,
    inviteMessage: null,
    inviteSent: false
  };

  render() {
    const { companies } = this.props.companies;
    const { inviteMessage, inviteSent } = this.state;

    const [company] = companies;

    return (
      <div className="corp-admin-users p-4">
        <Header as="h1">
          {company.name} Users
          <Button basic floated="right" onClick={this._onClickInvite}>
            Invite Employee
          </Button>
        </Header>
        <Users />
        {this._renderInviteClientDialog()}
        <Notification
          open={inviteSent}
          onClose={() => {
            this.setState({ inviteSent: false });
          }}>
          <Icon name="check" color="green" /> {inviteMessage}
        </Notification>
      </div>
    );
  }

  _renderInviteClientDialog() {
    const { inviteDialogOpen } = this.state;

    if (!inviteDialogOpen) {
      return null;
    }

    return (
      <InviteClientDialog
        onClose={this._onInviteDialogClosed}
        onSubmit={this._onInviteCreated}
      />
    );
  }

  _onClickInvite = () => {
    this.setState({ inviteDialogOpen: true });
  };

  _onInviteDialogClosed = () => {
    this.setState({ inviteDialogOpen: false });
  };

  _onInviteCreated = ({ emailAddress }) => {
    this.setState({
      inviteDialogOpen: false,
      inviteSent: true,
      inviteMessage: `Client invite sent to ${emailAddress}`
    });
  };
}
export default CorpAdminUsers;
