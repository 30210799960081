import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';

export const Timezones = [
  {
    title: 'Hawaii-Aleutian Time (Adak)',
    names: ['America/Adak']
  },
  {
    title: 'Hawaii-Aleutian Time (Honolulu)',
    names: ['Pacific/Honolulu']
  },
  {
    title: 'Alaskan Time',
    names: ['America/Anchorage']
  },
  {
    title: 'Pacific Time',
    names: ['America/Los_Angeles', 'America/Tijuana']
  },
  {
    title: 'Mountain Time (Denver)',
    names: ['America/Boise', 'America/Denver', 'America/Hermosillo']
  },
  {
    title: 'Mountain Time (Phoenix)',
    names: ['America/Phoenix']
  },
  {
    title: 'Central Time',
    names: ['America/Chicago', 'America/Mexico_City']
  },
  {
    title: 'Eastern Time',
    names: [
      'America/Detroit',
      'America/Idianapolis',
      'America/New_York',
      'America/Cancun',
      'America/Jamaica'
    ]
  },
  {
    title: 'Peru Time',
    names: ['America/Lima']
  },
  {
    title: 'Acre Time',
    names: ['America/Rio_Branco']
  },
  {
    title: 'Amazon Time',
    names: ['America/Manaus']
  },
  {
    title: 'Brasília Time',
    names: ['America/Sao_Paulo']
  },
  {
    title: 'Fernando de Noronha Time',
    names: ['America/Noronha']
  },
  {
    title: 'Argentina Time',
    names: ['America/Argentina/Buenos_Aires']
  },
  {
    title: 'Uruguay Time',
    names: ['America/Montevideo']
  },
  {
    title: 'Western European Time',
    names: [
      'Europe/Belfast',
      'Europe/Guernsey',
      'Europe/Isle_of_Man',
      'Europe/Jersey',
      'Europe/London'
    ]
  },
  {
    title: 'Central European Time',
    names: [
      'Europe/Amsterdam',
      'Europe/Andorra',
      'Europe/Belgrade',
      'Europe/Berlin',
      'Europe/Bratislava',
      'Europe/Brussels',
      'Europe/Budapest',
      'Europe/Busingen',
      'Europe/Copenhagen',
      'Europe/Gibraltar',
      'Europe/Ljubljana',
      'Europe/Luxembourg',
      'Europe/Madrid',
      'Europe/Malta',
      'Europe/Monaco',
      'Europe/Oslo',
      'Europe/Paris',
      'Europe/Podgorica',
      'Europe/Prague',
      'Europe/Rome',
      'Europe/San_Marino',
      'Europe/Sarajevo',
      'Europe/Skopje',
      'Europe/Stockholm',
      'Europe/Tirane',
      'Europe/Vaduz',
      'Europe/Vatican',
      'Europe/Vienna',
      'Europe/Warsaw',
      'Europe/Zagreb',
      'Europe/Zurich'
    ]
  },
  {
    title: 'Eastern European Time',
    names: [
      'Europe/Athens',
      'Europe/Bucharest',
      'Europe/Chisinau',
      'Europe/Helsinki',
      'Europe/Kiev',
      'Europe/Mariehamn',
      'Europe/Nicosia',
      'Europe/Riga',
      'Europe/Sofia',
      'Europe/Tallinn',
      'Europe/Tiraspol',
      'Europe/Uzhgorod',
      'Europe/Vilnius',
      'Europe/Zaporozhye'
    ]
  },
  {
    title: 'Eastern Africa Time',
    names: ['Africa/Nairobi']
  },
  {
    title: 'India Standard Time',
    names: ['Asia/Kolkata']
  },
  {
    title: 'Indochina Time',
    names: ['Asia/Bangkok']
  }
];

export default class TimezoneSelector extends Component {
  static propTypes = {
    value: PropTypes.string
  };

  render() {
    const { value } = this.props;

    const guess = moment.tz.guess();

    const timezones = [...Timezones];

    function addZone(name) {
      if (!timezones.find((zone) => zone.names.includes(name))) {
        const abbr = moment.tz(name).zoneAbbr();
        timezones.push({
          names: [name],
          title: `${name} (${abbr})`
        });
      }
    }
    addZone(value);
    addZone(guess);

    const selectedZone = timezones.find((zone) => zone.names.includes(value));

    const options = timezones.map((zone) => {
      const [first] = zone.names;
      const m = moment.tz(first);
      return {
        text: `(GMT ${m.format('Z')}) ${zone.title}`,
        value: first
      };
    });

    return (
      <Dropdown
        {...this.props}
        value={(selectedZone && selectedZone.names[0]) || ''}
        options={options}
      />
    );
  }
}
