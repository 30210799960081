import { useMutation } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import { Button } from '@windmill/react-ui';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { setAuthToken } from '../../../auth';
import { connect } from '../../../auth0';
import { REDIRECT_URL_KEY } from '../../../consts';
import AuthenticateAuth0Mutation from '../../../graphql/mutations/authenticate-auth0.graphql';
import history from '../../../history';
import ErrorMessage from '../../ui/error-message';
import LoadingSpinner from '../../ui/loading-spinner';

export default function Login() {
  const location = useLocation();

  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const [authenticate] = useMutation(AuthenticateAuth0Mutation);

  function _authenticate(code) {
    const variables = { code };

    setError(null);
    setSubmitting(true);
    authenticate({ variables })
      .then(
        ({
          data: {
            authenticateAuth0: { authToken }
          }
        }) => {
          setSubmitting(false);
          setAuthToken(authToken).then(onAuthenticate);
        }
      )
      .catch((error) => {
        setError(error);
        setSubmitting(false);

        Bugsnag.notify(error, function (event) {
          event.context = 'Login._authenticate';
          event.request.variables = variables;
        });
      });
  }

  function onAuthenticate() {
    const redirectUrl = localStorage.getItem(REDIRECT_URL_KEY);
    localStorage.removeItem(REDIRECT_URL_KEY);
    const options = {
      pathname: '/dashboard',
      hash: ''
    };

    if (redirectUrl) {
      const urlParts = redirectUrl.split('?');
      options.pathname = urlParts[0];
      options.search = urlParts[1];
    }

    history.push(options);
  }

  useEffect(function () {
    const search = qs.parse(location.hash.substr(1));
    if (search.code) {
      _authenticate(search.code);
    } else if (search.error) {
      const error = new Error(search.error_description);
      setError(error);
      Bugsnag.notify(error, function (event) {
        event.context = 'Login.componentDidMount';
      });
    } else {
      connect(null, '/login', {
        prompt: 'login'
      });
    }
  }, []);

  function renderError(error, children) {
    return (
      <div className="max-w-md p-8">
        <ErrorMessage error={error} />
        {children}
      </div>
    );
  }

  function renderBannedUserError() {
    const reason = error.message.replace('BANNED_USER: ', '');
    return renderError(
      new Error(`You've been banned from LifeGuides. Reason: ${reason}`)
    );
  }

  function renderUnregisteredUserError() {
    return renderError(
      new Error("You've not yet registered an account with these credentials."),
      <div className="flex flex-col space-y-4">
        <Button
          size="large"
          block
          onClick={() => {
            history.push('/register');
          }}>
          Sign Up
        </Button>
        <Button
          layout="outline"
          block
          size="large"
          onClick={() => {
            connect(null, '/login', {
              prompt: 'login'
            });
          }}>
          Sign In with a Different Account
        </Button>
      </div>
    );
  }

  if (error) {
    if (error.message.startsWith('BANNED_USER: ')) {
      return renderBannedUserError();
    }
    if (error.message === 'UNREGISTERED_USER') {
      return renderUnregisteredUserError();
    }
    return renderError(
      error,
      <Button
        size="large"
        iconLeft={() => <i className="icon lineawesome refresh" />}
        onClick={() => {
          connect(null, '/login', {
            prompt: 'login'
          });
        }}>
        Click here to try logging in again
      </Button>
    );
  }

  if (submitting) {
    return <LoadingSpinner className="w-48 h-48 mx-auto my-8" />;
  }

  return null;
}
