import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';

class ConfirmImpersonateDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    user: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string.isRequired
    }).isRequired
  };

  render() {
    const { onClose, onSubmit, user } = this.props;

    return (
      <Modal open size="tiny" onClose={onClose}>
        <Modal.Header>Are you sure?</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to impersonate {user.firstName}?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose} content="Nevermind" />
          <Button primary content="Impersonate" onClick={onSubmit} />
        </Modal.Actions>
      </Modal>
    );
  }
}
export default ConfirmImpersonateDialog;
