import 'semantic-ui-css/semantic.min.css';
import 'babel-polyfill';
import 'date-input-polyfill-react';
import 'whatwg-fetch';

import './assets/line-awesome/css/line-awesome-semantic-font-awesome.1.3.css';
import './assets/fonts/fonts.css';
import './tracker';
import './index.css';
import './bugsnag';

import { ApolloProvider } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import { Windmill } from '@windmill/react-ui';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import apolloClient from './apollo';
import App from './components/App';
import ScrollToTop from './components/routes/scroll-to-top';
import ServiceWorkerProvider from './components/ServiceWorkerProvider';
import history from './history';
import wmTheme from './wmTheme.js';

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const application = (
  <ApolloProvider client={apolloClient}>
    <ServiceWorkerProvider>
      <Router history={history}>
        <ErrorBoundary>
          <Windmill theme={wmTheme}>
            <ScrollToTop>
              <App />
            </ScrollToTop>
          </Windmill>
        </ErrorBoundary>
      </Router>
    </ServiceWorkerProvider>
  </ApolloProvider>
);

ReactDOM.render(application, document.getElementById('root'));
