import '../../../ui/powerful-cta/index.css';

import Bugsnag from '@bugsnag/js';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@windmill/react-ui';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import InviteClientMutation from '../../../../graphql/mutations/invite-client.graphql';
import UserQuery from '../../../../graphql/queries/user.graphql';
import graphql from '../../../hoc/graphql';
import withUser from '../../../hoc/with-user';
import ErrorDialog from '../../../ui/error-dialog';
import LoadingSpinner from '../../../ui/loading-spinner';
import SpouseInviteForm from '../../../ui/spouse-invite-form';

@graphql(InviteClientMutation, {
  name: 'inviteClient',
  options: {
    refetchQueries: [
      {
        query: UserQuery
      }
    ]
  }
})
@withUser({ authenticated: true })
class SpouseInviteDialog extends Component {
  static propTypes = {
    inviteClient: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string,
        companyIds: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            company: PropTypes.shape({ id: PropTypes.string })
          })
        )
      })
    }).isRequired
  };

  state = {
    data: null,
    error: null,
    isValid: false,
    submitting: false
  };

  render() {
    const { error, isValid, submitting } = this.state;

    return (
      <>
        <Modal isOpen onClose={this._onClose}>
          <ModalHeader>Invite Your Spouse</ModalHeader>
          <ModalBody>
            <SpouseInviteForm
              onChange={this._onDataChange}
              onValidate={this._onValidate}
            />
          </ModalBody>
          <ModalFooter>
            <Button disabled={!isValid || submitting} onClick={this._onSubmit}>
              {submitting ? (
                <>
                  <LoadingSpinner className="w-6 h-6" />
                  &nbsp;
                </>
              ) : (
                'Send Invite'
              )}
            </Button>
          </ModalFooter>
        </Modal>
        <ErrorDialog
          error={error}
          onClose={() => {
            this.setState({ error: null });
          }}
        />
      </>
    );
  }

  _onClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  _onDataChange = (data) => {
    this.setState({ data });
  };

  _onValidate = (errors) => {
    this.setState({ isValid: isEmpty(errors) });
  };

  _onSubmit = () => {
    const { inviteClient, onSubmit } = this.props;
    const { User } = this.props.user;
    const { data } = this.state;

    const [companyConnection] = User.companyIds;

    const variables = {
      ...data,
      companyId: companyConnection.company.id,
      createdById: User.id,
      isSpouse: true
    };

    this.setState({ error: null, submitting: true });
    inviteClient({ variables })
      .then(() => {
        this.setState({ submitting: false });

        if (onSubmit) {
          onSubmit(data);
        }
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
        Bugsnag.notify(error, function (event) {
          event.context = 'SpouseInviteDialog._onSubmit';
          event.request.variables = variables;
        });
      });
  };
}
export default SpouseInviteDialog;
