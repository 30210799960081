import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Checkbox, Form, Input } from 'semantic-ui-react';

import MyForm, { FormField } from '../../ui/form';
import PrivacyPolicy from '../../ui/privacy-policy/dialog';
import TermsAndConditions from '../../ui/terms-and-conditions/dialog';

export default class GuideRegistrationForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool
  };

  state = {
    data: {
      password: '',
      passwordConfirmation: '',
      agreedToTermsAndConditions: false,
      showTermsAndConditions: false,
      showPrivacyPolicy: false
    },
    isValid: false
  };

  render() {
    const { onSubmit, submitting } = this.props;
    const { data, isValid } = this.state;

    return (
      <MyForm
        data={data}
        onChange={(data) => {
          this.setState({ data });
        }}
        onValidate={(errors) => {
          this.setState({ isValid: isEmpty(errors) });
        }}>
        <Form.Group widths="equal">
          <FormField
            component={Input}
            name="password"
            type="password"
            aria-label="password"
            aria-required="true"
            placeholder="Password"
            validator={({ password }) => {
              if (!password) {
                throw new Error('Must enter a password');
              }
              if (password.length < 8) {
                throw new Error('Password must have 8 characters or more');
              }
            }}
          />
          <FormField
            component={Input}
            name="passwordConfirmation"
            type="password"
            aria-required="true"
            aria-label="Confirm Password"
            placeholder="Confirm Password"
            validator={({ password, passwordConfirmation }) => {
              if (!passwordConfirmation) {
                throw new Error('Must confirm password');
              }
              if (password !== passwordConfirmation) {
                throw new Error('Does not match given password');
              }
            }}
          />
        </Form.Group>
        <FormField
          component={Checkbox}
          name="agreedToTermsAndConditions"
          aria-required="true"
          label={
            <label aria-label="Check to agree to the LifeGuides terms and conditions.">
              I am 18 (or older) and agree to the{' '}
              <a
                href="#"
                onClick={() => {
                  this.setState({
                    showTermsAndConditions: true
                  });
                }}>
                Terms & Conditions
              </a>{' '}
              and{' '}
              <a
                href="#"
                onClick={() => {
                  this.setState({
                    showPrivacyPolicy: true
                  });
                }}>
                Privacy Policy
              </a>
            </label>
          }
          validator={({ agreedToTermsAndConditions }) => {
            if (!agreedToTermsAndConditions) {
              throw new Error('Must agree to terms and conditions');
            }
          }}
        />
        <Form.Button
          primary
          fluid
          size="huge"
          content="Sign Up"
          disabled={!isValid || submitting}
          loading={submitting}
          onClick={() => {
            onSubmit(data);
          }}
        />
        <PrivacyPolicy
          visible={this.state.showPrivacyPolicy}
          onClose={() => {
            this.setState({
              showPrivacyPolicy: false
            });
          }}
        />
        <TermsAndConditions
          visible={this.state.showTermsAndConditions}
          onClose={() => {
            this.setState({
              showTermsAndConditions: false
            });
          }}
        />
      </MyForm>
    );
  }
}
