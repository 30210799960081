import { ApolloLink, split } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';
import {
  getFragmentDefinitions,
  getMainDefinition
} from '@apollo/client/utilities';
import Bugsnag from '@bugsnag/js';
import { createUploadLink } from 'apollo-upload-client';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { getAuthToken } from './auth';
import UserFragment from './graphql/fragments/user.graphql';
import * as tracker from './tracker';

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URL
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 3,
    retryIf: (error, { query }) => {
      const { operation } = getMainDefinition(query);
      return operation === 'query';
    }
  }
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      Bugsnag.notify(new Error(`GraphQL error: ${message}`), function (event) {
        event.addMetadata('locations', locations);
        event.request.body = operation.query.loc.source.body;
        event.request.variables = operation.variables;
      });
    });
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`Network error: ${networkError}`);
  }
});

const authLink = setContext((_, context) => {
  const headers = { ...context.headers };

  const token = getAuthToken();
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  return {
    ...context,
    headers
  };
});

function isUserQuery({ query }) {
  const [userFragment] = getFragmentDefinitions(UserFragment);
  const [queryFragment] = getFragmentDefinitions(query);
  if (!queryFragment) {
    return false;
  }

  return userFragment.selectionSet.selections.every((selection) => {
    return queryFragment.selectionSet.selections.some(
      (s) => s.name.value === selection.name.value
    );
  });
}

function setBugsnagUser(user) {
  if (user) {
    Bugsnag.setUser(
      user.id,
      user.emailAddress,
      `${user.firstName} ${user.lastName}`
    );
  }
}

function setTrackerUser(user) {
  const data = {
    userId: null
  };

  if (user) {
    data.userId = user.id;
  }

  tracker.dataLayer(data);
}

const userLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (isUserQuery(operation)) {
      setBugsnagUser(response.data.User);
      setTrackerUser(response.data.User);
    }
    return response;
  });
});

const subscriptionClient = new SubscriptionClient(
  process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_URL,
  {
    connectionParams: () => {
      const token = getAuthToken();
      return { authorization: token ? `Bearer ${token}` : null };
    },
    inactivityTimeout: 0,
    reconnect: true,
    timeout: 30000
  }
);

setInterval(function () {
  if (subscriptionClient.status === 1) {
    subscriptionClient.client.send('{"type":"ka"}');
  }
}, 60 * 1000);

const wsLink = new WebSocketLink(subscriptionClient);

export function reconnectSubscription() {
  subscriptionClient.close();
  subscriptionClient.connect();
}

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  ApolloLink.from([authLink, userLink, errorLink, retryLink, httpLink])
);

export default new ApolloClient({
  link,
  cache: new InMemoryCache()
});
