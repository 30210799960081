import React from 'react';

import List from './list';
import TopicFilters from './topic-filters';

export default function Guides() {
  return (
    <div className="w-full flex flex-col lg:flex-row lg:items-start bg-blue-50">
      <TopicFilters />
      <List />
    </div>
  );
}
