import './scheduler.css';

import { useMutation, useQuery } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import { Button, Modal } from '@windmill/react-ui';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { REDIRECT_URL_KEY } from '../../../../../consts';
import CreateSessionMutation from '../../../../../graphql/mutations/create-session.graphql';
import ClientDashboardQuery from '../../../../../graphql/queries/client-dashboard.graphql';
import SessionsByGuideIdQuery from '../../../../../graphql/queries/sessions-by-guide-id.graphql';
import UserPaymentInfoQuery from '../../../../../graphql/queries/user-payment-info.graphql';
import UserQuery from '../../../../../graphql/queries/user.graphql';
import history from '../../../../../history';
import * as tracker from '../../../../../tracker';
import useUser from '../../../../hooks/use-user';
import ErrorDialog from '../../../../ui/error-dialog';
import ErrorMessage from '../../../../ui/error-message/index';
import LoadingSpinner from '../../../../ui/loading-spinner';
import Scheduler from '../../../scheduler';
import GuideContext from '../../guide-context';

// Move this to ../scheduler ?

function SchedulerDialog(props) {
  const { guide, isOpen, onClose } = props;

  const location = useLocation();
  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const { data: userData, loading: userLoading } = useUser();

  const {
    data: paymentData,
    error: paymentError,
    loading: paymentLoading,
    refetch
  } = useQuery(UserPaymentInfoQuery, {
    notifyOnNetworkStatusChange: true
  });

  const [createSession] = useMutation(CreateSessionMutation, {
    refetchQueries: [
      {
        query: ClientDashboardQuery,
        variables: { userId: get(userData, 'User.id', null) }
      },
      {
        query: SessionsByGuideIdQuery,
        variables: {
          afterDate: moment().subtract(1, 'month').startOf('day').toISOString(),
          guideId: guide.id
        }
      }
    ],
    update(cache) {
      const userData = cache.readQuery({ query: UserQuery });
      if (userData) {
        const { User } = userData;
        if (!User.savedGuides.some((g) => g.id === guide.id)) {
          const savedGuides = User.savedGuides.concat([{ id: guide.id }]);
          const updatedData = {
            User: {
              ...User,
              savedGuides
            }
          };
          cache.writeQuery({
            query: UserQuery,
            data: updatedData
          });
        }
      }
    }
  });

  function hasCompanyThatPays() {
    const { User } = userData;

    return (
      !!User &&
      User.companyIds.some(
        (c) => c.status === 'ACTIVE' && c.company.paysEmployeeSubscription
      )
    );
  }

  function hasActiveSubscription() {
    const { User } = paymentData;

    return (
      !!User &&
      User.subscriptions.some((subscription) =>
        ['ACTIVE', 'TRIALING'].includes(subscription.status)
      )
    );
  }

  function hasExpiredSubscription() {
    const { User } = paymentData;

    return (
      !!User &&
      User.subscriptions.some(
        (subscription) => subscription.status === 'PAST_DUE'
      )
    );
  }

  const onChange = useCallback((data) => {
    setData(data);
  }, []);

  function onSubmit() {
    const { User } = userData;

    const topicIds = search.topicId ? search.topicId : [];

    const variables = {
      clientId: User.id,
      reason: data.reason,
      guideId: guide.id,
      scheduledTime: data.scheduledTime,
      status: 'UPCOMING',
      topicConnections: topicIds.map((id) => ({ id })),
      userId: User.id
    };

    setError(null);
    setSubmitting(true);
    createSession({ variables })
      .then(({ data: { bookSession } }) => {
        tracker.event('requestCall', 1);

        setSubmitting(false);
        props.onSubmit(bookSession);
      })
      .catch((error) => {
        tracker.event('requestCall', 0);

        setError(error);
        setSubmitting(false);

        Bugsnag.notify(error, function (event) {
          event.context = 'SchedulerDialog._onSubmit';
          event.request.variables = variables;
        });
      });
  }

  function renderExpiredSubscription() {
    return (
      <div className="p-4 flex flex-col space-y-4">
        <h1 className="text-2xl">
          You LifeGuides subscription trial has expired
        </h1>
        <p className="text-lg">
          You will need to add a credit card on file to re-enable your
          LifeGuides subscription before you can start a session with{' '}
          {guide.firstName}.
        </p>
      </div>
    );
  }

  function renderNoSubscription() {
    return (
      <div className="p-4 flex flex-col space-y-4">
        <h1 className="text-2xl">You have no LifeGuides subscription</h1>
        <p className="text-lg">
          You will need to purchase a subscription before you can start a
          session with {guide.firstName}.
        </p>
      </div>
    );
  }

  function renderContent() {
    if (paymentError) {
      return <ErrorMessage error={paymentError} retry={() => refetch()} />;
    }
    if (paymentLoading || userLoading) {
      return <LoadingSpinner className="w-48 h-48 my-8 mx-auto" />;
    }

    if (!get(userData, 'User.roles', []).length) {
      localStorage.setItem(
        REDIRECT_URL_KEY,
        location.pathname + location.search
      );
      history.push({
        pathname: location.pathname,
        search: qs.stringify({ ...search, dialog: 'REGISTER' })
      });
      return;
    }

    if (hasActiveSubscription() || hasCompanyThatPays()) {
      return (
        <Scheduler
          onChange={onChange}
          header="Just a few more steps"
          subHeader={`Schedule a session${
            guide.firstName ? ` with ${guide.firstName}` : ''
          }`}>
          <div className="flex flex-row justify-end space-x-4">
            <Button
              className="w-full sm:w-auto"
              layout="outline"
              disabled={submitting}
              onClick={onClose}>
              Nevermind
            </Button>
            <Button
              className="w-full sm:w-auto"
              disabled={!isValid || submitting}
              onClick={onSubmit}>
              {submitting ? (
                <>
                  <LoadingSpinner className="w-6 h-6" />
                  &nbsp;
                </>
              ) : (
                'Confirm'
              )}
            </Button>
          </div>
        </Scheduler>
      );
    }
    if (hasExpiredSubscription()) {
      return renderExpiredSubscription();
    }
    return renderNoSubscription();
  }

  function renderActions() {
    const classNames = 'p-4 flex flex-row justify-end space-x-4';

    if (paymentError || paymentLoading || userLoading) {
      return (
        <div className={classNames}>
          <Button
            className="w-full sm:w-auto"
            layout="outline"
            disabled={submitting}
            onClick={onClose}>
            Nevermind
          </Button>
        </div>
      );
    }
    if (hasActiveSubscription() || hasCompanyThatPays()) {
      return null;
    }
    return (
      <div className={classNames}>
        <Button
          className="w-full sm:w-auto"
          layout="outline"
          disabled={submitting}
          onClick={onClose}>
          Nevermind
        </Button>
        <Button
          onClick={() => {
            history.push({
              pathname: '/settings/payment-options',
              search: ''
            });
          }}>
          Purchase Subscription
        </Button>
      </div>
    );
  }

  const classNames = [
    'scheduler-dialog relative w-full max-h-full overflow-y-auto max-h-screen bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4'
  ];
  if (data.date) {
    classNames.push('sm:max-w-5xl');
  } else {
    classNames.push('sm:max-w-4xl');
  }

  const isValid = !!data.scheduledTime;

  return (
    <>
      <Modal className={classNames.join(' ')} isOpen={isOpen} onClose={onClose}>
        {renderContent()}
        {renderActions()}
      </Modal>
      <ErrorDialog
        error={error}
        onClose={() => {
          setError(null);
        }}
      />
    </>
  );
}
SchedulerDialog.propTypes = {
  guide: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string
  }),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};
function SchedulerDialogWithGuideContext(props) {
  return (
    <GuideContext.Consumer>
      {(guide) => <SchedulerDialog guide={guide} {...props} />}
    </GuideContext.Consumer>
  );
}
export default SchedulerDialogWithGuideContext;
