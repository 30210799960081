import pick from 'lodash/pick';
import qs from 'qs';

export const PAGE_SIZE = 50;
export const SEARCH_DEFAULTS = {
  page: 1
};

export function parseSearch(str) {
  const search = qs.parse(str, { ignoreQueryPrefix: true });
  return {
    ...SEARCH_DEFAULTS,
    ...pick(search, ['page'])
  };
}

export function locationToVariables(location) {
  const search = parseSearch(location.search);

  const page = parseInt(search.page, 10);

  const first = PAGE_SIZE;
  const skip = page > 0 ? (page - 1) * PAGE_SIZE : 0;

  return {
    first,
    skip
  };
}
