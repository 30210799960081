import PropTypes from 'prop-types';
import React, { Component } from 'react';
import WifiIndicator, { SignalStrength } from 'react-wifi-indicator';

import {
  WifiDisconnected,
  WifiExcellent,
  WifiGreat,
  WifiOkay,
  WifiUnusable,
  WifiWeak
} from '../../../assets';

const NETWORK_QUALITY_MAP = {
  0: SignalStrength.DISCONNECTED,
  1: SignalStrength.UNUSABLE,
  2: SignalStrength.WEAK,
  3: SignalStrength.OKAY,
  4: SignalStrength.GREAT,
  5: SignalStrength.EXCELLENT
};

class NetworkQualityIndicator extends Component {
  static propTypes = {
    networkQualityLevel: PropTypes.number
  };

  render() {
    const { networkQualityLevel } = this.props;

    return (
      <WifiIndicator
        className="network-quality-indicator"
        strength={NETWORK_QUALITY_MAP[networkQualityLevel]}
        statusImages={{
          EXCELLENT: WifiExcellent,
          GREAT: WifiGreat,
          OKAY: WifiOkay,
          WEAK: WifiWeak,
          UNUSABLE: WifiUnusable,
          DISCONNECTED: WifiDisconnected
        }}
      />
    );
  }
}
export default NetworkQualityIndicator;
