import { AvatarSilhouette } from './assets/index';

export const WEBSITE_URL = 'https://lifeguides.com';

export const LIFEGUIDES_COMMUNITY_URL = 'https://community.lifeguides.com';
export const LIFEGUIDES_RESOURCES_URL = 'https://my.lifeguides.com/resources/';
export const BECOME_GUIDE_URL = 'https://lifeguides.com/become-a-guide';
export const CONCIERGE_PHONE_NUMBER = '1-877-532-3472';
export const ACCESS_CODE_KEY = 'access_code';

export const AUTH_0_DOMAIN = 'lifeguides.auth0.com';
export const AUTH_TOKEN_NAME = 'lifeguides-auth';

export const BUGSNAG_API_KEY = 'a798d4b9c26e5461ded1df76ddddeaef';

export const CLIENT_INVITE_KEY = 'invite_id';
export const REDIRECT_URL_KEY = 'redirect-url';
export const SAVED_GUIDES_KEY = 'saved-guides';

export const STRIPE_DEFAULT_PRICE_IDS = process.env.REACT_APP_STRIPE_DEFAULT_PRICE_IDS.split(
  ','
);

export const REACT_APP_GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL;
export const REACT_APP_AUTH_0_CLIENT_ID =
  process.env.REACT_APP_AUTH_0_CLIENT_ID;

const makeTokenExchangeUrl = (root) => {
  let _root = root;
  if (_root.charAt(_root.length - 1) !== '/') {
    _root = `${_root}/`;
  }
  return `${_root}tokens/exchange`;
};
export const REACT_APP_TOKEN_EXCHANGE_URL = makeTokenExchangeUrl(
  REACT_APP_GRAPHQL_URL
);

export const INTERCOM = {
  app_id: process.env.REACT_APP_INTERCOM_APP_ID,
  include_in_development: true,
  delay_timeout: 0
};

export const AVAILABILITY_DAYS = {
  WEEKDAYS: 'WEEKDAYS',
  WEEKENDS: 'WEEKENDS'
};
export const AVAILABILITY_TIMES = {
  MORNINGS: 'MORNINGS',
  AFTERNOONS: 'AFTERNOONS',
  EVENINGS: 'EVENINGS'
};

export const ROLES = {
  ADMIN: 'ADMIN',
  BANNED: 'BANNED',
  CLIENT: 'CLIENT',
  CONCIERGE: 'CONCIERGE',
  CORP_ADMIN: 'CORP_ADMIN',
  GUEST: 'GUEST',
  GUIDE: 'GUIDE',
  GUIDE_ADMIN: 'GUIDE_ADMIN',
  GUIDE_CONTENT_MANAGER: 'GUIDE_CONTENT_MANAGER',
  TESTER: 'TESTER'
};

export const ROLE_LABELS = {
  [ROLES.BANNED]: 'Banned User',
  [ROLES.ADMIN]: 'Administrator',
  [ROLES.CLIENT]: 'Member',
  [ROLES.CONCIERGE]: 'Concierge',
  [ROLES.CORP_ADMIN]: 'Corporate Administrator',
  [ROLES.GUEST]: 'Guest User',
  [ROLES.GUIDE]: 'LifeGuide',
  [ROLES.GUIDE_ADMIN]: 'Guide Administrator',
  [ROLES.GUIDE_CONTENT_MANAGER]: 'Guide Content Manager',
  [ROLES.TESTER]: 'Devleoper/Tester'
};

export const DEFAULT_AVATAR_URL = AvatarSilhouette;

/*Social Media*/
export const TWITTER_ACCOUNT_URL = 'https://twitter.com/LifeGuidesNow';
export const FACEBOOK_ACCOUNT_URL = 'https://www.facebook.com/LifeGuidesNow/';

/*Trackers*/
export const GOOGLE_TAG_MANAGER_ID = 'GTM-K5TQF4T';
