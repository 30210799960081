import { createBrowserHistory } from 'history';
import moment from 'moment-timezone';

const KEY = 'breadcrumbs';
const MAX_SIZE = 25;

const history = createBrowserHistory();

function replacer(key, value) {
  if (key === 'timestamp') {
    return moment(value).format();
  }
  return value;
}

function reviver(key, value) {
  if (key === 'timestamp') {
    return moment(value).toDate();
  }
  return value;
}

const str = localStorage.getItem(KEY);
const stack = str ? JSON.parse(atob(str), reviver) : [];

function push({ hash, pathname, search }, action) {
  stack.push({
    ...(action ? { action } : null),
    hash,
    pathname,
    search,
    timestamp: new Date()
  });
  while (stack.length > MAX_SIZE) {
    stack.shift();
  }
  localStorage.setItem(KEY, btoa(JSON.stringify(stack, replacer)));
}

push(history.location);
history.listen(push);

export function clearBreadcrumbs() {
  stack.splice(0, stack.length);
  localStorage.removeItem(KEY);
}

export function breadcrumbs() {
  return [...stack];
}

export default history;
