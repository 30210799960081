import * as React from 'react';
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={49}
    height={41}
    fill="none"
    {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#6EA3D4"
        d="M21.28 29.726C21.28 35.77 16.827 41 10.722 41 3.466 41 0 34.467 0 27.277 0 15.847 7.59 2.937 21.445 0l1.157 3.92c-6.927 2.939-11.386 8.657-11.386 15.521 5.94 0 10.064 4.41 10.064 10.291v-.006Zm26.398 0C47.678 35.77 43.225 41 37.12 41c-7.256 0-10.722-6.533-10.722-13.723 0-11.437 7.59-24.34 21.445-27.277L49 3.92c-6.927 2.939-11.386 8.657-11.386 15.521 5.94 0 10.064 4.41 10.064 10.291v-.006Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h49v41H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
