import { graphql } from '@apollo/client/react/hoc';
import debounce from 'lodash/debounce';
import upperFirst from 'lodash/upperFirst';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import UserByIdQuery from '../../../graphql/queries/user-by-id.graphql';
import Avatar from '../../ui/avatar';
import { ref as HeaderRef } from '../app-header';
import NetworkQualityIndicator from './network-quality-indicator';
import VolumeIndicator from './volume-indicator';

const CAN_SET_SINK_ID = 'setSinkId' in HTMLMediaElement.prototype;

const EVENT_NAMES = [
  'networkQualityLevelChanged',
  'trackDimensionsChanged',
  'trackDisabled',
  'trackEnabled',
  'trackMessage',
  'trackPublished',
  'trackPublishPriorityChanged',
  'trackStarted',
  'trackSubscribed',
  'trackSubscriptionFailed',
  'trackSwitchedOff',
  'trackSwitchedOn',
  'trackUnpublished',
  'trackUnsubscribed'
];

@graphql(UserByIdQuery, {
  name: 'user',
  options: ({ participant: { identity } }) => ({ variables: { id: identity } })
})
class Participant extends Component {
  static propTypes = {
    participant: PropTypes.shape({
      off: PropTypes.func,
      on: PropTypes.func,
      tracks: PropTypes.arrayOf(
        PropTypes.shape({
          track: PropTypes.object
        })
      )
    }).isRequired,
    sinkId: PropTypes.string,
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      user: PropTypes.shape({
        id: PropTypes.string,
        avatarUrl: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string
      })
    }).isRequired
  };

  constructor(props) {
    super(props);

    this._ref = React.createRef();

    this.state = {
      networkQualityLevel: 0,
      networkQualityStats: null,
      width: 0
    };
  }

  componentDidMount() {
    this._enableParticipantListeners(true);

    window.addEventListener('resize', this._onWindowResize);
    this._onWindowResize();
  }

  componentDidUpdate(prevProps) {
    const { sinkId } = this.props;

    if (sinkId !== prevProps.sinkId) {
      this._updateTrackSinkIds();
    }
  }

  componentWillUnmount() {
    this._enableParticipantListeners(false);

    window.removeEventListener('resize', this._onWindowResize);
  }

  _enableParticipantListeners(enabled) {
    const { participant } = this.props;

    EVENT_NAMES.forEach((eventName) => {
      const handlerName = `_on${upperFirst(eventName)}`;
      const handler = this[handlerName];
      if (!handler) {
        return;
      }
      const func = enabled ? participant.on : participant.off;
      func.call(participant, eventName, handler);
    });
  }

  _updateTrackSinkIds() {
    const { sinkId } = this.props;

    if (!CAN_SET_SINK_ID) {
      return;
    }

    const elements = this._ref.current.querySelectorAll('.tracks audio');
    elements.forEach((element) => {
      element.setSinkId(sinkId);
    });
  }

  _onWindowResize = debounce(() => {
    const elements = this._ref.current.querySelectorAll('.tracks video');
    elements.forEach((element) => {
      this._setVideoHeight(element);
    });

    const width = this._ref.current.getBoundingClientRect().width;
    if (width === this.state.width) {
      return;
    }

    this.setState({ width });
  }, 250);

  _setVideoHeight(element) {
    const bodyHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const maxHeight =
      bodyHeight - HeaderRef.current.getBoundingClientRect().height;

    const aspectRatio = element.videoHeight / element.videoWidth;
    const width = this._ref.current.getBoundingClientRect().width;
    const height = Math.round(width * aspectRatio);

    element.style.height = `${height}px`;
    element.style.maxHeight = `${maxHeight}px`;
  }

  _onNetworkQualityLevelChanged = (
    networkQualityLevel,
    networkQualityStats
  ) => {
    this.setState({ networkQualityLevel, networkQualityStats });
  };

  _onTrackEnabled = (track) => {
    this.forceUpdate();
  };

  _onTrackDisabled = (track) => {
    this.forceUpdate();
  };

  _onTrackSubscribed = (track, publication) => {
    const { sinkId } = this.props;

    const element = track.attach();

    if (track.kind === 'audio' && sinkId && CAN_SET_SINK_ID) {
      element.setSinkId(sinkId);
    }
    if (track.kind === 'video') {
      element.addEventListener('loadedmetadata', () => {
        this._setVideoHeight(element);
      });
    }

    this._ref.current.appendChild(element);

    this.forceUpdate();
  };

  _onTrackUnsubscribed = (track, publication) => {
    track.detach().forEach((element) => {
      element.remove();
    });
  };

  render() {
    const { participant } = this.props;
    const { user } = this.props.user;
    const { networkQualityLevel, networkQualityStats } = this.state;

    const publications = [...participant.tracks.values()];

    const isVideoEnabled = publications.some(
      (publication) =>
        publication.kind === 'video' &&
        publication.isSubscribed &&
        publication.isTrackEnabled
    );

    const [audioTrack] = publications
      .filter((publication) => publication.kind === 'audio')
      .map((publication) => publication.track)
      .filter((track) => !!track);

    const classNames = ['participant'];
    if (isVideoEnabled) {
      classNames.push('has-video');
    }

    return (
      <div className={classNames.join(' ')}>
        <div ref={this._ref} className="tracks"></div>
        {user && <Avatar user={user} />}
        {audioTrack && <VolumeIndicator track={audioTrack.mediaStreamTrack} />}
        <NetworkQualityIndicator
          networkQualityLevel={networkQualityLevel}
          networkQualityStats={networkQualityStats}
        />
        {user && (
          <div className="user">
            {user?.firstName} {user?.lastName?.[0]?.concat('.') || ''}
          </div>
        )}
      </div>
    );
  }
}
export default Participant;
