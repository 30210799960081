import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Button, Icon, Pagination, Table } from 'semantic-ui-react';

import TopicGroupsQuery from '../../../../graphql/queries/topic-groups.graphql';
import TopicsPaginationQuery from '../../../../graphql/queries/topics-pagination.graphql';
import graphql from '../../../hoc/graphql';
import FullscreenLoader from '../../../ui/fullscreen-loader';
import { PAGE_SIZE, locationToVariables, parseSearch } from './params';

@withRouter
@graphql(TopicGroupsQuery, { name: 'groups' })
@graphql(TopicsPaginationQuery, {
  name: 'topics',
  options: ({ location }) => ({
    fetchPolicy: 'cache-and-network',
    variables: locationToVariables(location)
  })
})
class AdminTopicsList extends Component {
  static propTypes = {
    groups: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      topicGroups: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string
        })
      )
    }).isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    onPageChange: PropTypes.func.isRequired,
    topics: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      topics: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string
        })
      ),
      topicsConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      })
    }).isRequired
  };

  render() {
    const { loading } = this.props.topics;

    if (loading) {
      return <FullscreenLoader />;
    }

    return <div>{this._renderTopics()}</div>;
  }

  _renderTopics() {
    const { topics } = this.props.topics;

    return (
      <>
        <Table className="admin-topics-list" striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Groups</Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>{topics.map(this._renderTopic)}</Table.Body>
        </Table>
        {this._renderPagination()}
      </>
    );
  }

  _renderTopic = (topic) => {
    const { history } = this.props;
    const { topicGroups } = this.props.groups;

    const groups = topic.groups
      .map(({ id }) =>
        topicGroups ? topicGroups.find((g) => g.id === id) : null
      )
      .filter((g) => !!g)
      .map((group) => group.title);

    return (
      <Table.Row key={`topic-${topic.id}`} className="topic">
        <Table.Cell>{topic.title}</Table.Cell>
        <Table.Cell>{groups.join(', ')}</Table.Cell>
        <Table.Cell textAlign="right">
          <Link
            to={{
              pathname: '/guides',
              search: `topicId[]=${topic.id}`
            }}>
            View Guides
          </Link>
          {/* <Button
            onClick={() => {
              history.push(
                this._createUrl('/dialog/guides', { id: topic.id })
              );
            }}>
            <Icon name="users" className="lineawesome" />
            View Guides
          </Button> */}
        </Table.Cell>
        <Table.Cell textAlign="right">
          <Button
            onClick={() => {
              history.push(this._createUrl('/dialog/edit', { id: topic.id }));
            }}>
            <Icon name="edit" />
            Edit Topic
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  };

  _renderPagination() {
    const { location, onPageChange } = this.props;
    const { topicsConnection } = this.props.topics;

    const search = parseSearch(location.search);

    const count = topicsConnection ? topicsConnection.aggregate.count : 0;
    const totalPages = Math.ceil(count / PAGE_SIZE);

    return (
      <Pagination
        activePage={search.page}
        ellipsisItem={{
          content: <Icon name="ellipsis horizontal" className="lineawesome" />,
          icon: true
        }}
        firstItem={null}
        lastItem={null}
        prevItem={
          count > PAGE_SIZE
            ? {
                content: <Icon name="angle left" className="lineawesome" />,
                icon: true
              }
            : null
        }
        nextItem={
          count > PAGE_SIZE
            ? {
                content: <Icon name="angle right" className="lineawesome" />,
                icon: true
              }
            : null
        }
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    );
  }

  _createUrl(path, params) {
    const { match } = this.props;

    const returnUrl = `${location.pathname}${location.search}`;
    const paramsStr = qs.stringify(params);

    return `${match.url}${path}${location.search}${
      location.search ? '&' : '?'
    }${paramsStr}&returnUrl=${encodeURIComponent(returnUrl)}`;
  }
}
export default AdminTopicsList;
