import { Button, Input } from '@windmill/react-ui';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import { useLocation } from 'react-router-dom';

import RichTextEditor from '../../../ui/rich-text-editor';
import { useEditing } from './actions';

function GuideProfileTopicDescriptions(props) {
  const { data, onAdd, onChange, onRemove } = props;

  const [isEditing] = useEditing();

  const location = useLocation();
  const search = qs.parse(location.search, {
    arrayLimit: 512,
    ignoreQueryPrefix: true
  });

  const topicDescriptions = [...get(data, 'topics', [])];
  topicDescriptions.sort((a, b) => {
    const ai = search.topicId ? search.topicId.indexOf(a.topic.id) : -1;
    const bi = search.topicId ? search.topicId.indexOf(b.topic.id) : -1;
    return bi - ai;
  });

  if (!topicDescriptions.length && !isEditing) {
    return null;
  }

  function renderTopicDescription({ long, short, topic }, i) {
    const [group] = topic.groups;

    const isMatch = search.topicId && search.topicId.includes(topic.id);

    return (
      <div
        key={topic.id}
        className="flex flex-col items-center md:items-start md:flex-row md:space-x-6">
        {
          <img
            src={
              group?.imageUrl ||
              `${process.env.PUBLIC_URL}/topic_no-category.png`
            }
            className="w-16 h-16"
          />
        }
        <div className="flex flex-col space-y-6">
          <div className="h-16 flex flex-row space-x-4 items-center">
            <h4 className="text-gray-500 text-2xl m-auto md:m-0">
              {topic.title}
            </h4>
            {isMatch && (
              <span className="py-0.5 px-6 rounded text-sm bg-gray-300 text-blue-600">
                Match
              </span>
            )}
            {isEditing && (
              <i
                className="icon times circlular big red inverted cursor-pointer"
                onClick={() => onRemove(topic)}
              />
            )}
          </div>
          {isEditing ? (
            <Input
              className="text-3xl font-bold border"
              value={short}
              onChange={({ target: { value } }) => {
                onChange({ long, short: value, topic });
              }}
            />
          ) : (
            <p className="pl-4 border-gray-300 border-l-4 text-lg sm:text-xl">
              {short}
            </p>
          )}
          {isEditing ? (
            <div>
              <RichTextEditor
                format="html"
                value={long}
                onChange={(value) => {
                  onChange({
                    // when text area is empty, value is set to '<p><br></p>'
                    long: value === '<p><br></p>' ? '' : value,
                    short,
                    topic
                  });
                }}
              />
            </div>
          ) : long ? (
            <p
              className="text-lg leading-8 text-gray-500"
              dangerouslySetInnerHTML={{ __html: long }}
            />
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl p-8">
      <h3 className="text-xl font-bold">My Relevant Experience</h3>
      <div className="mt-16 flex flex-col space-y-16">
        {isEditing && (
          <Button
            layout="outline"
            iconLeft={() => <i className="icon add" />}
            onClick={onAdd}>
            Add Topic
          </Button>
        )}
        {topicDescriptions.map(renderTopicDescription)}
      </div>
    </div>
  );
}
GuideProfileTopicDescriptions.propTypes = {
  data: PropTypes.shape({
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        long: PropTypes.string,
        short: PropTypes.string,
        topic: PropTypes.shape({
          id: PropTypes.string,
          groups: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              imageUrl: PropTypes.string
            })
          ),
          title: PropTypes.string
        })
      })
    ).isRequired
  }).isRequired,
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};
export default GuideProfileTopicDescriptions;
