import capitalize from 'lodash/capitalize';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Container,
  Dimmer,
  Icon,
  Loader,
  Pagination,
  Segment,
  Table
} from 'semantic-ui-react';

import { ROLES } from '../../../../consts';
import UserImportsPaginationQuery from '../../../../graphql/queries/user-imports-pagination.graphql';
import graphql from '../../../hoc/graphql';
import withUser from '../../../hoc/with-user';
import { PAGE_SIZE, SORT_DIRECTIONS, parseSearch } from './params';

const COLUMNS = [
  {
    name: 'id',
    title: 'ID',
    sortable: false
  },
  {
    name: 'status',
    title: 'Status',
    sortable: true
  },
  {
    name: 'createdAt',
    title: 'Created',
    sortable: true
  }
];

function searchToVariables(search) {
  const skip = search.page > 0 ? (search.page - 1) * PAGE_SIZE : 0;
  const first = PAGE_SIZE;
  const orderBy = `${search.sort}_${search.direction}`;

  return {
    first,
    skip,
    orderBy
  };
}

@withRouter
@graphql(UserImportsPaginationQuery, {
  name: 'userImports',
  options: (props) => {
    const { location } = props;

    const search = parseSearch(location.search);
    const variables = searchToVariables(search);

    return { variables };
  }
})
@withUser({ authenticated: [ROLES.ADMIN] })
class UserImports extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    userImports: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      userImports: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          createdAt: PropTypes.string
        })
      ),
      userImportsConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      })
    }).isRequired
  };

  state = {
    loading: false
  };

  componentDidUpdate(prevProps) {
    const { userImports, location } = this.props;

    const search = parseSearch(location.search);
    const prevSearch = parseSearch(prevProps.location.search);

    if (
      search.page !== prevSearch.page ||
      search.sort !== prevSearch.sort ||
      search.direction !== prevSearch.direction ||
      search.status !== prevSearch.status
    ) {
      this.setState({ loading: true });
      const variables = searchToVariables(search);
      userImports.refetch({ ...variables }).then(() => {
        this.setState({ loading: false });
      });
    }
  }

  render() {
    const { loading: queryLoading, userImports } = this.props.userImports;
    const { loading: stateLoading } = this.state;

    const loading = queryLoading || stateLoading;

    return (
      <Dimmer.Dimmable
        className="admin-user-imports"
        as={Segment}
        basic
        blurring
        dimmed={loading}>
        <Dimmer active={loading} blurring inverted>
          <Loader />
        </Dimmer>
        <Table>
          <Table.Header>
            <Table.Row>{COLUMNS.map(this._renderHeader)}</Table.Row>
          </Table.Header>
          <Table.Body>
            {userImports &&
              (userImports.length ? (
                userImports.map(this._renderUserImport)
              ) : (
                <p>No completed bulk imports to show</p>
              ))}
          </Table.Body>
        </Table>
        {this._renderPagination()}
      </Dimmer.Dimmable>
    );
  }

  _renderHeader = ({ name, sortable, title }) => {
    const { location } = this.props;

    const search = parseSearch(location.search);
    const direction =
      search.direction === SORT_DIRECTIONS.ASC ? 'ascending' : 'descending';

    return (
      <Table.HeaderCell
        key={`column-header-${name}`}
        style={{ cursor: sortable ? 'pointer' : 'auto' }}
        sorted={search.sort === name ? direction : null}
        onClick={() => {
          if (sortable) {
            this._onSort(name);
          }
        }}>
        {title}
      </Table.HeaderCell>
    );
  };

  _renderUserImport = (userImport) => {
    const { id, createdAt, status } = userImport;

    return (
      <Table.Row key={`user-import-${id}`}>
        <Table.Cell>
          <Link
            to={{
              pathname: `/admin/users/import/${id}`,
              search: ''
            }}>
            {id}
          </Link>
        </Table.Cell>
        <Table.Cell>{capitalize(status)}</Table.Cell>
        <Table.Cell>{moment(createdAt).fromNow()}</Table.Cell>
      </Table.Row>
    );
  };

  _renderPagination() {
    const { location } = this.props;
    const { userImportsConnection, loading } = this.props.userImports;

    if (loading) {
      return null;
    }

    const search = parseSearch(location.search);

    const count = userImportsConnection.aggregate.count;
    const totalPages = Math.ceil(count / PAGE_SIZE);

    return (
      <Container textAlign="center">
        <Pagination
          activePage={search.page}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true
          }}
          firstItem={null}
          lastItem={null}
          prevItem={
            count > PAGE_SIZE
              ? { content: <Icon name="angle left lineawesome" />, icon: true }
              : null
          }
          nextItem={
            count > PAGE_SIZE
              ? { content: <Icon name="angle right lineawesome" />, icon: true }
              : null
          }
          onPageChange={this._onPageChange}
          totalPages={totalPages}
        />
      </Container>
    );
  }

  _onSort = (name) => {
    const { location } = this.props;

    const search = parseSearch(location.search);

    const direction =
      search.sort === name
        ? search.direction === SORT_DIRECTIONS.ASC
          ? SORT_DIRECTIONS.DESC
          : SORT_DIRECTIONS.ASC
        : SORT_DIRECTIONS.ASC;

    this._search({
      sort: name,
      direction
    });
  };

  _onPageChange = (e, { activePage }) => {
    this._search({
      page: activePage
    });
  };

  _search(params) {
    const { history, location } = this.props;

    const search = parseSearch(location.search);
    const updated = {
      ...search,
      ...params
    };

    const url = `${location.pathname}?${qs.stringify(updated)}`;
    history.push(url);
  }
}
export default UserImports;
