import PropTypes from 'prop-types';
import React from 'react';

function MyCheckbox(props) {
  const { as, className, isChecked, label, onClick } = props;

  const baseClasses = 'flex whitespace-nowrap space-x-3 items-center cursor-pointer focus:outline-none'.split(
    ' '
  );
  const classNames = className ? className.split(' ') : [];
  const uniqueClassNames = Array.from(new Set(baseClasses.concat(classNames)));

  const iconClasses = ['icon square large bg-white'];
  if (isChecked) {
    iconClasses.push('check text-primary');
  } else {
    iconClasses.push('outline text-gray-400');
  }

  const Component = as || 'button';

  return (
    <Component className={uniqueClassNames.join(' ')} onClick={onClick}>
      <i
        className={iconClasses.join(' ')}
        style={{ margin: 0, fontSize: '1.25rem' }}
      />
      {label && <label className="cursor-pointer">{label}</label>}
    </Component>
  );
}
MyCheckbox.propTypes = {
  as: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  label: PropTypes.node,
  onClick: PropTypes.func
};
export default MyCheckbox;
