import './roadmaps-item.css';

import marked from 'marked';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Image } from 'semantic-ui-react';

@withRouter
class RoadmapsItem extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    roadmap: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  render() {
    const { roadmap, url, history } = this.props;
    const body = marked(roadmap.body);

    return (
      <Card
        key={`roadmap-${roadmap.id}`}
        onClick={() => {
          history.push(url);
        }}
        className={`roadmap-item`}>
        {roadmap.featuredImage && (
          <Image src={roadmap.featuredImage.file.url} />
        )}
        <Card.Content className="roadmap-content">
          <Card.Header className="roadmap-content-header">
            {roadmap.title}
          </Card.Header>
          <Card.Description className="roadmap-content-body">
            <div
              className="roadmap-content-body-preview"
              dangerouslySetInnerHTML={{ __html: body }}
            />
          </Card.Description>
        </Card.Content>
        <Card.Content extra className="roadmap-tags-container">
          <span className="roadmap-tags">
            {roadmap.tags.map((tag) => `${tag}`).join('; ')}
          </span>
        </Card.Content>
      </Card>
    );
  }
}

export default RoadmapsItem;
