import { intervalToDuration, formatDuration } from 'date-fns';

function zeroPad(num) {
  return String(num).padStart(2, '0');
}

export default function formatSeconds(seconds) {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  return formatDuration(duration, {
    format: ['hours', 'minutes', 'seconds'],
    zero: true,
    delimiter: ':',
    locale: {
      formatDistance: (_token, count) => zeroPad(count)
    }
  });
}
