import './session-list-item.css';

import { Button, Dropdown, DropdownItem } from '@windmill/react-ui';
import debounce from 'lodash/debounce';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ROLES } from '../../../consts';
import history from '../../../history';
import useUser from '../../hooks/use-user';
import Avatar from '../../ui/avatar';
import GuideContext from '../guide/guide-context';
import ScheduleSessionDialog from '../guide/profile-view/dialogs/scheduler';
import RescheduleSessionDialog from '../scheduler/reschedule-session-dialog';
import AddToCalendar from '../session/add-to-calendar';
import CancelSessionDialog from '../session/cancel-session-dialog';
import CompleteSessionDialog from '../session/complete-session-dialog';
import SendMessageDialog from '../session/send-message-dialog';
import SessionNotesDialog from '../session/session-notes-dialog';
import formatSeconds from '../../../utils/formatSeconds';

const ACTIONS = {
  BOOK_AGAIN: 'BOOK_AGAIN',
  CANCEL_SESSION: 'CANCEL_SESSION',
  COMPLETE_SESSION: 'COMPLETE_SESSION',
  RESCHEDULE: 'RESCHEDULE',
  SEND_MESSAGE: 'SEND_MESSAGE',
  SHOW_NOTES: 'SHOW_NOTES'
};

function SessionListItem(props) {
  const { session } = props;

  const [dropdown, setDropdown] = useState(null);
  const [dialog, setDialog] = useState(null);

  const location = useLocation();

  const { data: userData } = useUser();

  const User = userData.User;

  const isClient = User.roles.some((role) => role.name === ROLES.CLIENT);
  const isGuide = User.roles.some((role) => role.name === ROLES.GUIDE);
  const otherUser = [session.client, session.guide]
    .filter((x) => !!x)
    .find((user) => user.id !== User.id);
  const isGuideAvailable =
    isGuide || (isClient && otherUser.guideStatus === null);

  const [companyConnection] =
    (session.client && session.client.companyIds) || [];

  const Groups = [
    {
      name: 'UNAVAILABLE',
      text: "Can't make it?"
    }
  ];

  const Actions = [
    {
      name: ACTIONS.SEND_MESSAGE,
      text: 'Send Text'
    },
    {
      name: ACTIONS.CANCEL_SESSION,
      group: 'UNAVAILABLE',
      text: 'Cancel Session'
    },
    {
      name: ACTIONS.COMPLETE_SESSION,
      text: 'Complete Session'
    },
    {
      name: ACTIONS.RESCHEDULE,
      group: 'UNAVAILABLE',
      text: 'Reschedule'
    },
    {
      name: ACTIONS.SHOW_NOTES,
      text: 'Show Notes'
    },
    {
      name: ACTIONS.BOOK_AGAIN,
      onClick: () => {
        history.replace({
          pathname: location.pathname,
          search: qs.stringify({
            guideId: otherUser.id,
            topicId: session.topics.map(({ id }) => id)
          })
        });
        setDialog(ACTIONS.BOOK_AGAIN);
      },
      text: 'Book Another Call'
    }
  ];

  function onCloseDialog() {
    setDialog(null);
  }

  const toggleDropdown = debounce((name) => {
    setDropdown(name === dropdown ? null : name);
  }, 50);

  function inPreferedTimezone(str, formatter) {
    const timezone = User.timezone || moment.tz.guess();
    const dateTime = moment(str).tz(timezone);
    if (formatter) {
      return formatter(dateTime);
    }
    return dateTime;
  }

  function renderStatus() {
    const formats = {
      date: 'MMMM D',
      time: 'h:mma z'
    };

    const f = (str, format) => inPreferedTimezone(str).format(formats[format]);

    const { createdAt, logs, scheduledTime, startedAt, duration } = session;
    const cancelledLog = logs && logs.find((log) => log.status === 'CANCELLED');
    const completedLog = logs && logs.find((log) => log.status === 'COMPLETED');

    switch (session.status) {
      case 'UPCOMING':
        return `SCHEDULED session for ${f(scheduledTime, 'time')} on ${f(
          scheduledTime,
          'date'
        )}`;
      case 'REQUESTING':
        return `REQUESTING on demand session at ${f(createdAt, 'time')} ${f(
          createdAt,
          'date'
        )}`;
      case 'CONNECTING':
        return `CONNECTING on demand session`;
      case 'CANCELLED':
        return (
          <>
            CANCELLED session{' '}
            {cancelledLog
              ? `at ${f(cancelledLog.createdAt, 'time')} on ${f(
                  cancelledLog.createdAt,
                  'date'
                )}`
              : ''}
            {scheduledTime && (
              <span className="note">
                Scheduled for {f(scheduledTime, 'time')} on{' '}
                {f(scheduledTime, 'date')}
              </span>
            )}
          </>
        );
      case 'COMPLETED':
        return (
          <>
            COMPLETED session{' '}
            {completedLog &&
              `at ${f(completedLog.createdAt, 'time')} on ${f(
                completedLog.createdAt,
                'date'
              )}`}
            <span>
              Call Duration: {duration ? formatSeconds(duration) : 'N/A'}
            </span>
            {scheduledTime && (
              <span className="note">
                Scheduled for {f(scheduledTime, 'time')} on{' '}
                {f(scheduledTime, 'date')}
              </span>
            )}
          </>
        );
      case 'MISSED':
        return `MISSED on demand session at ${f(createdAt, 'time')} on ${f(
          createdAt,
          'date'
        )}`;
      case 'IN_PROGRESS':
        return (
          <>
            STARTED session{' '}
            {startedAt &&
              `at ${f(startedAt, 'time')} on ${f(startedAt, 'date')}`}
            {scheduledTime && (
              <span className="note">
                Scheduled for {f(scheduledTime, 'time')} on{' '}
                {f(scheduledTime, 'date')}
              </span>
            )}
          </>
        );
    }
  }

  function shouldShowAction(dialog) {
    switch (dialog) {
      case ACTIONS.BOOK_AGAIN:
        return isClient && session.status === 'COMPLETED' && isGuideAvailable;
      case ACTIONS.CANCEL_SESSION:
        return [
          'CONNECTING',
          'REQUESTING',
          ...(isClient ? ['UPCOMING'] : [])
        ].includes(session.status);
      case ACTIONS.COMPLETE_SESSION:
        return isGuide && session.status === 'IN_PROGRESS';
      case ACTIONS.RESCHEDULE:
        return isClient && session.status === 'UPCOMING';
      case ACTIONS.SEND_MESSAGE:
        return [
          'CANCELLED',
          'COMPLETED',
          'CONNECTING',
          'ERRORED',
          'IN_PROGRESS',
          'REQUESTING',
          'MISSED',
          'UPCOMING'
        ].includes(session.status);
      case ACTIONS.SHOW_NOTES:
        return isGuide && session.status === 'COMPLETED';
    }
  }

  function renderActions() {
    const actions = Actions.filter(({ name }) => shouldShowAction(name));
    const groups = actions.reduce((acc, action) => {
      if (action.group) {
        const group = acc.find((g) => g.every((a) => a.group === action.group));
        if (group) {
          group.push(action);
          return acc;
        } else {
          return [...acc, [action]];
        }
      } else {
        return [...acc, [action]];
      }
    }, []);

    function renderGroupActions(actions) {
      const [action] = actions;
      if (!action) {
        return null;
      }
      if (actions.length === 1) {
        return (
          <a
            href="#"
            className="session-action"
            onClick={(event) => {
              event.preventDefault();
              if (action.onClick) {
                action.onClick();
              } else {
                setDialog(action.name);
              }
            }}>
            {action.text}
          </a>
        );
      } else {
        const group = Groups.find((group) => group.name === actions[0].group);
        return (
          <span className="relative">
            <a
              href="#"
              className="session-action"
              onClick={(event) => {
                event.preventDefault();
                toggleDropdown(group.name);
              }}>
              {group.text}
            </a>
            <Dropdown
              align="right"
              className="bottom-0"
              isOpen={dropdown === group.name}
              onClose={() => setDropdown(null)}>
              {actions.map((action) => (
                <DropdownItem
                  key={action.name}
                  tag="a"
                  href="#"
                  className="justify-between"
                  onClick={() => {
                    event.preventDefault();
                    if (action.onClick) {
                      action.onClick();
                    } else {
                      setDialog(action.name);
                    }
                  }}>
                  <span>{action.text}</span>
                </DropdownItem>
              ))}
            </Dropdown>
          </span>
        );
      }
    }

    return groups.map((actions, i) => (
      <React.Fragment key={i}>
        {i > 0 && <>&nbsp;|&nbsp;</>}
        {renderGroupActions(actions)}
      </React.Fragment>
    ));
  }

  function renderDialogs() {
    const props = { isOpen: true, open: true, session, onClose: onCloseDialog };

    switch (dialog) {
      case ACTIONS.BOOK_AGAIN:
        return (
          <GuideContext.Provider value={otherUser}>
            <ScheduleSessionDialog {...props} onSubmit={onCloseDialog} />
          </GuideContext.Provider>
        );
      case ACTIONS.CANCEL_SESSION:
        return <CancelSessionDialog {...props} />;
      case ACTIONS.COMPLETE_SESSION:
        return <CompleteSessionDialog {...props} />;
      case ACTIONS.RESCHEDULE:
        return (
          <GuideContext.Provider value={otherUser}>
            <RescheduleSessionDialog
              {...props}
              session={session}
              onSubmit={onCloseDialog}
            />
          </GuideContext.Provider>
        );
      case ACTIONS.SEND_MESSAGE:
        return <SendMessageDialog {...props} />;
      case ACTIONS.SHOW_NOTES:
        return <SessionNotesDialog {...props} />;
    }
  }

  return (
    <>
      <div className="session-list-item">
        <span className="avatar-container">
          {otherUser && <Avatar user={otherUser} />}
        </span>
        <span className="info">
          <span className="user">
            {otherUser && (
              <span className="name">
                {otherUser?.firstName}{' '}
                {otherUser?.lastName?.[0]?.concat('.') || ''}
              </span>
            )}
            {isGuide && companyConnection && (
              <span className="company">{companyConnection.company.name}</span>
            )}
          </span>
          <span className="topics">
            {session.topics.map((topic) => (
              <span key={`topic-${topic.id}`}>{topic.title}</span>
            ))}
          </span>
          <span className="description">{renderStatus()}</span>
        </span>
        <span className="actions">
          {['UPCOMING', 'CONNECTING', 'IN_PROGRESS'].includes(
            session.status
          ) ? (
            <Button
              className="session-start-cta"
              onClick={() => {
                history.push({
                  pathname: `/session/${session.id}`,
                  search: ''
                });
              }}>
              Get Started
            </Button>
          ) : (
            <div />
          )}
          {session.status === 'UPCOMING' && <AddToCalendar session={session} />}
          <span>{renderActions()}</span>
        </span>
      </div>
      {renderDialogs()}
    </>
  );
}
SessionListItem.propTypes = {
  session: PropTypes.shape({
    id: PropTypes.string.isRequired,
    client: PropTypes.shape({
      id: PropTypes.string.isRequired,
      companyIds: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          company: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          })
        })
      )
    }),
    createdAt: PropTypes.string.isRequired,
    duration: PropTypes.number,
    guide: PropTypes.shape({
      id: PropTypes.string.isRequired
    }),
    logs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        createdAt: PropTypes.string,
        status: PropTypes.string
      })
    ),
    scheduledTime: PropTypes.string,
    startedAt: PropTypes.string,
    status: PropTypes.string.isRequired,
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired
};
export default SessionListItem;
