import qs from 'qs';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import * as auth from '../../../auth';
import * as auth0 from '../../../auth0';
import LoadingSpinner from '../../ui/loading-spinner/index';

export default function Logout() {
  const location = useLocation();

  useEffect(
    function () {
      const search = qs.parse(location.search, { ignoreQueryPrefix: true });
      if (search.complete) {
        // returning from auth0
        auth.logout();
      } else {
        auth0.logout();
      }
    },
    [location]
  );

  return <LoadingSpinner className="my-8 mx-auto w-48 h-48" />;
}
