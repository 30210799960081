import '../../../../ui/powerful-cta/index.css';

import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';

import { ROLES } from '../../../../../consts';
import CreateSessionMutation from '../../../../../graphql/mutations/create-session.graphql';
import SessionsByStatusQuery from '../../../../../graphql/queries/sessions-by-status-pagination.graphql';
import graphql from '../../../../hoc/graphql';
import withUser from '../../../../hoc/with-user';
import ErrorDialog from '../../../../ui/error-dialog';
import { PAGE_SIZE, SEARCH_DEFAULTS } from '../params';
import CreateSessionForm from './index';

@graphql(CreateSessionMutation, {
  name: 'createSession',
  options: {
    refetchQueries: [
      {
        query: SessionsByStatusQuery,
        variables: {
          first: PAGE_SIZE,
          skip: 0,
          orderBy: `${SEARCH_DEFAULTS.sort}_${SEARCH_DEFAULTS.direction}`,
          status: 'UPCOMING'
        }
      }
    ]
  }
})
@withUser({ authenticated: [ROLES.ADMIN, ROLES.CONCIERGE, ROLES.GUIDE_ADMIN] })
class CreateDialog extends Component {
  static propTypes = {
    createSession: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string
      }).isRequired
    }).isRequired
  };

  state = {
    data: null,
    error: null,
    isValid: false,
    submitting: false
  };

  render() {
    const { error, isValid, submitting } = this.state;

    return (
      <Modal
        className="concierge-call-reqest-dialog"
        open
        size="large"
        closeOnDimmerClick={false}
        closeIcon={true}
        onClose={this._onClose}>
        <Modal.Header>Schedule A New Call</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <CreateSessionForm
              onDataChange={this._onDataChange}
              onValidate={this._onValidate}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="powerful"
            loading={submitting}
            disabled={!isValid || submitting}
            onClick={this._onSubmit}>
            Schedule Call
          </Button>
          <ErrorDialog
            error={error}
            onClose={() => {
              this.setState({ error: null });
            }}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  _onClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  _onDataChange = (data) => {
    this.setState({ data, isValid: this._validate(data) });
  };

  //TODO: change this to use the form system
  _validate(data) {
    const { clientId, guideId, selectedDateTime, topicIds } = data;

    if (!clientId || !guideId || !selectedDateTime || !topicIds.length) {
      return false;
    }

    return true;
  }

  _onValidate = (errors) => {
    this.setState({ isValid: isEmpty(errors) });
  };

  _onSubmit = () => {
    const { createSession, onSubmit } = this.props;
    const { User } = this.props.user;
    const { data } = this.state;

    const scheduledTime = moment
      .tz(data.selectedDateTime, data.selectedTimezone) //start with TZ you're on.
      .tz(data.selectedClientTimezone) //always convert to client TZ.
      .format();

    const variables = {
      ...data,
      userId: User.id,
      scheduledTime: scheduledTime,
      status: 'UPCOMING',
      topicConnections: data.topicIds.map((id) => ({ id }))
    };

    this.setState({ error: null, submitting: true });
    createSession({ variables })
      .then(({ data }) => {
        this.setState({ submitting: false });

        if (_.isFunction(onSubmit)) {
          onSubmit(data.bookSession);
        }
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
      });
  };
}
export default CreateDialog;
