import 'react-phone-input-2/lib/semantic-ui.css';

import './phone-number-input.css';

import PropTypes from 'prop-types';
import React from 'react';
import PhoneInput from 'react-phone-input-2';

function PhoneNumberInput(props) {
  const { name, onChange, ...remaining } = props;
  return (
    <PhoneInput
      onChange={(value) => {
        onChange({}, { name, value });
      }}
      country="us"
      preferredCountries={['us']}
      {...remaining}
    />
  );
}

PhoneNumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default PhoneNumberInput;
