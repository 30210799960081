import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { CONCIERGE_PHONE_NUMBER } from '../../../consts';
import PresentGuidesQuery from '../../../graphql/queries/present-users.graphql';
import UserToggledPresenceSubscription from '../../../graphql/subscriptions/user-toggled-presence.graphql';
import Avatar from '../../ui/avatar';
import qs from 'qs';

// const MOCK_PRESENT_USERS = [
//   {
//     __typename: 'User',
//     avatarUrl:
//       'https://s3.amazonaws.com/lifeguides.production/cl3dpjgaj00001iwlavdz8qa0',
//     firstName: 'Joey',
//     id: 'cl3bwtuxz059a075267h6g6xv',
//     isPresent: true,
//     lastName: 'Maraconney',
//     shortIntro: ''
//   }
// ];

export default function PresentGuides() {
  const { data, error, loading, subscribeToMore } = useQuery(
    PresentGuidesQuery,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  );

  useEffect(
    function () {
      if (!data) {
        return null;
      }

      try {
        const subscription = subscribeToMore({
          document: UserToggledPresenceSubscription,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData) {
              return prev;
            }
            const { node } = subscriptionData.data.userToggledPresence;

            const presentUsers = prev.presentUsers.slice();
            const index = presentUsers.findIndex((user) => user.id === node.id);

            if (node.isPresent && index < 0) {
              presentUsers.push(node);
            } else if (!node.isPresent && index >= 0) {
              presentUsers.splice(index, 1);
            }

            return {
              ...prev,
              presentUsers
            };
          }
        });

        return function cleanUp() {
          subscription();
        };
      } catch (error) {
        /* */
      }
    },
    [data, subscribeToMore]
  );

  // if (error) {
  //   return null;
  // }

  if (loading) {
    return null;
  }

  const presentUsers = (data && data.presentUsers) || [];

  //const presentUsers = MOCK_PRESENT_USERS;

  const CONCIERGE_TOPIC_PARAMS = process.env.REACT_APP_CONCIERGE_TOPICID
    ? qs.stringify({ topicId: [process.env.REACT_APP_CONCIERGE_TOPICID] })
    : '';

  return (
    <div className="flex flex-row justify-center w-full bg-blue-50">
      <div className="grid lg:grid-flow-col items-center w-full max-w-screen-xl px-4 my-16">
        <div className="p-4">
          <h2 className="my-2 text-5xl font-extrabold text-neutral text-center lg:text-left">
            Unsure Where to Start?
          </h2>
          <h3 className="text-xl text-center lg:text-left">
            Talk to a Wellbeing Concierge!
          </h3>
        </div>
        <div className="flex flex-col lg:flex-row lg:items-left justify-center lg:justify-left items-center gap-8 p-6">
          <div className="flex flex-row space-x-4">
            {(presentUsers || []).map((g) => (
              <PresentGuide key={`present-guide-${g.id}`} guide={g} />
            ))}
          </div>
          <div>
            <Link
              to={{
                pathname: '/guides',
                search: CONCIERGE_TOPIC_PARAMS,
                hash: ''
              }}>
              <span className="py-4 px-8 bg-white text-black rounded-full border border-solid border-gray-300 hover:border-gray-900 transition-all duration-500 whitespace-nowrap">
                Schedule Session
              </span>
            </Link>
          </div>
          <div>
            <span>
              or call{' '}
              <a
                className="text-bold cursor-pointer pl-2"
                href={`tel:${CONCIERGE_PHONE_NUMBER.split('-').join('')}`}>
                {CONCIERGE_PHONE_NUMBER}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function PresentGuide({ guide }) {
  const SIZE = 48;
  return (
    <Link
      key={guide.id}
      to={{ pathname: `/guide/${guide.id}`, search: '', hash: '' }}>
      <div className="p-0 cursor-pointer">
        <span className="flex flex-row items-center p-0 border-3xl bg-primary rounded-full border opacity-100 hover:opacity-90 transition-all duration-500 text-gray-200 hover:text-white">
          <Avatar user={guide} style={{ width: SIZE, height: SIZE }} />
          <span className="py-2.5 pr-8 text-lg">Talk Now</span>
        </span>
      </div>
    </Link>
  );
}
