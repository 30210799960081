import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

import CallMeMutation from '../../../graphql/mutations/call-me.graphql';
import graphql from '../../hoc/graphql';
import withUser from '../../hoc/with-user';
import ErrorDialog from '../../ui/error-dialog';
import Notification from '../../ui/notification';
import CallMeForm from './call-me-form';
import SessionContext from './session-context';

@withUser()
@graphql(CallMeMutation, { name: 'callMe' })
class CallMeDialog extends Component {
  static propTypes = {
    callMe: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    session: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string,
        phoneNumber: PropTypes.string
      })
    })
  };

  state = {
    data: {
      phoneNumber: ''
    },
    error: null,
    submitting: false
  };

  componentDidMount() {
    this._setData();
  }

  componentDidUpdate(prevProps) {
    const { User } = this.props.user;

    if (User && !prevProps.user.User) {
      this._setData();
    }
  }

  _setData() {
    const { User } = this.props.user;
    if (!User) {
      return;
    }

    const data = {
      phoneNumber: User.phoneNumber
    };

    this.setState({ data, submitting: false, success: false });
  }

  render() {
    const { callMe, onClose, session, user, ...remaining } = this.props;
    const { data, error, submitting, success } = this.state;

    return (
      <>
        <Modal size="mini" {...remaining}>
          <Modal.Header>Join Via phone</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <CallMeForm data={data} onChange={this._onChange} />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              disabled={submitting}
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}>
              Nevermind
            </Button>
            <Button
              primary
              disabled={submitting || success}
              loading={submitting || success}
              onClick={this._callMe}>
              Call Me
            </Button>
          </Modal.Actions>
        </Modal>
        <Notification
          open={success}
          onClose={() => {
            if (onClose) {
              onClose();
            }
          }}>
          <Icon name="check" color="green" />
          Call initiated
        </Notification>
        <ErrorDialog
          error={error}
          onClose={() => {
            this.setState({ error: null });
          }}
        />
      </>
    );
  }

  _callMe = () => {
    const { callMe, session } = this.props;
    const { data } = this.state;

    const variables = {
      sessionId: session.id,
      phoneNumber: data.phoneNumber
    };

    this.setState({ error: null, submitting: true });
    callMe({ variables })
      .then(() => {
        this.setState({
          submitting: false,
          success: true
        });
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
      });
  };

  _onChange = (data) => {
    this.setState({ data });
  };
}

function CallMeDialogWithContext(props) {
  return (
    <SessionContext.Consumer>
      {(session) => <CallMeDialog session={session} {...props} />}
    </SessionContext.Consumer>
  );
}

export default CallMeDialogWithContext;
