import './index.css';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Popup } from 'semantic-ui-react';

import { DEFAULT_AVATAR_URL } from '../../../consts';

export default class Avatar extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    user: PropTypes.shape({
      avatarUrl: PropTypes.string,
      firstName: PropTypes.string,
      isPresent: PropTypes.bool,
      lastName: PropTypes.string
    })
  };

  imageRef = React.createRef();

  state = {
    imageWidth: 0,
    appearClass: '',
    failed: null
  };

  componentDidMount() {
    if (this.imageRef.current) {
      const imageWidth = this.imageRef.current.clientWidth;
      this.setState(
        {
          imageWidth
        },
        () => {
          this.setState({
            appearClass: 'show'
          });
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      get(prevProps, 'user.avatarUrl') !== get(this.props, 'user.avatarUrl')
    ) {
      this.setState({ failed: null });
    }
  }

  fixBrokenImage = () => {
    this.setState({
      failed: true
    });
  };

  getImage = () => {
    const { user } = this.props;
    const { failed } = this.state;

    if (failed) {
      return DEFAULT_AVATAR_URL;
    }
    if (user && user.avatarUrl) {
      return user.avatarUrl;
    }
    return DEFAULT_AVATAR_URL;
  };

  render() {
    const { className, onClick, style, user, ...remaining } = this.props;
    const { imageWidth, appearClass } = this.state;

    const src = this.getImage();

    const alt = user ? `${user.firstName}'s Avatar` : 'Guide Avatar';
    const s = Math.floor(imageWidth / 7);

    const icon = user.isPresent ? (
      <div className={`light-holder ${appearClass}`}>
        <div
          className="light-anchor"
          style={{
            width: imageWidth,
            height: imageWidth,
            left: `calc(50% - ${imageWidth / 2}px)`
          }}>
          <Popup
            position="top center"
            trigger={
              <div
                className="featured"
                style={{
                  top: `-${s / 2}px`,
                  right: `calc(50% - ${s / 2}px`,
                  width: `${s}px`,
                  height: `${s}px`
                }}
              />
            }
            content={`${user.firstName} is currently online and available to talk right now!`}></Popup>
        </div>
      </div>
    ) : (
      <div className="light-holder">
        <div
          className="light-anchor"
          style={{
            width: imageWidth,
            height: imageWidth,
            left: `calc(50% - ${imageWidth / 2}px)`
          }}></div>
      </div>
    );

    const _style = style || {};

    return (
      <div className={`avi-frame ${className || ''}`}>
        {icon}
        <div
          className="ui avatar image"
          ref={this.imageRef}
          {...remaining}
          onClick={onClick ? onClick : null}
          style={{ ..._style, ...(onClick ? { cursor: 'pointer' } : null) }}>
          <img src={src} alt={alt} onError={this.fixBrokenImage} />
        </div>
      </div>
    );
  }
}
